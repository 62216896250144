export const getStringifiedList = (list: any[] | undefined): string | undefined => {
	if (!list || list.length === 0) {
		return undefined;
	}

	try {
		return JSON.stringify(list);
	} catch {
		return undefined;
	}
};
