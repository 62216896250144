export namespace Elearning {
	export const ELEARNING_SUBSCRIBE = "ELEARNING_SUBSCRIBE";
	export const ELEARNING_SUBSCRIBE_STARTED = "ELEARNING_SUBSCRIBE_STARTED";
	export const ELEARNING_SUBSCRIBE_FINISHED = "ELEARNING_SUBSCRIBE_FINISHED";
	export const ELEARNING_SUBSCRIBE_FINISHED_WITH_ERRORS = "ELEARNING_SUBSCRIBE_FINISHED_WITH_ERRORS";
	export const ELEARNING_SUBSCRIBE_RESET = "ELEARNING_SUBSCRIBE_RESET";

	interface ElearningSubscribeAction {
		type: typeof ELEARNING_SUBSCRIBE;
		payload: {
			elearningId: string;
			userId: string;
		};
	}

	interface ElearningSubscribeStartedAction {
		type: typeof ELEARNING_SUBSCRIBE_STARTED;
	}

	interface ElearningSubscribeFinishedAction {
		type: typeof ELEARNING_SUBSCRIBE_FINISHED;
	}

	interface ElearningSubscribeFinishedWithErrosAction {
		type: typeof ELEARNING_SUBSCRIBE_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface ElearningSubscribeResetAction {
		type: typeof ELEARNING_SUBSCRIBE_RESET;
	}

	export type ElearningActionTypes =
		| ElearningSubscribeAction
		| ElearningSubscribeStartedAction
		| ElearningSubscribeFinishedAction
		| ElearningSubscribeFinishedWithErrosAction
		| ElearningSubscribeResetAction;

	export function subscribe(elearningId: string, userId: string): ElearningSubscribeAction {
		return {
			type: ELEARNING_SUBSCRIBE,
			payload: {
				elearningId,
				userId,
			},
		};
	}

	export function subscribeStarted(): ElearningSubscribeStartedAction {
		return {
			type: ELEARNING_SUBSCRIBE_STARTED,
		};
	}

	export function subscribeFinished(): ElearningSubscribeFinishedAction {
		return {
			type: ELEARNING_SUBSCRIBE_FINISHED,
		};
	}

	export function subscribeFinishedWithErrors(err: string): ElearningSubscribeFinishedWithErrosAction {
		return {
			type: ELEARNING_SUBSCRIBE_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function subscribeReset(): ElearningSubscribeResetAction {
		return {
			type: ELEARNING_SUBSCRIBE_RESET,
		};
	}
}
