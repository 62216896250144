export const setLocalStorageWithExpiry = (key: string, value: string, milliseconds: number): void => {
	const now = new Date();
	const item = {
		value: value,
		expiry: now.getTime() + milliseconds,
	};

	localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = (key: string): string | null => {
	const cachedItem = localStorage.getItem(key);

	if (!cachedItem) {
		return null;
	}

	const item = JSON.parse(cachedItem);
	const now = new Date();

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
};
