import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Talent } from "./types";
import axios from "axios";

const fetchTalentById = (talentId: string, accessToken: string) => {
	return function (): Promise<Talent> {
		return axios
			.get(`${PROD_URL}/talents/${talentId}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
};

export const useTalentByIdQuery = (talentId: string, options: UseQueryOptions<Talent, any, Talent> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Talent>(["talent-by-id", talentId], fetchTalentById(talentId, accessToken), {
		...options,
	});
};
