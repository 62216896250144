import CategoriesModel from "models/categories/Categories";
import CategoryModel, { Category } from "models/categories/Category";
import { TranslationData, TranslationDataUnit } from "i18n/typing";
import { TranslationService } from "../translations/TranslationsService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { CategoryType } from "api/CategoriesAPI/urls";

class CategoriesService {
	static instance: CategoriesService;
	translationService: TranslationService | null = null;
	list: CategoriesModel = new CategoriesModel([]);

	constructor(translationService: TranslationService) {
		if (!CategoriesService.instance) {
			CategoriesService.instance = this;
			this.translationService = translationService;
			this.list = new CategoriesModel([]);
		}

		return CategoriesService.instance;
	}

	getList = () => {
		return this.list;
	};

	setCategoriesToList(categories: CategoryModel[]) {
		this.list.setCategories(categories);
	}

	setCategoriesToListFromObject(categories: Category[]) {
		const categoriesModels = categories.map((category) => CategoryModel.generateFromObject(category));
		this.list.setCategories(categoriesModels);
	}

	initAllTranslations = () => {
		this.initAllFeaturesTranslations();
		this.initAllWorldTranslations();
		this.initAllDatesTranslations();
	};

	private initAllFeaturesTranslations = () => {
		if (!this.translationService) {
			return;
		}

		const translations = this.translationService.getFeatureTranslations([
			"gender",
			"area",
			"personalSkills",
			"businessSkills",
			"salesAndMarketingSkills",
			"investmentSkills",
			"designAndProductoSkills",
			"itSkills",
			"legalSkills",
			"accountantSkills",
			"engineeringAndArchitectureSkills",
			"marketplaceType",
			"school",
			"topic",
			"careerType",
			"careerModality",
			"adviserSkills",
			"market",
			"roles",
			"categoriesBusiness",
			"categoriesSalesAndMarketing",
			"categoriesInvestment",
			"categoriesDesingAndProduct",
			"categoriesIT",
			"categoriesLegal",
			"categoriesAccountant",
			"categoriesEngineeringAndArchitecture",
			"categoriesMedicineAndPsychology",
			"talentType",
			"applierStatus",
		]);

		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SECTOR) as CategoryModel[],
			translations["market"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.MARKET) as CategoryModel[],
			translations["market"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SCHOOL) as CategoryModel[],
			translations["school"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.TALENT_ROLE) as CategoryModel[],
			translations["roles"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.TALENT_TYPE) as CategoryModel[],
			translations["talentType"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.TOPIC) as CategoryModel[],
			translations["topic"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.AREA) as CategoryModel[],
			translations["area"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ATTENDANCE) as CategoryModel[],
			translations["careerModality"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.APPLIER_STATUS) as CategoryModel[],
			translations["applierStatus"],
		);

		this.initAllSkillsTranslations(translations);
		this.initAllAdviserSkillsTranslations(translations);
	};

	private initAllSkillsTranslations(translations: TranslationData) {
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["personalSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["businessSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["salesAndMarketingSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["investmentSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["designAndProductoSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["itSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["legalSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["accountantSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.SKILL) as CategoryModel[],
			translations["engineeringAndArchitectureSkills"],
		);
	}

	private initAllAdviserSkillsTranslations(translations: TranslationData) {
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["adviserSkills"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesBusiness"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesSalesAndMarketing"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesInvestment"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesDesingAndProduct"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesIT"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesLegal"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesAccountant"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesEngineeringAndArchitecture"],
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.ADVISER_SKILLS) as CategoryModel[],
			translations["categoriesMedicineAndPsychology"],
		);
	}

	private initAllWorldTranslations = () => {
		if (!this.translationService) {
			return;
		}

		const {
			country: countryTranslations,
			region: regionTranslations,
			language: languageTranslations,
		} = this.translationService.getWorldTranslations(["country", "region", "language"]);

		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.COUNTRY) as CategoryModel[],
			countryTranslations,
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.REGION) as CategoryModel[],
			regionTranslations,
		);
		this.addDataUnitTranslations(
			this.list.filterByCategoryType(CategoryType.LANGUAGES) as CategoryModel[],
			languageTranslations,
		);
	};

	private initAllDatesTranslations = () => {
		if (!this.translationService) {
			return;
		}

		const { days: daysTranslations, months: monthsTranslations } = this.translationService.getDatesTranslations([
			"days",
			"months",
		]);
	};

	// Mutable method -> take care
	addDataUnitTranslations = (
		categories: CategoryModel | CategoryModel[],
		translationDataUnit: TranslationDataUnit,
	): void => {
		const translationsOptions: { value: string; text: string }[] = [];

		if (translationDataUnit && translationDataUnit.items) {
			for (const key in translationDataUnit.items) {
				if (Object.prototype.hasOwnProperty.call(translationDataUnit.items, key)) {
					translationsOptions.push({
						value: key,
						text: translationDataUnit.items[key],
					});
				}
			}
		}
		const getCategoryTranslation = (categoryKey: string) => {
			return translationsOptions.find((options) => options.value === categoryKey);
		};
		const applyTranslationToCategory = (category: CategoryModel) => {
			const categoryTranslation = getCategoryTranslation(category.key);

			if (categoryTranslation) {
				category.text = String(categoryTranslation?.text);
			}

			return category;
		};

		if (Array.isArray(categories)) {
			categories.map(applyTranslationToCategory);
		} else {
			applyTranslationToCategory(categories);
		}
	};
}

const CategoriesServiceSingleton = new CategoriesService(TranslationsServiceSingleton);
Object.freeze(CategoriesServiceSingleton);

export default CategoriesServiceSingleton;
