export const FeaturesEn = `
{
  "gender": {
    "key": {
      "singular": "Gender",
      "plural": "Genders"
    },
    "items": {
      "male": "Male",
      "female": "Female",
      "indeterminate": "To say nothing of"
    }
  },
  "area": {
    "key": {
      "singular": "Area",
      "plural": "Areas"
    },
    "items": {
      "tech": "Technology",
      "business": "Business",
      "science": "Science"
    }
  },
  "personalSkills": {
    "key": {
      "singular": "Personal Skills",
      "plural": "Personal Skills"
    },
    "items": {
      "positiveAttitude": "Positive attitude",
      "adaptationToChange": "Adaptation to change",
      "autonomy": "Autonomy",
      "resolutionCreativity": "Resolution capacity",
      "creativity": "Creativity",
      "curiosity": "Curiosity",
      "empathy": "Empathy",
      "activeListening": "Active listening",
      "focusAndVision": "Focus and vision",
      "integrity": "Integrity",
      "emotionalInteligence": "Emotional intelligence",
      "criticalThinking": "Critical thinking",
      "teamwork": "Teamwork",
      "commitment": "Commitment",
      "flexibility": "Flexibility",
      "frustrationTolerance": "Frustration tolerance",
      "motivation": "Motivation",
      "negotiation": "Negotiation",
      "patience": "Patience",
      "persuasion": "Persuasion",
      "skillsToSolveProblems": "Skills to solve problems",
      "timeManagement": "Time management",
      "workEthic": "Work ethic"
    }
  },
  "businessSkills": {
    "key": {
      "singular": "Business Skill",
      "plural": "Business Skill"
    },
    "items": {
      "communicationAndNegotiation": "Communication and negotiation",
      "delegation": "Delegation",
      "financialManagement": "Financial management",
      "leadership": "Leadership",
      "networking": "Networking",
      "problemSolving": "Problem solving"
    }
  },
  "salesAndMarketingSkills": {
    "key": {
      "singular": "Sales and Marketing",
      "plural": "Sales and Marketing"
    },
    "items": {
      "buyerSellerAgreement": "Buyer-Seller Agreement",
      "communication": "Communication",
      "demoSkills": "Demo skills",
      "productKnowledge": "Product Knowledge",
      "qualificationQuestioning": "Qualification Questioning",
      "rapportBuilding": "Rapport Building",
      "strategicProspectingSkills": "Strategic Prospecting Skills"
    }
  },
  "investmentSkills": {
    "key": {
      "singular": "Investment",
      "plural": "Investments"
    },
    "items": {
      "confidence": "Confidence",
      "determination": "Determination",
      "goodNumerical": "Good numerical",
      "selfMotivation": "Self-motivation",
      "strongTime": "Strong time",
      "teamworkingSkills": "Teamworking skills",
      "understandingOfFinancialMarkets": "Understanding of financial markets",
      "workUnderPressure": "Work under pressure"
    }
  },
  "designAndProductoSkills": {
    "key": {
      "singular": "Design and Producto",
      "plural": "Design and Producto"
    },
    "items": {
      "ABTesting": "A/B Testing",
      "benchmarking": "Benchmarking",
      "dataCollection": "Data collection",
      "fabrication": "Fabrication",
      "interactionDesing": "Interaction desing",
      "learningAboutCode": "Learning about code",
      "prototyping": "Prototyping",
      "serviceDesign": "Service design",
      "visualDesign": "Visual design"
    }
  },
  "itSkills": {
    "key": {
      "singular": "IT",
      "plural": "IT"
    },
    "items": {
      "digitalCommunications": "Digital Communications",
      "goalOriented": "Goal Oriented",
      "informationAndCommunicationsTechnology": "Information and Communications Technology",
      "manageRemoteWorkingTeams": "Manage Remote Working Teams",
      "meetingDeadlines": "Meeting Deadlines",
      "multitasking": "Multitasking",
      "reviewProcessesForImprovement": "Review Processes for Improvement",
      "scheduling": "Scheduling"
    }
  },
  "legalSkills": {
    "key": {
      "singular": "Legal",
      "plural": "Legal"
    },
    "items": {
      "analyticalAndLogicalReasoning": "Analytical and Logical Reasoning",
      "clientService": "Client Service",
      "legalProcedure": "Legal Procedure",
      "legalResearch": "Legal Research",
      "oralCommunication": "Oral Communication",
      "organization": "Organization",
      "technologySkills": "Technology Skills",
      "writtenCommunication": "Written Communication"
    }
  },
  "accountantSkills": {
    "key": {
      "singular": "Accountant",
      "plural": "Accountant"
    },
    "items": {
      "adaptability": "Adaptability",
      "analyticalSkills": "Analytical Skills",
      "financialSkills": "Financial Skills",
      "generalBusinessSkills": "General Business Skills",
      "industryKnowledge": "Industry Knowledge",
      "informationTechnologySkills": "Information Technology Skills",
      "interpersonalCommunication": "Interpersonal Communication",
      "organizationalSkills": "Organizational Skills"
    }
  },
  "engineeringAndArchitectureSkills": {
    "key": {
      "singular": "Accountant",
      "plural": "Accountant"
    },
    "items": {
      "budgeting": "Budgeting",
      "calculations": "Calculations",
      "computerScience": "Computer Science",
      "conceptualandLogical": "Conceptual and Logical",
      "convertingScaleFromBlueprints": "Converting Scale from Blueprints",
      "estimating": "Estimating",
      "nanotechnology": "Nanotechnology",
      "physicalDataModeling": "Physical Data Modeling",
      "processManagement": "Process Management",
      "programmingLanguages": "Programming Languages",
      "specifications": "Specifications",
      "statistics": "Statistics",
      "structuralAnalysis": "Structural Analysis"
    }
  },
  "marketplaceType": {
    "key": {
      "singular": "Type of Marketplace",
      "plural": "Marketplace types"
    },
    "items": {
      "product": "Product",
      "service": "Service"
    }
  },
  "school": {
    "key": {
      "singular": "Faculty",
      "plural": "Faculties"
    },
    "items": {
      "agronomy": "Agronomy",
      "architecture": "Architecture",
      "biochemistry": "Biochemistry",
      "biology": "Biology",
      "chemistry": "Chemistry",
      "cinema": "Cinema",
      "design": "Design",
      "documentation": "Documentation",
      "earthScience": "Natural Sciences",
      "economyAndCompany": "Business Administration",
      "engineer": "Engineering",
      "exactScience": "Exact Sciences",
      "fineArts": "Fine arts",
      "geography": "Geography",
      "health": "Health",
      "history": "History",
      "information_and_audiovisual_media": "Information and Audiovisual Media",
      "it": "Development IT",
      "law": "Laws",
      "librarianship": "Librarian",
      "math": "Mathematics",
      "medicine": "Medicine",
      "nutrition": "Nutrition",
      "pharmacy": "Pharmacology",
      "philology": "Philology",
      "philosofy": "Philosophy",
      "phisyc": "Physical",
      "psicology": "Psychology",
      "socialScience": "Social Sciences",
      "townPlanning": "Town planning",
      "veterinary": "Veterinary"
    }
  },
  "topic": {
    "key": {
      "singular": "Topic",
      "plural": "Topics"
    },
    "items": {
      "education": "Education",
      "mobile": "Mobile",
      "personalizedMedicine": "Personalized medicine",
      "syntheticBiology": "Synthetic biology",
      "government": "Government",
      "foodAndDrinks": "Food and drinks",
      "travelToTheSpace": "Travel to the space",
      "agronomy": "Agronomy",
      "healthServices": "Health services",
      "internetWebservices": "Internet / Web Services",
      "legal": "Legal",
      "cattleRaising": "Cattle raising",
      "cleanEnergy": "Clean energy",
      "robotics": "Robotics",
      "water": "Water desalination",
      "cloudEducation": "Cloud education",
      "lifestyle": "Lifestyle",
      "mediaAndEntertainment": "Media and entertainment",
      "cleanTransportation": "Clean transportation",
      "oilAndGas": "Oil and gas",
      "dataStorage": "Data storage",
      "transport": "Transport",
      "construction": "Construction",
      "sports": "Sports",
      "trip": "Trip",
      "retailSale": "Retail sale",
      "brainaging": "Brain aging",
      "virtualReality": "Virtual reality",
      "aerospace": "Aerospace",
      "3dPrint": "3d print",
      "blockChain": "Blockchain",
      "electronicsInstrumentation": "Electronics / Instrumentation",
      "itServices": "IT services",
      "fintech": "Fintech",
      "artificialIntelligence": "Artificial intelligence",
      "syntheticMaterials": "Synthetic materials",
      "financialServices": "Financial services",
      "pc": "Pc",
      "smartThings": "Smartthings",
      "augmentEdreality": "Augmented reality",
      "brainConnectomy": "Brain connectomy",
      "logistics": "Logistics",
      "smartCities": "Smart cities",
      "fishing": "Fishing",
      "biotechnology": "Biotechnology",
      "digitalAdvertising": "Digital advertising",
      "others": "Others",
      "smartphonesWithContactlenses": "Smartphones with contact lenses",
      "artificialFood": "Artificial food",
      "foreignTrade": "Foreign trade",
      "customerService": "Customer service",
      "nanoTechnology": "Nanotechnology",
      "fashion": "Fashion",
      "naturalUserInterfaces": "Natural user interfaces",
      "realEstate": "Real estate",
      "software": "Software",
      "telecommunications": "Telecommunications",
      "geneSequencing": "Gene sequencing",
      "computersAndPeripherals": "Computers and peripherals",
      "marketingAds": "Marketing Ads",
      "gaming": "Gaming",
      "bigData": "Big data",
      "chemicalsAndProductChemicals": "Chemicals and product chemicals",
      "commerce": "Commerce",
      "electronicsDevices": "Electronics devices",
      "networksAndEquipment": "Networks and equipment",
      "quantumComputing": "Quantum computing",
      "drones": "Drones",
      "cybersecurity": "Cyber security",
      "iot": "IoT",
      "climateEngineering": "Climate engineering",
      "medicalDevicesAndEquipment": "Medical devices and equipment",
      "consumerProducts": "Consumer products",
      "productsForCompanies": "Products for companies",
      "mining": "Mining",
      "smartCar": "Smart car"
    }
  },
  "careerType": {
    "key": {
      "singular": "Type of Career",
      "plural": "Types of Careers"
    },
    "items": {
      "course": "Course",
      "degree": "Grade",
      "master": "Master"
    }
  },
  "careerModality": {
    "key": {
      "singular": "Career Modality",
      "plural": "Career Modalities"
    },
    "items": {
      "online": "Online",
      "presential": "Presential",
      "semiPresential": "Semi-Class"
    }
  },
  "adviserSkills": {
    "key": {
      "singular": "Adviser skill",
      "plural": "Adviser skills"
    },
    "items": {
      "careerAdvise": "Career Counselor",
      "financialConsulting": "Financial advisor",
      "businessDevelopment": "Business development",
      "legal": "Legal",
      "socialMedia": "Social networks",
      "branding": "Brand",
      "salesAnd_lead": "Leadership and Sales",
      "advertising": "Advertising",
      "crowfounding": "Crowfounding",
      "ventureCapital": "Risk capital"
    }
  },
  "market": {
    "key": {
      "singular": "Market",
      "plural": "Markets"
    },
    "items": {
      "aerospace": "Aerospace",
      "agriculture": "Farming",
      "retailer": "Retail",
      "foodAndDrink": "Food and drink",
      "marketing": "Marketing",
      "realState": "Real Estate",
      "biotechnology": "Biotechnology",
      "computers": "Computers",
      "construction": "Building",
      "sports": "Sports",
      "medicalDevices": "Medical devices",
      "education": "Education",
      "electronic": "Electronics",
      "lifestyle": "Lifestyle",
      "fintech": "Financial Technologies",
      "industryAndEnergy": "Industry and Energy",
      "internet": "Internet",
      "insurtech": "Insurtech",
      "gameOfChance": "Games of Chance",
      "legal": "Legal",
      "logistic": "Logistics",
      "entertainmentAndMedia": "Training",
      "mining": "Mining",
      "moda": "Fashion",
      "movil": "Mobile",
      "nanotechnology": "Nanotechnology",
      "others": "Others",
      "oilAndGas": "Oil and Gas",
      "consumableProducts": "Consumables Products",
      "companyProducts": "Product Company",
      "chemistryProducts": "Chemical products",
      "chemistry": "Chemistry",
      "networkAndEquipment": "Networks and Equipment",
      "robotic": "Robotics",
      "security": "Security",
      "clientService": "Customer service",
      "health": "Health",
      "finance": "Finance",
      "it": "Development IT",
      "software": "Software",
      "cleanTechnology": "Clean technologies",
      "telecomunication": "Telecommunication",
      "transport": "Transport",
      "trips": "Travels",
      "videogames": "Video game"
    }
  },
  "roles": {
    "key:": {
      "singlular": "Rol",
      "plural": "Roles"
    },
    "items": {
      "CEO": "CEO",
      "COO": "COO",
      "CTO": "CTO",
      "CDO": "CDO",
      "CIO": "CIO",
      "CFO": "CFO",
      "CMO": "CMO",
      "CCO": "CCO",
      "CSO": "CSO",
      "CLO": "CLO",
      "director": "Director",
      "manager": "Manager",
      "chief": "Chief",
      "delegate": "Delegate",
      "coordinator": "Coordinator",
      "senior": "Senior",
      "semiSenior": "Semi Senior",
      "junior": "Junior",
      "operative": "Operative",
      "assistant": "Assistant",
      "intern": "Intern",
      "scholar": "Scholar"
    }
  },
  "categoriesBusiness": {
    "key": {
      "singular": "Business",
      "plural": "Business"
    },
    "items": {
      "businessCoaching": "Business coaching",
      "businessPlan": "Business plan",
      "canvas": "Canvas",
      "financialQuery": "Financial query",
      "businessDevelopment": "Business development",
      "strategy": "Strategy",
      "executiveCoach": "Executive Coach",
      "legal": "Legal",
      "brandAndPatents": "Brand and patents",
      "humanResources": "Human Resources"
    }
  },
  "categoriesSalesAndMarketing": {
    "key": {
      "singular": "Sales and marketing",
      "plural": "Sales and marketing"
    },
    "items": {
      "commercialCoaching": "Commercial coaching",
      "emailMarketing": "Email marketing",
      "commercialStrategy": "Commercial strategy",
      "inboundMarketing": "Inbound Marketing",
      "leadsAndSales": "Leads & sales",
      "branding": "Branding",
      "others": "Others",
      "publishing": "Publishing",
      "advertising": "Advertising",
      "copywriting": "Copywriting",
      "publicRelations": "Public relations",
      "socialMedia": "Social media",
      "translations": "Translations"
    }
  },
  "categoriesInvestment": {
    "key": {
      "singular": "Investment",
      "plural": "Investments"
    },
    "items": {
      "accelerator": "Accelerator",
      "businessAngel": "Business Angel",
      "ventureCapital": "Venture capital",
      "crowdfunding": "Crowdfunding",
      "incubator": "Incubator",
      "investmentNegotiation": "Investment negotiation",
      "nonprofit": "Nonprofit"
    }
  },
  "categoriesDesingAndProduct": {
    "key": {
      "singular": "Disseny i Producte",
      "plural": "Disseny i Producte"
    },
    "items": {
      "agile": "Agile",
      "identity": "Identity",
      "learnStartup": "Learn Startup",
      "metricAndAnalytics": "Metric & Analytics",
      "mvp": "MVP",
      "other": "Other",
      "productManager": "Product Management",
      "projectManager": "Project Management",
      "scrum": "Scrum",
      "userExperience": "User Experience"
    }
  },
  "categoriesIT": {
    "key": {
      "singular": "IT",
      "plural": "IT"
    },
    "items": {
      "itDesing": "IT Design",
      "erp": "ERP",
      "leanLogistics": "Lean logistics",
      "leanManagement": "Lean Management",
      "mobile": "Mobile",
      "reviewAndDiagnosis": "Review and diagnosis",
      "digitalTransformation": "Digital transformation",
      "web": "Web",
      "Wordpress": "Wordpress"
    }
  },
  "categoriesLegal": {
    "key": {
      "singular": "Legal",
      "plural": "Legals"
    },
    "items": {
      "nda": "NDA",
      "database": "Databases",
      "compliance": "Compliance",
      "softwareDevelopmentContract": "Software Development Contract",
      "intellectualPropertyContract": "Intellectual Property Contract",
      "seedContract": "Seed contract",
      "lopd": "LOPD",
      "softwareRegistration": "Software registration",
      "termsAndConditions": "Terms and conditions",
      "vehicleSAAS": "Vehicle SAAS"
    }
  },
  "categoriesAccountant": {
    "key": {
      "singular": "Accountant",
      "plural": "Accountants"
    },
    "items": {
      "accounting": "Accounting",
      "dueDiligence": "Due Diligence",
      "taxationAndTaxes": "Taxation and Taxes",
      "managementOfPayroll": "Management of Payrolls",
      "acquisitionOperations": "Acquisition operations",
      "phantomShares": "Phantom Shares",
      "socialSecurity": "Social security",
      "stockOptions": "Stock Options"
    }
  },
  "categoriesEngineeringAndArchitecture": {
    "key": {
      "singular": "Engineering and architecture",
      "plural": "Engineering and architecture"
    },
    "items": {
      "analysisOfTheSupplyChain": "Analysis of the supply chain",
      "audits": "Audits",
      "quality": "Quality",
      "viabilityStudy": "Viability study",
      "legalEngineeringAndIndustrialSafety": "Legal engineering and industrial safety",
      "masterplan": "Masterplan",
      "enviroment": "Enviroment",
      "costOptimization": "Cost optimization",
      "industrialPlanning": "Industrial plannig",
      "supplyChainAndLogistics": "Supply chain and logistics"
    }
  },
  "categoriesMedicineAndPsychology": {
    "key": {
      "singular": "Medicine and Psychology",
      "plural": "Medicine and Psychology"
    },
    "items": {
      "accreditWorks": "Accredit works",
      "applyAndInterpretAssessments": "Apply and interpret assessments",
      "clinicalTrials": "Clinical trials",
      "co-reportStudies": "Co-Report studies",
      "developTreatmentsAndPlans": "Develop treatments and plans",
      "directMedicalProjects": "Lead medical projects",
      "documentTheInformation": "Document the information",
      "evaluateTheResults": "Evaluate the results",
      "evaluateTrials": "Evaluate trials",
      "gatherInformation": "Gather information",
      "investigate": "Investigate",
      "keyDecisionMaker": "Key decision maker",
      "meetingCoordinator": "Meeting coordinator",
      "presentScientificInformation": "Present scientific information",
      "supervise": "Supervise",
      "toDiagnose": "Diagnose"
    }
  },
  "talentType": {
    "key": {
      "singular": "Talent Type",
      "plural": "Talent Types"
    },
    "items": {
      "employee": "Job",
      "co-founder": "Co-founder",
      "advisor": "Advisor"
    }
  },
  "applierStatus": {
    "key": {
      "singular": "Applier status",
      "plural": "Applier status"
    },
    "items": {
      "new": "New",
      "interview": "Interview",
      "extendedOffer": "Extended Offer",
      "hired": "Hired",
      "filed": "Filed"
    }
  },
  "applierStatusForTender": {
    "key": {
      "singular": "Applier status",
      "plural": "Applier status"
    },
    "items": {
      "new": "New",
      "interview": "Interview",
      "extendedOffer": "Extended Offer",
      "withinTheTeam": "Within the team",
      "filed": "Filed"
    }
  }
}

`;
