import { ReactNode } from "react";

export type SidebarLayoutProps = {
	sidebar: ReactNode;
	children: ReactNode;
};

export const SidebarLayout = ({ sidebar, children }: SidebarLayoutProps) => (
	<>
		<aside className="bg-neutral-0 fixed top-0 z-10 hidden h-full w-80 overflow-auto border-r border-neutral-200 pt-40 lg:block">
			{sidebar}
		</aside>
		<section className="pl-0 lg:pl-80">{children}</section>
	</>
);
