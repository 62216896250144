import React, { useMemo } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import { Router } from "next/router";
import dayjs from "dayjs";
import { IntlProvider, MessageDescriptor, useIntl } from "react-intl";
import { Provider as ReactReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import relativeTime from "dayjs/plugin/relativeTime";

import { BrowserUtils } from "@utils";
import { UserProfileProvider } from "@context";
import { NotificationProvider, SeoPageProps, useLocales, withRedirection, CategoriesProvider } from "@libs";

import store from "redux-app";

import English from "../content/locales/en.json";
import Spanish from "../content/locales/es.json";
import Catalan from "../content/locales/ca.json";
import Portuguese from "../content/locales/pt.json";
import "../styles/globals.css";
import "../app__Legacy/app.scss";

import "semantic-ui-css/semantic.min.css";

dayjs.extend(relativeTime);

const MyAppHead = ({ pageProps: { meta, robots }, router }: { pageProps: SeoPageProps; router: Router }) => {
	const { formatMessage } = useIntl();
	const vingletUrl = BrowserUtils.isRunningOnBrowser() ? `${location.protocol}//${location.host}` : "";
	let metaTitle = "Vinglet";
	let metaDescription = "";

	if (meta?.title) {
		const messageTitleDescriptor: MessageDescriptor = {
			id: meta?.title,
		};

		metaTitle = formatMessage(messageTitleDescriptor);
	}

	if (meta?.description) {
		const messageDescriptionDescriptor: MessageDescriptor = {
			id: meta?.description,
		};

		metaDescription = formatMessage(messageDescriptionDescriptor);
	}

	return (
		<Head>
			<title>{metaTitle}</title>
			<link
				rel="shortcut icon"
				href={"/logo.ico"}
			/>
			{router.locales?.map((locale) => (
				<link
					key={locale}
					rel="alternate"
					hrefLang={locale}
					href={`${vingletUrl}/${locale}${router.pathname}`}
				/>
			))}
			<link
				rel="alternate"
				hrefLang="x-default"
				href={`${vingletUrl}/${router.defaultLocale}${router.pathname}`}
			/>
			{" Meta "}
			<meta
				name="description"
				content={metaDescription}
			/>
			<meta
				name="google"
				content="notranslate"
			/>
			<meta
				name="viewport"
				content="initial-scale=1.0, width=device-width"
			/>
			<meta
				name="theme-color"
				content="#42389D"
			/>
			{robots?.index && (
				<meta
					name="robots"
					content="INDEX,FOLLOW"
				/>
			)}
			<meta
				httpEquiv="Content-Type"
				content="text/html; charset=utf-8"
			/>
			<meta
				httpEquiv="Content-Language"
				content={router.locale}
			/>
			{" Open Graph Meta "}
			<meta
				property="og:title"
				content={metaTitle || "Vinglet"}
			/>
			<meta
				property="og:description"
				content={metaDescription}
			/>
			<meta
				property="og:locale"
				content={router.locale}
			/>
			<meta
				property="og:url"
				content={`${vingletUrl}/${router.locale}${router.pathname}`}
			/>
			<meta
				property="og:type"
				content="website"
			/>
			{meta?.next && (
				<link
					rel="next"
					href={`${vingletUrl}/${router.locale}${router.pathname}${meta.next}`}
				/>
			)}
			{meta?.prev && (
				<link
					rel="prev"
					href={`${vingletUrl}/${router.locale}${router.pathname}${meta.prev}`}
				/>
			)}
		</Head>
	);
};

const MyApp = ({ Component, pageProps, router }: AppProps<SeoPageProps>) => {
	const { currentShortLocale } = useLocales();
	const [queryClient] = React.useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						staleTime: 0,
						refetchOnWindowFocus: !BrowserUtils.isCypress(),
						refetchOnMount: !BrowserUtils.isCypress(),
						retry: BrowserUtils.isCypress() ? false : 2,
					},
					mutations: {
						retry: BrowserUtils.isCypress() ? false : 2,
					},
				},
			}),
	);
	const messages = useMemo(() => {
		switch (currentShortLocale) {
			case "en":
				return English;
			case "es":
				return Spanish;
			case "ca":
				return Catalan;
			case "pt":
				return Portuguese;
			default:
				return Spanish;
		}
	}, [currentShortLocale]);

	return (
		<IntlProvider
			messages={messages}
			locale={currentShortLocale}
		>
			<MyAppHead
				pageProps={pageProps}
				router={router}
			/>
			<ReactReduxProvider store={store}>
				<NotificationProvider>
					<QueryClientProvider client={queryClient}>
						<CategoriesProvider>
							<UserProfileProvider router={router}>
								{withRedirection(
									Component,
									{
										...pageProps,
										query: router.query,
									},
									router.pathname,
								)}
							</UserProfileProvider>
						</CategoriesProvider>
					</QueryClientProvider>
				</NotificationProvider>
			</ReactReduxProvider>
		</IntlProvider>
	);
};

export default MyApp;
