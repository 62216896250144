import { Toast } from "flowbite-react";
import { Exclamation } from "@common";

export const ErrorToast = ({ message, closable, fluid }: { message: string; closable?: boolean; fluid?: boolean }) => {
	return (
		<Toast
			style={{
				width: fluid ? "100%" : undefined,
				maxWidth: fluid ? "100%" : undefined,
			}}
		>
			<div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
				<Exclamation className="h-5 w-5" />
			</div>
			<div className="ml-3 text-base font-normal">{message}</div>
			{closable && <Toast.Toggle />}
		</Toast>
	);
};
