import { ComponentType, Suspense, useContext } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { CookiesUtils, PageDirectoryUtils } from "@utils";
import { VingletLoader } from "@common";
import { UserProfileContext } from "@context";

import withHeader from "hocs/withHeader";
import withFooter from "hocs/withFooter";

const DynamicLogin = dynamic(() => import("@app-components").then((components) => components.Auth.Login), {
	suspense: true,
});
const DynamicOnboardingProfile = dynamic(
	() => import("@app-components").then((components) => components.Profiles.OnboardingProfile),
	{
		suspense: true,
	},
);

export const withRedirection = <P extends object>(
	ComponentToRender: ComponentType<P>,
	props: P,
	currentPathName: string,
) => {
	const WithRedirection = () => {
		const router = useRouter();
		const { user, userProfile } = useContext(UserProfileContext);

		const userIsProperlyLogged = CookiesUtils.getAccessTokenFromCookie() && user?.id;

		if (!userIsProperlyLogged && PageDirectoryUtils.isAPrivateRoute(currentPathName)) {
			const LoginWithHeaderAndFooter = withHeader(withFooter(DynamicLogin));

			return (
				<Suspense fallback={<VingletLoader />}>
					<LoginWithHeaderAndFooter />
				</Suspense>
			);
		}

		if (userIsProperlyLogged && !userProfile?.id) {
			return (
				<Suspense fallback={<VingletLoader />}>
					<DynamicOnboardingProfile />
				</Suspense>
			);
		}

		if (userIsProperlyLogged && PageDirectoryUtils.isAPrivateRouteForLoggedUsers(currentPathName)) {
			router.push("/");
			return <></>;
		}

		return <ComponentToRender {...props} />;
	};

	const WithRedirectionFromRoot = () => {
		const router = useRouter();
		const { userProfile } = useContext(UserProfileContext);

		if (!userProfile?.id) {
			return (
				<Suspense fallback={<VingletLoader />}>
					<DynamicOnboardingProfile />
				</Suspense>
			);
		}

		router.push("/profile/" + userProfile.links.link);
		return <></>;
	};

	return currentPathName === "/" ? <WithRedirectionFromRoot /> : <WithRedirection />;
};
