import RequestModel, { Request } from "models/requests/Request";
import { LinkProfilePropsState } from "redux-app/reducers/UserReducer/typing";
import ProfileAboutModel from "./ProfileAbout";
import ProfileAwardsModel from "./ProfileAwards";
import ProfileConnectionsModel from "./ProfileConnections";
import ProfileExperienceModel from "./ProfileExperience";
import ProfileInfoModel from "./ProfileInfo";
import ProfileOverviewModel from "./ProfileOverview";
import ProfileRecommendationsModel, { ProfileRecommendations } from "./ProfileRecommendations";
import ProfileSkillsModel from "./ProfileSkills";
import ProfileSocialModel from "./ProfileSocial";
import ProfileStudyModel from "./ProfileStudy";

export interface Profile {
	id: string;
	about: ProfileAboutModel;
	awards: ProfileAwardsModel;
	connections: ProfileConnectionsModel;
	experience: ProfileExperienceModel;
	info: ProfileInfoModel;
	// This should be included inside "info"
	links: LinkProfilePropsState;
	overview: ProfileOverviewModel;
	recommendations: ProfileRecommendationsModel;
	skills: ProfileSkillsModel;
	social: ProfileSocialModel;
	studies: ProfileStudyModel;
	adviserSkills: ProfileSkillsModel;
	myProfile: boolean;
	email: string;
	request: Request;
}

class ProfileModel implements Partial<Profile> {
	id: string;
	about: ProfileAboutModel;
	awards: ProfileAwardsModel;
	connections: ProfileConnectionsModel;
	experience: ProfileExperienceModel;
	info: ProfileInfoModel;
	// This should be included inside "info" and replace by our ProfileLink
	links: LinkProfilePropsState;
	overview: ProfileOverviewModel;
	recommendations: ProfileRecommendations;
	skills: ProfileSkillsModel;
	social: ProfileSocialModel;
	studies: ProfileStudyModel;
	adviserSkills: ProfileSkillsModel;
	myProfile: boolean;
	email: string = "";
	request: Request;

	constructor(
		id: string,
		about: ProfileAboutModel,
		awards: ProfileAwardsModel,
		connections: ProfileConnectionsModel,
		experience: ProfileExperienceModel,
		info: ProfileInfoModel,
		// This should be included inside "info"
		links: LinkProfilePropsState,
		overview: ProfileOverviewModel,
		recommendations: ProfileRecommendations,
		skills: ProfileSkillsModel,
		social: ProfileSocialModel,
		studies: ProfileStudyModel,
		adviserSkills: ProfileSkillsModel,
		myProfile: boolean,
		request: Request,
	) {
		this.id = id;
		this.about = about;
		this.awards = awards;
		this.connections = connections;
		this.experience = experience;
		this.info = info;
		// This should be included inside "info"
		this.links = links;
		this.overview = overview;
		this.recommendations = recommendations;
		this.skills = skills;
		this.social = social;
		this.studies = studies;
		this.adviserSkills = adviserSkills;
		this.myProfile = myProfile;
		this.request = request;
	}

	set setAbout(newAbout: ProfileAboutModel) {
		this.about = newAbout;
	}

	set setAwards(newAwards: ProfileAwardsModel) {
		this.awards = newAwards;
	}

	set setConnections(newConnections: ProfileConnectionsModel) {
		this.connections = newConnections;
	}

	set setExperience(newExperience: ProfileExperienceModel) {
		this.experience = newExperience;
	}

	set setInfo(newInfo: ProfileInfoModel) {
		this.info = newInfo;
	}

	// This should be included inside "info" and replace by our ProfileLink
	set setLinks(newLinks: LinkProfilePropsState) {
		this.links = newLinks;
	}

	set setOverview(newOverview: ProfileOverviewModel) {
		this.overview = newOverview;
	}

	set setRecommendations(newRecommendations: ProfileRecommendations) {
		this.recommendations = newRecommendations;
	}

	set setSkills(newSkills: ProfileSkillsModel) {
		this.skills = newSkills;
	}

	set setSocial(newSocial: ProfileSocialModel) {
		this.social = newSocial;
	}

	set setStudies(newStudies: ProfileStudyModel) {
		this.studies = newStudies;
	}

	set setAdviserSkills(newAdviserSkills: ProfileSkillsModel) {
		this.adviserSkills = newAdviserSkills;
	}

	set setEmail(newEmail: string) {
		this.email = newEmail;
	}

	static generateFromAPI(data: any) {
		return new ProfileModel(
			data.id,
			ProfileAboutModel.generateFromAPI(data),
			ProfileAwardsModel.generateFromAPI(data),
			ProfileConnectionsModel.generateFromAPI(data),
			ProfileExperienceModel.generateFromAPI(data),
			ProfileInfoModel.generateFromAPI(data),
			{
				link: data.slug,
				checking: false,
				isValid: false,
				getting: false,
			},
			ProfileOverviewModel.generateFromAPI(data),
			ProfileRecommendationsModel.generateFromAPI(data),
			ProfileSkillsModel.generateFromAPI(data),
			ProfileSocialModel.generateFromAPI(data),
			ProfileStudyModel.generateFromAPI(data),
			ProfileSkillsModel.generateFromAPIForAdviser(data),
			data.is_creator && data.is_admin,
			RequestModel.requestDefault(),
		);
	}

	static generateFromObject = (profile: Profile) => {
		return new ProfileModel(
			profile.id,
			profile.about,
			profile.awards,
			profile.connections,
			profile.experience,
			ProfileInfoModel.generateFromObject(profile.info),
			profile.links,
			profile.overview,
			profile.recommendations,
			profile.skills,
			profile.social,
			profile.studies,
			profile.adviserSkills,
			profile.myProfile,
			profile.request,
		);
	};

	static generateEmpty = () => {
		return new ProfileModel(
			"",
			ProfileAboutModel.generateEmpty(),
			ProfileAwardsModel.generateEmpty(),
			ProfileConnectionsModel.generateEmpty(),
			ProfileExperienceModel.generateEmpty(),
			ProfileInfoModel.generateEmpty(),
			{
				link: "",
				getting: false,
			},
			ProfileOverviewModel.generateEmpty(),
			ProfileRecommendationsModel.generateEmpty(),
			ProfileSkillsModel.generateEmpty(),
			ProfileSocialModel.generateEmpty(),
			ProfileStudyModel.generateEmpty(),
			ProfileSkillsModel.generateEmpty(),
			false,
			RequestModel.requestDefault(),
		);
	};

	getDirectLink() {
		return "/profile/" + this.links.link;
	}

	exportToAPIRequest = () => {
		const preparedDataToSend = {
			...this.info.exportToAPIRequest(),
			description: this.about.text,
			awards: this.awards.exportToAPIRequest(),
			skills: this.skills.exportToAPIRequest(),
			educations: this.studies.exportToAPIRequest(),
			jobs: this.experience.exportToAPIRequest(),
			slug: this.links.link,
			social_networks: this.social.exportToAPIRequest(),
			...this.overview.exportToAPIRequest(),
		};

		return preparedDataToSend;
	};
}

export default ProfileModel;
