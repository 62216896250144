import {LoginTexts, SignUpTexts} from "i18n/containers/Auth";
import {LoginTranslation, SignupTranslation} from "i18n/containers/Auth/typing";
import * as React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import {Button} from "@common";
import Link from "next/link";
import {useLocales} from "@libs";

const RightMenu = () => {
    const {currentShortLocale} = useLocales();
    const loginTranslation: LoginTranslation = {
        ...LoginTexts[currentShortLocale],
    };
    const signUpTranslation: SignupTranslation = {
        ...SignUpTexts[currentShortLocale],
    };

    return (
        <Menu.Menu
            className="flex w-96 items-center gap-4 pr-4"
            position="right"
        >
            <Link
                href={"/login"}
                locale={currentShortLocale}
                passHref
            >
                <a className="w-full">
                    <Button
                        size="lg"
                        fluid
                        color="gray"
                    >
                        {loginTranslation.title}
                    </Button>
                </a>
            </Link>
            <Link
                href={"/signup"}
                locale={currentShortLocale}
                passHref
            >
                <a className="w-full">
                    <Button
                        size="lg"
                        fluid
                        color="purple"
                    >
                        {signUpTranslation.title}
                    </Button>
                </a>
            </Link>
        </Menu.Menu>
    );
};

export default React.memo(RightMenu);
