export const getListFromStringifiedList = (list: string | undefined): any[] => {
	if (!list) {
		return [];
	}

	try {
		return JSON.parse(list);
	} catch {
		return [];
	}
};
