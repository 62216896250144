import { defaultError, getGetHeaderWithCredentials } from "api/config";
import { URL_SEARCHER_GET_ELEARNINGS, URL_SEARCHER_GET_TALENTS } from "./urls";

export function getElearningsAPI(accessToken: string, page?: number, extraParameters?: any) {
	return function () {
		return fetch(URL_SEARCHER_GET_ELEARNINGS(page, extraParameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getTalentsAPI(accessToken: string, page?: number, extraParameters?: any, perPage?: number) {
	return function () {
		return fetch(URL_SEARCHER_GET_TALENTS(page, perPage, extraParameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
