import { CategoryType } from "@api";

import CategoryModel, { Category } from "./Category";

export interface Categories {
	categories: Category[];
}

class CategoriesModel implements Categories {
	categories: CategoryModel[] = [];

	constructor(categories: CategoryModel[]) {
		this.categories = categories || [];
	}

	getCategories = () => {
		return this.categories;
	};

	setCategories = (categories: CategoryModel[]) => {
		if (categories) {
			this.categories = categories;
		}
	};

	filterById = (ids: number | number[]): CategoryModel | CategoryModel[] => {
		if (Array.isArray(ids)) {
			return this.categories.filter((category) => ids.includes(Number(category.id)));
		} else {
			const category = this.categories.filter((category) => ids === Number(category.id)).shift();

			return category ? category : [];
		}
	};

	filterByKey = (keys: string | string[]): CategoryModel | CategoryModel[] => {
		if (Array.isArray(keys)) {
			return this.categories.filter((category) => keys.includes(category.key));
		} else {
			const category = this.categories.filter((category) => keys === category.key).shift();

			return category ? category : [];
		}
	};

	filterByCategoryType = (
		categoryTypes: CategoryType | CategoryType[],
		forceWithTranslations: boolean = false,
	): CategoryModel[] => {
		let categories: CategoryModel[];

		if (Array.isArray(categoryTypes)) {
			categories = this.categories.filter((category) => categoryTypes.includes(category.category!!));
		} else {
			categories = this.categories.filter((category) => categoryTypes === category.category);
		}

		if (forceWithTranslations) {
			return categories.filter((category) => category.text);
		}

		return categories;
	};

	filterByKeyAndCategoryType = (
		keys: string[],
		categoryTypes: CategoryType | CategoryType[],
	): CategoryModel | CategoryModel[] => {
		let selectedCategories: CategoryModel[] = this.filterByKey(keys) as CategoryModel[];

		if (Array.isArray(categoryTypes)) {
			return selectedCategories.filter((category) => categoryTypes.includes(category.category!!));
		} else {
			return selectedCategories.filter((category) => categoryTypes === category.category);
		}
	};

	filterByIdsAndCategoryType = (ids: number[], categoryTypes: CategoryType | CategoryType[]): CategoryModel[] => {
		let selectedCategories: CategoryModel[] = this.filterById(ids) as CategoryModel[];

		if (Array.isArray(categoryTypes)) {
			return selectedCategories.filter((category) => categoryTypes.includes(category.category!!));
		} else {
			return selectedCategories.filter((category) => categoryTypes === category.category);
		}
	};

	static generateFromAPI(data: any) {
		const categories = data.items && data.items.map((item: any) => CategoryModel.generateFromAPI(item));

		return new CategoriesModel(categories);
	}
}

export default CategoriesModel;
