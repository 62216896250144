import { BusinessProfileTypes } from "constants/Profiles";
import {
	URL_GET_ADVISER_ADVISED_CHALLENGES,
	URL_GET_ADVISER_PROFILE,
	URL_GET_ADVISER_PROFILE_BY_NAME,
	URL_GET_ADVISER_TENDERS,
	URL_GET_ALL_ACCELERATORS_PROFILES,
	URL_GET_ALL_COMPANY_PROFILES,
	URL_GET_ALL_INCUBATORS_PROFILES,
	URL_GET_ALL_INSTITUTIONS_PROFILES,
	URL_GET_ALL_INVESTOR_PROFILES,
	URL_GET_ALL_UNIVERSITIES_PROFILES,
	URL_GET_COMPANY_PROFILE,
	URL_GET_INSTITUTION_PROFILE,
	URL_GET_INVESTOR_PROFILE,
	URL_GET_UNIVERSITY_PROFILE,
	URL_PATCH_ACCEPT_INSTITUTION_AGREEMENT,
	URL_PATCH_ADVISER_PROFILE,
	URL_PATCH_COMPANY_PROFILE,
	URL_PATCH_INSTITUTION_PROFILE,
	URL_PATCH_INVESTOR_PROFILE,
	URL_PATCH_UNIVERSITY_PROFILE,
	URL_POST_ADVISER_PROFILE,
} from "./urls";
import {
	defaultError,
	getGetHeaderWithCredentials,
	getPatchHeaderWithCredentials,
	getPostHeaderWithCredentials,
} from "api/config";

export function getInstitution(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_INSTITUTION_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllInstitutions(accessToken: string, page: number, parameters?: any) {
	return function () {
		return fetch(URL_GET_ALL_INSTITUTIONS_PROFILES(page, parameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchInstitution(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_INSTITUTION_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchAcceptInstitutionAgreement(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_ACCEPT_INSTITUTION_AGREEMENT(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getUniversity(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_UNIVERSITY_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllUniversities(accessToken: string, page: number, parameters?: any) {
	return function () {
		return fetch(URL_GET_ALL_UNIVERSITIES_PROFILES(page, parameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchUniversity(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_UNIVERSITY_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAdviser(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ADVISER_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAdviserByName(name: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ADVISER_PROFILE_BY_NAME(name), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAdviserTendersAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ADVISER_TENDERS(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAdviserAdvisedChallengesAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ADVISER_ADVISED_CHALLENGES(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function fetchNewAdviser(accessToken: string, data: object) {
	return function () {
		return fetch(URL_POST_ADVISER_PROFILE, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchAdviser(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_ADVISER_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getInvestor(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_INVESTOR_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllInvestors(
	accessToken: string,
	page: number,
	bpType: BusinessProfileTypes,
	extraParameters?: any,
) {
	return function () {
		return fetch(URL_GET_ALL_INVESTOR_PROFILES(page, bpType, extraParameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllAccelerators(accessToken: string, page: number, parameters?: any) {
	return function () {
		return fetch(URL_GET_ALL_ACCELERATORS_PROFILES(page, parameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllIncubators(accessToken: string, page: number, parameters?: any) {
	return function () {
		return fetch(URL_GET_ALL_INCUBATORS_PROFILES(page, parameters), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchInvestor(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_INVESTOR_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getCompanyAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_COMPANY_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAllCompaniesAPI(
	accessToken: string,
	bpType: BusinessProfileTypes,
	markets: string[],
	regions: string[],
	page: number,
	perPage: number = 20,
) {
	return function () {
		return fetch(
			URL_GET_ALL_COMPANY_PROFILES(bpType, markets, regions, page, perPage),
			getGetHeaderWithCredentials(accessToken),
		)
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchCompany(accessToken: string, slug: string, data: object) {
	return function () {
		return fetch(URL_PATCH_COMPANY_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
