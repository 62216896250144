import { Router } from "next/router";
import ProfileModel from "models/profiles/Profile";
import React, { useEffect, useState } from "react";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { BrowserUtils, CookiesUtils } from "@utils";
import { useAnalytics } from "@libs";
import { useBusinessProfilesByCurrentUserQuery, useUserLoggedInQuery, useUserProfileLoggedInQuery } from "@api";
import { VingletLoader } from "@common";

import { UserProfileContext, userProfileContextDefaultValue } from ".";
import classNames from "classnames";

export const UserProfileProvider = ({ children, router }: { children: JSX.Element; router: Router }) => {
	const { pageView } = useAnalytics();
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const hasAnAccessToken = Boolean(accessToken);
	const [appLoading, setAppLoading] = useState(true);

	const { data: user = null, isLoading: isUserLoading } = useUserLoggedInQuery({
		enabled: hasAnAccessToken,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
	const { data: userProfile = ProfileModel.generateEmpty(), isLoading: isUserProfileLoading } =
		useUserProfileLoggedInQuery({
			enabled: hasAnAccessToken,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		});
	const { data: businessProfiles = [], isLoading: isBusinessProfilesLoading } = useBusinessProfilesByCurrentUserQuery({
		enabled: hasAnAccessToken,
	});

	const isUserDataLoading = hasAnAccessToken && (isUserProfileLoading || isUserLoading);

	if (BrowserUtils.isRunningOnBrowser()) {
		if (userProfile?.id) {
			CookiesUtils.setCookieForever("idProfile", String(userProfile.id));
		}

		pageView(router.pathname, userProfile);
	}

	useEffect(() => {
		setAppLoading(false);
	}, []);

	return (
		<UserProfileContext.Provider
			value={
				accessToken
					? {
							user,
							isUserLoading,
							userProfile: ProfileModel.generateFromAPI(userProfile),
							isUserProfileLoading,
							businessProfiles: businessProfiles.map((businessProfile: any) =>
								BusinessProfileModel.generateFromAPI(businessProfile),
							),
							isBusinessProfilesLoading,
					  }
					: userProfileContextDefaultValue
			}
		>
			<div className={classNames(appLoading || isUserDataLoading ? "" : "hide")}>
				<VingletLoader />
			</div>
			<div className={classNames(appLoading || isUserDataLoading ? "hide" : "")}>{children}</div>
		</UserProfileContext.Provider>
	);
};
