import axios from "axios";
import queryString from "query-string";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { BusinessProfile, getRequestConfig, InvestorTypes, PROD_URL } from "@api";
import { getStringifiedList } from "@libs";

interface FetchInvestorsRequestAverageInvestmentParams {
	"search[min_averageInvestment]": number | undefined;
	"search[max_averageInvestment]": number | undefined;
}

interface FetchInvestorsRequestAverageEquityParams {
	"search[min_averageInvestmentPercentage]": number | undefined;
	"search[max_averageInvestmentPercentage]": number | undefined;
}

interface FetchInvestorsRequestNumberOfStartupsToInvestParams {
	"search[min_numberOfStartupsToInvest]": number | undefined;
	"search[max_numberOfStartupsToInvest]": number | undefined;
}

export interface FetchInvestorsRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[investorType]"?: InvestorTypes;
	"search[regions]"?: number[];
	"search[sectors]"?: number[];
	"search[languages]"?: number[];
	averageInvestment?: FetchInvestorsRequestAverageInvestmentParams;
	averageEquity?: FetchInvestorsRequestAverageEquityParams;
	numberOfStartupsToInvest?: FetchInvestorsRequestNumberOfStartupsToInvestParams;
}

export interface FetchInvestorsResponse {
	total: number;
	page: number;
	num_pages: number;
	items: BusinessProfile[];
}

export function fetchInvestors(queryParams: FetchInvestorsRequestParams) {
	const params = {
		page: queryParams.page,
		perPage: queryParams.perPage,
		orderDirection: queryParams.orderDirection,
		"search[investorType]": queryParams["search[investorType]"] || "",
		"search[type]": queryParams["search[investorType]"] || "",
		"search[regions]": getStringifiedList(queryParams["search[regions]"]),
		"search[sectors]": getStringifiedList(queryParams["search[sectors]"]),
		"search[languages]": getStringifiedList(queryParams["search[languages]"]),
		"search[min_averageInvestment]": queryParams.averageInvestment?.["search[min_averageInvestment]"] || "",
		"search[max_averageInvestment]": queryParams.averageInvestment?.["search[max_averageInvestment]"] || "",
		"search[min_averageInvestmentPercentage]":
			queryParams.averageEquity?.["search[min_averageInvestmentPercentage]"] || "",
		"search[max_averageInvestmentPercentage]":
			queryParams.averageEquity?.["search[max_averageInvestmentPercentage]"] || "",
		"search[min_numberOfStartupsToInvest]":
			queryParams.numberOfStartupsToInvest?.["search[min_numberOfStartupsToInvest]"] || "",
		"search[max_numberOfStartupsToInvest]":
			queryParams.numberOfStartupsToInvest?.["search[max_numberOfStartupsToInvest]"] || "",
	};

	return function () {
		return axios
			.get<FetchInvestorsResponse>(`${PROD_URL}/investors-profiles`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
}

export const useInvestorsQuery = (
	queryParams: FetchInvestorsRequestParams,
	options: UseQueryOptions<FetchInvestorsResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchInvestorsResponse>(["investors", queryParams], fetchInvestors(queryParams), {
		...options,
		onSuccess: (investors: FetchInvestorsResponse) => {
			if (investors && investors.items) {
				investors.items.forEach((investor) => {
					queryClient.setQueryData(["business-profile", investor.slug], investor);
				});
			}

			options.onSuccess && options.onSuccess(investors);
		},
	});
};
