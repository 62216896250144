import { DatesPrt } from "i18n/localization/dates/pt";
import { FeaturesPrt } from "i18n/localization/features/pt";
import { WolrdPrt } from "i18n/localization/world/pt";

export const localizationPrt = `
[  
    {
        "field": "dates",
        "translations": [
            {
                "lang": "pt",
                "data": ${DatesPrt}
            }
        ]
    },
    {
        "field": "features",
        "translations": [
            {
                "lang": "pt",
                "data": ${FeaturesPrt}
            }
        ]
    },
    {
        "field": "world",
        "translations": [
            {
                "lang": "pt",
                "data": ${WolrdPrt}
            }
        ]
    }
]
`;
