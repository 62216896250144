export const FeaturesEs = `
{
    "gender": {
      "key": {
        "singular": "Genero",
        "plural": "Generos"
      },
      "items": {
        "male": "Masculino",
        "female": "Femenino",
        "indeterminate": "Sin mencionar"
      }
    },
    "area": {
      "key": {
        "singular": "Area",
        "plural": "Areas"
      },
      "items": {
        "tech": "Tecnologia",
        "business": "Negocios",
        "science": "Ciencia"
      }
    },
    "personalSkills": {
      "key": {
        "singular": "Personal Skills",
        "plural": "Personal Skills"
      },
      "items": {
        "positiveAttitude": "Actitud positiva",
        "adaptationToChange": "Adaptación al cambio",
        "autonomy": "Autonomía",
        "resolutionCreativity": "Capacidad resolutiva",
        "creativity": "Creatividad",
        "curiosity": "Curiosidad",
        "empathy": "Empatía",
        "activeListening": "Escucha activa",
        "focusAndVision": "Foco y visión",
        "integrity": "Integridad",
        "emotionalInteligence": "Inteligencia emocional",
        "criticalThinking": "Pensamiento crítico",
        "teamwork": "Trabajo en equipo",
        "commitment": "Compromiso",
        "flexibility": "Flexibilidad",
        "frustrationTolerance": "Tolerancia a la frustración",
        "motivation": "Motivación",
        "negotiation": "Negociación",
        "patience": "Patience",
        "persuasion": "Persuasión",
        "skillsToSolveProblems": "Habilidades para resolver problemas",
        "workEthic": "Ética de trabajo"
      }
    },
    "businessSkills": {
      "key": {
        "singular": "Habilidad en negocio",
        "plural": "Habilidades en negocio"
      },
      "items": {
        "communicationAndNegotiation": "Comunicación y negociación",
        "delegation": "Delegación",
        "financialManagement": "Gestión financiera",
        "leadership": "Liderazgo",
        "networking": "Redes",
        "problemSolving": "Resolución de problemas"
      }
    },
    "salesAndMarketingSkills": {
      "key": {
        "singular": "Ventas y Marketing",
        "plural": "Ventas y Marketing"
      },
      "items": {
        "buyerSellerAgreement": "Acuerdo comprador-vendedor",
        "communication": "Comunicación",
        "demoSkills": "Habilidades de demostración",
        "productKnowledge": "Conocimiento del producto",
        "qualificationQuestioning": "Preguntas de calificación",
        "rapportBuilding": "Construcción de relaciones",
        "strategicProspectingSkills": "Habilidades de prospección estratégica"
      }
    },
    "investmentSkills": {
      "key": {
        "singular": "Investment",
        "plural": "Investments"
      },
      "items": {
        "confidence": "Confianza",
        "determination": "Determinación",
        "goodNumerical": "Buena numérica",
        "selfMotivation": "Automotivación",
        "teamworkingSkills": "Habilidades de trabajo en equipo",
        "understandingOfFinancialMarkets": "Comprensión de los mercados financieros",
        "workUnderPressure": "Trabajar bajo presión"
      }
    },
    "designAndProductoSkills": {
      "key": {
        "singular": "Diseño y Producto",
        "plural": "Diseño y Producto"
      },
      "items": {
        "ABTesting": "Prueba A / B",
        "benchmarking": "Benchmarking",
        "dataCollection": "Recopilación, extracción y análisis de datos",
        "fabrication": "Fabricación",
        "interactionDesing": "Diseño de interacción",
        "learningAboutCode": "Aprendiendo sobre el código",
        "prototyping": "Prototipos",
        "serviceDesign": "Diseño de servicio",
        "visualDesign": "Diseño visual"
      }
    },
    "itSkills": {
      "key": {
        "singular": "IT",
        "plural": "IT"
      },
      "items": {
        "digitalCommunications": "Comunicaciones digitales",
        "goalOriented": "Orientado a Objetos",
        "informationAndCommunicationsTechnology": "Tecnología de información y comunicaciones",
        "manageRemoteWorkingTeams": "Administrar equipos de trabajo remotos",
        "meetingDeadlines": "Cumplimiento de plazos",
        "multitasking": "Multitarea",
        "reviewProcessesForImprovement": "Procesos de revisión para mejorar",
        "scheduling": "Planificación"
      }
    },
    "legalSkills": {
      "key": {
        "singular": "Legal",
        "plural": "Legal"
      },
      "items": {
        "analyticalAndLogicalReasoning": "Razonamiento Analítico y Lógico",
        "clientService": "Servicio al cliente",
        "legalProcedure": "Procedimiento legal",
        "legalResearch": "Investigación legal",
        "oralCommunication": "Comunicación oral",
        "organization": "Organización",
        "technologySkills": "Habilidades tecnológicas",
        "writtenCommunication": "Comunicación escrita"
      }
    },
    "accountantSkills": {
      "key": {
        "singular": "Contable",
        "plural": "Contable"
      },
      "items": {
        "adaptability": "Adaptability",
        "analyticalSkills": "Analytical Skills",
        "financialSkills": "Financial Skills",
        "generalBusinessSkills": "General Business Skills",
        "industryKnowledge": "Industry Knowledge",
        "informationTechnologySkills": "Information Technology Skills",
        "interpersonalCommunication": "Interpersonal Communication",
        "organizationalSkills": "Habilidades Organizacionales"
      }
    },
    "engineeringAndArchitectureSkills": {
      "key": {
        "singular": "Ingeniería y Arquitectura",
        "plural": "Ingeniería y Arquitectura"
      },
      "items": {
        "budgeting": "Presupuesto",
        "calculations": "Cálculos",
        "computerScience": "Ciencias de la Computación",
        "conceptualandLogical": "Conceptual y lógico",
        "convertingScaleFromBlueprints": "Convertir escala de planos",
        "estimating": "Estimando",
        "nanotechnology": "Nanotecnología",
        "physicalDataModeling": "Modelado de datos físicos",
        "processManagement": "Gestión de proceso",
        "programmingLanguages": "Lenguajes de programación",
        "specifications": "Especificaciones",
        "statistics": "Estadísticas",
        "structuralAnalysis": "Análisis estructural"
      }
    },
    "marketplaceType": {
      "key": {
        "singular": "Tipo de Marketplace",
        "plural": "Tipos de Marketplace"
      },
      "items": {
        "product": "Producto",
        "service": "Servicio"
      }
    },
    "school": {
      "key": {
        "singular": "Facultad",
        "plural": "Facultades"
      },
      "items": {
        "agronomy": "Agronomia",
        "nutrition": "Nutricion",
        "feeding": "Alimentación",
        "architecture": "Arquitectura",
        "fineArts": "Bellas Artes",
        "librarianship": "Bibliotecario",
        "biology": "Biologia",
        "biochemistry": "Bioquímica",
        "earthScience": "Ciencias Naturalez",
        "exactScience": "Ciencias Exactas",
        "socialScience": "Ciencias Sociales",
        "cinema": "Cine",
        "law": "Leyes",
        "design": "Diseño",
        "documentation": "Documentación",
        "economyAndCompany": "Administración de Empresas",
        "information_and_audiovisual_media": "Medios de información y audiovisuales",
        "pharmacy": "Farmacologia",
        "philology": "Filologia",
        "philosofy": "Filosofía",
        "phisyc": "Física",
        "geography": "Geografía",
        "history": "Historia",
        "it": "Desarrollo IT",
        "engineer": "Ingeniería",
        "math": "Matemática",
        "medicine": "Medicina",
        "psicology": "Psicología",
        "chemistry": "Quimica",
        "health": "Salud",
        "townPlanning": "Urbanismo",
        "veterinary": "Veterinaria"
      }
    },
    "topic": {
      "key": {
        "singular": "Temática",
        "plural": "Temáticas"
      },
      "items": {
        "education": "Educación",
        "mobile": "Móvil",
        "personalizedMedicine": "Medicina personalizada",
        "syntheticBiology": "Biología sintética",
        "government": "Gobierno",
        "foodAndDrinks": "Alimentos y bebidas",
        "travelToTheSpace": "Viajar al espacio",
        "agronomy": "Agronomía",
        "healthServices": "Servicios de salud",
        "internetWebservices": "Internet / Servicios Web",
        "legal": "Legal",
        "cattleRaising": "Ganadería",
        "cleanEnergy": "Energía limpia",
        "robotics": "Robótica",
        "water": "Desalinización del agua",
        "cloudEducation": "Educación en la nube",
        "lifestyle": "Estilo de vida",
        "mediaAndEntertainment": "Medios de comunicación y entretenimiento",
        "cleanTransportation": "Transporte limpio",
        "oilAndGas": "Petróleo y gas",
        "dataStorage": "Almacenamiento de datos",
        "transport": "Transporte",
        "construction": "Construcción",
        "sports": "Deportes",
        "trip": "Viaje",
        "retailSale": "Venta minorista",
        "brainaging": "Envejecimiento del cerebro",
        "virtualReality": "Realidad virtual",
        "aerospace": "Aeroespacial",
        "3dPrint": "Impresión 3d",
        "blockChain": "Blockchain",
        "electronicsInstrumentation": "Electrónica / Instrumentación",
        "itServices": "Servicios de informática",
        "fintech": "Fintech",
        "artificialIntelligence": "Inteligencia artificial",
        "syntheticMaterials": "Materiales sintéticos",
        "financialServices": "Servicios financieros",
        "pc": "PC",
        "smartThings": "Smartthings",
        "augmentEdreality": "Realidad aumentada",
        "brainConnectomy": "Conectoma cerebral",
        "logistics": "Logística",
        "smartCities": "Ciudades inteligentes",
        "fishing": "Pesca",
        "biotechnology": "Biotecnología",
        "digitalAdvertising": "Publicidad digital",
        "others": "Otros",
        "smartphonesWithContactlenses": "Smartphones con lentes de contacto",
        "artificialFood": "Comida artificial",
        "foreignTrade": "Comercio Exterior",
        "customerService": "Servicio al Cliente",
        "nanoTechnology": "Nanotecnología",
        "fashion": "Moda",
        "naturalUserInterfaces": "Interfaces de usuario naturales",
        "realEstate": "Bienes raíces",
        "software": "Software",
        "telecommunications": "Telecomunicaciones",
        "geneSequencing": "Secuenciación de genes",
        "computersAndPeripherals": "Computadoras y periféricos",
        "marketingAds": "Anuncios de marketing",
        "gaming": "Juegos",
        "bigData": "Big data",
        "chemicalsAndProductChemicals": "Productos químicos y químicos",
        "commerce": "Comercio",
        "electronicsDevices": "Dispositivos electrónicos",
        "networksAndEquipment": "Redes y equipamiento",
        "quantumComputing": "Computación cuántica",
        "drones": "Drones",
        "cybersecurity": "La seguridad cibernética",
        "iot": "IoT",
        "climateEngineering": "Ingeniería climática",
        "medicalDevicesAndEquipment": "Dispositivos y equipos médicos",
        "consumerProducts": "Productos de consumo",
        "productsForCompanies": "Productos para empresas",
        "mining": "Minería",
        "smartCar": "Auto inteligente"
      }
    },
    "careerType": {
      "key": {
        "singular": "Tipo de Carrera",
        "plural": "Tipos de Carreras"
      },
      "items": {
        "course": "Curso",
        "degree": "Grado",
        "master": "Master"
      }
    },
    "careerModality": {
      "key": {
        "singular": "Modalidad",
        "plural": "Modalidades"
      },
      "items": {
        "online": "En linea",
        "presential": "Presencial",
        "semiPresential": "Semi-Presencial"
      }
    },
    "adviserSkills": {
      "key": {
        "singular": "Habilidad del Adviser",
        "plural": "Habilidades del Adviser"
      },
      "items": {
        "careerAdvise": "Consejero de Carrera",
        "financialConsulting": "Consejero Financiero",
        "businessDevelopment": "Desarrollo de Negocios",
        "legal": "Legales",
        "socialMedia": "Redes Sociales",
        "branding": "Marca",
        "salesAnd_lead": "Liderazgo y Ventas",
        "advertising": "Publicidad",
        "crowfounding": "Crowfounding",
        "ventureCapital": "Capital de Riesgo"
      }
    },
    "market": {
      "key": {
        "singular": "Sector",
        "plural": "Sectores"
      },
      "items": {
        "aerospace": "Aeroespacial",
        "agriculture": "Agricultura",
        "retailer": "Al por menor",
        "foodAndDrink": "Comidas y Bebidas",
        "marketing": "Marketing",
        "realState": "Inmobiliario",
        "biotechnology": "Biotecnologia",
        "computers": "Computadoras",
        "construction": "Construcción",
        "sports": "Deportes",
        "medicalDevices": "Dispositivos Medicos",
        "education": "Educación",
        "electronic": "Electrónica",
        "lifestyle": "Estilo de Vida",
        "fintech": "Tecnologias Financieras",
        "industryAndEnergy": "Industria y Energia",
        "internet": "Internet",
        "insurtech": "Insurtech",
        "gameOfChance": "Juegos de Azar",
        "legal": "Legal",
        "logistic": "Logistica",
        "entertainmentAndMedia": "Entrenimiento",
        "mining": "Mineria",
        "moda": "Moda",
        "movil": "Móvil",
        "nanotechnology": "Nanotecnologia",
        "others": "Otros",
        "oilAndGas": "Petroleo y Gas",
        "consumableProducts": "Productos Consumibles",
        "companyProducts": "Compañia de Producto",
        "chemistryProducts": "Productos Quimicos",
        "chemistry": "Quimica",
        "networkAndEquipment": "Redes y Equipamiento",
        "robotic": "Robótica",
        "security": "Seguridad",
        "clientService": "Servicio al Cliente",
        "health": "Salud",
        "finance": "Finanzas",
        "it": "Desarrollo IT",
        "software": "Software",
        "cleanTechnology": "Tecnologias Limpias",
        "telecomunication": "Telecomunicación",
        "transport": "Transporte",
        "trips": "Viajes",
        "videogames": "Videojuegos"
      }
    },
    "roles": {
      "key:": {
        "singlular": "Rol",
        "plural": "Roles"
      },
      "items": {
        "CEO": "CEO",
        "COO": "COO",
        "CTO": "CTO",
        "CDO": "CDO",
        "CIO": "CIO",
        "CFO": "CFO",
        "CMO": "CMO",
        "CCO": "CCO",
        "CSO": "CSO",
        "CLO": "CLO",
        "director": "Director",
        "manager": "Gerente",
        "chief": "Jefe",
        "delegate": "Delegado",
        "coordinator": "Coordinador",
        "senior": "Senior",
        "semiSenior": "Semi Senior",
        "junior": "Junior",
        "operative": "Operativo",
        "assistant": "Auxiliar",
        "intern": "Pasante",
        "scholar": "Becario"
      }
    },
    "categoriesBusiness": {
      "key": {
        "singular": "Negocio",
        "plural": "Negocios"
      },
      "items": {
        "businessCoaching": "Business Coaching",
        "businessPlan": "Business Plan",
        "canvas": "Canvas",
        "financialQuery": "Consulta financiera",
        "businessDevelopment": "Desarrollo de negocio",
        "strategy": "Estrategia",
        "executiveCoach": "Executive Coach",
        "legal": "Legal",
        "brandAndPatents": "Marca y patentes",
        "humanResources": "Recursos humanos"
      }
    },
    "categoriesSalesAndMarketing": {
      "key": {
        "singular": "Venta y marketing",
        "plural": "Ventas y marketing"
      },
      "items": {
        "commercialCoaching": "Coaching Comercial",
        "emailMarketing": "Email Marketing",
        "commercialStrategy": "Estrategia comercial",
        "inboundMarketing": "Inbound Marketing",
        "leadsAndSales": "Leads & sales",
        "branding": "Marca",
        "others": "Otro",
        "publishing": "Publicación",
        "advertising": "Publicidad",
        "copywriting": "Redacción",
        "publicRelations": "Relaciones públicas",
        "socialMedia": "Social media",
        "translations": "Traducciones"
      }
    },
    "categoriesInvestment": {
      "key": {
        "singular": "Inversión",
        "plural": "Inversiones"
      },
      "items": {
        "accelerator": "Aceleradora",
        "businessAngel": "Business Angel",
        "ventureCapital": "Capital de riesgo",
        "crowdfunding": "Crowdfunding",
        "incubator": "Incubadora",
        "investmentNegotiation": "Negociación con Inversores",
        "nonprofit": "Sin ánimo de lucro"
      }
    },
    "categoriesDesingAndProduct": {
      "key": {
        "singular": "Disseny i Producte",
        "plural": "Disseny i Producte"
      },
      "items": {
        "agile": "Agile",
        "identity": "Identity",
        "learnStartup": "Learn Startup",
        "metricAndAnalytics": "Metric & Analytics",
        "mvp": "MVP",
        "other": "Other",
        "productManager": "Product Management",
        "projectManager": "Project Management",
        "scrum": "Scrum",
        "userExperience": "User Experience"
      }
    },
    "categoriesIT": {
      "key": {
        "singular": "IT",
        "plural": "IT"
      },
      "items": {
        "itDesing": "Diseño IT",
        "erp": "ERP",
        "leanLogistics": "Lean Logistics",
        "leanManagement": "Lean Management",
        "mobile": "Móvil",
        "reviewAndDiagnosis": "Revisión y Diagnostico",
        "digitalTransformation": "Transformación Digital",
        "web": "Web",
        "Wordpress": "Wordpress"
      }
    },
    "categoriesLegal": {
      "key": {
        "singular": "Legal",
        "plural": "Legales"
      },
      "items": {
        "nda": "Acuerdo de confidencialidad",
        "database": "Base de datos",
        "compliance": "Compliance",
        "softwareDevelopmentContract": "Contrato desarrollo software",
        "intellectualPropertyContract": "Contrato propiedad intelectual",
        "seedContract": "Contrato semilla",
        "lopd": "LOPD",
        "softwareRegistration": "Registro de software",
        "termsAndConditions": "Terminos y condiciones",
        "vehicleSAAS": "Vehículo S.A.S"
      }
    },
    "categoriesAccountant": {
      "key": {
        "singular": "Contable",
        "plural": "Contables"
      },
      "items": {
        "accounting": "Contabilidad",
        "dueDiligence": "Due Diligence",
        "taxationAndTaxes": "Fiscalidad e Impuestos",
        "managementOfPayroll": "Gestión de nóminas",
        "acquisitionOperations": "Operaciones de adquisición",
        "phantomShares": "Phantom Shares",
        "socialSecurity": "Seguridad Social",
        "stockOptions": "Stock Options"
      }
    },
    "categoriesEngineeringAndArchitecture": {
      "key": {
        "singular": "Ingeniería y Arquitectura",
        "plural": "Ingeniería y Arquitectura"
      },
      "items": {
        "analysisOfTheSupplyChain": "Análisis de la Cadena de Suministros",
        "audits": "Auditorías",
        "quality": "Calidad",
        "viabilityStudy": "Estudio de viabilidad",
        "legalEngineeringAndIndustrialSafety": "Ingeniería legal y seguridad industrial",
        "masterplan": "Masterplan",
        "enviroment": "Medio ambiente",
        "costOptimization": "Optimización de Costes",
        "industrialPlanning": "Planificación industrial",
        "supplyChainAndLogistics": "Supply Chain y Logística"
      }
    },
  "categoriesMedicineAndPsychology": {
    "key": {
      "singular": "Medicina y Psicologia",
      "plural": "Medicina y Psicologia"
    },
    "items": {
      "accreditWorks": "Acreditar trabajos",
      "applyAndInterpretAssessments": "Aplicar e interpretar evaluaciones",
      "clinicalTrials": "Ensayos clínicos",
      "co-reportStudies": "Co-informar estudios",
      "developTreatmentsAndPlans": "Desarrollar tratamientos y planes",
      "directMedicalProjects": "Dirigir proyectos médicos",
      "documentTheInformation": "Documentar la información",
      "evaluateTheResults": "Evaluar los resultados",
      "evaluateTrials": "Evaluar ensayos",
      "gatherInformation": "Recopilar información",
      "investigate": "Investigar",
      "keyDecisionMaker": "Tomador de decisiones claves",
      "meetingCoordinator": "Coordinador de juntas",
      "presentScientificInformation": "Presentar información científica",
      "supervise": "Supervisar",
      "toDiagnose": "Diagnosticar"
    }
  },
    "talentType": {
      "key": {
        "singular": "Tipo de Talent",
        "plural": "Tipos de Talent"
      },
      "items": {
        "employee": "Empleo",
        "co-founder": "Co-fundador",
        "advisor": "Asesor"
      }
    },
    "applierStatus": {
      "key": {
        "singular": "Estat del procés",
        "plural": "Eestat del procés"
      },
      "items": {
        "new": "Nuevo",
        "interview": "Entrevista",
        "extendedOffer": "Oferta extendida",
        "hired": "Contratado",
        "filed": "Archivado"
      }
    },
    "applierStatusForTender": {
      "key": {
        "singular": "Estat del procés",
        "plural": "Eestat del procés"
      },
      "items": {
        "new": "Nuevo",
        "interview": "Entrevista",
        "extendedOffer": "Oferta extendida",
        "withinTheTeam": "Dentro del equipo",
        "filed": "Archivado"
      }
    }
  }
`;
