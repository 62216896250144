import CategoryModel from "models/categories/Category";
import ExperienceModel, { Experience } from "models/experiences/Experience";
import RequestModel, { Request } from "models/requests/Request";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { DateUtils } from "@utils";

export interface ProfileExperience {
	items: Experience[];
	fetching?: Request;
}

class ProfileExperienceModel implements ProfileExperience {
	items: Experience[];
	fetching?: Request;

	constructor(items: Experience[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const categoriesServices = CategoriesServiceSingleton.getList();
		const experiences: Experience[] =
			data.jobs &&
			data.jobs.map((job: any) => {
				const { market: marketTranslations } = TranslationsServiceSingleton.getFeatureTranslations(["market"]);
				const idsSelectedMarkets = job.markets.map((jobMarket: any) => jobMarket.id);
				const selectedMarkets = categoriesServices.filterById(idsSelectedMarkets) as CategoryModel[];

				try {
					selectedMarkets.forEach((markets) => {
						if (marketTranslations.items[markets.key]) {
							markets.setText(marketTranslations.items[markets.key]);
						}
					});
				} catch (err) {}

				return new ExperienceModel(
					job.id,
					DateUtils.getEpochFromAPIFormat(job.date_from),
					DateUtils.getEpochFromAPIFormat(job.date_to),
					job.description,
					job.company,
					job.name,
					job.now,
					selectedMarkets,
				);
			});

		return new ProfileExperienceModel(experiences || [], RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileExperienceModel([], RequestModel.requestLoading());
	};

	exportToAPIRequest = () => {
		return this.items.map((item) => ({
			name: item.name,
			company: item.company,
			markets: item.markets.map((market) => market.id),
			description: item.description,
			date_from: DateUtils.getTimeAPIFormat(item.dateFrom),
			date_to: DateUtils.getTimeAPIFormat(item.dateTo),
			now: item.now,
		}));
	};
}

export default ProfileExperienceModel;
