import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getRequestConfig, INTERACTION_SERVICE_URL } from "@api";

export interface FetchElearningLikesResponse {
	totalLikes: number;
}

export const fetchElearningLikes = (elearningId: number) => {
	return function (): Promise<FetchElearningLikesResponse> {
		return axios
			.get(`${INTERACTION_SERVICE_URL}/like/elearning/${elearningId}`, getRequestConfig())
			.then((response) => response.data);
	};
};

export const useElearningLikesQuery = (
	elearningId: number,
	options: UseQueryOptions<FetchElearningLikesResponse, any, FetchElearningLikesResponse> = {},
) => {
	return useQuery<FetchElearningLikesResponse>(["elearning-likes", elearningId], fetchElearningLikes(elearningId), {
		...options,
	});
};
