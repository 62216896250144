import Image from "next/image";
import classNames from "classnames";
import { CardProps as FlowbiteCardProps } from "flowbite-react";
import { InternalLink } from "../Links/InternalLink";

type CardProps = FlowbiteCardProps & {
	imgHref?: string;
	className?: string;
	imgClassName?: string;
};

export const Card = ({ children, imgSrc, imgAlt, imgHref, className, imgClassName, href }: CardProps) => (
	<div
		className={classNames(
			"flex max-w-sm flex-col",
			"rounded-lg bg-white shadow-md",
			"border border-gray-200",
			"dark:border-gray-700 dark:bg-gray-800",
			className,
		)}
	>
		{imgSrc && imgHref && (
			<InternalLink
				href={imgHref}
				className={classNames("relative block aspect-video w-full", imgClassName)}
			>
				<Image
					className="w-full rounded-t-lg"
					src={imgSrc}
					alt={imgAlt}
					layout="fill"
				/>
			</InternalLink>
		)}
		{href ? (
			<InternalLink
				href={href}
				className="block flex-1 cursor-pointer p-5 hover:bg-gray-100 dark:hover:bg-gray-700"
			>
				{children}
			</InternalLink>
		) : (
			<div className="flex-1 p-5">{children}</div>
		)}
	</div>
);
