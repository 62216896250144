import CategoryModel from "models/categories/Category";
import { StringUtils } from "utils/StringUtils";

export interface Study {
	id: number;
	dateFrom: number;
	dateTo: number;
	description: string;
	institution: string;
	name: string;
	now: boolean;
	school: CategoryModel;
}

class StudyModel implements Study {
	id: number;
	dateFrom: number;
	dateTo: number;
	description: string;
	institution: string;
	name: string;
	now: boolean;
	school: CategoryModel;

	constructor(
		id: number,
		dateFrom: number,
		dateTo: number,
		description: string,
		institution: string,
		name: string,
		now: boolean,
		school: CategoryModel,
	) {
		this.id = id;
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
		this.description = description;
		this.institution = institution;
		this.name = StringUtils.capitalize(name);
		this.now = now;
		this.school = school;
	}
}

export default StudyModel;
