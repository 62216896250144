import { defineMessages } from "react-intl";
import {Locales} from "@libs";

export const languagesMessages = defineMessages<Locales>({
	"en": {
		defaultMessage: "English",
	},
	"es": {
		defaultMessage: "Spanish",
	},
	"ca": {
		defaultMessage: "Catalan",
	},
	"pt": {
		defaultMessage: "Portuguese",
	},
});

export default defineMessages({
	changeLanguage: {
		defaultMessage: "Change language",
	},
});
