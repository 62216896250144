// @ts-nocheck
import { getIdsFromItemsCategories, getSpecificCategory } from "api/CategoriesAPI";
import { CategoryType } from "api/CategoriesAPI/urls";
import { BusinessProfileTypes } from "constants/Profiles";
import { BusinessProfileState } from "redux-app/reducers/BusinessProfileReducer/typing";

function getSocialField(state: BusinessProfileState) {
	const [socials] = [getSpecificCategory(CategoryType.SOCIAL_NETWORK)];
	const socialNetworksList: {
		url: string;
		social_network_id: number;
	}[] = [];

	for (const socialKey in state.social) {
		const socialItem = socials.find((item: any) => item.key === socialKey);

		if (socialItem) {
			socialNetworksList.push({
				url: String(state.social[socialKey]),
				social_network_id: socialItem.id,
			});
		}
	}

	return socialNetworksList;
}

export function* convertBusinessProfileStateToAPIRequest(state: BusinessProfileState): any {
	const [languages, schools, markets, sectors, countries, regions] = [
		getSpecificCategory(CategoryType.LANGUAGES),
		getSpecificCategory(CategoryType.SCHOOL),
		getSpecificCategory(CategoryType.MARKET),
		getSpecificCategory(CategoryType.SECTOR),
		getSpecificCategory(CategoryType.COUNTRY),
		getSpecificCategory(CategoryType.REGION),
	];
	const selectedMarkets = getIdsFromItemsCategories(state.markets.items, markets);
	const selectedSectors = getIdsFromItemsCategories(state.markets.items, sectors);
	const selectedSchools = getIdsFromItemsCategories(state.schools.items, schools);
	const selectedLanguages = getIdsFromItemsCategories(state.languages.items, languages);
	const selectedRegions = getIdsFromItemsCategories(state.regions.items, regions);
	const selectedCountry = countries.find((country: any) => state.info.country === country.key);

	const preparedDataToSend: any = {
		markets:
			state.type === BusinessProfileTypes.UNIVERSITY || state.type === BusinessProfileTypes.INSTITUTION
				? selectedMarkets || []
				: selectedSectors || [],
		// It's the same like "markets" HARDCODED
		sectors: selectedSectors || [],
		schools: selectedSchools || [],
		name: state.info.fullName,
		city: "",
		url: state.info.url,
		email: state.info.email,
		emblem: state.info.emblem,
		type: state.type,
		country: selectedCountry && selectedCountry.id,
		slug: state.info.slug.value,
		description: state.about.text,
		average_investment: state.statistic ? state.statistic.middleFunds : 0,
		average_investment_percentage: state.statistic ? state.statistic.percentajePerStartup : 0,
		number_of_startups_to_invest: state.statistic ? state.statistic.startUpsPerYear : 0,
		social_networks: getSocialField(state),
		regions: selectedRegions || [],
		languages: selectedLanguages || [],
		areas: [] as number[],
	};

	if (state.info.backgroundImage.src) {
		preparedDataToSend.cover_image = state.info.backgroundImage.src;
	}

	if (state.info.avatar.src) {
		preparedDataToSend.profile_image = state.info.avatar.src;
	}

	return preparedDataToSend;
}

export function* convertAdviserBusinessProfileStateToAPIRequest(state: BusinessProfileState): any {
	const [languages, regions, countries, adviserSkills] = [
		getSpecificCategory(CategoryType.LANGUAGES),
		getSpecificCategory(CategoryType.REGION),
		getSpecificCategory(CategoryType.COUNTRY),
		getSpecificCategory(CategoryType.ADVISER_SKILLS),
	];
	const selectedLanguages = getIdsFromItemsCategories(state.languages.items, languages);
	const selectedRegions = getIdsFromItemsCategories(state.regions.items, regions);
	const selectedSkills = getIdsFromItemsCategories(state.adviserSkills?.items, adviserSkills);
	const selectedCountry = countries.find((country: any) => state.info.country === country.key);

	const preparedDataToSend: any = {
		cost: state.adviserStatistic ? state.adviserStatistic.wagePerHour : 0,
		currency: "USD",
		percentage: state.adviserStatistic ? state.adviserStatistic.percentajePerStartup : 0,
		name: state.info.fullName,
		city: "",
		url: state.info.url,
		email: state.info.email,
		emblem: state.info.emblem,
		type: state.type,
		country: selectedCountry && selectedCountry.id,
		slug: state.info.slug.value,
		description: state.about.text,
		social_networks: getSocialField(state),
		languages: selectedLanguages,
		areas: [] as any[],
		skills: selectedSkills,
		region: selectedRegions,
	};

	if (state.info.backgroundImage.src) {
		preparedDataToSend.cover_image = state.info.backgroundImage.src;
	}

	if (state.info.avatar.src) {
		preparedDataToSend.profile_image = state.info.avatar.src;
	}

	return preparedDataToSend;
}
