import * as React from "react";

import { BadgeCheckSolid, Beaker, Card, InternalLink, LocationMarker } from "@common";

interface UniversityCardProps {
	name: string;
	href: string;
	imgSrc: string;
	country: string;
	agreementAccepted: boolean;
	schoolsNames: string[];
}

const MAX_SCHOOLS_TO_DISPLAY = 4;

export const UniversityCard = ({
	name,
	href,
	imgSrc,
	country,
	agreementAccepted,
	schoolsNames,
}: UniversityCardProps) => {
	let schoolNamesText = "";

	if (schoolsNames.length > MAX_SCHOOLS_TO_DISPLAY) {
		const firstFourSchoolsNames = schoolsNames.slice(0, MAX_SCHOOLS_TO_DISPLAY);
		schoolNamesText = firstFourSchoolsNames.join(", ") + ` +${schoolsNames.length - MAX_SCHOOLS_TO_DISPLAY}`;
	} else {
		schoolNamesText = schoolsNames.join(", ");
	}

	return (
		<Card
			className="h-full"
			imgAlt={name}
			imgSrc={imgSrc}
			imgHref={href}
			imgClassName="aspect-square"
		>
			<div className="flex h-full flex-col justify-between text-left">
				<InternalLink
					href={href}
					className="mb-3 block flex-1"
				>
					<h5 className="mt-0 flex items-center text-lg font-semibold capitalize tracking-tight text-gray-900 line-clamp-2 hover:text-blue-600 dark:text-white">
						{name}
						{agreementAccepted && <BadgeCheckSolid className="ml-2 h-6 w-6 shrink-0 text-blue-500" />}
					</h5>
				</InternalLink>
				<ul className="mb-6 flex w-full flex-col gap-4 text-neutral-500">
					<li className="flex gap-2">
						<LocationMarker className="h-6 w-6" />
						<span>{country}</span>
					</li>
					<li className="flex gap-2">
						<Beaker className="h-6 w-6 flex-shrink-0" />
						<span>{schoolNamesText}</span>
					</li>
				</ul>
			</div>
		</Card>
	);
};
