import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

const deleteWebinar = (webinarId: string, config: AxiosRequestConfig) =>
	axios.delete(`${PROD_URL}/streamings/${webinarId}`, config);

export const useDeleteWebinarMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, string> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((webinarId: string) => deleteWebinar(webinarId, getRequestConfig(accessToken)), {
		...options,
	});
};
