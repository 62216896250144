import { Reducer } from "redux";
import { Elearning } from "redux-app/actions/ElearningAction";
import { ElearningState } from "./typing";

const initialState: ElearningState = {
	fetching: {
		loading: false,
		done: false,
		error: "",
	},
	fetchingSubscribe: {
		loading: false,
		done: false,
		error: "",
	},
};

const elearningReducer: Reducer<ElearningState> = (
	state: ElearningState = initialState,
	action: Elearning.ElearningActionTypes,
) => {
	switch (action.type) {
		case Elearning.ELEARNING_SUBSCRIBE_STARTED:
			state = {
				...state,
				fetchingSubscribe: {
					loading: true,
					done: false,
					error: "",
				},
			};
			break;
		case Elearning.ELEARNING_SUBSCRIBE_FINISHED:
			state = {
				...state,
				fetchingSubscribe: {
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case Elearning.ELEARNING_SUBSCRIBE_FINISHED_WITH_ERRORS:
			state = {
				...state,
				fetchingSubscribe: {
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case Elearning.ELEARNING_SUBSCRIBE_RESET:
			state = {
				...initialState,
			};
			break;
	}

	return state;
};

export default elearningReducer;
