import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

export function fetchDocument(documentName: string, accessToken: string = CookiesUtils.getAccessTokenFromCookie()) {
	return function () {
		return axios
			.get(`${PROD_URL}/document/${documentName}`, {
				...getRequestConfig(accessToken),
				responseType: "blob",
			})
			.then((response) => {
				const linkToTheDocument = document.createElement("a");
				const href = URL.createObjectURL(response.data);

				linkToTheDocument.href = href;
				linkToTheDocument.setAttribute("download", documentName);
				document.body.appendChild(linkToTheDocument);
				linkToTheDocument.click();

				document.body.removeChild(linkToTheDocument);
				URL.revokeObjectURL(href);
			});
	};
}

export const useDocumentQuery = (documentName: string) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery(["document", documentName], fetchDocument(documentName, accessToken));
};
