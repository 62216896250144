import {Locales} from "@libs";

export const PageDirectoryUtils = {
	ROUTE_ELEARNING: "/elearnings",
	ROUTE_MARKETPLACE: "/marketplaces",
	ROUTE_CHALLENGUE: "/challenge",
	ROUTE_CHALLENGUE_TENDER: "/tenders",
	ROUTE_TENDERS: "/tenders",
	ROUTE_TALENT: "/talent",

	ROUTE_ELEARNING_WITH_LOCALE: (locale: Locales) => `/${locale}/elearnings`,
	ROUTE_MARKETPLACE_WITH_LOCALE: (locale: Locales) =>  `/${locale}/marketplaces`,
	ROUTE_CHALLENGUE_WITH_LOCALE: (locale: Locales) =>  `/${locale}/challenge`,
	ROUTE_CHALLENGUE_TENDER_WITH_LOCALE: (locale: Locales) =>  `/${locale}/tenders`,
	ROUTE_TALENT_WITH_LOCALE: (locale: Locales) =>  `/${locale}/talent`,

	getPublicRoutes: (): string[] => {
		return [
			"/signup",
			"/login",
			"/forgot-password",
			"/reset-password",
			"/401",
			"/404",
			"/500",
			"/help",
			"/searcher/challenges",
			"/searcher/challenges/invitations",
			"/elearnings",
			"/elearnings/explorer/courses",
			"/elearnings/explorer/webinars",
			"/elearnings/explorer/workshops",
			"/marketplaces/explorer",
			"/postulate/explorer",
			"/startups/explorer",
			"/talents/explorer",
			"/pricing",
		];
	},

	isAPublicRoute: function (route: string): boolean {
		return this.getPublicRoutes().includes(route);
	},

	getPrivateRoutes: function (): string[] {
		return [
			"/business-profile/new",
			"/profile/new",
			"/settings",
			"/notifications",
			"/mynetwork",
			"/home",
			"/messages",
			this.ROUTE_ELEARNING + "/dashboard",
			this.ROUTE_ELEARNING + "/new",
			this.ROUTE_ELEARNING + "/update",
			this.ROUTE_MARKETPLACE + "/dashboard",
			this.ROUTE_MARKETPLACE + "/new",
			this.ROUTE_MARKETPLACE + "/update",
			this.ROUTE_CHALLENGUE + "/dashboard",
			this.ROUTE_CHALLENGUE + "/new",
			this.ROUTE_CHALLENGUE + "/update",
			this.ROUTE_TENDERS,
			this.ROUTE_TALENT + "/dashboard",
			this.ROUTE_TALENT + "/new",
			this.ROUTE_TALENT + "/update",
		];
	},

	isAPrivateRoute: function (route: string): boolean {
		return this.getPrivateRoutes().includes(route);
	},

	getPrivateRoutesForLoggedUsers: (): string[] => {
		return ["/signup", "/login", "/forgot-password", "/reset-password"];
	},

	isAPrivateRouteForLoggedUsers: function (route: string): boolean {
		return this.getPrivateRoutesForLoggedUsers().includes(route);
	},

	getRoutesWithoutHeader: (): string[] => {
		return ["/signup", "/login", "/profile/new", "/forgot-password", "/reset-password"];
	},

	isARouteWithoutHeader: function (route: string): boolean {
		return this.getRoutesWithoutHeader().includes(route);
	},

	isAnElearningRoute: function (route: string) {
		const elearningRoutes = [
			this.ROUTE_ELEARNING,
			this.ROUTE_ELEARNING + "/dashboard",
			this.ROUTE_ELEARNING + "/new",
			this.ROUTE_ELEARNING + "/update",
		];

		return elearningRoutes.includes(this.ROUTE_ELEARNING + "/" + route);
	},

	isATalentRoute: function (route: string) {
		const elearningRoutes = [
			this.ROUTE_TALENT + "/dashboard",
			this.ROUTE_TALENT + "/new",
			this.ROUTE_TALENT + "/update",
		];

		return elearningRoutes.includes(this.ROUTE_TALENT + "/" + route);
	},

	isAMarketplaceRoute: function (route: string) {
		const marketplaceRoutes = [
			this.ROUTE_MARKETPLACE + "/dashboard",
			this.ROUTE_MARKETPLACE + "/new",
			this.ROUTE_MARKETPLACE + "/update",
		];

		return marketplaceRoutes.includes(this.ROUTE_MARKETPLACE + "/" + route);
	},

	isAChallengeRoute: function (route: string) {
		const challengeRoutes = [
			this.ROUTE_CHALLENGUE + "/dashboard",
			this.ROUTE_CHALLENGUE + "/new",
			this.ROUTE_CHALLENGUE + "/update",
		];

		return challengeRoutes.includes(this.ROUTE_CHALLENGUE + "/" + route);
	},

	isAChallengeTenderRoute: function (route: string) {
		const challengeTenderRoutes = [this.ROUTE_CHALLENGUE_TENDER];

		return challengeTenderRoutes.includes(this.ROUTE_CHALLENGUE + "/" + route);
	},

	isATendersRoute: function (route: string) {
		const challengeTenderRoutes = [this.ROUTE_TENDERS];

		return challengeTenderRoutes.includes(this.ROUTE_TENDERS + "/" + route);
	},

	getRoutesForSitemap: () => {
		return [
			"/signup",
			"/login",
			"/forgot-password",
			"/reset-password",
			"/401",
			"/404",
			"/500",
			"/help",
			"/profile/new",
			"/reset-password",
			"/searcher/challenges",
			"/searcher/challenges/invitations",
			"/elearnings",
			"/elearnings/explorer/courses",
			"/elearnings/explorer/webinars",
			"/elearnings/explorer/workshops",
			"/marketplaces/explorer",
			"/postulate/explorer",
			"/startups/explorer",
			"/talents/explorer",
			"/pricing",
		];
	},
};
