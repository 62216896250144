import axios from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { ElearningListItem } from "./types";

const fetchElearningsByProfile = (profileSlug: string, accessToken: string) => {
	return function (): Promise<ElearningListItem[]> {
		return axios
			.get(`${PROD_URL}/profiles/${profileSlug}/elearnings`, getRequestConfig(accessToken))
			.then((response) => response.data)
			.then((parsedResponse: ElearningListItem[]) => parsedResponse || [])
			.then((elearnings) => elearnings.reverse());
	};
};

export const useElearningsByProfileQuery = (
	profileSlug: string,
	options: UseQueryOptions<ElearningListItem[], any, ElearningListItem[]> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useQuery<ElearningListItem[]>(
		["elearnings", profileSlug],
		fetchElearningsByProfile(profileSlug, accessToken),
		{
			...options,
			onSuccess: (elearnings: ElearningListItem[]) => {
				if (elearnings) {
					elearnings.forEach((elearning) => {
						queryClient.setQueryData(["elearning", elearning.slug], elearning);
					});
				}

				options.onSuccess && options.onSuccess(elearnings);
			},
		},
	);
};
