import axios from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import queryString from "query-string";

import { getRequestConfig, PROD_URL } from "@api";
import { getStringifiedList } from "@libs";

import { Talent } from "./types";

interface FetchAdvisersRequestAverageSalaryParams {
	"search[min_averageSalary]": number | undefined;
	"search[max_averageSalary]": number | undefined;
}

interface FetchAdvisersRequestAverageEquityParams {
	"search[min_averageEquity]": number | undefined;
	"search[max_averageEquity]": number | undefined;
}

export interface FetchTalentsRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[type]"?: number;
	"search[markets]"?: number[];
	"search[sectors]"?: number[];
	"search[countries]"?: number[];
	"search[schools]"?: number[];
	averageSalary?: FetchAdvisersRequestAverageSalaryParams;
	averageEquity?: FetchAdvisersRequestAverageEquityParams;
}

export interface FetchTalentsResponse {
	total: number;
	page: number;
	num_pages: number;
	items: Talent[];
}

export const fetchTalents = (queryParams: FetchTalentsRequestParams) => {
	const params = {
		page: queryParams.page,
		perPage: queryParams.perPage,
		orderDirection: queryParams.orderDirection,
		"search[type]": queryParams["search[type]"] || "",
		"search[markets]": getStringifiedList(queryParams["search[markets]"]),
		"search[sectors]": getStringifiedList(queryParams["search[sectors]"]),
		"search[countries]": getStringifiedList(queryParams["search[countries]"]),
		"search[schools]": getStringifiedList(queryParams["search[schools]"]),
		"search[min_averageSalary]": queryParams.averageSalary?.["search[min_averageSalary]"] || "",
		"search[max_averageSalary]": queryParams.averageSalary?.["search[max_averageSalary]"] || "",
		"search[min_averageEquity]": queryParams.averageEquity?.["search[min_averageEquity]"] || "",
		"search[max_averageEquity]": queryParams.averageEquity?.["search[max_averageEquity]"] || "",
	};

	return function (): Promise<FetchTalentsResponse> {
		return axios
			.get(`${PROD_URL}/talents`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
};

export const useTalentsQuery = (
	queryParams: FetchTalentsRequestParams,
	options: UseQueryOptions<FetchTalentsResponse, any, FetchTalentsResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchTalentsResponse>(["talents", queryParams], fetchTalents(queryParams), {
		...options,
		onSuccess: (talents: FetchTalentsResponse) => {
			if (talents && talents.items) {
				talents.items.forEach((talent) => {
					queryClient.setQueryData(["talent", talent.slug], talent);
				});
			}

			options.onSuccess && options.onSuccess(talents);
		},
	});
};
