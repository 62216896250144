import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

export const KEYWORD_BUSINESS_PROFILE_TYPES = "business_profile_types";

export enum BusinessProfileTypes {
	UNIVERSITY = "UNIVERSITY",
	BUSINESS_ANGEL = "BUSINESS_ANGEL",
	COMPANY = "COMPANY",
	ACCELERATOR = "ACCELERATOR",
	INSTITUTION = "INSTITUTION",
	INCUBATOR = "INCUBATOR",
	ADVISER = "ADVISER",
	CONSULTANT = "CONSULTANT",
	STARTUP = "STARTUP",
}

// This should be equal to the URL of each profile's page
export enum MicroBusinessProfileTypes {
	UNIVERSITY = "university",
	BUSINESS_ANGEL = "business-angel",
	COMPANY = "company",
	ACCELERATOR = "accelerator",
	INSTITUTION = "institution",
	INCUBATOR = "incubator",
	ADVISER = "adviser",
	STARTUP = "startups",
}

export enum TalentType {
	COFOUNDER = "COFOUNDER",
	JOBS = "TALENTS",
	ADVISER = "ADVISER",
}

export enum BusinessProfileColor {
	UNIVERSITY = "blue",
	BUSINESS_ANGEL = "purple",
	COMPANY = "orange",
	ACCELERATOR = "teal",
	INSTITUTION = "yellow",
	INCUBATOR = "green",
	ADVISER = "brown",
	STARTUP = "black",
}

export const BusinessProfileTypesArray = [
	BusinessProfileTypes.ACCELERATOR,
	BusinessProfileTypes.BUSINESS_ANGEL,
	BusinessProfileTypes.COMPANY,
	BusinessProfileTypes.INCUBATOR,
	BusinessProfileTypes.INSTITUTION,
	BusinessProfileTypes.UNIVERSITY,
	BusinessProfileTypes.ADVISER,
	BusinessProfileTypes.STARTUP,
].sort();

export const BusinessProfileTypesDropdown: DropdownItemProps[] = BusinessProfileTypesArray.map((type) => {
	return {
		text: type,
		value: type,
	};
});

export const TalentTypeArray = [TalentType.COFOUNDER, TalentType.JOBS].sort();
