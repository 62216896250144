import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, INTERACTION_SERVICE_URL } from "../config";

interface FetchNewNotificationsWithoutBeingReadPayload {
	areThereNewNotifications: boolean;
}

function fetchNewNotificationsWithoutBeingRead(userId: string, accessToken: string) {
	return function () {
		return axios
			.get(`${INTERACTION_SERVICE_URL}/notification/${userId}/check/new-notifications`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useNewNotificationsWithoutBeingRead = (
	userId: string,
	options: UseQueryOptions<FetchNewNotificationsWithoutBeingReadPayload> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<FetchNewNotificationsWithoutBeingReadPayload>(
		["new-notifications-without-being-read", accessToken],
		fetchNewNotificationsWithoutBeingRead(userId, accessToken),
		{
			...options,
		},
	);
};
