import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Tender } from "./types";

function fetchTendersByChallengeId(challengeId: number, accessToken: string) {
	return function () {
		return axios
			.get<Tender[]>(`${PROD_URL}/challenges/${challengeId}/challenge-tenders`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useTendersByChallengeIdQuery = (challengeId: number, options: UseQueryOptions<Tender[]> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Tender[]>(
		["tenders-by-challenge-id", challengeId],
		fetchTendersByChallengeId(challengeId, accessToken),
		{
			...options,
		},
	);
};
