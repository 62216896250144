export interface Request {
	loading: boolean;
	done: boolean;
	error: string;
}

class RequestModel implements Request {
	loading: boolean;
	done: boolean;
	error: string;

	constructor(loading: boolean, done: boolean, error: string = "") {
		this.loading = loading;
		this.done = done;
		this.error = error;
	}

	static requestDefault(): RequestModel {
		return new RequestModel(false, false, "");
	}

	static requestLoading(): RequestModel {
		return new RequestModel(true, false, "");
	}

	static requestDone(): RequestModel {
		return new RequestModel(false, true, "");
	}

	static requestError(error: string): RequestModel {
		return new RequestModel(false, false, error);
	}

	toObject = (): Request => {
		return {
			loading: this.loading,
			done: this.done,
			error: this.error,
		};
	};
}

export default RequestModel;
