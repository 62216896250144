import { NumberUtils } from "utils/NumberUtils";

export interface TalentRequirementsAdvisor {
	averageEquity: number;
	startupAdvised: number;
	costPerHour: number;
}

class TalentRequirementsAdvisorModel implements TalentRequirementsAdvisor {
	averageEquity: number;
	startupAdvised: number;
	costPerHour: number;

	constructor(averageEquity: number, startupAdvised: number, costPerHour: number) {
		this.averageEquity = averageEquity;
		this.startupAdvised = startupAdvised;
		this.costPerHour = costPerHour;
	}

	getShorterCostPerHour = () => {
		return NumberUtils.convertNumberToShorterNumber(this.costPerHour);
	};

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}) {
		return new TalentRequirementsAdvisorModel(data.percentage, 0, data.cost);
	}

	static generateEmpty() {
		return new TalentRequirementsAdvisorModel(0, 0, 0);
	}

	static generateFromObject(talentObject: TalentRequirementsAdvisor) {
		return new TalentRequirementsAdvisorModel(
			talentObject.averageEquity,
			talentObject.startupAdvised,
			talentObject.costPerHour,
		);
	}
}

export default TalentRequirementsAdvisorModel;
