import { useLocales, Locales } from "@libs";

export enum VideoTutorialProfileAndTopic {
	UNIVERSITY_ELEARNING = "UNIVERSITY_ELEARNING",
	UNIVERSITY_CHALLENGE = "UNIVERSITY_CHALLENGE",
	INVESTOR_ELEARNING = "INVESTOR_ELEARNING",
	INVESTOR_CHALLENGE = "INVESTOR_CHALLENGE",
	COMPANY_ELEARNING = "COMPANY_ELEARNING",
	COMPANY_CHALLENGE = "COMPANY_CHALLENGE",
	INSTITUTION_ELEARNING = "INSTITUTION_ELEARNING",
	INSTITUTION_CHALLENGE = "INSTITUTION_CHALLENGE",
	REGULAR_PROFILE_ELEARNING = "REGULAR_PROFILE_ELEARNING",
}

export type VideoTutorialsUrls = Record<VideoTutorialProfileAndTopic, Record<Locales, string | null>>;

const videoTutorialsUrls: VideoTutorialsUrls = {
	UNIVERSITY_ELEARNING: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=ML38WgVFySQ",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://youtu.be/zJiWhhhMPHs",
	},
	UNIVERSITY_CHALLENGE: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=u_p_5m3yvz8&list=PLnHTQ634OeW2oIhYHDnBOqx5AJzZ1gwqe",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://www.youtube.com/watch?v=aq2v8Z7vEtI&list=PLnHTQ634OeW3WUUdvuXuwM9sl1UuMnTA2",
	},
	INVESTOR_ELEARNING: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=ML38WgVFySQ",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://youtu.be/zJiWhhhMPHs",
	},
	INVESTOR_CHALLENGE: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=u_p_5m3yvz8&list=PLnHTQ634OeW1F17wMRpSFPbveaR-rg00H",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://www.youtube.com/watch?v=cVTuFfFN5io&list=PLnHTQ634OeW00GszLB7LxfJmYdyP2Kl14",
	},
	COMPANY_ELEARNING: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=ML38WgVFySQ",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://youtu.be/zJiWhhhMPHs",
	},
	COMPANY_CHALLENGE: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=FmuqXTMTbO4&list=PLnHTQ634OeW0uCk-b9KV3-jRojw3iII_v",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: null,
	},
	INSTITUTION_ELEARNING: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=ML38WgVFySQ",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://youtu.be/zJiWhhhMPHs",
	},
	INSTITUTION_CHALLENGE: {
		[Locales.spanish]: "https://www.youtube.com/watch?v=imwjZ4NoDU8&list=PLnHTQ634OeW03DqbhFfgUKmQcakebwt7S",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: null,
	},
	REGULAR_PROFILE_ELEARNING: {
		[Locales.spanish]: "https://youtu.be/Y4oV7THpOkM",
		[Locales.english]: null,
		[Locales.catalan]: null,
		[Locales.portuguese]: "https://youtu.be/_tWF0SoN5QI",
	},
};

export const useVideoTutorialUrl = (videoTutorialByProfileAndTopic: VideoTutorialProfileAndTopic | null) => {
	const { currentShortLocale } = useLocales();

	if (!videoTutorialByProfileAndTopic) {
		return null;
	}

	const videoTutorialsUrl = videoTutorialsUrls[videoTutorialByProfileAndTopic];

	if (!currentShortLocale || !videoTutorialsUrl) {
		return null;
	}

	return videoTutorialsUrl[currentShortLocale];
};
