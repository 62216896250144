import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { BusinessProfile } from "./types";

function fetchBusinessProfilesByCurrentUser(accessToken: string) {
	return function () {
		return axios
			.get(`${PROD_URL}/profiles-by-user`, getRequestConfig(accessToken))
			.then((response) => response.data)
			.then((parsedResponse) => parsedResponse.data || []);
	};
}

export const useBusinessProfilesByCurrentUserQuery = (options: UseQueryOptions<BusinessProfile[]> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<BusinessProfile[]>(
		["business-profiles-by-current-user", accessToken],
		fetchBusinessProfilesByCurrentUser(accessToken),
		{
			...options,
		},
	);
};
