import { BrowserUtils } from "@utils";

export function getWindowDimensions() {
	if (BrowserUtils.isRunningOnBrowser()) {
		const { innerWidth: width, innerHeight: height } = window;

		return {
			width,
			height,
		};
	}

	return {
		width: 0,
		height: 0,
	};
}

interface BreakpointsValues {
	mobile: number;
	tablet: number;
	computer: number;
	largeScreen: number;
	widescreen: number;
}

const breakpointsValues: BreakpointsValues = {
	mobile: 0,
	tablet: 768,
	computer: 992,
	largeScreen: 1200,
	widescreen: 1920,
};

const minMobile = breakpointsValues.mobile;
const maxMobile = breakpointsValues.tablet - 1;

const minTablet = breakpointsValues.tablet;
const maxTablet = breakpointsValues.computer - 1;

const minComputer = breakpointsValues.computer;
const maxComputer = breakpointsValues.largeScreen - 1;

const minLargeScreen = breakpointsValues.largeScreen;
const maxLargeScreen = breakpointsValues.widescreen - 1;

export { minMobile, maxMobile, minTablet, maxTablet, minComputer, maxComputer, minLargeScreen, maxLargeScreen };
