import { Category, User } from "@api";

export enum BusinessProfileTypes {
	UNIVERSITY = "UNIVERSITY",
	BUSINESS_ANGEL = "BUSINESS_ANGEL",
	COMPANY = "COMPANY",
	ACCELERATOR = "ACCELERATOR",
	INSTITUTION = "INSTITUTION",
	INCUBATOR = "INCUBATOR",
	ADVISER = "ADVISER",
	CONSULTANT = "CONSULTANT",
	STARTUP = "STARTUP",
}

type Region = Category;
type Area = Category;
type Skill = Category;
type Country = Category;
type Language = Category;
type Sector = Category;
type Market = Category;
type School = Category;

interface SocialNetwork {
	id: number;
	url: string;
	social_network: string;
}

export interface BusinessProfile {
	user: User;
	id: number;
	cost: number;
	average_investment?: number;
	average_investment_percentage?: number;
	number_of_startups_to_invest?: number;
	percentage: number;
	currency: string;
	slug: string;
	description: string | null;
	cover_image: string;
	profile_image: string;
	email: string;
	emblem: string;
	url: string;
	type: BusinessProfileTypes;
	company_type: string;
	name: string;
	investor_type: string;
	city: string;
	institution_type: string;
	want_challenge_invitations: boolean;
	available_user: boolean;
	is_admin: boolean;
	is_creator: boolean;
	agreement_accepted: boolean;
	country: Country;
	followers: any[];
	areas: Area[];
	skills: Skill[];
	markets: Market[];
	schools: School[];
	sectors: Sector[];
	region: Region[];
	regions: Region[];
	languages: Language[];
	social_networks: SocialNetwork[];
}

interface SocialNetworkPayload {
	url: string;
	social_network_id: number;
}

export interface GenericBusinessProfilePayload {
	name: string;
	city: string;
	url: string;
	email: string;
	emblem: string;
	slug: string;
	description: string;
	cover_image?: string;
	profile_image?: string;
	average_investment: number;
	average_investment_percentage: number;
	number_of_startups_to_invest: number;
	country?: number;
	markets: number[];
	sectors: number[];
	schools: number[];
	regions: number[];
	languages: number[];
	areas: number[];
	type: BusinessProfileTypes;
	social_networks: SocialNetworkPayload[];
}
