import * as EmailValidator from "email-validator";
import isUrl from "is-url";

export const isValidEmail = (email: string | undefined | null): boolean => {
	if (!email) {
		return false;
	}

	return EmailValidator.validate(email);
};

export const isLength = (valueToValidate: string, options: { min?: number; max?: number }): boolean => {
	const min = options.min || 0;
	const max = options.max || valueToValidate.length + 1;

	return valueToValidate.length >= min && valueToValidate.length <= max;
};

export const isValidUrl = (urlToValidate: string): boolean => {
	return isUrl(urlToValidate);
};
