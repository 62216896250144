import axios from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import queryString from "query-string";

import { getRequestConfig, PROD_URL } from "@api";
import { getStringifiedList } from "@libs";

import { ElearningListItem } from "./types";

export type ElearningTypeSearch = "Course" | "Webinar" | "Workshop";

interface FetchElearningsRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[type]"?: ElearningTypeSearch;
	"search[schools]"?: number[];
	"search[sectors]"?: number[];
	"search[languages]"?: number[];
	"search[careerModalities]"?: number[];
}

export interface FetchElearningsResponse {
	total: number;
	page: number;
	num_pages: number;
	items: ElearningListItem[];
}

export const fetchElearnings = (queryParams: FetchElearningsRequestParams) => {
	const params = {
		...queryParams,
		"search[schools]": getStringifiedList(queryParams["search[schools]"]),
		"search[sectors]": getStringifiedList(queryParams["search[sectors]"]),
		"search[languages]": getStringifiedList(queryParams["search[languages]"]),
		"search[careerModalities]": getStringifiedList(queryParams["search[careerModalities]"]),
	};

	return function (): Promise<FetchElearningsResponse> {
		return axios
			.get(`${PROD_URL}/elearnings`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
};

export const useElearningsQuery = (
	queryParams: FetchElearningsRequestParams,
	options: UseQueryOptions<FetchElearningsResponse, any, FetchElearningsResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchElearningsResponse>(["elearnings", queryParams], fetchElearnings(queryParams), {
		...options,
		onSuccess: (elearnings: FetchElearningsResponse) => {
			if (elearnings && elearnings.items) {
				elearnings.items.forEach((elearning) => {
					queryClient.setQueryData(["elearning", elearning.slug], elearning);
				});
			}

			options.onSuccess && options.onSuccess(elearnings);
		},
	});
};
