import { CategoryType } from "api/CategoriesAPI/urls";
import CategorySelectableModel from "models/categories/CategorySelectable";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

export interface TalentInfo {
	location: string;
	description: string;
	country: CategorySelectableModel;
	role: CategorySelectableModel;
	type: CategorySelectableModel;
}

class TalentInfoModel implements TalentInfo {
	location: string;
	description: string;
	country: CategorySelectableModel;
	role: CategorySelectableModel;
	type: CategorySelectableModel;

	constructor(
		location: string,
		description: string,
		country: CategorySelectableModel,
		role: CategorySelectableModel,
		type: CategorySelectableModel,
	) {
		this.location = location;
		this.description = description;
		this.country = country;
		this.role = role;
		this.type = type;
	}

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}) {
		const { country, role, type } = data;
		const { country: countryTranslation } = TranslationsServiceSingleton.getWorldTranslations(["country"]);
		const { roles: rolesTranslation, talentType: talentTypesTranslation } =
			TranslationsServiceSingleton.getFeatureTranslations(["roles", "talentType"]);
		const talentCountry = new CategorySelectableModel(
			country.id,
			country.name,
			countryTranslation.items[country.name],
			country.category as CategoryType,
			true,
		);
		const talentType = new CategorySelectableModel(
			type.id,
			type.name,
			talentTypesTranslation.items[type.name],
			type.category as CategoryType,
			true,
		);
		let talentRole;

		if (talentType.key !== "advisor" && data.role) {
			talentRole = new CategorySelectableModel(
				role.id,
				role.name,
				rolesTranslation.items[role.name],
				role.category as CategoryType,
				true,
			);
		}

		return new TalentInfoModel(
			data.city,
			data.description,
			talentCountry,
			talentRole || CategorySelectableModel.generateFromEmpty(),
			talentType,
		);
	}

	static generateEmpty() {
		return new TalentInfoModel(
			"",
			"",
			new CategorySelectableModel(0, "", "", null, false),
			new CategorySelectableModel(0, "", "", null, false),
			new CategorySelectableModel(0, "", "", null, false),
		);
	}

	static generateFromObject(talentObject: TalentInfo) {
		return new TalentInfoModel(
			talentObject.location,
			talentObject.description,
			talentObject.country,
			talentObject.role,
			talentObject.type,
		);
	}
}

export default TalentInfoModel;
