import { BusinessProfileTypes } from "constants/Profiles";
import { ElearningPayload } from "containers/ElearningDashboard/typing";
import ElearningModel from "models/elearnings/Elearning";
import {
	AboutBusinessProfileState,
	AdviseSkillsBusinessProfileState,
	BusinessProfileState,
	InfoBusinessProfileState,
	LanguagesBusinessProfileState,
	MarketsBusinessProfileState,
	PartnersBusinessProfileState,
	RegionBusinessProfileState,
	SchoolsBusinessProfileState,
	StatisticBusinessProfileAdviserState,
	StatisticBusinessProfileState,
	TeamBusinessProfileState,
} from "redux-app/reducers/BusinessProfileReducer/typing";
import { SocialProfilePropsState } from "redux-app/reducers/UserReducer/typing";

export namespace BusinessProfile {
	export const BUSINESS_PROFILE_INIT = "BUSINESS_PROFILE_INIT";
	export const BUSINESS_PROFILE_START_CREATION = "BUSINESS_PROFILE_START_CREATION";
	export const BUSINESS_PROFILE_FINISH_CREATION = "BUSINESS_PROFILE_FINISH_CREATION";
	export const BUSINESS_PROFILE_CREATE_ACCELERATOR = "BUSINESS_PROFILE_CREATE_ACCELERATOR";
	export const BUSINESS_PROFILE_CREATE_ACCELERATOR_FAILED = "BUSINESS_PROFILE_CREATE_ACCELERATOR_FAILED";
	export const BUSINESS_PROFILE_CREATE_ACCELERATOR_SUCCESSED = "BUSINESS_PROFILE_CREATE_ACCELERATOR_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_STARTUP = "BUSINESS_PROFILE_CREATE_STARTUP";
	export const BUSINESS_PROFILE_CREATE_STARTUP_FAILED = "BUSINESS_PROFILE_CREATE_STARTUP_FAILED";
	export const BUSINESS_PROFILE_CREATE_STARTUP_SUCCESSED = "BUSINESS_PROFILE_CREATE_STARTUP_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_INCUBATOR = "BUSINESS_PROFILE_CREATE_INCUBATOR";
	export const BUSINESS_PROFILE_CREATE_INCUBATOR_FAILED = "BUSINESS_PROFILE_CREATE_INCUBATOR_FAILED";
	export const BUSINESS_PROFILE_CREATE_INCUBATOR_SUCCESSED = "BUSINESS_PROFILE_CREATE_INCUBATOR_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL = "BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL";
	export const BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_FAILED = "BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_FAILED";
	export const BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_SUCCESSED = "BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_COMPANY = "BUSINESS_PROFILE_CREATE_COMPANY";
	export const BUSINESS_PROFILE_CREATE_COMPANY_FAILED = "BUSINESS_PROFILE_CREATE_COMPANY_FAILED";
	export const BUSINESS_PROFILE_CREATE_COMPANY_SUCCESSED = "BUSINESS_PROFILE_CREATE_COMPANY_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_INSTITUTION = "BUSINESS_PROFILE_CREATE_INSTITUTION";
	export const BUSINESS_PROFILE_CREATE_INSTITUTION_FAILED = "BUSINESS_PROFILE_CREATE_INSTITUTION_FAILED";
	export const BUSINESS_PROFILE_CREATE_INSTITUTION_SUCCESSED = "BUSINESS_PROFILE_CREATE_INSTITUTION_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_ADVISER = "BUSINESS_PROFILE_CREATE_ADVISER";
	export const BUSINESS_PROFILE_CREATE_ADVISER_FAILED = "BUSINESS_PROFILE_CREATE_ADVISER_FAILED";
	export const BUSINESS_PROFILE_CREATE_ADVISER_SUCCESSED = "BUSINESS_PROFILE_CREATE_ADVISER_SUCCESSED";
	export const BUSINESS_PROFILE_CREATE_UNIVERSITY = "BUSINESS_PROFILE_CREATE_UNIVERSITY";
	export const BUSINESS_PROFILE_CREATE_UNIVERSITY_FAILED = "BUSINESS_PROFILE_CREATE_UNIVERSITY_FAILED";
	export const BUSINESS_PROFILE_CREATE_UNIVERSITY_SUCCESSED = "BUSINESS_PROFILE_CREATE_UNIVERSITY_SUCCESSED";
	export const BUSINESS_PROFILE_INIT_CREATE_ELEARNING = "BUSINESS_PROFILE_INIT_CREATE_ELEARNING";
	export const BUSINESS_PROFILE_FINISH_CREATE_ELEARNING = "BUSINESS_PROFILE_FINISH_CREATE_ELEARNING";
	export const BUSINESS_PROFILE_GET_ELEARNINGS = "BUSINESS_PROFILE_GET_ELEARNINGS";
	export const BUSINESS_PROFILE_GET_ELEARNINGS_STARTED = "BUSINESS_PROFILE_GET_ELEARNINGS_STARTED";
	export const BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED = "BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED";
	export const BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_GET_ONE_ELEARNING = "BUSINESS_PROFILE_GET_ONE_ELEARNING";
	export const BUSINESS_PROFILE_GET_ONE_ELEARNING_STARTED = "BUSINESS_PROFILE_GET_ONE_ELEARNING_STARTED";
	export const BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED = "BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED";
	export const BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_COURSE = "BUSINESS_PROFILE_SET_COURSE";
	export const BUSINESS_PROFILE_SET_COURSE_STARTED = "BUSINESS_PROFILE_SET_COURSE_STARTED";
	export const BUSINESS_PROFILE_SET_COURSE_FAILED = "BUSINESS_PROFILE_SET_COURSE_FAILED";
	export const BUSINESS_PROFILE_SET_COURSE_SUCCEDED = "BUSINESS_PROFILE_SET_COURSE_SUCCEDED";
	export const BUSINESS_PROFILE_SET_WEBINAR = "BUSINESS_PROFILE_SET_WEBINAR";
	export const BUSINESS_PROFILE_SET_WEBINAR_STARTED = "BUSINESS_PROFILE_SET_WEBINAR_STARTED";
	export const BUSINESS_PROFILE_SET_WEBINAR_FAILED = "BUSINESS_PROFILE_SET_WEBINAR_FAILED";
	export const BUSINESS_PROFILE_SET_WEBINAR_SUCCEDED = "BUSINESS_PROFILE_SET_WEBINAR_SUCCEDED";
	export const BUSINESS_PROFILE_UPDATE_COURSE = "BUSINESS_PROFILE_UPDATE_COURSE";
	export const BUSINESS_PROFILE_UPDATE_COURSE_STARTED = "BUSINESS_PROFILE_UPDATE_COURSE_STARTED";
	export const BUSINESS_PROFILE_UPDATE_COURSE_FAILED = "BUSINESS_PROFILE_UPDATE_COURSE_FAILED";
	export const BUSINESS_PROFILE_UPDATE_COURSE_SUCCEDED = "BUSINESS_PROFILE_UPDATE_COURSE_SUCCEDED";
	export const BUSINESS_PROFILE_UPDATE_WEBINAR = "BUSINESS_PROFILE_UPDATE_WEBINAR";
	export const BUSINESS_PROFILE_UPDATE_WEBINAR_STARTED = "BUSINESS_PROFILE_UPDATE_WEBINAR_STARTED";
	export const BUSINESS_PROFILE_UPDATE_WEBINAR_FAILED = "BUSINESS_PROFILE_UPDATE_WEBINAR_FAILED";
	export const BUSINESS_PROFILE_UPDATE_WEBINAR_SUCCEDED = "BUSINESS_PROFILE_UPDATE_WEBINAR_SUCCEDED";
	export const BUSINESS_PROFILE_DELETE_COURSE = "BUSINESS_PROFILE_DELETE_COURSE";
	export const BUSINESS_PROFILE_DELETE_COURSE_STARTED = "BUSINESS_PROFILE_DELETE_COURSE_STARTED";
	export const BUSINESS_PROFILE_DELETE_COURSE_FAILED = "BUSINESS_PROFILE_DELETE_COURSE_FAILED";
	export const BUSINESS_PROFILE_DELETE_COURSE_SUCCEDED = "BUSINESS_PROFILE_DELETE_COURSE_SUCCEDED";
	export const BUSINESS_PROFILE_DELETE_WEBINAR = "BUSINESS_PROFILE_DELETE_WEBINAR";
	export const BUSINESS_PROFILE_DELETE_WEBINAR_STARTED = "BUSINESS_PROFILE_DELETE_WEBINAR_STARTED";
	export const BUSINESS_PROFILE_DELETE_WEBINAR_FAILED = "BUSINESS_PROFILE_DELETE_WEBINAR_FAILED";
	export const BUSINESS_PROFILE_DELETE_WEBINAR_SUCCEDED = "BUSINESS_PROFILE_DELETE_WEBINAR_SUCCEDED";
	export const BUSINESS_PROFILE_SET_ABOUT = "BUSINESS_PROFILE_SET_ABOUT";
	export const BUSINESS_PROFILE_SET_ABOUT_STARTED = "BUSINESS_PROFILE_SET_ABOUT_STARTED";
	export const BUSINESS_PROFILE_SET_ABOUT_FINISHED = "BUSINESS_PROFILE_SET_ABOUT_FINISHED";
	export const BUSINESS_PROFILE_SET_ABOUT_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_ABOUT_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_MARKETS = "BUSINESS_PROFILE_SET_MARKETS";
	export const BUSINESS_PROFILE_SET_MARKETS_STARTED = "BUSINESS_PROFILE_SET_MARKETS_STARTED";
	export const BUSINESS_PROFILE_SET_MARKETS_FINISHED = "BUSINESS_PROFILE_SET_MARKETS_FINISHED";
	export const BUSINESS_PROFILE_SET_MARKETS_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_MARKETS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_SCHOOLS = "BUSINESS_PROFILE_SET_SCHOOLS";
	export const BUSINESS_PROFILE_SET_SCHOOLS_STARTED = "BUSINESS_PROFILE_SET_SCHOOLS_STARTED";
	export const BUSINESS_PROFILE_SET_SCHOOLS_FINISHED = "BUSINESS_PROFILE_SET_SCHOOLS_FINISHED";
	export const BUSINESS_PROFILE_SET_SCHOOLS_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_SCHOOLS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_LANGUAGES = "BUSINESS_PROFILE_SET_LANGUAGES";
	export const BUSINESS_PROFILE_SET_LANGUAGES_STARTED = "BUSINESS_PROFILE_SET_LANGUAGES_STARTED";
	export const BUSINESS_PROFILE_SET_LANGUAGES_FINISHED = "BUSINESS_PROFILE_SET_LANGUAGES_FINISHED";
	export const BUSINESS_PROFILE_SET_LANGUAGES_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_SET_LANGUAGES_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_TEAM = "BUSINESS_PROFILE_SET_TEAM";
	export const BUSINESS_PROFILE_SET_TEAM_STARTED = "BUSINESS_PROFILE_SET_TEAM_STARTED";
	export const BUSINESS_PROFILE_SET_TEAM_FINISHED = "BUSINESS_PROFILE_SET_TEAM_FINISHED";
	export const BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_PARTNERS = "BUSINESS_PROFILE_SET_PARTNERS";
	export const BUSINESS_PROFILE_SET_PARTNERS_STARTED = "BUSINESS_PROFILE_SET_PARTNERS_STARTED";
	export const BUSINESS_PROFILE_SET_PARTNERS_FINISHED = "BUSINESS_PROFILE_SET_PARTNERS_FINISHED";
	export const BUSINESS_PROFILE_SET_PARTNERS_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_SET_PARTNERS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_SOCIAL = "BUSINESS_PROFILE_SET_SOCIAL";
	export const BUSINESS_PROFILE_SET_SOCIAL_STARTED = "BUSINESS_PROFILE_SET_SOCIAL_STARTED";
	export const BUSINESS_PROFILE_SET_SOCIAL_FINISHED = "BUSINESS_PROFILE_SET_SOCIAL_FINISHED";
	export const BUSINESS_PROFILE_SET_SOCIAL_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_SOCIAL_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_INFO = "BUSINESS_PROFILE_SET_INFO";
	export const BUSINESS_PROFILE_SET_INFO_STARTED = "BUSINESS_PROFILE_SET_INFO_STARTED";
	export const BUSINESS_PROFILE_SET_INFO_FINISHED = "BUSINESS_PROFILE_SET_INFO_FINISHED";
	export const BUSINESS_PROFILE_SET_INFO_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_INFO_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_STATISTICS = "BUSINESS_PROFILE_SET_STATISTICS";
	export const BUSINESS_PROFILE_SET_STATISTICS_STARTED = "BUSINESS_PROFILE_SET_STATISTICS_STARTED";
	export const BUSINESS_PROFILE_SET_STATISTICS_FINISHED = "BUSINESS_PROFILE_SET_STATISTICS_FINISHED";
	export const BUSINESS_PROFILE_SET_STATISTICS_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_SET_STATISTICS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_ADVISER_STATISTICS = "BUSINESS_PROFILE_SET_ADVISER_STATISTICS";
	export const BUSINESS_PROFILE_SET_ADVISER_STATISTICS_STARTED = "BUSINESS_PROFILE_SET_ADVISER_STATISTICS_STARTED";
	export const BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED = "BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED";
	export const BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_ADVISER_SKILLS = "BUSINESS_PROFILE_SET_ADVISER_SKILLS";
	export const BUSINESS_PROFILE_SET_ADVISER_SKILLS_STARTED = "BUSINESS_PROFILE_SET_ADVISER_SKILLS_STARTED";
	export const BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED = "BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED";
	export const BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED_WITH_ERRORS =
		"BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_SET_REGIONS = "BUSINESS_PROFILE_SET_REGIONS";
	export const BUSINESS_PROFILE_SET_REGIONS_STARTED = "BUSINESS_PROFILE_SET_REGIONS_STARTED";
	export const BUSINESS_PROFILE_SET_REGIONS_FINISHED = "BUSINESS_PROFILE_SET_REGIONS_FINISHED";
	export const BUSINESS_PROFILE_SET_REGIONS_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_SET_REGIONS_FINISHED_WITH_ERRORS";
	export const BUSINESS_PROFILE_CHECK_SLUG = "BUSINESS_PROFILE_CHECK_SLUG";
	export const BUSINESS_PROFILE_CHECK_SLUG_INIT = "BUSINESS_PROFILE_CHECK_SLUG_INIT";
	export const BUSINESS_PROFILE_CHECK_SLUG_FAILED = "BUSINESS_PROFILE_CHECK_SLUG_FAILED";
	export const BUSINESS_PROFILE_CHECK_SLUG_SUCCEDED = "BUSINESS_PROFILE_CHECK_SLUG_SUCCEDED";
	export const BUSINESS_PROFILE_SET_TYPE = "BUSINESS_PROFILE_SET_TYPE";

	export const BUSINESS_PROFILE_GET = "BUSINESS_PROFILE_GET";
	export const BUSINESS_PROFILE_GET_STARTED = "BUSINESS_PROFILE_GET_STARTED";
	export const BUSINESS_PROFILE_GET_FINISHED = "BUSINESS_PROFILE_GET_FINISHED";
	export const BUSINESS_PROFILE_GET_FINISHED_WITH_ERRORS = "BUSINESS_PROFILE_GET_FINISHED_WITH_ERRORS";

	interface BusinessProfileInit {
		type: typeof BUSINESS_PROFILE_INIT;
		payload: BusinessProfileState;
	}

	interface BusinessProfileStartCreation {
		type: typeof BUSINESS_PROFILE_START_CREATION;
	}

	interface BusinessProfileFinishCreation {
		type: typeof BUSINESS_PROFILE_FINISH_CREATION;
	}

	interface BusinessProfileCreateAcceleratorAction {
		type: typeof BUSINESS_PROFILE_CREATE_ACCELERATOR;
	}

	interface BusinessProfileCreateAcceleratorFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_ACCELERATOR_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateAcceleratorSuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_ACCELERATOR_SUCCESSED;
	}

	interface BusinessProfileCreateStartupAction {
		type: typeof BUSINESS_PROFILE_CREATE_STARTUP;
	}

	interface BusinessProfileCreateStartupFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_STARTUP_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateStartupSuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_STARTUP_SUCCESSED;
	}

	interface BusinessProfileCreateIncubatorAction {
		type: typeof BUSINESS_PROFILE_CREATE_INCUBATOR;
	}

	interface BusinessProfileCreateIncubatorFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_INCUBATOR_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateIncubatorSuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_INCUBATOR_SUCCESSED;
	}

	interface BusinessProfileCreateBusinessAngelAction {
		type: typeof BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL;
	}

	interface BusinessProfileCreateBusinessAngelFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateBusinessAngelSuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_SUCCESSED;
	}

	interface BusinessProfileCreateCompanyAction {
		type: typeof BUSINESS_PROFILE_CREATE_COMPANY;
	}

	interface BusinessProfileCreateCompanyFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_COMPANY_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateCompanySuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_COMPANY_SUCCESSED;
	}

	interface BusinessProfileCreateInstitutionAction {
		type: typeof BUSINESS_PROFILE_CREATE_INSTITUTION;
	}

	interface BusinessProfileCreateInstitutionFailed {
		type: typeof BUSINESS_PROFILE_CREATE_INSTITUTION_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateInstitutionSuccessed {
		type: typeof BUSINESS_PROFILE_CREATE_INSTITUTION_SUCCESSED;
	}

	interface BusinessProfileCreateAdviserAction {
		type: typeof BUSINESS_PROFILE_CREATE_ADVISER;
	}

	interface BusinessProfileCreateAdviserFailedAction {
		type: typeof BUSINESS_PROFILE_CREATE_ADVISER_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateAdviserSuccessedAction {
		type: typeof BUSINESS_PROFILE_CREATE_ADVISER_SUCCESSED;
	}

	interface BusinessProfileCreateUniversityAction {
		type: typeof BUSINESS_PROFILE_CREATE_UNIVERSITY;
	}

	interface BusinessProfileCreateUniversityFailed {
		type: typeof BUSINESS_PROFILE_CREATE_UNIVERSITY_FAILED;
		payload: string;
	}

	interface BusinessProfileCreateUniversitySuccessed {
		type: typeof BUSINESS_PROFILE_CREATE_UNIVERSITY_SUCCESSED;
	}

	interface BusinessProfileInitCreateElearning {
		type: typeof BUSINESS_PROFILE_INIT_CREATE_ELEARNING;
	}

	interface BusinessProfileFinishCreateElearning {
		type: typeof BUSINESS_PROFILE_FINISH_CREATE_ELEARNING;
	}

	interface BusinessProfileGetElearning {
		type: typeof BUSINESS_PROFILE_GET_ELEARNINGS;
		payload: string;
	}

	interface BusinessProfileGetElearningStarted {
		type: typeof BUSINESS_PROFILE_GET_ELEARNINGS_STARTED;
	}

	interface BusinessProfileGetElearningFinished {
		type: typeof BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED;
		payload: ElearningModel[];
	}

	interface BusinessProfileGetElearningFinishedWithErrors {
		type: typeof BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileGetOneElearning {
		type: typeof BUSINESS_PROFILE_GET_ONE_ELEARNING;
		payload: ElearningPayload;
	}

	interface BusinessProfileGetOneElearningStarted {
		type: typeof BUSINESS_PROFILE_GET_ONE_ELEARNING_STARTED;
	}

	interface BusinessProfileGetOneElearningFinished {
		type: typeof BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED;
		payload: ElearningModel;
	}

	interface BusinessProfileGetOneElearningFinishedWithErrors {
		type: typeof BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetCourseAction {
		type: typeof BUSINESS_PROFILE_SET_COURSE;
		payload: ElearningModel;
	}

	interface BusinessProfileSetCourseStartedAction {
		type: typeof BUSINESS_PROFILE_SET_COURSE_STARTED;
	}

	interface BusinessProfileSetCourseSuccededAction {
		type: typeof BUSINESS_PROFILE_SET_COURSE_SUCCEDED;
		payload: ElearningModel;
	}

	interface BusinessProfileSetCourseFailedAction {
		type: typeof BUSINESS_PROFILE_SET_COURSE_FAILED;
		payload: string;
	}

	interface BusinessProfileSetWebinarAction {
		type: typeof BUSINESS_PROFILE_SET_WEBINAR;
		payload: ElearningModel;
	}

	interface BusinessProfileSetWebinarStartedAction {
		type: typeof BUSINESS_PROFILE_SET_WEBINAR_STARTED;
	}

	interface BusinessProfileSetWebinarSuccededAction {
		type: typeof BUSINESS_PROFILE_SET_WEBINAR_SUCCEDED;
		payload: ElearningModel;
	}

	interface BusinessProfileSetWebinarFailedAction {
		type: typeof BUSINESS_PROFILE_SET_WEBINAR_FAILED;
		payload: string;
	}

	interface BusinessProfileUpdateCourseAction {
		type: typeof BUSINESS_PROFILE_UPDATE_COURSE;
		payload: ElearningModel;
	}

	interface BusinessProfileUpdateCourseStartedAction {
		type: typeof BUSINESS_PROFILE_UPDATE_COURSE_STARTED;
	}

	interface BusinessProfileUpdateCourseSuccededAction {
		type: typeof BUSINESS_PROFILE_UPDATE_COURSE_SUCCEDED;
	}

	interface BusinessProfileUpdateCourseFailedAction {
		type: typeof BUSINESS_PROFILE_UPDATE_COURSE_FAILED;
		payload: string;
	}

	interface BusinessProfileUpdateWebinarAction {
		type: typeof BUSINESS_PROFILE_UPDATE_WEBINAR;
		payload: ElearningModel;
	}

	interface BusinessProfileUpdateWebinarStartedAction {
		type: typeof BUSINESS_PROFILE_UPDATE_WEBINAR_STARTED;
	}

	interface BusinessProfileUpdateWebinarSuccededAction {
		type: typeof BUSINESS_PROFILE_UPDATE_WEBINAR_SUCCEDED;
	}

	interface BusinessProfileUpdateWebinarFailedAction {
		type: typeof BUSINESS_PROFILE_UPDATE_WEBINAR_FAILED;
		payload: string;
	}

	interface BusinessProfileDeleteCourseAction {
		type: typeof BUSINESS_PROFILE_DELETE_COURSE;
		payload: string;
	}

	interface BusinessProfileDeleteCourseStartedAction {
		type: typeof BUSINESS_PROFILE_DELETE_COURSE_STARTED;
	}

	interface BusinessProfileDeleteCourseSuccededAction {
		type: typeof BUSINESS_PROFILE_DELETE_COURSE_SUCCEDED;
	}

	interface BusinessProfileDeleteCourseFailedAction {
		type: typeof BUSINESS_PROFILE_DELETE_COURSE_FAILED;
		payload: string;
	}

	interface BusinessProfileDeleteWebinarAction {
		type: typeof BUSINESS_PROFILE_DELETE_WEBINAR;
		payload: string;
	}

	interface BusinessProfileDeleteWebinarStartedAction {
		type: typeof BUSINESS_PROFILE_DELETE_WEBINAR_STARTED;
	}

	interface BusinessProfileDeleteWebinarSuccededAction {
		type: typeof BUSINESS_PROFILE_DELETE_WEBINAR_SUCCEDED;
	}

	interface BusinessProfileDeleteWebinarFailedAction {
		type: typeof BUSINESS_PROFILE_DELETE_WEBINAR_FAILED;
		payload: string;
	}

	interface BusinessProfileSetAboutAction {
		type: typeof BUSINESS_PROFILE_SET_ABOUT;
		payload: AboutBusinessProfileState;
	}

	interface BusinessProfileSetAboutStartedAction {
		type: typeof BUSINESS_PROFILE_SET_ABOUT_STARTED;
	}

	interface BusinessProfileSetAboutFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_ABOUT_FINISHED;
		payload: AboutBusinessProfileState;
	}

	interface BusinessProfileSetAboutFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_ABOUT_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetMarketsAction {
		type: typeof BUSINESS_PROFILE_SET_MARKETS;
		payload: MarketsBusinessProfileState;
	}

	interface BusinessProfileSetMarketsStartedAction {
		type: typeof BUSINESS_PROFILE_SET_MARKETS_STARTED;
	}

	interface BusinessProfileSetMarketsFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_MARKETS_FINISHED;
		payload: MarketsBusinessProfileState;
	}

	interface BusinessProfileSetMarketsFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_MARKETS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetSchoolsAction {
		type: typeof BUSINESS_PROFILE_SET_SCHOOLS;
		payload: SchoolsBusinessProfileState;
	}

	interface BusinessProfileSetSchoolsStartedAction {
		type: typeof BUSINESS_PROFILE_SET_SCHOOLS_STARTED;
	}

	interface BusinessProfileSetSchoolsFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_SCHOOLS_FINISHED;
		payload: SchoolsBusinessProfileState;
	}

	interface BusinessProfileSetSchoolsFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_SCHOOLS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetLanguagesAction {
		type: typeof BUSINESS_PROFILE_SET_LANGUAGES;
		payload: LanguagesBusinessProfileState;
	}

	interface BusinessProfileSetLanguagesStartedAction {
		type: typeof BUSINESS_PROFILE_SET_LANGUAGES_STARTED;
	}

	interface BusinessProfileSetLanguagesFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_LANGUAGES_FINISHED;
		payload: LanguagesBusinessProfileState;
	}

	interface BusinessProfileSetLanguagesFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_LANGUAGES_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetTeamAction {
		type: typeof BUSINESS_PROFILE_SET_TEAM;
		payload: TeamBusinessProfileState;
	}

	interface BusinessProfileSetTeamStartedAction {
		type: typeof BUSINESS_PROFILE_SET_TEAM_STARTED;
	}

	interface BusinessProfileSetTeamFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_TEAM_FINISHED;
		payload: TeamBusinessProfileState;
	}

	interface BusinessProfileSetTeamFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetPartnersAction {
		type: typeof BUSINESS_PROFILE_SET_PARTNERS;
		payload: PartnersBusinessProfileState;
	}

	interface BusinessProfileSetPartnersStartedAction {
		type: typeof BUSINESS_PROFILE_SET_PARTNERS_STARTED;
	}

	interface BusinessProfileSetPartnersFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_PARTNERS_FINISHED;
		payload: PartnersBusinessProfileState;
	}

	interface BusinessProfileSetPartnersFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_PARTNERS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetSocialAction {
		type: typeof BUSINESS_PROFILE_SET_SOCIAL;
		payload: SocialProfilePropsState;
	}

	interface BusinessProfileSetSocialStartedAction {
		type: typeof BUSINESS_PROFILE_SET_SOCIAL_STARTED;
	}

	interface BusinessProfileSetSocialFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_SOCIAL_FINISHED;
		payload: SocialProfilePropsState;
	}

	interface BusinessProfileSetSocialFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_SOCIAL_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetInfoAction {
		type: typeof BUSINESS_PROFILE_SET_INFO;
		payload: InfoBusinessProfileState;
	}

	interface BusinessProfileSetInfoStartedAction {
		type: typeof BUSINESS_PROFILE_SET_INFO_STARTED;
	}

	interface BusinessProfileSetInfoFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_INFO_FINISHED;
		payload: InfoBusinessProfileState;
	}

	interface BusinessProfileSetInfoFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_INFO_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetStatisticsAction {
		type: typeof BUSINESS_PROFILE_SET_STATISTICS;
		payload: StatisticBusinessProfileState;
	}

	interface BusinessProfileSetStatisticsStartedAction {
		type: typeof BUSINESS_PROFILE_SET_STATISTICS_STARTED;
	}

	interface BusinessProfileSetStatisticsFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_STATISTICS_FINISHED;
		payload: StatisticBusinessProfileState;
	}

	interface BusinessProfileSetStatisticsFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_STATISTICS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetStatisticsAdviserAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_STATISTICS;
		payload: StatisticBusinessProfileAdviserState;
	}

	interface BusinessProfileSetStatisticsAdviserStartedAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_STATISTICS_STARTED;
	}

	interface BusinessProfileSetStatisticsAdviserFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED;
		payload: StatisticBusinessProfileAdviserState;
	}

	interface BusinessProfileSetStatisticsAdviserFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetSkillsAdviserAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_SKILLS;
		payload: AdviseSkillsBusinessProfileState;
	}

	interface BusinessProfileSetSkillsAdviserStartedAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_SKILLS_STARTED;
	}

	interface BusinessProfileSetSkillsAdviserFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED;
		payload: AdviseSkillsBusinessProfileState;
	}

	interface BusinessProfileSetSkillsAdviserFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileSetRegionsAction {
		type: typeof BUSINESS_PROFILE_SET_REGIONS;
		payload: RegionBusinessProfileState;
	}

	interface BusinessProfileSetRegionsStartedAction {
		type: typeof BUSINESS_PROFILE_SET_REGIONS_STARTED;
	}

	interface BusinessProfileSetRegionsFinishedAction {
		type: typeof BUSINESS_PROFILE_SET_REGIONS_FINISHED;
		payload: RegionBusinessProfileState;
	}

	interface BusinessProfileSetRegionsFinishedWithErrorsAction {
		type: typeof BUSINESS_PROFILE_SET_REGIONS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface BusinessProfileCheckSlugAction {
		type: typeof BUSINESS_PROFILE_CHECK_SLUG;
		payload: string;
	}

	interface BusinessProfileCheckSlugInitAction {
		type: typeof BUSINESS_PROFILE_CHECK_SLUG_INIT;
		payload: string;
	}

	interface BusinessProfileCheckSlugFailedAction {
		type: typeof BUSINESS_PROFILE_CHECK_SLUG_FAILED;
		payload: string;
	}

	interface BusinessProfileCheckSlugSuccededAction {
		type: typeof BUSINESS_PROFILE_CHECK_SLUG_SUCCEDED;
	}

	interface BusinessProfileSetTypeAction {
		type: typeof BUSINESS_PROFILE_SET_TYPE;
		payload: BusinessProfileTypes;
	}

	interface BusinessProfileGetAction {
		type: typeof BUSINESS_PROFILE_GET;
		payload: string;
	}

	interface BusinessProfileGetStartedAction {
		type: typeof BUSINESS_PROFILE_GET_STARTED;
	}

	interface BusinessProfileGetFinished {
		type: typeof BUSINESS_PROFILE_GET_FINISHED;
		payload: BusinessProfileState;
	}

	interface BusinessProfileGetFinishedWithErrors {
		type: typeof BUSINESS_PROFILE_GET_FINISHED_WITH_ERRORS;
		payload: string;
	}

	export type BusinessProfileActionTypes =
		| BusinessProfileInit
		| BusinessProfileStartCreation
		| BusinessProfileFinishCreation
		| BusinessProfileCreateAcceleratorAction
		| BusinessProfileCreateAcceleratorFailedAction
		| BusinessProfileCreateAcceleratorSuccessedAction
		| BusinessProfileCreateStartupAction
		| BusinessProfileCreateStartupFailedAction
		| BusinessProfileCreateStartupSuccessedAction
		| BusinessProfileCreateIncubatorAction
		| BusinessProfileCreateIncubatorFailedAction
		| BusinessProfileCreateIncubatorSuccessedAction
		| BusinessProfileCreateBusinessAngelAction
		| BusinessProfileCreateBusinessAngelFailedAction
		| BusinessProfileCreateBusinessAngelSuccessedAction
		| BusinessProfileCreateCompanyAction
		| BusinessProfileCreateCompanyFailedAction
		| BusinessProfileCreateCompanySuccessedAction
		| BusinessProfileCreateInstitutionAction
		| BusinessProfileCreateInstitutionFailed
		| BusinessProfileCreateInstitutionSuccessed
		| BusinessProfileCreateAdviserAction
		| BusinessProfileCreateAdviserFailedAction
		| BusinessProfileCreateAdviserSuccessedAction
		| BusinessProfileCreateUniversityAction
		| BusinessProfileCreateUniversityFailed
		| BusinessProfileCreateUniversitySuccessed
		| BusinessProfileInitCreateElearning
		| BusinessProfileFinishCreateElearning
		| BusinessProfileGetElearning
		| BusinessProfileGetElearningStarted
		| BusinessProfileGetElearningFinished
		| BusinessProfileGetElearningFinishedWithErrors
		| BusinessProfileGetOneElearning
		| BusinessProfileGetOneElearningStarted
		| BusinessProfileGetOneElearningFinished
		| BusinessProfileGetOneElearningFinishedWithErrors
		| BusinessProfileSetCourseAction
		| BusinessProfileSetCourseStartedAction
		| BusinessProfileSetCourseFailedAction
		| BusinessProfileSetCourseSuccededAction
		| BusinessProfileSetWebinarAction
		| BusinessProfileSetWebinarStartedAction
		| BusinessProfileSetWebinarFailedAction
		| BusinessProfileSetWebinarSuccededAction
		| BusinessProfileUpdateCourseAction
		| BusinessProfileUpdateCourseStartedAction
		| BusinessProfileUpdateCourseFailedAction
		| BusinessProfileUpdateCourseSuccededAction
		| BusinessProfileUpdateWebinarAction
		| BusinessProfileUpdateWebinarStartedAction
		| BusinessProfileUpdateWebinarFailedAction
		| BusinessProfileUpdateWebinarSuccededAction
		| BusinessProfileDeleteCourseAction
		| BusinessProfileDeleteCourseStartedAction
		| BusinessProfileDeleteCourseFailedAction
		| BusinessProfileDeleteCourseSuccededAction
		| BusinessProfileDeleteWebinarAction
		| BusinessProfileDeleteWebinarStartedAction
		| BusinessProfileDeleteWebinarFailedAction
		| BusinessProfileDeleteWebinarSuccededAction
		| BusinessProfileSetAboutAction
		| BusinessProfileSetAboutStartedAction
		| BusinessProfileSetAboutFinishedAction
		| BusinessProfileSetAboutFinishedWithErrorsAction
		| BusinessProfileSetMarketsAction
		| BusinessProfileSetMarketsStartedAction
		| BusinessProfileSetMarketsFinishedAction
		| BusinessProfileSetMarketsFinishedWithErrorsAction
		| BusinessProfileSetSchoolsAction
		| BusinessProfileSetSchoolsStartedAction
		| BusinessProfileSetSchoolsFinishedAction
		| BusinessProfileSetSchoolsFinishedWithErrorsAction
		| BusinessProfileSetLanguagesAction
		| BusinessProfileSetLanguagesStartedAction
		| BusinessProfileSetLanguagesFinishedAction
		| BusinessProfileSetLanguagesFinishedWithErrorsAction
		| BusinessProfileSetTeamAction
		| BusinessProfileSetTeamStartedAction
		| BusinessProfileSetTeamFinishedAction
		| BusinessProfileSetTeamFinishedWithErrorsAction
		| BusinessProfileSetPartnersAction
		| BusinessProfileSetPartnersStartedAction
		| BusinessProfileSetPartnersFinishedAction
		| BusinessProfileSetPartnersFinishedWithErrorsAction
		| BusinessProfileSetSocialAction
		| BusinessProfileSetSocialStartedAction
		| BusinessProfileSetSocialFinishedAction
		| BusinessProfileSetSocialFinishedWithErrorsAction
		| BusinessProfileSetInfoAction
		| BusinessProfileSetInfoStartedAction
		| BusinessProfileSetInfoFinishedAction
		| BusinessProfileSetInfoFinishedWithErrorsAction
		| BusinessProfileSetStatisticsAction
		| BusinessProfileSetStatisticsStartedAction
		| BusinessProfileSetStatisticsFinishedAction
		| BusinessProfileSetStatisticsFinishedWithErrorsAction
		| BusinessProfileSetStatisticsAdviserAction
		| BusinessProfileSetStatisticsAdviserStartedAction
		| BusinessProfileSetStatisticsAdviserFinishedAction
		| BusinessProfileSetStatisticsAdviserFinishedWithErrorsAction
		| BusinessProfileSetSkillsAdviserAction
		| BusinessProfileSetSkillsAdviserStartedAction
		| BusinessProfileSetSkillsAdviserFinishedAction
		| BusinessProfileSetSkillsAdviserFinishedWithErrorsAction
		| BusinessProfileSetRegionsAction
		| BusinessProfileSetRegionsStartedAction
		| BusinessProfileSetRegionsFinishedAction
		| BusinessProfileSetRegionsFinishedWithErrorsAction
		| BusinessProfileCheckSlugAction
		| BusinessProfileCheckSlugInitAction
		| BusinessProfileCheckSlugFailedAction
		| BusinessProfileCheckSlugSuccededAction
		| BusinessProfileSetTypeAction
		| BusinessProfileGetAction
		| BusinessProfileGetStartedAction
		| BusinessProfileGetFinished
		| BusinessProfileGetFinishedWithErrors;

	export function init(profile: BusinessProfileState): BusinessProfileInit {
		return {
			type: BUSINESS_PROFILE_INIT,
			payload: profile,
		};
	}

	export function startCreation(): BusinessProfileStartCreation {
		return {
			type: BUSINESS_PROFILE_START_CREATION,
		};
	}

	export function finishCreation(): BusinessProfileFinishCreation {
		return {
			type: BUSINESS_PROFILE_FINISH_CREATION,
		};
	}

	export function createAccelerator(): BusinessProfileCreateAcceleratorAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ACCELERATOR,
		};
	}

	export function createAcceleratorFailed(error: string): BusinessProfileCreateAcceleratorFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ACCELERATOR_FAILED,
			payload: error,
		};
	}

	export function createAcceleratorSuccessed(): BusinessProfileCreateAcceleratorSuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ACCELERATOR_SUCCESSED,
		};
	}

	export function createStartup(): BusinessProfileCreateStartupAction {
		return {
			type: BUSINESS_PROFILE_CREATE_STARTUP,
		};
	}

	export function createStartupFailed(error: string): BusinessProfileCreateStartupFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_STARTUP_FAILED,
			payload: error,
		};
	}

	export function createStartupSuccessed(): BusinessProfileCreateStartupSuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_STARTUP_SUCCESSED,
		};
	}

	export function createIncubator(): BusinessProfileCreateIncubatorAction {
		return {
			type: BUSINESS_PROFILE_CREATE_INCUBATOR,
		};
	}

	export function createIncubatorFailed(error: string): BusinessProfileCreateIncubatorFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_INCUBATOR_FAILED,
			payload: error,
		};
	}

	export function createIncubatorSuccessed(): BusinessProfileCreateIncubatorSuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_INCUBATOR_SUCCESSED,
		};
	}

	export function createBusinessAngel(): BusinessProfileCreateBusinessAngelAction {
		return {
			type: BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL,
		};
	}

	export function createBusinessAngelFailed(error: string): BusinessProfileCreateBusinessAngelFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_FAILED,
			payload: error,
		};
	}

	export function createBusinessAngelSuccessed(): BusinessProfileCreateBusinessAngelSuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_SUCCESSED,
		};
	}

	export function createCompany(): BusinessProfileCreateCompanyAction {
		return {
			type: BUSINESS_PROFILE_CREATE_COMPANY,
		};
	}

	export function createCompanyFailed(error: string): BusinessProfileCreateCompanyFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_COMPANY_FAILED,
			payload: error,
		};
	}

	export function createCompanySuccessed(): BusinessProfileCreateCompanySuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_COMPANY_SUCCESSED,
		};
	}

	export function createInstitution(): BusinessProfileCreateInstitutionAction {
		return {
			type: BUSINESS_PROFILE_CREATE_INSTITUTION,
		};
	}

	export function createInstitutionFailed(error: string): BusinessProfileCreateInstitutionFailed {
		return {
			type: BUSINESS_PROFILE_CREATE_INSTITUTION_FAILED,
			payload: error,
		};
	}

	export function createInstitutionSuccessed(): BusinessProfileCreateInstitutionSuccessed {
		return {
			type: BUSINESS_PROFILE_CREATE_INSTITUTION_SUCCESSED,
		};
	}

	export function createAdviser(): BusinessProfileCreateAdviserAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ADVISER,
		};
	}

	export function createAdviserFailed(error: string): BusinessProfileCreateAdviserFailedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ADVISER_FAILED,
			payload: error,
		};
	}

	export function createAdviserSucceded(): BusinessProfileCreateAdviserSuccessedAction {
		return {
			type: BUSINESS_PROFILE_CREATE_ADVISER_SUCCESSED,
		};
	}

	export function createUniversity(): BusinessProfileCreateUniversityAction {
		return {
			type: BUSINESS_PROFILE_CREATE_UNIVERSITY,
		};
	}

	export function createUniversityFailed(error: string): BusinessProfileCreateUniversityFailed {
		return {
			type: BUSINESS_PROFILE_CREATE_UNIVERSITY_FAILED,
			payload: error,
		};
	}

	export function createUniversitySuccessed(): BusinessProfileCreateUniversitySuccessed {
		return {
			type: BUSINESS_PROFILE_CREATE_UNIVERSITY_SUCCESSED,
		};
	}

	export function initCreateElearning(): BusinessProfileInitCreateElearning {
		return {
			type: BUSINESS_PROFILE_INIT_CREATE_ELEARNING,
		};
	}

	export function finishCreateElearning(): BusinessProfileFinishCreateElearning {
		return {
			type: BUSINESS_PROFILE_FINISH_CREATE_ELEARNING,
		};
	}

	export function getElearning(slug: string): BusinessProfileGetElearning {
		return {
			type: BUSINESS_PROFILE_GET_ELEARNINGS,
			payload: slug,
		};
	}

	export function getElearningStarted(): BusinessProfileGetElearningStarted {
		return {
			type: BUSINESS_PROFILE_GET_ELEARNINGS_STARTED,
		};
	}

	export function getElearningFinished(items: ElearningModel[]): BusinessProfileGetElearningFinished {
		return {
			type: BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED,
			payload: items,
		};
	}

	export function getElearningFinishedWithErrors(err: string): BusinessProfileGetElearningFinishedWithErrors {
		return {
			type: BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getOneElearning(payload: ElearningPayload): BusinessProfileGetOneElearning {
		return {
			type: BUSINESS_PROFILE_GET_ONE_ELEARNING,
			payload,
		};
	}

	export function getOneElearningStarted(): BusinessProfileGetOneElearningStarted {
		return {
			type: BUSINESS_PROFILE_GET_ONE_ELEARNING_STARTED,
		};
	}

	export function getOneElearningFinished(item: ElearningModel): BusinessProfileGetOneElearningFinished {
		return {
			type: BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED,
			payload: item,
		};
	}

	export function getOneElearningFinishedWithErrors(err: string): BusinessProfileGetOneElearningFinishedWithErrors {
		return {
			type: BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function newCourse(course: ElearningModel): BusinessProfileSetCourseAction {
		return {
			type: BUSINESS_PROFILE_SET_COURSE,
			payload: course,
		};
	}

	export function newCourseStarted(): BusinessProfileSetCourseStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_COURSE_STARTED,
		};
	}

	export function newCourseFailed(message: string): BusinessProfileSetCourseFailedAction {
		return {
			type: BUSINESS_PROFILE_SET_COURSE_FAILED,
			payload: message,
		};
	}

	export function newCourseSucceded(payload: ElearningModel): BusinessProfileSetCourseSuccededAction {
		return {
			type: BUSINESS_PROFILE_SET_COURSE_SUCCEDED,
			payload,
		};
	}

	export function newWebinar(Webinar: ElearningModel): BusinessProfileSetWebinarAction {
		return {
			type: BUSINESS_PROFILE_SET_WEBINAR,
			payload: Webinar,
		};
	}

	export function newWebinarStarted(): BusinessProfileSetWebinarStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_WEBINAR_STARTED,
		};
	}

	export function newWebinarFailed(message: string): BusinessProfileSetWebinarFailedAction {
		return {
			type: BUSINESS_PROFILE_SET_WEBINAR_FAILED,
			payload: message,
		};
	}

	export function newWebinarSucceded(payload: ElearningModel): BusinessProfileSetWebinarSuccededAction {
		return {
			type: BUSINESS_PROFILE_SET_WEBINAR_SUCCEDED,
			payload,
		};
	}

	export function updateCourse(course: ElearningModel): BusinessProfileUpdateCourseAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_COURSE,
			payload: course,
		};
	}

	export function updateCourseStarted(): BusinessProfileUpdateCourseStartedAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_COURSE_STARTED,
		};
	}

	export function updateCourseFailed(message: string): BusinessProfileUpdateCourseFailedAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_COURSE_FAILED,
			payload: message,
		};
	}

	export function updateCourseSucceded(): BusinessProfileUpdateCourseSuccededAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_COURSE_SUCCEDED,
		};
	}

	export function updateWebinar(Webinar: ElearningModel): BusinessProfileUpdateWebinarAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_WEBINAR,
			payload: Webinar,
		};
	}

	export function updateWebinarStarted(): BusinessProfileUpdateWebinarStartedAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_WEBINAR_STARTED,
		};
	}

	export function updateWebinarFailed(message: string): BusinessProfileUpdateWebinarFailedAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_WEBINAR_FAILED,
			payload: message,
		};
	}

	export function updateWebinarSucceded(): BusinessProfileUpdateWebinarSuccededAction {
		return {
			type: BUSINESS_PROFILE_UPDATE_WEBINAR_SUCCEDED,
		};
	}

	export function deleteCourse(courseId: string): BusinessProfileDeleteCourseAction {
		return {
			type: BUSINESS_PROFILE_DELETE_COURSE,
			payload: courseId,
		};
	}

	export function deleteCourseStarted(): BusinessProfileDeleteCourseStartedAction {
		return {
			type: BUSINESS_PROFILE_DELETE_COURSE_STARTED,
		};
	}

	export function deleteCourseFailed(message: string): BusinessProfileDeleteCourseFailedAction {
		return {
			type: BUSINESS_PROFILE_DELETE_COURSE_FAILED,
			payload: message,
		};
	}

	export function deleteCourseSucceded(): BusinessProfileDeleteCourseSuccededAction {
		return {
			type: BUSINESS_PROFILE_DELETE_COURSE_SUCCEDED,
		};
	}

	export function deleteWebinar(webinarId: string): BusinessProfileDeleteWebinarAction {
		return {
			type: BUSINESS_PROFILE_DELETE_WEBINAR,
			payload: webinarId,
		};
	}

	export function deleteWebinarStarted(): BusinessProfileDeleteWebinarStartedAction {
		return {
			type: BUSINESS_PROFILE_DELETE_WEBINAR_STARTED,
		};
	}

	export function deleteWebinarFailed(message: string): BusinessProfileDeleteWebinarFailedAction {
		return {
			type: BUSINESS_PROFILE_DELETE_WEBINAR_FAILED,
			payload: message,
		};
	}

	export function deleteWebinarSucceded(): BusinessProfileDeleteWebinarSuccededAction {
		return {
			type: BUSINESS_PROFILE_DELETE_WEBINAR_SUCCEDED,
		};
	}

	export function setAbout(about: AboutBusinessProfileState): BusinessProfileSetAboutAction {
		return {
			type: BUSINESS_PROFILE_SET_ABOUT,
			payload: about,
		};
	}

	export function setAboutStarted(): BusinessProfileSetAboutStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_ABOUT_STARTED,
		};
	}

	export function setAboutFinished(about: AboutBusinessProfileState): BusinessProfileSetAboutFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_ABOUT_FINISHED,
			payload: about,
		};
	}

	export function setAboutFinishedWithErrors(error: string): BusinessProfileSetAboutFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_ABOUT_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function setMarkets(markets: MarketsBusinessProfileState): BusinessProfileSetMarketsAction {
		return {
			type: BUSINESS_PROFILE_SET_MARKETS,
			payload: markets,
		};
	}

	export function setMarketsStarted(): BusinessProfileSetMarketsStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_MARKETS_STARTED,
		};
	}

	export function setMarketsFinished(markets: MarketsBusinessProfileState): BusinessProfileSetMarketsFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_MARKETS_FINISHED,
			payload: markets,
		};
	}

	export function setMarketsFinishedWithErrors(error: string): BusinessProfileSetMarketsFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_MARKETS_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function setSchools(schools: SchoolsBusinessProfileState): BusinessProfileSetSchoolsAction {
		return {
			type: BUSINESS_PROFILE_SET_SCHOOLS,
			payload: schools,
		};
	}

	export function setSchoolsStarted(): BusinessProfileSetSchoolsStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_SCHOOLS_STARTED,
		};
	}

	export function setSchoolsFinished(schools: SchoolsBusinessProfileState): BusinessProfileSetSchoolsFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_SCHOOLS_FINISHED,
			payload: schools,
		};
	}

	export function setSchoolsFinishedWithErrors(error: string): BusinessProfileSetSchoolsFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_SCHOOLS_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function setLanguages(languages: LanguagesBusinessProfileState): BusinessProfileSetLanguagesAction {
		return {
			type: BUSINESS_PROFILE_SET_LANGUAGES,
			payload: languages,
		};
	}

	export function setLanguagesStarted(): BusinessProfileSetLanguagesStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_LANGUAGES_STARTED,
		};
	}

	export function setLanguagesFinished(
		languages: LanguagesBusinessProfileState,
	): BusinessProfileSetLanguagesFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_LANGUAGES_FINISHED,
			payload: languages,
		};
	}

	export function setLanguagesFinishedWithErrors(error: string): BusinessProfileSetLanguagesFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_LANGUAGES_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function saveTeam(team: TeamBusinessProfileState): BusinessProfileSetTeamAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM,
			payload: team,
		};
	}

	export function saveTeamStarted(): BusinessProfileSetTeamStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_STARTED,
		};
	}

	export function saveTeamFinished(team: TeamBusinessProfileState): BusinessProfileSetTeamFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_FINISHED,
			payload: team,
		};
	}

	export function saveTeamFinishedWithErrors(error: string): BusinessProfileSetTeamFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function savePartners(team: PartnersBusinessProfileState): BusinessProfileSetTeamAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM,
			payload: team,
		};
	}

	export function savePartnersStarted(): BusinessProfileSetTeamStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_STARTED,
		};
	}

	export function savePartnersFinished(team: PartnersBusinessProfileState): BusinessProfileSetTeamFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_FINISHED,
			payload: team,
		};
	}

	export function savePartnersFinishedWithErrors(error: string): BusinessProfileSetTeamFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function setSocial(social: SocialProfilePropsState): BusinessProfileSetSocialAction {
		return {
			type: BUSINESS_PROFILE_SET_SOCIAL,
			payload: social,
		};
	}

	export function setSocialStarted(): BusinessProfileSetSocialStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_SOCIAL_STARTED,
		};
	}

	export function setSocialFinished(social: SocialProfilePropsState): BusinessProfileSetSocialFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_SOCIAL_FINISHED,
			payload: social,
		};
	}

	export function setSocialFinishedWithErrors(error: string): BusinessProfileSetSocialFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_SOCIAL_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}

	export function setInfo(info: InfoBusinessProfileState): BusinessProfileSetInfoAction {
		return {
			type: BUSINESS_PROFILE_SET_INFO,
			payload: info,
		};
	}

	export function setInfoStarted(): BusinessProfileSetInfoStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_INFO_STARTED,
		};
	}

	export function setInfoFinished(info: InfoBusinessProfileState): BusinessProfileSetInfoFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_INFO_FINISHED,
			payload: info,
		};
	}

	export function setInfoFinishedWithErrors(err: string): BusinessProfileSetInfoFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_INFO_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function setStatistics(statistics: StatisticBusinessProfileState): BusinessProfileSetStatisticsAction {
		return {
			type: BUSINESS_PROFILE_SET_STATISTICS,
			payload: statistics,
		};
	}

	export function setStatisticsStarted(): BusinessProfileSetStatisticsStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_STATISTICS_STARTED,
		};
	}

	export function setStatisticsFinished(
		statistics: StatisticBusinessProfileState,
	): BusinessProfileSetStatisticsFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_STATISTICS_FINISHED,
			payload: statistics,
		};
	}

	export function setStatisticsFinishedWithErrors(err: string): BusinessProfileSetStatisticsFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_STATISTICS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function setAdviserStatistics(
		statistics: StatisticBusinessProfileAdviserState,
	): BusinessProfileSetStatisticsAdviserAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_STATISTICS,
			payload: statistics,
		};
	}

	export function setAdviserStatisticsStarted(): BusinessProfileSetStatisticsAdviserStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_STATISTICS_STARTED,
		};
	}

	export function setAdviserStatisticsFinished(
		statistics: StatisticBusinessProfileAdviserState,
	): BusinessProfileSetStatisticsAdviserFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED,
			payload: statistics,
		};
	}

	export function setAdviserStatisticsFinishedWithErrors(
		err: string,
	): BusinessProfileSetStatisticsAdviserFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function setAdviserSkills(adviser: AdviseSkillsBusinessProfileState): BusinessProfileSetSkillsAdviserAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_SKILLS,
			payload: adviser,
		};
	}

	export function setAdviserSkillsStarted(): BusinessProfileSetSkillsAdviserStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_SKILLS_STARTED,
		};
	}

	export function setAdviserSkillsFinished(
		adviser: AdviseSkillsBusinessProfileState,
	): BusinessProfileSetSkillsAdviserFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED,
			payload: adviser,
		};
	}

	export function setAdviserSkillsFinishedWithErrors(
		err: string,
	): BusinessProfileSetSkillsAdviserFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function setRegions(regions: RegionBusinessProfileState): BusinessProfileSetRegionsAction {
		return {
			type: BUSINESS_PROFILE_SET_REGIONS,
			payload: regions,
		};
	}

	export function setRegionsStarted(): BusinessProfileSetRegionsStartedAction {
		return {
			type: BUSINESS_PROFILE_SET_REGIONS_STARTED,
		};
	}

	export function setRegionsFinished(regions: RegionBusinessProfileState): BusinessProfileSetRegionsFinishedAction {
		return {
			type: BUSINESS_PROFILE_SET_REGIONS_FINISHED,
			payload: regions,
		};
	}

	export function setRegionsFinishedWithErrors(err: string): BusinessProfileSetRegionsFinishedWithErrorsAction {
		return {
			type: BUSINESS_PROFILE_SET_REGIONS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function checkSlug(slug: string): BusinessProfileCheckSlugAction {
		return {
			type: BUSINESS_PROFILE_CHECK_SLUG,
			payload: slug,
		};
	}

	export function checkSlugInit(slug: string): BusinessProfileCheckSlugInitAction {
		return {
			type: BUSINESS_PROFILE_CHECK_SLUG_INIT,
			payload: slug,
		};
	}

	export function checkSlugFailed(error: string): BusinessProfileCheckSlugFailedAction {
		return {
			type: BUSINESS_PROFILE_CHECK_SLUG_FAILED,
			payload: error,
		};
	}

	export function checkSlugSucceded(): BusinessProfileCheckSlugSuccededAction {
		return {
			type: BUSINESS_PROFILE_CHECK_SLUG_SUCCEDED,
		};
	}

	export function setType(type: BusinessProfileTypes): BusinessProfileSetTypeAction {
		return {
			type: BUSINESS_PROFILE_SET_TYPE,
			payload: type,
		};
	}

	export function getBusinessProfile(slug: string): BusinessProfileGetAction {
		return {
			type: BUSINESS_PROFILE_GET,
			payload: slug,
		};
	}

	export function getBusinessProfileStarted(): BusinessProfileGetStartedAction {
		return {
			type: BUSINESS_PROFILE_GET_STARTED,
		};
	}

	export function getBusinessProfileFinished(profile: BusinessProfileState): BusinessProfileGetFinished {
		return {
			type: BUSINESS_PROFILE_GET_FINISHED,
			payload: profile,
		};
	}

	export function getBusinessProfileFinishedWithErrors(error: string): BusinessProfileGetFinishedWithErrors {
		return {
			type: BUSINESS_PROFILE_GET_FINISHED_WITH_ERRORS,
			payload: error,
		};
	}
}
