import { URL_VINGLET_IMAGE } from "constants/URLs";
import RequestModel, { Request } from "models/requests/Request";
import { StringUtils } from "utils/StringUtils";

const DefaultProfileImage = "/default_profile_image.png";

export interface ProfileInfo {
	name: string;
	surname: string;
	emblem: string;
	avatar: string;
	fetching?: Request;
}

class ProfileInfoModel implements ProfileInfo {
	name: string;
	surname: string;
	emblem: string;
	avatar: string;
	fetching?: Request;

	constructor(name: string, surname: string, emblem: string, avatar: string, fetching?: Request) {
		this.name = StringUtils.capitalize(name);
		this.surname = StringUtils.capitalize(surname);
		this.emblem = emblem;
		this.avatar = avatar;
		this.fetching = fetching;
	}

	get fullName() {
		if (!this.name && !this.surname) {
			return "Unknown User";
		}

		return `${this.name} ${this.surname}`;
	}

	static generateFromAPI(data: any) {
		const firstName = data.user ? data.user.first_name : "";
		const lastName = data.user ? data.user.last_name : "";

		return new ProfileInfoModel(
			firstName,
			lastName,
			data.emblem,
			data.profile_image && data.profile_image != DefaultProfileImage
				? URL_VINGLET_IMAGE + data.profile_image
				: String(DefaultProfileImage),
			RequestModel.requestDefault(),
		);
	}

	static generateEmpty = () => {
		return new ProfileInfoModel("", "", "", "", RequestModel.requestLoading());
	};

	static generateFromObject(info: ProfileInfo) {
		return new ProfileInfoModel(info.name, info.surname, info.emblem, info.avatar, info.fetching);
	}

	getCleantImage = () => {
		const cleantAvatar = this.avatar.replace(URL_VINGLET_IMAGE, "");
		return cleantAvatar === DefaultProfileImage ? null : cleantAvatar;
	};

	getAvatarImage() {
		if (!this.avatar || this.avatar === DefaultProfileImage) {
			return DefaultProfileImage;
		}

		if (this.avatar.includes(URL_VINGLET_IMAGE)) {
			return this.avatar;
		}

		return this.avatar ? URL_VINGLET_IMAGE + this.avatar : String(DefaultProfileImage);
	}

	exportToAPIRequest = () => {
		return {
			emblem: this.emblem,
			name: this.name,
			first_name: this.name,
			last_name: this.surname,
			cover_image: this.getCleantImage(),
			profile_image: this.getCleantImage(),
		};
	};
}

export default ProfileInfoModel;
