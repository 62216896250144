import axios from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Marketplace } from "./types";

const fetchMarketplacesByProfile = (profileSlug: string, accessToken: string) => {
	return function (): Promise<Marketplace[]> {
		return axios
			.get(`${PROD_URL}/marketplace/profile/${profileSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data)
			.then((parsedResponse: Marketplace[]) => parsedResponse || [])
			.then((marketplaces: Marketplace[]) => marketplaces.reverse());
	};
};

export const useMarketplacesByProfileQuery = (
	profileSlug: string,
	options: UseQueryOptions<Marketplace[], any, Marketplace[]> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useQuery<Marketplace[]>(["marketplaces", profileSlug], fetchMarketplacesByProfile(profileSlug, accessToken), {
		...options,
		onSuccess: (marketplaces: Marketplace[]) => {
			if (marketplaces) {
				marketplaces.forEach((marketplace) => {
					queryClient.setQueryData(["marketplace", marketplace.slug], marketplace);
				});
			}

			options.onSuccess && options.onSuccess(marketplaces);
		},
	});
};
