import CategoryModel, { CategoryAPI } from "models/categories/Category";
import CategorySelectableModel, { CategorySelectable } from "models/categories/CategorySelectable";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

export interface BusinessProfileFeature {
	sectors: CategorySelectable[];
	areas: CategorySelectable[];
	regions: CategorySelectable[];
	languages: CategorySelectable[];
	schools: CategorySelectable[];
}

class BusinessProfileFeatureModel implements BusinessProfileFeature {
	sectors: CategorySelectable[];
	areas: CategorySelectable[];
	regions: CategorySelectable[];
	languages: CategorySelectable[];
	schools: CategorySelectable[];

	constructor(
		sectors: CategorySelectable[],
		areas: CategorySelectable[],
		regions: CategorySelectable[],
		languages: CategorySelectable[],
		schools: CategorySelectable[],
	) {
		this.sectors = sectors;
		this.areas = areas;
		this.regions = regions;
		this.languages = languages;
		this.schools = schools;
	}

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}) {
		const categoriesServices = CategoriesServiceSingleton.getList();
		const {
			market: sectorsTranslations,
			area: areasTranslations,
			school: schoolsTranslations,
		} = TranslationsServiceSingleton.getFeatureTranslations(["market", "area", "school"]);
		const { language: languagesTranslations, region: regionsTranslations } =
			TranslationsServiceSingleton.getWorldTranslations(["language", "region"]);
		// AREAS
		const idsSelectedAreas = data.areas && data.areas.map((item: CategoryAPI) => item.id);
		const selectedAreas = categoriesServices.filterById(idsSelectedAreas) as CategoryModel[];
		// SECTORS
		const idsSelectedSectors = data.sectors ? data.sectors.map((item: CategoryAPI) => item.id) : [];
		const selectedSectors = categoriesServices.filterById(idsSelectedSectors) as CategoryModel[];
		// LANGUAGES
		const idsSelectedLanguages = data.languages ? data.languages.map((item: CategoryAPI) => item.id) : [];
		const selectedLanguages = categoriesServices.filterById(idsSelectedLanguages) as CategoryModel[];
		// REGIONS
		const idsSelectedRegions = data.regions ? data.regions.map((item: CategoryAPI) => item.id) : [];
		const selectedRegions = categoriesServices.filterById(idsSelectedRegions) as CategoryModel[];
		// SCHOOLS
		const idsSelectedSchools = data.schools ? data.schools.map((item: CategoryAPI) => item.id) : [];
		const selectedSchools = categoriesServices.filterById(idsSelectedSchools) as CategoryModel[];

		try {
			selectedAreas.map((area) => {
				if (areasTranslations.items[area.key]) {
					area.setText(areasTranslations.items[area.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(area, true);
			});
			selectedSectors.map((sector) => {
				if (sectorsTranslations.items[sector.key]) {
					sector.setText(sectorsTranslations.items[sector.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(sector, true);
			});
			selectedLanguages.map((language) => {
				if (languagesTranslations.items[language.key]) {
					language.setText(languagesTranslations.items[language.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(language, true);
			});
			selectedRegions.map((region) => {
				if (regionsTranslations.items[region.key]) {
					region.setText(regionsTranslations.items[region.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(region, true);
			});
			selectedSchools.map((school) => {
				if (schoolsTranslations.items[school.key]) {
					school.setText(schoolsTranslations.items[school.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(school, true);
			});
		} catch (err) {}

		return new BusinessProfileFeatureModel(
			selectedSectors,
			selectedAreas,
			selectedRegions,
			selectedLanguages,
			selectedSchools,
		);
	}

	static generateEmpty() {
		return new BusinessProfileFeatureModel([], [], [], [], []);
	}

	static generateFromObject(businessProfileFeature: BusinessProfileFeature) {
		return new BusinessProfileFeatureModel(
			businessProfileFeature.sectors,
			businessProfileFeature.areas,
			businessProfileFeature.regions,
			businessProfileFeature.languages,
			businessProfileFeature.schools,
		);
	}
}

export default BusinessProfileFeatureModel;
