import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from "@libs";
import { Category, getRequestConfig, PROD_URL } from "@api";

export interface FetchCategories {
	total: number;
	page: number;
	num_pages: number;
	items: Category[];
}

const fetchCategories = (currentShortLocale: string) => {
	return function (): Promise<FetchCategories> {
		const categoriesKey = `categories-${currentShortLocale}`;
		const cachedCategories = getLocalStorageWithExpiry(categoriesKey);

		if (cachedCategories) {
			return Promise.resolve(JSON.parse(cachedCategories));
		}

		return axios.get(`${PROD_URL}/categories?perPage=10000`, getRequestConfig()).then((response) => {
			const sevenDays = 1000 * 60 * 60 * 24 * 7;

			setLocalStorageWithExpiry(categoriesKey, JSON.stringify(response.data), sevenDays);

			return response.data;
		});
	};
};

export const useFetchCategoriesQuery = (
	currentShortLocale: string,
	options: UseQueryOptions<FetchCategories, any, FetchCategories> = {},
) => {
	return useQuery<FetchCategories>(["categories", currentShortLocale], fetchCategories(currentShortLocale), {
		...options,
	});
};
