import * as React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import { HeaderLink } from "../useDropdownHeaderLinks";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Link from "next/link";

function HeaderLinkItem(props: { item: HeaderLink; pathname: string }) {
	const { pathname, item } = props;
	const { label, color, icon, link } = item;

	return (
		<Link
			href={link}
			passHref
		>
			<Menu.Item
				as="a"
				className="Header--links"
				active={link === pathname}
				name={label.toLowerCase()}
			>
				<Icon
					className={"font-color-white-grey"}
					color={color}
					name={icon}
					size={"large"}
				/>
				<span className={"margin-0_5-top font-color-white-grey"}>{label}</span>
			</Menu.Item>
		</Link>
	);
}

export default React.memo(HeaderLinkItem);
