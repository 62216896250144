import {
	Label as FlowbiteLabel,
	TextInput as FlowbiteTextInput,
	TextInputProps as FlowbiteTextInputProps,
	Spinner as FlowbiteSpinner,
} from "flowbite-react";

export const InputField = ({
	loading,
	labelValue,
	errorMessage,
	...rest
}: FlowbiteTextInputProps & {
	loading?: boolean;
	labelValue?: string;
	errorMessage?: string;
}) => {
	return (
		<>
			{labelValue && (
				<div className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
					<FlowbiteLabel
						htmlFor={rest.id}
						value={labelValue}
					/>
				</div>
			)}
			<div className="relative">
				<FlowbiteTextInput
					{...rest}
					shadow
				/>
				{loading && (
					<div className="absolute right-5 top-0 flex h-full items-center justify-center">
						<FlowbiteSpinner
							color={rest.color}
							size={rest.size}
						/>
					</div>
				)}
			</div>
			{errorMessage && <span className="text-sm text-red-600"> {errorMessage} </span>}
		</>
	);
};
