export const FeaturesPrt = `
{
    "gender": {
      "key": {
        "singular": "Gênero",
        "plural": "Gêneros"
      },
      "items": {
        "male": "Masculino",
        "female": "Fêmea",
        "indeterminate": "Para não mencionar"
      }
    },
    "area": {
      "key": {
        "singular": "Área",
        "plural": "Áreas"
      },
      "items": {
        "tech": "Tecnologia",
        "business": "Negócios",
        "science": "Ciência"
      }
    },
    "personalSkills": {
      "key": {
        "singular": "Habilidade Pessoal",
        "plural": "Habilidades Pessoais"
      },
      "items": {
        "positiveAttitude": "Atitude positiva",
        "adaptationToChange": "Adaptação à mudança",
        "autonomy": "Autonomia",
        "resolutionCreativity": "Capacidade de resolução",
        "creativity": "Criatividade",
        "curiosity": "Curiosidade",
        "empathy": "Empatia",
        "activeListening": "Escuta activa",
        "focusAndVision": "Foco e visão",
        "integrity": "Integridade",
        "emotionalInteligence": "Inteligência emocional",
        "criticalThinking": "Pensamento critico",
        "teamwork": "Trabalho em equipe",
        "commitment": "Comprometimento",
        "flexibility": "Flexibilidade",
        "frustrationTolerance": "Tolerância à frustração",
        "motivation": "Motivação",
        "negotiation": "Negociação",
        "patience": "Paciência",
        "persuasion": "Persuasão",
        "skillsToSolveProblems": "Habilidades para resolver problemas",
        "workEthic": "Ética de trabalho"
      }
    },
    "businessSkills": {
      "key": {
        "singular": "Habilidad de negócio",
        "plural": "Habilidades de negócio"
      },
      "items": {
        "communicationAndNegotiation": "Comunicação e negociação",
        "delegation": "Delegação",
        "financialManagement": "Gestão financeira",
        "leadership": "Liderança",
        "networking": "Networking",
        "problemSolving": "Resolução de problemas"
      }
    },
    "salesAndMarketingSkills": {
      "key": {
        "singular": "Vendas e Marketing",
        "plural": "Vendas e Marketing"
      },
      "items": {
        "buyerSellerAgreement": "Acordo comprador-vendedor",
        "communication": "Comunicação",
        "demoSkills": "Habilidades de demonstração",
        "productKnowledge": "Conhecimento do produto",
        "qualificationQuestioning": "Questões de qualificação",
        "rapportBuilding": "Construindo relacionamentos",
        "strategicProspectingSkills": "Habilidades de prospecção estratégica"
      }
    },
    "investmentSkills": {
      "key": {
        "singular": "Investimento",
        "plural": "Investimentos"
      },
      "items": {
        "confidence": "Confiança",
        "determination": "Determinação",
        "goodNumerical": "Bom numérico",
        "selfMotivation": "Auto-motivação",
        "teamworkingSkills": "Habilidades de trabalho em grupo",
        "understandingOfFinancialMarkets": "Compreensão dos mercados financeiros",
        "workUnderPressure": "Trabalhando sob pressão"
      }
    },
    "designAndProductoSkills": {
      "key": {
        "singular": "Design e Produto",
        "plural": "Design e Produto"
      },
      "items": {
        "ABTesting": "Teste A / B",
        "benchmarking": "Benchmarking",
        "dataCollection": "Coleta, extração e análise de dados",
        "fabrication": "Manufatura",
        "interactionDesing": "Design de interação",
        "learningAboutCode": "Aprendendo sobre o código",
        "prototyping": "Protótipos",
        "serviceDesign": "Projeto de serviço",
        "visualDesign": "Design visual"
      }
    },
    "itSkills": {
      "key": {
        "singular": "IT",
        "plural": "IT"
      },
      "items": {
        "digitalCommunications": "Comunicações digitais",
        "goalOriented": "Objetivo orientado",
        "informationAndCommunicationsTechnology": "Tecnologia da informação e Comunicação",
        "manageRemoteWorkingTeams": "Gerenciar equipes de trabalho remotas",
        "meetingDeadlines": "Conformidade dos prazos",
        "multitasking": "Multitarefa",
        "reviewProcessesForImprovement": "Revise os processos para melhorar",
        "scheduling": "Planejamento"
      }
    },
    "legalSkills": {
      "key": {
        "singular": "Jurídico",
        "plural": "Jurídico"
      },
      "items": {
        "analyticalAndLogicalReasoning": "Raciocínio Analítico e Lógico",
        "clientService": "Serviço ao cliente",
        "legalProcedure": "Procedimento legal",
        "legalResearch": "Investigação legal",
        "oralCommunication": "Comunicação oral",
        "organization": "Organização",
        "technologySkills": "Habilidades tecnológicas",
        "writtenCommunication": "Comunicação escrita"
      }
    },
    "accountantSkills": {
      "key": {
        "singular": "Contador",
        "plural": "Contador"
      },
      "items": {
        "adaptability": "Adaptabilidade",
        "analyticalSkills": "Capacidade de análise",
        "financialSkills": "Habilidades financeiras",
        "generalBusinessSkills": "Habilidades gerais de negócios",
        "industryKnowledge": "Conhecimento da indústria",
        "informationTechnologySkills": "Habilidades de Tecnologia da Informação",
        "interpersonalCommunication": "Comunicação interpessoal",
        "organizationalSkills": "Habilidades organizacionais"
      }
    },
    "engineeringAndArchitectureSkills": {
      "key": {
        "singular": "Engenharia e arquitetura",
        "plural": "Engenharia e arquitetura"
      },
      "items": {
        "budgeting": "Orçamento",
        "calculations": "Cálculos",
        "computerScience": "Ciência da Computação",
        "conceptualandLogical": "Conceitual e lógico",
        "convertingScaleFromBlueprints": "Conversão de escala de plantas",
        "estimating": "Estimando",
        "nanotechnology": "Nanotecnologia",
        "physicalDataModeling": "Modelagem de dados físicos",
        "processManagement": "Gerenciamento de processos",
        "programmingLanguages": "Linguagens de programação",
        "specifications": "Especificações",
        "statistics": "Estatisticas",
        "structuralAnalysis": "Análise estrutural"
      }
    },
    "marketplaceType": {
      "key": {
        "singular": "Tipo de Marketplace",
        "plural": "Tipos de Marketplace"
      },
      "items": {
        "product": "Produto",
        "service": "Serviço"
      }
    },
    "school": {
      "key": {
        "singular": "Faculdade",
        "plural": "Faculdades"
      },
      "items": {
        "agronomy": "Agronomia",
        "nutrition": "Nutricion",
        "feeding": "Alimentação",
        "architecture": "Arquitetura",
        "fineArts": "Belas Artes",
        "librarianship": "Biblioteca",
        "biology": "Biologia",
        "biochemistry": "Bioquímica",
        "earthScience": "Ciências da Terra",
        "exactScience": "Ciências Exatas",
        "socialScience": "Ciências Sociais",
        "cinema": "Cine",
        "law": "Jurídico",
        "design": "Design",
        "documentation": "Documentação",
        "economyAndCompany": "Administración de Empresas",
        "information_and_audiovisual_media": "Informação e Mídia Audiovisual",
        "pharmacy": "Farmácia",
        "philology": "Filologia",
        "philosofy": "Filosofia",
        "phisyc": "Físico",
        "geography": "Geografia",
        "history": "História",
        "it": "Informática",
        "engineer": "Engenharia",
        "math": "Matemática",
        "medicine": "Medicina",
        "psicology": "Psicologia",
        "chemistry": "Química",
        "health": "Saúde",
        "townPlanning": "Urbanismo",
        "veterinary": "Veterinário"
      }
    },
    "topic": {
      "key": {
        "singular": "Tema",
        "plural": "Temas"
      },
      "items": {
        "education": "Educação",
        "mobile": "Celular",
        "personalizedMedicine": "Remédio personalizado",
        "syntheticBiology": "Biologia sintética",
        "government": "Governo",
        "foodAndDrinks": "Comida e bebida",
        "travelToTheSpace": "Viajar para o espaço",
        "agronomy": "Agronomia",
        "healthServices": "Serviços de saúde",
        "internetWebservices": "Serviços de Internet / Web",
        "legal": "Jurídico",
        "cattleRaising": "Pecuária",
        "cleanEnergy": "Energia limpa",
        "robotics": "Robótico",
        "water": "Dessalinização de água",
        "cloudEducation": "Educação na nuvem",
        "lifestyle": "Estilo de vida",
        "mediaAndEntertainment": "Mídia e entretenimento",
        "cleanTransportation": "Transporte limpo",
        "oilAndGas": "Petróleo e gás",
        "dataStorage": "Armazenamento de dados",
        "transport": "Transporte",
        "construction": "Construção",
        "sports": "Esportes",
        "trip": "Viagem",
        "retailSale": "Venda à varejo",
        "brainaging": "Envelhecimento do cérebro",
        "virtualReality": "Realidade virtual",
        "aerospace": "Aeroespacial",
        "3dPrint": "Impressão 3D",
        "blockChain": "Blockchain",
        "electronicsInstrumentation": "Eletrônica / Instrumentação",
        "itServices": "Serviços de TI",
        "fintech": "Fintech",
        "artificialIntelligence": "Inteligência artificial",
        "syntheticMaterials": "Materiais sintéticos",
        "financialServices": "Serviços financeiros",
        "pc": "PC",
        "smartThings": "SmartThings",
        "augmentEdreality": "Realidade aumentada",
        "brainConnectomy": "Conectividade do cérebro",
        "logistics": "Logística",
        "smartCities": "Cidades inteligentes",
        "fishing": "Pescaria",
        "biotechnology": "Biotecnologia",
        "digitalAdvertising": "Publicidade digital",
        "others": "Outros",
        "smartphonesWithContactlenses": "Smartphones com lentes de contato",
        "artificialFood": "Comida artificial",
        "foreignTrade": "Comércio exterior",
        "customerService": "Serviço ao cliente",
        "nanoTechnology": "Nanotecnologia",
        "fashion": "Moda",
        "naturalUserInterfaces": "Interfaces de usuário naturais",
        "realEstate": "Imobiliária",
        "software": "Software",
        "telecommunications": "Telecomunicações",
        "geneSequencing": "Sequenciamento de genes",
        "computersAndPeripherals": "Computadores e periféricos",
        "marketingAds": "Anúncios de marketing",
        "gaming": "Jogos",
        "bigData": "Big data",
        "chemicalsAndProductChemicals": "Química e produtos químicos",
        "commerce": "Comércio",
        "electronicsDevices": "Dispositivos eletrônicos",
        "networksAndEquipment": "Redes e equipamentos",
        "quantumComputing": "Computação quântica",
        "drones": "Drones",
        "cybersecurity": "Cibersegurança",
        "iot": "IoT",
        "climateEngineering": "Engenharia climática",
        "medicalDevicesAndEquipment": "Dispositivos e equipamentos médicos",
        "consumerProducts": "Produtos de consumo",
        "productsForCompanies": "Produtos para empresas",
        "mining": "Mineração",
        "smartCar": "Carro inteligente"
      }
    },
    "careerType": {
      "key": {
        "singular": "Tipo de Carrera",
        "plural": "Tipos de Carreras"
      },
      "items": {
        "course": "Curso",
        "degree": "Licenciatura",
        "master": "Mestre"
      }
    },
    "careerModality": {
      "key": {
        "singular": "Modalidade",
        "plural": "Modalidades"
      },
      "items": {
        "online": "Online",
        "presential": "Pessoalmente",
        "semiPresential": "Semi-Presenciais"
      }
    },
    "adviserSkills": {
      "key": {
        "singular": "Habilidades do Consultor",
        "plural": "Habilidades do Consultor"
      },
      "items": {
        "careerAdvise": "Conselheiro de Carreira",
        "financialConsulting": "Consultor financeiro",
        "businessDevelopment": "Desenvolvimento de Negócios",
        "legal": "Jurídico",
        "socialMedia": "Redes Sociais",
        "branding": "Marca",
        "salesAnd_lead": "Liderança e Vendas",
        "advertising": "Publicidade",
        "crowfounding": "Crowfounding",
        "ventureCapital": "Capital de risco"
      }
    },
    "market": {
      "key": {
        "singular": "Setor",
        "plural": "Setores"
      },
      "items": {
        "aerospace": "Aeroespacial",
        "agriculture": "Agricultura",
        "retailer": "Retalho",
        "foodAndDrink": "Alimentos e bebidas",
        "marketing": "Anúncios de marketing",
        "realState": "Imobiliária",
        "biotechnology": "Biotecnologia",
        "computers": "Computadores e Periféricos",
        "construction": "Construção",
        "sports": "Esportes",
        "medicalDevices": "Dispositivos e equipamentos médicos",
        "education": "Educação",
        "electronic": "Eletrônica / Instrumentação",
        "lifestyle": "Estilo de vida",
        "fintech": "Fintech",
        "industryAndEnergy": "Industrial / Energia",
        "internet": "Serviços de Internet / Internet",
        "insurtech": "Insurtech",
        "gameOfChance": "Jogos",
        "legal": "Jurídico",
        "logistic": "Logística",
        "entertainmentAndMedia": "Mídia e entretenimento",
        "mining": "Mineração",
        "moda": "Moda",
        "movil": "Celular",
        "nanotechnology": "Nanotecnologia",
        "others": "Outras",
        "oilAndGas": "Óleo e gás",
        "consumableProducts": "Produtos de consumo",
        "companyProducts": "Produtos para empresas",
        "chemistryProducts": "Produtos Químicos",
        "chemistry": "Química",
        "networkAndEquipment": "Networking e equipamentos",
        "robotic": "Robótica",
        "security": "Segurança",
        "clientService": "Serviço ao cliente",
        "health": "Serviços de saúde",
        "finance": "Serviços financeiros",
        "it": "Serviços de informática",
        "software": "Software",
        "cleanTechnology": "Tecnologia limpa",
        "telecomunication": "Telecomunicações",
        "transport": "Transporte",
        "trips": "Viajar",
        "videogames": "Videojogos"
      }
    },
    "roles": {
      "key:": {
        "singlular": "Funçaõ",
        "plural": "Funções"
      },
      "items": {
        "CEO": "CEO",
        "COO": "COO",
        "CTO": "CTO",
        "CDO": "CDO",
        "CIO": "CIO",
        "CFO": "CFO",
        "CMO": "CMO",
        "CCO": "CCO",
        "CSO": "CSO",
        "CLO": "CLO",
        "director": "Diretor",
        "manager": "Gerente",
        "chief": "Chefe",
        "delegate": "Delegar",
        "coordinator": "Coordenador",
        "senior": "Senior",
        "semiSenior": "Semi Senior",
        "junior": "Júnior",
        "operative": "Operário",
        "assistant": "Assistente",
        "intern": "Estagiário",
        "scholar": "Estudioso"
      }
    },
    "categoriesBusiness": {
      "key": {
        "singular": "Negócio",
        "plural": "Negócios"
      },
      "items": {
        "businessCoaching": "Coaching de negócios",
        "businessPlan": "Business plan",
        "canvas": "Canvas",
        "financialQuery": "Consulta financeira",
        "businessDevelopment": "Desenvolvimento de negócios",
        "strategy": "Estratégia",
        "executiveCoach": "Coach Executivo",
        "legal": "Jurídico",
        "brandAndPatents": "Marca registrada e patentes",
        "humanResources": "Recursos humanos"
      }
    },
    "categoriesSalesAndMarketing": {
      "key": {
        "singular": "Vendas e Marketing",
        "plural": "Vendas e Marketing"
      },
      "items": {
        "commercialCoaching": "Coaching Comercial",
        "emailMarketing": "Email Marketing",
        "commercialStrategy": "Estratégia comercial",
        "inboundMarketing": "Inbound Marketing",
        "leadsAndSales": "Leads e vendas",
        "branding": "Marca",
        "others": "Outros",
        "publishing": "Publicação",
        "advertising": "Publicidade",
        "copywriting": "Copywriting",
        "publicRelations": "Relações públicas",
        "socialMedia": "Social Media",
        "translations": "Traduções"
      }
    },
    "categoriesInvestment": {
      "key": {
        "singular": "Investimento",
        "plural": "Investimentos"
      },
      "items": {
        "accelerator": "Acelerador",
        "businessAngel": "Business Angel",
        "ventureCapital": "Capital de risco",
        "crowdfunding": "Crowdfunding",
        "incubator": "Incubadora",
        "investmentNegotiation": "Negociação de Investimentos",
        "nonprofit": "Sem fins lucrativos"
      }
    },
    "categoriesDesingAndProduct": {
      "key": {
        "singular": "Design e Produto",
        "plural": "Design e Produto"
      },
      "items": {
        "agile": "Ágil",
        "identity": "Identidade",
        "learnStartup": "Lean Startup",
        "metricAndAnalytics": "Métricas e análises",
        "mvp": "MVP",
        "other": "Outros",
        "productManager": "Gestão de produtos",
        "projectManager": "Gerenciamento de Projetos",
        "scrum": "Scrum",
        "userExperience": "Experiência de usuário"
      }
    },
    "categoriesIT": {
      "key": {
        "singular": "IT",
        "plural": "IT"
      },
      "items": {
        "itDesing": "Design de TI",
        "erp": "ERP",
        "leanLogistics": "Logística Lean",
        "leanManagement": "Gestão Lean",
        "mobile": "Celulares",
        "reviewAndDiagnosis": "Revisão e diagnóstico",
        "digitalTransformation": "Transformação digital",
        "web": "Web",
        "Wordpress": "Wordpress"
      }
    },
    "categoriesLegal": {
      "key": {
        "singular": "Jurídico",
        "plural": "Jurídicos"
      },
      "items": {
        "nda": "Acordo de confidencialidade",
        "database": "Base de dados",
        "compliance": "Compliance",
        "softwareDevelopmentContract": "Contrato de desenvolvimento de software",
        "intellectualPropertyContract": "Contrato de propriedade intelectual",
        "seedContract": "Contrato de semente",
        "lopd": "LOPD",
        "softwareRegistration": "Registro de software",
        "termsAndConditions": "Termos e Condições",
        "vehicleSAAS": "Veículo S.A.S"
      }
    },
    "categoriesAccountant": {
      "key": {
        "singular": "Contable",
        "plural": "Contables"
      },
      "items": {
        "accounting": "Contabilidade",
        "dueDiligence": "Due diligence",
        "taxationAndTaxes": "Tributação e Impostos",
        "managementOfPayroll": "Gestão da folha de pagamento",
        "acquisitionOperations": "Operações de aquisição",
        "phantomShares": "Açõs Fantasmas",
        "socialSecurity": "Segurança social",
        "stockOptions": "Stock Options"
      }
    },
    "categoriesEngineeringAndArchitecture": {
      "key": {
        "singular": "Engenharia e arquitetura",
        "plural": "Engenharia e arquitetura"
      },
      "items": {
        "analysisOfTheSupplyChain": "Análise da Cadeia de Suprimentos",
        "audits": "Auditorias",
        "quality": "Qualidade",
        "viabilityStudy": "Estudo de viabilidade",
        "legalEngineeringAndIndustrialSafety": "Engenharia jurídica e segurança industrial",
        "masterplan": "Masterplan",
        "enviroment": "Meio Ambiente",
        "costOptimization": "Otimização de custos",
        "industrialPlanning": "Planejamento industrial",
        "supplyChainAndLogistics": "Supply Chain e Logística"
      }
    },
  "categoriesMedicineAndPsychology": {
    "key": {
      "singular": "Medicina y Psicologia",
      "plural": "Medicina y Psicologia"
    },
    "items": {
      "accreditWorks": "Acreditar trabajos",
      "applyAndInterpretAssessments": "Aplicar e interpretar evaluaciones",
      "clinicalTrials": "Ensayos clínicos",
      "co-reportStudies": "Co-informar estudios",
      "developTreatmentsAndPlans": "Desarrollar tratamientos y planes",
      "directMedicalProjects": "Dirigir proyectos médicos",
      "documentTheInformation": "Documentar la información",
      "evaluateTheResults": "Evaluar los resultados",
      "evaluateTrials": "Evaluar ensayos",
      "gatherInformation": "Recopilar información",
      "investigate": "Investigar",
      "keyDecisionMaker": "Tomador de decisiones claves",
      "meetingCoordinator": "Coordinador de juntas",
      "presentScientificInformation": "Presentar información científica",
      "supervise": "Supervisar",
      "toDiagnose": "Diagnosticar"
    }
  },
    "talentType": {
      "key": {
        "singular": "Tipo de Talent",
        "plural": "Tipos de Talent"
      },
      "items": {
        "employee": "Emprego",
        "co-founder": "Co-fundador",
        "advisor": "Assessor"
      }
    },
    "applierStatus": {
      "key": {
        "singular": "Status do processo",
        "plural": "Status do processo"
      },
      "items": {
        "new": "Novo",
        "interview": "Entrevista",
        "extendedOffer": "Oferta estendida",
        "hired": "Contratado",
        "filed": "Arquivado"
      }
    },
    "applierStatusForTender": {
      "key": {
        "singular": "Status do processo",
        "plural": "Status do processo"
      },
      "items": {
        "new": "Novo",
        "interview": "Entrevista",
        "extendedOffer": "Oferta estendida",
        "withinTheTeam": "Dentro da equipe",
        "filed": "Arquivado"
      }
    }
  }
`;
