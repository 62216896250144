import {
	defaultError,
	getDeleteHeaderWithCredentials,
	getGetHeaderWithCredentials,
	getPatchHeaderWithCredentials,
	getPostHeaderWithCredentials,
	getPutHeaderWithCredentials,
} from "api/config";
import {
	URL_DELETE_COURSE_BY_ID,
	URL_DELETE_STREAMING_BY_ID,
	URL_GET_ALL_PROFILE_ELEARNING,
	URL_GET_ELEARNING_BY_ID,
	URL_GET_ELEARNING_BY_SLUG,
	URL_PATCH_UPDATE_COURSE,
	URL_PATCH_UPDATE_STREAMING,
	URL_POST_NEW_COURSE,
	URL_POST_NEW_STREAMING,
	URL_SUBSCRIBE_TO_ELEARNING,
} from "./urls";

export function fetchNewCourse(data: object, accessToken: string) {
	return function () {
		return fetch(URL_POST_NEW_COURSE, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchUpdateCourse(data: object, courseId: string, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_UPDATE_COURSE(courseId), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function deleteCourseById(courseId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_COURSE_BY_ID(courseId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function fetchNewWebinar(data: object, accessToken: string) {
	return function () {
		return fetch(URL_POST_NEW_STREAMING, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchUpdateWebinar(data: object, webinarId: string, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_UPDATE_STREAMING(webinarId), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function deleteWebinarById(webinarId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_STREAMING_BY_ID(webinarId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getElearningsFromSlugProfile(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ALL_PROFILE_ELEARNING(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getElearningFromElearningId(elearningId: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ELEARNING_BY_ID(elearningId), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getElearningFromElearningSlug(slug: string, accessToken: string = "") {
	return function () {
		return fetch(URL_GET_ELEARNING_BY_SLUG(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function subscribeToElearningAPI(elearningId: string, profileId: string, accessToken: string) {
	return function () {
		return fetch(URL_SUBSCRIBE_TO_ELEARNING(elearningId, profileId), getPutHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
