import { FormattedMessage } from "react-intl";

import {useLocales, useToggle} from "@libs";
import { Button, ArrowRight, InternalLink } from "@common";

import TalentModel from "models/talents/Talent";

import messages from "./messages";

const MAX_TALENTS_TO_DISPLAY = 3;

export const TenderTalentsList = ({ talents }: { talents: TalentModel[] }) => {
	const [isCollapse, { toggleOn }] = useToggle();
	const showAll = () => toggleOn();
	const {currentShortLocale} = useLocales();

	return (
		<>
			{talents
				.filter((_, index) => isCollapse || index < MAX_TALENTS_TO_DISPLAY)
				.map((talent) => (
					<div
						key={talent.id}
						className="flex items-center justify-between py-1"
					>
						<InternalLink
							href={talent.getInternalURL(currentShortLocale)}
							className="flex-1 text-neutral-600"
						>
							{talent.title}
						</InternalLink>
						<div className="w-32 text-center font-bold text-yellow-400">{talent.info.role.text}</div>
						<InternalLink href={talent.getInternalURL(currentShortLocale)}>
							<Button color="gray">
								<ArrowRight className="h-3 w-3" />
							</Button>
						</InternalLink>
					</div>
				))}
			{!isCollapse && talents.length > MAX_TALENTS_TO_DISPLAY && (
				<Button
					color="gray"
					fluid
					onClick={showAll}
				>
					<FormattedMessage
						{...messages.loadMoreTalents}
						values={{
							numberOfHiddenTalents: talents.length - MAX_TALENTS_TO_DISPLAY,
						}}
					/>
				</Button>
			)}
		</>
	);
};
