import RequestModel, { Request } from "models/requests/Request";
import ProfileLink from "./ProfileLink";

export interface ProfileConnections {
	privated: boolean;
	items: ProfileLink[];
	fetching?: Request;
}

class ProfileConnectionsModel implements ProfileConnections {
	privated: boolean = false;
	items: ProfileLink[];
	fetching?: Request;

	constructor(items: ProfileLink[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const connections: ProfileLink[] = [];

		return new ProfileConnectionsModel(connections, RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileConnectionsModel([], RequestModel.requestLoading());
	};

	turnPrivacyOn = () => (this.privated = true);
}

export default ProfileConnectionsModel;
