import * as React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import { HeaderLink } from "./useDropdownHeaderLinks";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Link from "next/link";

function DropdownHeaderLinkItem(props: { item: HeaderLink; pathname: string }) {
	return (
		<Link
			href={props.item.link}
			passHref
		>
			<Menu.Item
				as="a"
				className="padding-1-y"
				active={props.item.link === props.pathname}
				name={props.item.label.toLowerCase()}
			>
				<Icon
					className={"font-color-white-grey"}
					color={props.item.color}
					name={props.item.icon}
					size={"large"}
				/>
				<span className={"margin-1-top font-color-white-grey"}>{props.item.label}</span>
			</Menu.Item>
		</Link>
	);
}

export default React.memo(DropdownHeaderLinkItem);
