export const DatesEs = `
{
    "days": {
        "key": {            
            "singular": "Dia",
            "plural": "Dias"
        },
        "items": {
            "monday": "Lunes",
            "tuesday": "Martes",
            "wednesday": "Miercoles",
            "thursday": "Jueves",
            "friday": "Viernes",
            "saturday": "Sabado",
            "sunday": "Domingo"
        }
    },
    "months": {
        "key": {            
            "singular": "Mes",
            "plural": "Meses"
        },
        "items": {
            "january": "Enero",
            "february": "Febrero",
            "march": "Marzo",
            "april": "Abril",
            "may": "Mayo",
            "june": "Junio",
            "july": "Julio",
            "august": "Agosto",
            "september": "Septiembre",
            "october": "Octubre",
            "november": "Noviembre",
            "december": "Diciembre"
        }
    }  
}
`;
