import { Category } from "models/categories/Category";
import { StringUtils } from "utils/StringUtils";

export interface Experience {
	id: number;
	dateFrom: number;
	dateTo: number;
	description: string;
	company: string;
	name: string;
	now: boolean;
	markets: Category[];
}

class ExperienceModel implements Experience {
	id: number;
	dateFrom: number;
	dateTo: number;
	description: string;
	company: string;
	name: string;
	now: boolean;
	markets: Category[];

	constructor(
		id: number,
		dateFrom: number,
		dateTo: number,
		description: string,
		company: string,
		name: string,
		now: boolean,
		markets: Category[],
	) {
		this.id = id;
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
		this.description = description;
		this.company = company;
		this.name = StringUtils.capitalize(name);
		this.now = now;
		this.markets = markets;
	}
}

export default ExperienceModel;
