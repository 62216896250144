import { Reducer } from "redux";
import { BusinessProfile } from "redux-app/actions/BusinessProfileAction";
import { BusinessProfileState } from "redux-app/reducers/BusinessProfileReducer/typing";

const initialState: BusinessProfileState = {
	id: "",
	about: {
		text: "",
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	markets: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	schools: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	languages: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	info: {
		fullName: "",
		emblem: "",
		url: "",
		country: "",
		slug: {
			value: "",
			isValid: true,
		},
		email: "",
		avatar: {
			src: "",
		},
		backgroundImage: {
			src: "",
		},
		termsAndCondition: false,
		agreementAccepted: false,
	},
	team: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	partners: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	social: {
		facebook: "",
		twitter: "",
		linkedin: "",
		instagram: "",
		youtube: "",
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	statistic: {
		middleFunds: 0,
		percentajePerStartup: 0,
		startUpsPerYear: 0,
	},
	adviserStatistic: {
		wagePerHour: 0,
		percentajePerStartup: 0,
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	adviserSkills: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	regions: {
		items: [],
		fetching: {
			loading: false,
			done: false,
			error: "",
		},
	},
	creation: {
		done: false,
		loading: false,
		error: "",
	},
	privileges: {
		creator: false,
		admin: false,
	},
	getting: {
		done: false,
		loading: false,
		error: "",
	},
	deleting: {
		done: false,
		loading: false,
		error: "",
	},
	updating: {
		done: false,
		loading: false,
		error: "",
	},
};

const businessProfileReducer: Reducer<BusinessProfileState> = (
	state: BusinessProfileState = initialState,
	action: BusinessProfile.BusinessProfileActionTypes,
) => {
	switch (action.type) {
		case BusinessProfile.BUSINESS_PROFILE_INIT:
			state = {
				...action.payload,
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_START_CREATION:
			state = {
				...state,
				creation: {
					done: false,
					loading: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_CREATE_INSTITUTION_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_ADVISER_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_UNIVERSITY_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_COMPANY_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_ACCELERATOR_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_INCUBATOR_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_FAILED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_STARTUP_FAILED:
			state = {
				...state,
				creation: {
					done: false,
					loading: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_CREATE_INSTITUTION_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_UNIVERSITY_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_ADVISER_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_COMPANY_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_ACCELERATOR_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_INCUBATOR_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_BUSINESS_ANGEL_SUCCESSED:
		case BusinessProfile.BUSINESS_PROFILE_CREATE_STARTUP_SUCCESSED:
			state = {
				...state,
				creation: {
					done: true,
					loading: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_FINISH_CREATION:
			state = {
				...state,
				creation: {
					done: false,
					loading: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ABOUT_STARTED:
			state = {
				...state,
				about: {
					...state.about,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ABOUT_FINISHED:
			state = {
				...state,
				about: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ABOUT_FINISHED_WITH_ERRORS:
			state = {
				...state,
				about: {
					...state.about,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_MARKETS_STARTED:
			state = {
				...state,
				markets: {
					...state.markets,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_MARKETS_FINISHED:
			state = {
				...state,
				markets: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_MARKETS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				markets: {
					...state.markets,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SCHOOLS_STARTED:
			state = {
				...state,
				schools: {
					...state.schools,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SCHOOLS_FINISHED:
			state = {
				...state,
				schools: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SCHOOLS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				schools: {
					...state.schools,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_LANGUAGES_STARTED:
			state = {
				...state,
				languages: {
					...state.languages,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_LANGUAGES_FINISHED:
			state = {
				...state,
				languages: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_LANGUAGES_FINISHED_WITH_ERRORS:
			state = {
				...state,
				languages: {
					...state.languages,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_FINISH_CREATE_ELEARNING:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: false,
					doneElearning: false,
					isElearningValid: false,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_INIT_CREATE_ELEARNING:
		case BusinessProfile.BUSINESS_PROFILE_SET_COURSE_STARTED:
		case BusinessProfile.BUSINESS_PROFILE_SET_WEBINAR_STARTED:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: true,
					doneElearning: false,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_COURSE_SUCCEDED:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: false,
					doneElearning: true,
					isElearningValid: true,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_WEBINAR_SUCCEDED:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: false,
					doneElearning: true,
					isElearningValid: true,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_COURSE_FAILED:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: false,
					doneElearning: true,
					isElearningValid: false,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_WEBINAR_FAILED:
			state = {
				...state,
				creation: state.creation && {
					...state.creation,
					loadingElearning: false,
					doneElearning: true,
					isElearningValid: false,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_COURSE_STARTED:
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_WEBINAR_STARTED:
			state = {
				...state,
				updating: {
					...state.updating,
					loading: true,
					done: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_COURSE_SUCCEDED:
			state = {
				...state,
				updating: {
					...state.updating,
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_WEBINAR_SUCCEDED:
			state = {
				...state,
				updating: {
					...state.updating,
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_COURSE_FAILED:
			state = {
				...state,
				updating: {
					...state.updating,
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_UPDATE_WEBINAR_FAILED:
			state = {
				...state,
				updating: {
					...state.updating,
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_DELETE_COURSE_STARTED:
		case BusinessProfile.BUSINESS_PROFILE_DELETE_WEBINAR_STARTED:
			state = {
				...state,
				deleting: {
					...state.deleting,
					loading: true,
					done: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_DELETE_COURSE_SUCCEDED:
			state = {
				...state,
				deleting: {
					...state.deleting,
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_DELETE_WEBINAR_SUCCEDED:
			state = {
				...state,
				deleting: {
					...state.deleting,
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_DELETE_COURSE_FAILED:
			state = {
				...state,
				deleting: {
					...state.deleting,
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_DELETE_WEBINAR_FAILED:
			state = {
				...state,
				deleting: {
					...state.deleting,
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_TEAM_STARTED:
			state = {
				...state,
				team: {
					...state.team,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_TEAM_FINISHED:
			state = {
				...state,
				team: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_TEAM_FINISHED_WITH_ERRORS:
			state = {
				...state,
				team: {
					...state.team,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_PARTNERS_STARTED:
			state = {
				...state,
				partners: {
					...state.partners,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_PARTNERS_FINISHED:
			state = {
				...state,
				partners: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_PARTNERS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				partners: {
					...state.partners,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SOCIAL_STARTED:
			state = {
				...state,
				social: {
					...state.social,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SOCIAL_FINISHED:
			state = {
				...state,
				social: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_SOCIAL_FINISHED_WITH_ERRORS:
			state = {
				...state,
				social: {
					...state.social,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_INFO_STARTED:
			state = {
				...state,
				info: {
					...state.info,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_INFO_FINISHED:
			state = {
				...state,
				info: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_INFO_FINISHED_WITH_ERRORS:
			state = {
				...state,
				info: {
					...state.info,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_STATISTICS_STARTED:
			state = {
				...state,
				statistic: state.statistic && {
					...state.statistic,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_STATISTICS_FINISHED:
			state = {
				...state,
				statistic: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_STATISTICS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				statistic: state.statistic && {
					...state.statistic,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_STATISTICS_STARTED:
			state = {
				...state,
				adviserStatistic: state.adviserStatistic && {
					...state.adviserStatistic,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED:
			state = {
				...state,
				adviserStatistic: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_STATISTICS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				adviserStatistic: state.adviserStatistic && {
					...state.adviserStatistic,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_SKILLS_STARTED:
			state = {
				...state,
				adviserSkills: {
					...state.adviserSkills,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED:
			state = {
				...state,
				adviserSkills: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_SKILLS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				adviserSkills: {
					...state.adviserSkills,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_REGIONS_STARTED:
			state = {
				...state,
				regions: {
					...state.regions,
					fetching: {
						loading: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_REGIONS_FINISHED:
			state = {
				...state,
				regions: {
					...action.payload,
					fetching: {
						loading: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_REGIONS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				regions: {
					...state.regions,
					fetching: {
						loading: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_CHECK_SLUG_INIT:
			state = {
				...state,
				info: {
					...state.info,
					slug: {
						value: action.payload,
						isValid: false,
						checking: true,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_CHECK_SLUG_FAILED:
			state = {
				...state,
				info: {
					...state.info,
					slug: {
						...state.info.slug,
						isValid: false,
						checking: false,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_CHECK_SLUG_SUCCEDED:
			state = {
				...state,
				info: {
					...state.info,
					slug: {
						...state.info.slug,
						isValid: true,
						checking: false,
					},
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_SET_TYPE:
			state = {
				...state,
				type: action.payload,
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_GET_STARTED:
			state = {
				...state,
				getting: {
					loading: true,
					done: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_GET_FINISHED:
			state = {
				...state,
				...action.payload,
				getting: {
					loading: false,
					done: true,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_GET_FINISHED_WITH_ERRORS:
			state = {
				...state,
				getting: {
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_GET_ELEARNINGS_STARTED:
		case BusinessProfile.BUSINESS_PROFILE_GET_ONE_ELEARNING_STARTED:
			state = {
				...state,
				updating: {
					loading: false,
					done: false,
					error: "",
				},
				getting: {
					loading: true,
					done: false,
					error: "",
				},
			};
			break;
		case BusinessProfile.BUSINESS_PROFILE_GET_ELEARNINGS_FINISHED_WITH_ERRORS:
		case BusinessProfile.BUSINESS_PROFILE_GET_ONE_ELEARNING_FINISHED_WITH_ERRORS:
			state = {
				...state,
				getting: {
					loading: false,
					done: false,
					error: action.payload,
				},
			};
			break;
	}

	return state;
};

export default businessProfileReducer;
