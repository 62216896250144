import { getSpecificCategory } from "api/CategoriesAPI";
import { CategoryType } from "api/CategoriesAPI/urls";
import { BusinessProfileTypes } from "constants/Profiles";
import { BusinessProfileState } from "redux-app/reducers/BusinessProfileReducer/typing";
import CategorySelectableModel from "../../../models/categories/CategorySelectable";

export const mapCategoriesWithLabelListItemProfile = (
	categories: CategorySelectableModel[],
	selectedCategories: { id: number; name: string; category: CategoryType }[],
) => {
	return selectedCategories
		? selectedCategories
				.map((item: any) => {
					const selectedCategory = categories.find((category) => category.id === item.id);

					if (selectedCategory) {
						return {
							...item,
							id: selectedCategory.id,
							idCategory: selectedCategory.key,
							name: selectedCategory.text,
							selected: true,
						};
					}
				})
				.filter((category: any) => category)
		: [];
};

export const convertAPIResponseToBusinessProfileState = (data: any): BusinessProfileState => {
	function getSocial(socialName: string) {
		return data.social_networks ? data.social_networks.find((item: any) => item.social_network === socialName) : [];
	}

	const socials = {
		facebook: getSocial("facebook"),
		twitter: getSocial("twitter"),
		linkedin: getSocial("linkedin"),
		instagram: getSocial("instagram"),
		youtube: getSocial("youtube"),
	};

	const [languages, schools, markets, sectors, countries, regions, adviserSkills] = [
		getSpecificCategory(CategoryType.LANGUAGES),
		getSpecificCategory(CategoryType.SCHOOL),
		getSpecificCategory(CategoryType.MARKET),
		getSpecificCategory(CategoryType.SECTOR),
		getSpecificCategory(CategoryType.COUNTRY),
		getSpecificCategory(CategoryType.REGION),
		getSpecificCategory(CategoryType.ADVISER_SKILLS),
	];

	function isTheProfileAnInstitutions() {
		return [BusinessProfileTypes.UNIVERSITY, BusinessProfileTypes.INSTITUTION].includes(data.institution_type);
	}

	function isTheProfileAEitherACompanyOrStartup() {
		return [BusinessProfileTypes.COMPANY, BusinessProfileTypes.STARTUP].includes(data.company_type);
	}

	function isTheProfileAnInvestor() {
		return [
			BusinessProfileTypes.BUSINESS_ANGEL,
			BusinessProfileTypes.INCUBATOR,
			BusinessProfileTypes.ACCELERATOR,
		].includes(data.investor_type);
	}

	function getSelectedMarkets() {
		return isTheProfileAnInstitutions()
			? mapCategoriesWithLabelListItemProfile(markets, data.markets)
			: mapCategoriesWithLabelListItemProfile(sectors, data.sectors);
	}

	function getSelectedRegions() {
		// I don't know why sometime the backend retrieves in plural and sometime in singular :/
		const dataRegion = data.region ? data.region : [];
		const dataRegions = data.regions ? data.regions : [];

		return mapCategoriesWithLabelListItemProfile(regions, [...dataRegion, ...dataRegions]);
	}

	function getFetching() {
		return {
			loading: false,
			done: false,
			error: "",
		};
	}

	function getStatisticField(statisticNumber?: number) {
		return statisticNumber || 0;
	}

	function getFullName() {
		if (isTheProfileAnInstitutions() || isTheProfileAEitherACompanyOrStartup() || isTheProfileAnInvestor()) {
			return data.name;
		} else {
			const { user } = data;

			return `${user ? user.first_name : ""} ${user ? user.last_name : ""}`;
		}
	}

	const fullName = getFullName();
	const selectedRegions = getSelectedRegions();
	const selectedMarkets = getSelectedMarkets();
	const selectedCountry =
		data.country && data.country.name ? countries.find((country) => country.key === data.country.name) : "";

	return {
		id: data.id,
		about: {
			text: data.description,
			fetching: getFetching(),
		},
		markets: {
			items: selectedMarkets,
			fetching: getFetching(),
		},
		schools: {
			items: mapCategoriesWithLabelListItemProfile(schools, data.schools),
			fetching: getFetching(),
		},
		languages: {
			items: mapCategoriesWithLabelListItemProfile(languages, data.languages),
			fetching: getFetching(),
		},
		adviserSkills: {
			items: mapCategoriesWithLabelListItemProfile(adviserSkills, data.skills),
			fetching: getFetching(),
		},
		info: {
			fullName,
			emblem: data.emblem || "",
			url: data.url || "",
			country: selectedCountry ? selectedCountry.key : "",
			slug: {
				value: data.slug,
			},
			email: data.email || "",
			avatar: {
				src: data["profile_image"],
			},
			backgroundImage: {
				src: data["cover_image"],
			},
			termsAndCondition: false,
			agreementAccepted: data["agreement_accepted"],
		},
		team: {
			items: [],
			fetching: getFetching(),
		},
		partners: {
			items: [],
			fetching: getFetching(),
		},
		social: {
			facebook: (socials.facebook && socials.facebook.url) || "",
			twitter: (socials.twitter && socials.twitter.url) || "",
			linkedin: (socials.linkedin && socials.linkedin.url) || "",
			instagram: (socials.instagram && socials.instagram.url) || "",
			youtube: (socials.youtube && socials.youtube.url) || "",
			fetching: getFetching(),
		},
		statistic: {
			middleFunds: getStatisticField(data.average_investment),
			percentajePerStartup: getStatisticField(data.average_investment_percentage),
			startUpsPerYear: getStatisticField(data.number_of_startups_to_invest),
		},
		adviserStatistic: {
			wagePerHour: getStatisticField(data.cost),
			percentajePerStartup: getStatisticField(data.percentage),
			fetching: getFetching(),
		},
		regions: {
			items: selectedRegions,
		},
		creation: {
			done: false,
			loading: false,
			error: "",
		},
		privileges: {
			creator: data.is_creator,
			admin: data.is_admin,
		},
		getting: {
			loading: false,
			done: true,
			error: "",
		},
	};
};
