import { useQuery } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { PROD_URL, getRequestConfig } from "../config";

import { Marketplace } from "./types";
import axios from "axios";

const fetchMarketplace = (marketplaceSlug: string, accessToken: string) => {
	return function (): Promise<Marketplace> {
		return axios
			.get(`${PROD_URL}/marketplace/${marketplaceSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
};

export const useMarketplaceQuery = (marketplaceSlug: string) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Marketplace>(["marketplace", marketplaceSlug], fetchMarketplace(marketplaceSlug, accessToken));
};
