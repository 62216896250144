import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { WebinarPayload } from "./types";

type UpdateWebinarPayload = {
	webinarId: string;
	data: WebinarPayload;
};

function updateWebinar(payload: UpdateWebinarPayload, config: AxiosRequestConfig) {
	return axios.patch(`${PROD_URL}/streamings/${payload.webinarId}`, payload.data, config);
}

export const useUpdateWebinarMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, UpdateWebinarPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: UpdateWebinarPayload) => updateWebinar(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
