export const IS_IT_PROD = process.env.NEXT_PUBLIC_ENVIRONMENT === "prod";
export const PROD_URL = process.env.NEXT_PUBLIC_VINGLET_API_URL;

export const INTERACTION_SERVICE_URL = process.env.NEXT_PUBLIC_INTERACTION_SERVICE_URL;

export function getPostHeader(data: object): RequestInit {
	return {
		method: "POST",
		body: JSON.stringify(data),
		mode: "cors",
		headers: {
			cache: "no-cache",
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
		},
	};
}

export function getPostHeaderWithCredentials(data: object, accessToken: string): RequestInit {
	return {
		method: "POST",
		body: JSON.stringify(data),
		mode: "cors",
		headers: {
			cache: "no-cache",
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};
}

export function getFileHeaderWithCredentials(data: FormData, contentType: string, accessToken: string): RequestInit {
	return {
		method: "POST",
		body: data,
		mode: "cors",
		headers: {
			cache: "no-cache",
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
			//"Content-Type": "multipart/form-data;",
			Authorization: `Bearer ${accessToken}`,
		},
	};
}

export function getPatchHeaderWithCredentials(data: object, accessToken: string): RequestInit {
	return {
		method: "PATCH",
		mode: "cors",
		body: JSON.stringify(data),
		headers: {
			cache: "no-cache",
			Authorization: `Bearer ${accessToken}`,
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
		},
	};
}

export function getPutHeaderWithCredentials(data: object, accessToken: string): RequestInit {
	return {
		method: "PUT",
		mode: "cors",
		body: JSON.stringify(data),
		headers: {
			cache: "no-cache",
			Authorization: `Bearer ${accessToken}`,
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
		},
	};
}

export function getDeleteHeaderWithCredentials(accessToken: string, data: object = {}): RequestInit {
	return {
		method: "DELETE",
		mode: "cors",
		body: JSON.stringify(data),
		headers: {
			cache: "no-cache",
			Authorization: `Bearer ${accessToken}`,
			"Access-Control-Allow-Headers": "*",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};
}

export function getGetHeader(): RequestInit {
	return {
		method: "GET",
		mode: "cors",
	};
}

export function getGetHeaderWithCredentials(accessToken: string): any {
	const credentials = {
		method: "GET",
		mode: "cors",
		headers: {},
	};

	if (accessToken) {
		credentials.headers = {
			cache: "no-cache",
			Authorization: accessToken && `Bearer ${accessToken}`,
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
		};
	}

	return credentials;
}

export function defaultError() {
	return {
		code: 404,
		message: "Oops something went wrong try again later...",
	};
}

export function defaultSuccess() {
	return {
		code: 200,
		message: "OK",
	};
}
