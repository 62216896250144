import { PROD_URL } from "api/config";

const URL_USERS = `${PROD_URL}/users`;
const URL_USER_DATA = () => `${PROD_URL}/user-logged-in`;
const URL_FORGOT_PASSWORD = () => `${PROD_URL}/forgot-password`;
const URL_RESET_PASSWORD = () => `${PROD_URL}/reset-password`;
const URL_DELETE_USER_ACCOUNT = () => `${PROD_URL}/user/delete`;
const URL_GET_USERS_BY_FULL_NAME = (userNameToSearch: string, page: number, itemPerPage: number) =>
	`${PROD_URL}/user-profiles/search?search=${userNameToSearch}&page=${page}&per_page=${itemPerPage}`;

export {
	URL_USERS,
	URL_USER_DATA,
	URL_FORGOT_PASSWORD,
	URL_RESET_PASSWORD,
	URL_DELETE_USER_ACCOUNT,
	URL_GET_USERS_BY_FULL_NAME,
};
