import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { UserProfile } from "./types";

function fetchUserProfile(profileSlug: string, accessToken: string) {
	return function () {
		return axios
			.get(`${PROD_URL}/profiles/${profileSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useUserProfileQuery = (
	profileSlug: string,
	options: UseQueryOptions<UserProfile, any, UserProfile> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<UserProfile>(["profile", profileSlug], fetchUserProfile(profileSlug, accessToken), {
		...options,
	});
};
