import { NumberUtils } from "utils/NumberUtils";

export interface TalentRequirements {
	minEquity: number;
	maxEquity: number;
	salary: number;
	minExperience: number;
}

class TalentRequirementsModel implements TalentRequirements {
	minEquity: number;
	maxEquity: number;
	salary: number;
	minExperience: number;

	constructor(minEquity: number, maxEquity: number, salary: number, minExperience: number) {
		this.minEquity = minEquity;
		this.maxEquity = maxEquity;
		this.salary = salary;
		this.minExperience = minExperience;
	}

	getEquityUIText = () => {
		return this.minEquity === this.maxEquity
			? `${this.minEquity}%`
			: `${this.minEquity}% ${this.maxEquity && "- " + this.maxEquity + "%"}`;
	};

	getShorterSalary = () => {
		return NumberUtils.convertNumberToShorterNumber(this.salary);
	};

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}) {
		return new TalentRequirementsModel(data.min_shares, data.max_shares, data.salary, data.min_experience);
	}

	static generateEmpty() {
		return new TalentRequirementsModel(0, 0, 0, 0);
	}

	static generateFromObject(talentObject: TalentRequirements) {
		return new TalentRequirementsModel(
			talentObject.minEquity,
			talentObject.maxEquity,
			talentObject.salary,
			talentObject.minExperience,
		);
	}
}

export default TalentRequirementsModel;
