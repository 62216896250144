import AwardsModel, { Awards } from "models/awards/Award";
import RequestModel, { Request } from "models/requests/Request";
import { DateUtils } from "@utils";

export interface ProfileAwards {
	items: Awards[];
	fetching?: Request;
}

class ProfileAwardsModel implements ProfileAwards {
	items: Awards[];
	fetching?: Request;

	constructor(items: Awards[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const awards =
			data.awards &&
			Array.isArray(data.awards) &&
			data.awards.map(
				(award: any) =>
					new AwardsModel(award.id, award.title, DateUtils.getEpochFromAPIFormat(award.date), award.description),
			);

		return new ProfileAwardsModel(awards, RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileAwardsModel([], RequestModel.requestLoading());
	};

	exportToAPIRequest = () => {
		return this.items.map((item) => ({
			title: item.title,
			description: item.description,
			date: DateUtils.getTimeAPIFormat(item.date),
		}));
	};
}

export default ProfileAwardsModel;
