import { defaultError } from "api/config";
import { checkSlugAPI, getUserProfileBySlug, patchUserProfileBySlug } from "api/ProfileAPI";
import AwardsModel from "models/awards/Award";
import ExperienceModel from "models/experiences/Experience";
import ProfileModel from "models/profiles/Profile";
import ProfileAboutModel from "models/profiles/ProfileAbout";
import ProfileAwardsModel from "models/profiles/ProfileAwards";
import ProfileExperienceModel from "models/profiles/ProfileExperience";
import ProfileInfoModel from "models/profiles/ProfileInfo";
import ProfileOverviewModel from "models/profiles/ProfileOverview";
import ProfileRecommendationsModel from "models/profiles/ProfileRecommendations";
import ProfileSkillsModel from "models/profiles/ProfileSkills";
import ProfileSocialModel from "models/profiles/ProfileSocial";
import ProfileStudyModel from "models/profiles/ProfileStudy";
import RecommendationModel from "models/recommendations/Recommendation";
import RequestModel from "models/requests/Request";
import SkillModel from "models/skills/Skill";
import StudyModel from "models/studies/Study";
import { AppState } from "redux-app";
import { Auth } from "redux-app/actions/AuthAction";
import { User } from "redux-app/actions/UserAction";
import { LinkProfilePropsState } from "redux-app/reducers/UserReducer/typing";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkCredentials } from "../AuthSagas";
import { convertAPIProfileResponseToUserState, convertUserStateToApiRequest } from "./formatter";
import { CookiesUtils } from "@utils";

function* checkSlug(action: { type: typeof User.USER_CHECK_LINKS; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(User.checkLinkStatus(true));

		try {
			const slugIsANumber = Number.isInteger(Number(action.payload));

			if (action.payload !== "" && !slugIsANumber) {
				// @ts-ignore
				const link = yield call(checkSlugAPI(action.payload, accessToken));
				// If exists or return an error
				if (link.message || link.slug != action.payload) {
					yield put(User.checkLinkFailed());
				} else {
					yield put(User.checkLinkSucceded());
				}
			} else {
				yield put(User.checkLinkFailed());
			}
		} catch (err) {
			yield put(User.checkLinkFailed());
		} finally {
			yield put(User.checkLinkStatus(false));
		}
	}
}

function* getProfileBySlug(action: { type: typeof User.USER_GET_PROFILE; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	yield put(User.loading(true));

	try {
		// @ts-ignore
		const user = yield call(getUserProfileBySlug(action.payload, accessToken));

		if (user.message) {
			yield put(User.receiveProfileFailed(user.message));
		} else {
			const userState: ProfileModel = convertAPIProfileResponseToUserState(user);

			yield put(User.receiveProfileSucceded(userState));

			if (userState.myProfile) {
				yield put(Auth.updateUserProfile(userState));
			}

			yield put(User.userGetProfilesByUser());
		}
	} catch (err) {
		yield put(User.receiveProfileFailed(defaultError().message));
	} finally {
		yield put(User.loading(false));
	}
}

function* setProfile(
	profile: ProfileModel,
	onFinish: (currentProfile: ProfileModel) => ProfileModel,
	onError: (currentProfile: ProfileModel, err: string) => ProfileModel,
) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	yield put(User.setProfileStarted(profile));

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		try {
			const preparedDataToSend = convertUserStateToApiRequest(profile);
			// @ts-ignore
			const response = yield call(patchUserProfileBySlug(preparedDataToSend, profile.links.link, accessToken));

			if (response.message) {
				yield put(User.setProfileFinishWithErrors(onError(profile, response.message)));
			} else {
				yield put(User.setProfileFinish(onFinish(profile)));
			}
		} catch (err) {
			yield put(User.setProfileFinishWithErrors(onError(profile, defaultError().message)));
		}
	}
}

function* setAbout(action: { payload: ProfileAboutModel; type: typeof User.USER_SET_ABOUT }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.about = new ProfileAboutModel(String(action.payload.text), RequestModel.requestLoading());

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.about.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.about.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setAdviserSkills(action: { type: typeof User.USER_SET_ADVISER_SKILLS; payload: SkillModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.adviserSkills = new ProfileSkillsModel(action.payload, RequestModel.requestLoading());

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.adviserSkills.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.adviserSkills.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setAwards(action: { type: typeof User.USER_SET_AWARDS; payload: AwardsModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.awards = new ProfileAwardsModel(action.payload, RequestModel.requestLoading());
	profile.awards.items.forEach((item) => {
		if (!item.id) {
			item.id = Math.floor(Math.random() * -1000 + 1);
		}
	});

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.awards.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.awards.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setExperiences(action: { type: typeof User.USER_SET_EXPERIENCES; payload: ExperienceModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.experience = new ProfileExperienceModel(action.payload, RequestModel.requestLoading());
	profile.experience.items.forEach((item) => {
		if (!item.id) {
			item.id = Math.floor(Math.random() * -1000 + 1);
		}
	});

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.experience.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.experience.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setInfo(action: { type: typeof User.USER_SET_INFO; payload: ProfileInfoModel }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.info = ProfileInfoModel.generateFromObject({
		...action.payload,
		fetching: RequestModel.requestLoading(),
	});

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.info.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.info.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setLinks(action: { type: typeof User.USER_SET_LINKS; payload: LinkProfilePropsState }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.links = action.payload;
	profile.links.checking = true;

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.links.checking = false;
			currentProfile.links.isValid = true;
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.links.checking = false;
			currentProfile.links.isValid = false;
			return currentProfile;
		},
	);
}

function* setOverview(action: { type: typeof User.USER_SET_OVERVIEW; payload: ProfileOverviewModel }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.overview = action.payload;
	profile.overview.fetching = RequestModel.requestLoading();

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.overview.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.overview.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* serRecommendation(action: { type: typeof User.USER_SET_RECOMMENDATIONS; payload: RecommendationModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.recommendations = new ProfileRecommendationsModel(action.payload, RequestModel.requestLoading());

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.recommendations.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.recommendations.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setSkills(action: { type: typeof User.USER_SET_SKILLS; payload: SkillModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.skills = new ProfileSkillsModel(action.payload, RequestModel.requestLoading());

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.skills.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.skills.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setSocial(action: { type: typeof User.USER_SET_SOCIAL; payload: ProfileSocialModel }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.social = action.payload;
	profile.social.fetching = RequestModel.requestLoading();

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.social.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.social.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

function* setStudy(action: { type: typeof User.USER_SET_STUDIES; payload: StudyModel[] }) {
	const profile: ProfileModel = yield select((state: AppState) => state.authReducer.userState);
	profile.studies = new ProfileStudyModel(action.payload, RequestModel.requestLoading());
	profile.studies.items.forEach((item) => {
		if (!item.id) {
			item.id = Math.floor(Math.random() * -1000 + 1);
		}
	});

	yield setProfile(
		profile,
		(currentProfile) => {
			currentProfile.studies.fetching = RequestModel.requestDone();
			return currentProfile;
		},
		(currentProfile, err) => {
			currentProfile.studies.fetching = RequestModel.requestError(err);
			return currentProfile;
		},
	);
}

export const rootProfileSagas = [
	takeLatest(User.USER_SET_ABOUT, setAbout),
	takeLatest(User.USER_SET_ADVISER_SKILLS, setAdviserSkills),
	takeLatest(User.USER_SET_AWARDS, setAwards),
	takeLatest(User.USER_SET_EXPERIENCES, setExperiences),
	takeLatest(User.USER_SET_INFO, setInfo),
	takeLatest(User.USER_SET_LINKS, setLinks),
	takeLatest(User.USER_SET_OVERVIEW, setOverview),
	takeLatest(User.USER_SET_RECOMMENDATIONS, serRecommendation),
	takeLatest(User.USER_SET_SKILLS, setSkills),
	takeLatest(User.USER_SET_SOCIAL, setSocial),
	takeLatest(User.USER_SET_STUDIES, setStudy),
	takeLatest(User.USER_CHECK_LINKS, checkSlug),
	takeLatest(User.USER_GET_PROFILE, getProfileBySlug),
];
