import React from "react";
import { ProductContext } from "./ProductContext";
import { usePaymentsProductsWithPricesQuery } from "../../api";
import { VingletLoader } from "../../common";

export const ProductProvider = ({ children }: { children: JSX.Element }) => {
	const { data: productWithPrices = [], isLoading } = usePaymentsProductsWithPricesQuery();

	if (isLoading) {
		return <VingletLoader />;
	}

	return <ProductContext.Provider value={{ productWithPrices }}>{children}</ProductContext.Provider>;
};
