import * as React from "react";
import classNames from "classnames";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { UniversityCard } from "../UniversityCard/UniversityCard";

interface UniversitiesListProps {
	universities: BusinessProfileModel[];
	loading: boolean;
	className?: string;
}

export const UniversitiesList = ({ universities, loading, className }: UniversitiesListProps) => {
	return (
		<ul
			className={classNames(
				"mt-6 grid w-full items-center justify-between gap-4 text-center",
				"grid-cols-2 xl:grid-cols-4",
				className,
			)}
		>
			{loading
				? [1, 2, 3, 4].map(() => <></>)
				: universities?.map((university) => (
						<li
							key={university.info.id}
							className="h-full flex-1"
							data-testid={`UNIVERSITY_${university.info.id}`}
						>
							<UniversityCard
								name={university.info.fullName}
								href={university.info.url}
								imgSrc={university.info.avatar.src}
								country={university.info.country.text}
								agreementAccepted={university.info.agreementAccepted}
								schoolsNames={university.features.schools.map((school) => school.text)}
							/>
						</li>
				  ))}
		</ul>
	);
};
