import { URL_HTTP, URL_HTTPS } from "constants/URLs";

export const URLUtils = {
	sanitize: function (urlValue: string) {
		if (!urlValue || URL_HTTPS.includes(urlValue)) {
			return URL_HTTPS;
		}

		let sanitizedURL = urlValue;

		sanitizedURL = sanitizedURL.replace(new RegExp(URL_HTTPS, "g"), "");
		sanitizedURL = sanitizedURL.replace(new RegExp(URL_HTTP, "g"), "");

		return URL_HTTPS + sanitizedURL;
	},

	removeHttpsProtocol: function (urlValue: string) {
		const sanitizeValue = this.sanitize(urlValue);

		return sanitizeValue.replace(new RegExp(URL_HTTPS, "g"), "");
	},
};
