import { DatesEn } from "i18n/localization/dates/en";
import { FeaturesEn } from "i18n/localization/features/en";
import { WolrdEn } from "i18n/localization/world/en";

export const localizationEn = `
[  
    {
        "field": "dates",
        "translations": [
            {
                "lang": "en",
                "data": ${DatesEn}
            }
        ]
    },
    {
        "field": "features",
        "translations": [
            {
                "lang": "en",
                "data": ${FeaturesEn}
            }
        ]
    },
    {
        "field": "world",
        "translations": [
            {
                "lang": "en",
                "data": ${WolrdEn}
            }
        ]
    }
]
`;
