import { ComponentProps, FC } from "react";

import { Breadcrumb as BreadcrumbFlowbite } from "flowbite-react";
import classNames from "classnames";

export type BreadcrumbsItemProps = {
	text: string;
	href?: string;
	icon?: FC<ComponentProps<"svg">>;
	className?: string;
};

interface BreadcrumbsProps {
	items: BreadcrumbsItemProps[];
	ariaLabel: string;
}

export const Breadcrumbs = ({ items, ariaLabel }: BreadcrumbsProps) => (
	<div className={classNames("w-full bg-gray-100 dark:bg-gray-900", "px-4 lg:px-10 xl:px-20 2xl:px-44")}>
		<BreadcrumbFlowbite
			aria-label={ariaLabel}
			className="mx-auto w-full py-3"
		>
			{items.map((item) => (
				<BreadcrumbFlowbite.Item
					key={item.text}
					{...item}
				>
					{item.text}
				</BreadcrumbFlowbite.Item>
			))}
		</BreadcrumbFlowbite>
	</div>
);
