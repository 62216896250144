import { getGetHeader } from "api/config";
import { LabelListItemProfileLegacy } from "components/Profile/LabelListProfile/typing";
import { CategoryType, URL_GET_CATEGORIES } from "./urls";
import CategoryModel from "../../models/categories/Category";
import CategoriesServiceSingleton from "../../services/categories/CategoriesService";
import CategorySelectableModel from "../../models/categories/CategorySelectable";

export const getCategoriesAPI = () => {
	return function () {
		return fetch(URL_GET_CATEGORIES, getGetHeader())
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw Error(`Request error with status ${response.status}`);
				}
			})
			.catch((err) => err);
	};
};

export const getSpecificCategory = (type: CategoryType): CategorySelectableModel[] => {
	const categoriesList = CategoriesServiceSingleton.getList();
	const selectedCategories = categoriesList.filterByCategoryType(type) as CategoryModel[];

	return selectedCategories.map((category) => CategorySelectableModel.createFromCommonCategory(category));
};

export const getIdsFromItemsCategories = (
	items: LabelListItemProfileLegacy[] | undefined,
	itemsCategory: CategorySelectableModel[],
) => {
	return items && Array.isArray(itemsCategory)
		? items
				.filter((item) => item.selected)
				.map((item) => {
					const el = itemsCategory.find((itemCategory: any) => item.idCategory === itemCategory.key);

					if (el) {
						return el.id;
					}
				})
				.filter((item) => item)
		: [];
};
