export const MAX_LENGTH_SUMMARY = 100;
export const MAX_COUNT_LABELS_TO_SHOW = 6;
export const MAX_COUNT_LABELS_TO_SHOW_MOBILE = 3;
export const MAX_FILTERS_LISTED = 6;

export const MIN_LENGTH_NAME = 1;
export const MAX_LENGTH_NAME = 16;

export const MIN_LENGTH_SURNAME = 1;
export const MAX_LENGTH_SURNAME = 16;

export const MIN_LENGTH_EMBLEM = 0;
export const MAX_LENGTH_EMBLEM = 70;

export const MIN_LENGTH_PASSWORD = 1;
export const MAX_LENGTH_PASSWORD = 40;

export const MIN_LENGTH_TOKEN = 1;
export const MAX_LENGTH_TOKEN = 100;

export const MIN_LENGTH_ELEARNING_TITLE = 4;
export const MAX_LENGTH_ELEARNING_TITLE = 200;

export const MIN_LENGTH_ELEARNING_WEBINAR_TITLE = 4;
export const MAX_LENGTH_ELEARNING_WEBINAR_TITLE = 200;

export const MIN_LENGTH_MARKETPLACE_TITLE = 4;
export const MAX_LENGTH_MARKETPLACE_TITLE = 200;

export const MIN_LENGTH_CHALLENGUE_TITLE = 4;
export const MAX_LENGTH_CHALLENGUE_TITLE = 200;

export const MIN_LENGTH_TALENT_TITLE = 4;
export const MAX_LENGTH_TALENT_TITLE = 200;

export const MIN_LENGTH_TALENT_ROLE = 2;
export const MAX_LENGTH_TALENT_ROLE = 70;

export const MIN_LENGTH_TALENT_LOCATION = 2;
export const MAX_LENGTH_TALENT_LOCATION = 70;

export const MIN_LENGTH_TALENT_COUNTRY = 2;
export const MAX_LENGTH_TALENT_COUNTRY = 70;

export const MIN_LENGTH_STUDIES_CAREER = 4;
export const MAX_LENGTH_STUDIES_CAREER = 70;

export const MIN_LENGTH_STUDIES_UNIVERSITY = 4;
export const MAX_LENGTH_STUDIES_UNIVERSITY = 70;

export const MIN_LENGTH_EXPERIENCES_JOB = 4;
export const MAX_LENGTH_EXPERIENCES_JOB = 70;

export const MIN_LENGTH_EXPERIENCES_PLACE = 4;
export const MAX_LENGTH_EXPERIENCES_PLACE = 70;

export const MIN_LENGTH_AWARDS_TITLE = 4;
export const MAX_LENGTH_AWARDS_TITLE = 200;

export const MIN_AVERAGE_INVESTMENT = 0;
export const MAX_AVERAGE_INVESTMENT = 500000;
export const STEP_AVERAGE_INVESTMENT = 10000;

export const MIN_AVERAGE_NUMBER_STARTUP_TO_INVEST = 0;
export const MAX_AVERAGE_NUMBER_STARTUP_TO_INVEST = 20;
export const STEP_AVERAGE_NUMBER_STARTUP_TO_INVEST = 1;

export const MIN_AVERAGE_SALARY = 0;
export const MAX_AVERAGE_SALARY = 60000;
export const STEP_AVERAGE_SALARY = 500;

export const MIN_PRICE = 0;
export const MAX_PRICE = 60000;
export const STEP_PRICE = 500;

export const MIN_QUANTITY = 0;
export const MAX_QUANTITY = 60000;
export const STEP_QUANTITY = 500;

export const MIN_DISCOUNT = 0;
export const MAX_DISCOUNT = 100;
export const STEP_DISCOUNT = 5;

export const MIN_AVERAGE_EQUITY = 0;
export const MAX_AVERAGE_EQUITY = 100;
export const STEP_AVERAGE_EQUITY = 0;

export const MIN_COST_PER_HOUR = 0;
export const MAX_COST_PER_HOUR = 200;
export const STEP_COST_PER_HOUR = 50;

export const MIN_STARTUPS_ADVISED = 0;
export const MAX_STARTUPS_ADVISED = 30;
export const STEP_STARTUPS_ADVISED = 1;

export const MAX_LENGTH_ELEARNING_DESCRIPTION = 300;

export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_TALENT_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_TALENT_LANDSCAPE_MOBILE = 150;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_STARTUP_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_STARTUP_LANDSCAPE_MOBILE = 150;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE_MOBILE = 150;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_POSTULATE_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_POSTULATE_LANDSCAPE_MOBILE = 150;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_MARKETPLACE_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_MARKETPLACE_LANDSCAPE_MOBILE = 150;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ELEARNING_LANDSCAPE = 250;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ELEARNING_LANDSCAPE_MOBILE = 150;

export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_BUSINESS_PROFILE = 350;
export const MAX_LENGTH_PARAGRAPH_TO_DISPLAY_PROFILE = 350;
