import axios from "axios";
import queryString from "query-string";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { BusinessProfile, getRequestConfig, PROD_URL } from "@api";
import { getStringifiedList } from "@libs";

interface FetchStartupsRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[regions]"?: number[];
	"search[markets]"?: number[];
}

export interface FetchStartupsResponse {
	total: number;
	page: number;
	num_pages: number;
	items: BusinessProfile[];
}

export function fetchStartups(queryParams: FetchStartupsRequestParams) {
	const params = {
		...queryParams,
		"search[regions]": getStringifiedList(queryParams["search[regions]"]),
		"search[markets]": getStringifiedList(queryParams["search[markets]"]),
	};

	return function () {
		return axios
			.get<FetchStartupsResponse>(`${PROD_URL}/companies-profiles?search[company_type]=STARTUP`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
}

export const useStartupsQuery = (
	queryParams: FetchStartupsRequestParams,
	options: UseQueryOptions<FetchStartupsResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchStartupsResponse>(["startups", queryParams], fetchStartups(queryParams), {
		...options,
		onSuccess: (startups: FetchStartupsResponse) => {
			if (startups && startups.items) {
				startups.items.forEach((startup) => {
					queryClient.setQueryData(["startup", startup.slug], startup);
				});
			}

			options.onSuccess && options.onSuccess(startups);
		},
	});
};
