import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

type DeleteMarketplacePayload = {
	marketplaceSlug: string;
	profileId: string;
};

function deleteMarketplace(payload: DeleteMarketplacePayload, config: AxiosRequestConfig) {
	return axios.delete(`${PROD_URL}/marketplace/${payload.marketplaceSlug}`, {
		...config,
		data: {
			profile_id: payload.profileId,
		},
	});
}

export const useDeleteMarketplaceMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, DeleteMarketplacePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: DeleteMarketplacePayload) => deleteMarketplace(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
