import axios, { AxiosRequestConfig } from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import queryString from "query-string";

import { getRequestConfig, PROD_URL } from "@api";

import { BusinessProfile } from "../types";

interface FetchUniversitiesRequestParams {
	page: number;
	perPage: number;
	orderDirection: "DESC" | "ASC";
}

export interface FetchUniversitiesResponse {
	total: number;
	page: number;
	num_pages: number;
	items: BusinessProfile[];
}

export const fetchUniversities = (
	queryParams: FetchUniversitiesRequestParams,
	config: AxiosRequestConfig = getRequestConfig(),
) => {
	return function (): Promise<FetchUniversitiesResponse> {
		return axios
			.get(`${PROD_URL}/profiles?search[type]=UNIVERSITY`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params: queryParams,
				...config,
			})
			.then((response) => response.data);
	};
};

export const useUniversitiesQuery = (
	queryParams: FetchUniversitiesRequestParams,
	options: UseQueryOptions<FetchUniversitiesResponse, any, FetchUniversitiesResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchUniversitiesResponse>(["universities", queryParams], fetchUniversities(queryParams), {
		...options,
		onSuccess: (universities: FetchUniversitiesResponse) => {
			if (universities && universities.items) {
				universities.items.forEach((university) => {
					queryClient.setQueryData(["university", university.slug], university);
				});
			}

			options.onSuccess && options.onSuccess(universities);
		},
	});
};
