export class URLBuilder {
	private readonly url: string = "";
	private parameters: string = "";
	private searchParameters: string = "";

	constructor(initialPath: string) {
		this.url = initialPath;
	}

	private static isParameterAnArray(searchParameters: any, filterName: string) {
		return Array.isArray(searchParameters[filterName]);
	}

	private static getArrayParameters(parameters: any, filterName: string) {
		if (parameters[filterName].length > 0) {
			return `&${filterName}=[${parameters[filterName]}]`;
		} else {
			return "";
		}
	}

	private static getParameter(searchParameters: any, filterName: string) {
		return `&${filterName}=${searchParameters[filterName]}`;
	}

	withParameters(parameters: any) {
		for (const filter in parameters) {
			if (parameters[filter]) {
				if (URLBuilder.isParameterAnArray(parameters, filter)) {
					this.parameters += URLBuilder.getArrayParameters(parameters, filter);
				} else {
					this.parameters += URLBuilder.getParameter(parameters, filter);
				}
			}
		}

		return this;
	}

	private static getArraySearchParameters(searchParameters: any, filterName: string) {
		if (searchParameters[filterName].length > 0) {
			return `&search[${filterName}]=[${searchParameters[filterName]}]`;
		} else {
			return "";
		}
	}

	private static getSearchParameter(searchParameters: any, filterName: string) {
		return `&search[${filterName}]=${searchParameters[filterName]}`;
	}

	withSearchParameters(searchParameters: any) {
		for (const filter in searchParameters) {
			if (searchParameters[filter]) {
				if (URLBuilder.isParameterAnArray(searchParameters, filter)) {
					this.searchParameters += URLBuilder.getArraySearchParameters(searchParameters, filter);
				} else {
					this.searchParameters += URLBuilder.getSearchParameter(searchParameters, filter);
				}
			}
		}

		return this;
	}

	build() {
		return this.url + this.parameters + this.searchParameters;
	}
}
