import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";

export const useNotifications = () => {
	const { addErrorNotification, addSuccessNotification } = useContext(NotificationContext);

	return {
		addSuccessNotification,
		addErrorNotification,
	};
};
