import { IS_IT_PROD } from "api/config";
import { applyMiddleware, combineReducers, createStore } from "redux";
import businessProfileReducer from "redux-app/reducers/BusinessProfileReducer";
import userReducer from "redux-app/reducers/UserReducer";
import { rootSagas } from "redux-app/sagas";
import authReducer from "./reducers/AuthReducer";
import elearningReducer from "./reducers/ElearningReducer";
import searcherReducer from "./reducers/SearcherReducer";
import talentReducer from "./reducers/TalentReducer";
// @ts-ignore
import createSagaMiddleware from "@redux-saga/core";

export const reducer = combineReducers({
	userReducer: userReducer,
	authReducer: authReducer,
	businessProfileReducer: businessProfileReducer,
	searcherReducer: searcherReducer,
	elearningReducer: elearningReducer,
	talentReducer: talentReducer,
});

export const sagaMiddleware = createSagaMiddleware();
export type AppState = ReturnType<typeof reducer>;
let middlewares = applyMiddleware(sagaMiddleware);

if (!IS_IT_PROD) {
	middlewares = applyMiddleware(sagaMiddleware);
}

const store = createStore(reducer, {}, middlewares);
sagaMiddleware.run(rootSagas);

export default store;
