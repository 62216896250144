import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { BusinessProfile } from "./types";

function fetchBusinessProfile(businessProfileSlug: string, accessToken: string) {
	return function () {
		return axios
			.get(`${PROD_URL}/profiles/${businessProfileSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useBusinessProfileQuery = (
	businessProfileSlug: string,
	options: UseQueryOptions<BusinessProfile> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<BusinessProfile>(
		["business-profile", businessProfileSlug],
		fetchBusinessProfile(businessProfileSlug, accessToken),
		{
			...options,
		},
	);
};
