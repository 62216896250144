import slugify from "react-slugify";

const StringStripHTML = require("striptags");

export namespace StringUtils {
	export const hasExceedAmountOfCharacters = (paragraph: string, limit: number) => {
		return Boolean(paragraph && paragraph.length > limit);
	};

	export const getLimittedParagraph = (paragraph: string, limit: number) => {
		if (hasExceedAmountOfCharacters(paragraph, limit)) {
			paragraph = paragraph.substr(0, limit) + "...";
		}

		return paragraph;
	};

	export const firstCharacterLowerCase = (phrase: string) => {
		return phrase.charAt(0).toLocaleLowerCase() + phrase.slice(1);
	};

	export const firstCharacterUpperCase = (phrase: string) => {
		return phrase.charAt(0).toLocaleUpperCase() + phrase.slice(1);
	};

	export const capitalize = (text: string | undefined) => {
		if (!text) {
			return "";
		}

		return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
	};

	export const capitalizeEveryWord = (text: string | undefined) => {
		if (!text) {
			return "";
		}

		const words = text.split(" ");

		for (let i = 0; i < words.length; i++) {
			words[i] = capitalize(words[i]);
		}

		return words.join(" ");
	};

	export const getHashtashStyle = (word: string = "") => {
		// Capitalize it
		word = word.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
		word = firstCharacterLowerCase(word);
		word = word.replaceAll(" ", "");
		word = word.replaceAll("-", "");

		return word;
	};

	export const convertStringToCorrectSlug = (phrase: string) => {
		const slug = slugify(phrase).replace(/-+/g, "-");
		const splittedSlug = slug.split("-");
		const lastPositionSplittedSlug = splittedSlug.length - 1;

		if (splittedSlug[lastPositionSplittedSlug] === "") {
			return slug.slice(0, -1);
		}

		return slug;
	};

	export const getTextWithoutHtml = (rawHTML: string): string => {
		return StringStripHTML(rawHTML, [], " ");
	};
}
