import { BrowserUtils } from "./BrowserUtils";

const seconds = 60;
const minutes = 60;
const hours = 24;

export const CookiesUtils = {
	getCookie: (name: string) => {
		if (BrowserUtils.isRunningOnBrowser()) {
			const cookieArr = document.cookie.split(";");

			for (let i = 0; i < cookieArr.length; i++) {
				const cookiePair = cookieArr[i].split("=");

				if (name == cookiePair[0].trim()) {
					return decodeURIComponent(cookiePair[1]);
				}
			}
		}

		return null;
	},

	getAccessTokenFromCookie: (): string => {
		const accessTokenFromCookie = CookiesUtils.getCookie("accessToken");

		if (accessTokenFromCookie != null) {
			return String(accessTokenFromCookie);
		}

		return "";
	},

	setCookie: (name: string, value: string, daysToExpire: number) => {
		if (BrowserUtils.isRunningOnBrowser() && document) {
			const today = new Date();
			const expirationDay = new Date();
			const expirationMilliseconds = 1000 * seconds * minutes * hours * daysToExpire;

			expirationDay.setTime(today.getTime() + expirationMilliseconds);

			document.cookie = `${name}=${value}; expires=${expirationDay.toUTCString()}; path=/;`;
		}
	},

	setCookieForever: (name: string, value: string) => {
		if (BrowserUtils.isRunningOnBrowser() && document) {
			document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;`;
		}
	},

	removeCookie: (name: string) => {
		if (BrowserUtils.isRunningOnBrowser() && document) {
			document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		}
	},
};
