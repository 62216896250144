import { Translation } from "i18n/config";
import { HeaderTranslation } from "./typing";

export const HeaderTexts: Translation<HeaderTranslation> = {
	es: {
		youMustBeLoggedToUseIt: "Debes iniciar sesión para acceder",
		searchers: "Buscadores",
		searchUsers: "Buscar Usuarios...",
		messages: "Mensajes",
		notifications: "Notificaciones",
		invitations: "Mi Red",
		home: "Inicio",
		marketplace: "Marketplace",
		challenges: "Retos",
		createBusinessProfile: "Crear Perfil de Startup, Empresas u Otros",
		profiles: "Perfiles",
		adminProfiles: "Perfiles (Admin)",
		logout: "Cerrar Sesion",
		myProfile: "Mi perfil",
		elearning: "E-Learning",
		talent: "Talento",
		startup: "StartUps",
		setting: "Configuración",
		help: "Ayuda",
		pricing: "Precios y Productos",
	},
	en: {
		youMustBeLoggedToUseIt: "You must log-in for accessing this feature",
		searchers: "Searchers",
		searchUsers: "Search Users...",
		messages: "Messages",
		notifications: "Notifications",
		invitations: "My Network",
		home: "Home",
		marketplace: "Marketplace",
		challenges: "Challenges",
		createBusinessProfile: "Create Business Startup, Company or Others",
		profiles: "Profiles",
		adminProfiles: "Profiles (Admin)",
		logout: "Logout",
		myProfile: "My profile",
		elearning: "E-Learning",
		talent: "Talent",
		startup: "StartUps",
		setting: "Settings",
		help: "Help",
		pricing: "Pricing and Products",
	},
	ca: {
		youMustBeLoggedToUseIt: "Debes iniciar sesión para acceder",
		searchers: "Buscadores",
		searchUsers: "Buscar Usuarios...",
		messages: "Mensajes",
		notifications: "Notificaciones",
		invitations: "Mi Red",
		home: "Inicio",
		marketplace: "Marketplace",
		challenges: "Retos",
		createBusinessProfile: "Crea un perfil de Startup, Empreses o Altres",
		profiles: "Perfils",
		adminProfiles: "Perfils (Admin)",
		logout: "Tancar sessió",
		myProfile: "El meu perfil",
		elearning: "E-Learning",
		talent: "Talent",
		startup: "StartUps",
		setting: "Configuració",
		help: "Ayuda",
		pricing: "Precios y Productos",
	},
	pt: {
		youMustBeLoggedToUseIt: "Você deve fazer login para acessar",
		searchers: "Motores de busca",
		searchUsers: "Pesquisar usuários...",
		messages: "Mensagens",
		notifications: "Notificações",
		invitations: "A minha rede",
		home: "Início",
		marketplace: "Marketplace",
		challenges: "Desafios",
		createBusinessProfile: "Criar perfil de Startup, empresas ou outros",
		profiles: "Perfis",
		adminProfiles: "Perfis (Admin)",
		logout: "Fechar sessão",
		myProfile: "Meu perfil",
		elearning: "E-Learning",
		talent: "Talento",
		startup: "StartUps",
		setting: "Configuração",
		help: "Ajuda",
		pricing: "Preços e Produtos",
	},
};
