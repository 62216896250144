import { useMemo, useState } from "react";

type UseToggleValues = [
	boolean,
	{
		toggle: () => void;
		toggleOn: () => void;
		toggleOff: () => void;
	},
];

export const useToggle = (initial = false): UseToggleValues => {
	const [on, set] = useState(initial);
	const actions = useMemo(
		() => ({
			toggle: () => set((prevState) => !prevState),
			toggleOn: () => set(true),
			toggleOff: () => set(false),
		}),
		[],
	);
	return [on, actions];
};
