import { getAllInvestors } from "api/BusinessProfileAPI";
import { defaultError } from "api/config";
import { getTalentsAPI } from "api/SearcherAPI";
import { BusinessProfileTypes, TalentType } from "constants/Profiles";
import { PostulateLandscapeCardProps } from "containers/Searcher/ContentSearcher/PostulateLandscapeCard/typing";
import TalentModel from "models/talents/Talent";
import { AppState } from "redux-app";
import { Searcher } from "redux-app/actions/SearcherAction";
import { SearcherFetching, SearcherOptions } from "redux-app/reducers/SearcherReducer/typing";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { NumberUtils } from "utils/NumberUtils";
import { convertAPIRequestToTalentState } from "../TalentSagas/formatter";
import {
	convertAPIRequestToPostulateState,
	convertObjectSearcherToArray,
	getAdviserSkillsTypeIDByListKeyNamesAsPromise,
	getCountriesIDByListKeyNamesAsPromise,
	getLanguagesIDByListKeyNamesAsPromise,
	getMarketsIDByListKeyNamesAsPromise,
	getRegionsIDByListKeyNamesAsPromise,
	getSchoolsIDByListKeyNamesAsPromise,
	getSectorsIDByListKeyNamesAsPromise,
	getTalentTypeIDByListKeyNames,
} from "./formatter";
import { CookiesUtils } from "@utils";

function* getTalents(action: {
	type: typeof Searcher.SEARCHER_GET_TALENTS;
	payload: {
		options: SearcherOptions;
		searcherFetching: Pick<SearcherFetching, "loading">;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	try {
		yield put(Searcher.getTalentsStarted(action.payload.searcherFetching));

		const { page, filters } = action.payload.options;
		let type: any;
		let countries: any;
		let regions: any;
		let schools: any;
		let adviserSkills: any;
		let languages: any;
		let averageSalary: any;
		let averageEquity: any;
		let costPerHour: any;
		let markets: any;
		let sectors: any;

		if (filters) {
			regions = filters.regions || {};
			regions = convertObjectSearcherToArray(regions) || [];
			markets = filters.markets || {};
			markets = convertObjectSearcherToArray(markets) || [];
			sectors = filters.markets || {};
			sectors = convertObjectSearcherToArray(sectors) || [];
			countries = filters.countries || {};
			countries = convertObjectSearcherToArray(countries) || [];
			schools = filters.schools || {};
			schools = convertObjectSearcherToArray(schools) || [];
			languages = filters.languages || {};
			languages = convertObjectSearcherToArray(languages) || [];
			adviserSkills = filters.adviserSkills || [];

			if (filters.averageSalary) {
				const { range } = filters.averageSalary;
				averageSalary = NumberUtils.getDataFromRange(range, "averageSalary");
			}

			if (filters.averageEquity) {
				const { range } = filters.averageEquity;
				averageEquity = NumberUtils.getDataFromRange(range, "averageEquity");
			}

			if (filters.costPerHour) {
				const { range } = filters.costPerHour;
				costPerHour = NumberUtils.getDataFromRange(range, "costPerHour");
			}

			for (const key in filters.type) {
				let talentTypeToFind = "";
				// Currently we have a mismatch between
				// the way that we store the TalentType's ID at the FE and BE.
				switch (key) {
					case TalentType.COFOUNDER:
						talentTypeToFind = "co-founder";
						break;
					case TalentType.JOBS:
						talentTypeToFind = "employee";
						break;
					case TalentType.ADVISER:
						talentTypeToFind = "advisor";
						break;
				}

				// @ts-ignore
				const talentTypes = yield getTalentTypeIDByListKeyNames([talentTypeToFind]);
				type = talentTypes && talentTypes.length > 0 ? talentTypes[0] : key;
			}

			yield Promise.all([
				getRegionsIDByListKeyNamesAsPromise(regions),
				getMarketsIDByListKeyNamesAsPromise(markets),
				getSectorsIDByListKeyNamesAsPromise(sectors),
				getCountriesIDByListKeyNamesAsPromise(countries),
				getSchoolsIDByListKeyNamesAsPromise(schools),
				getLanguagesIDByListKeyNamesAsPromise(languages),
				getAdviserSkillsTypeIDByListKeyNamesAsPromise(adviserSkills),
			]).then(([idsRegions, idsMarkets, idsSectors, idsCountries, idsSchools, idsLanguages, idsAdviserSkills]) => {
				regions = idsRegions;
				markets = idsMarkets;
				sectors = idsSectors;
				countries = idsCountries;
				schools = idsSchools;
				languages = idsLanguages;
				adviserSkills = idsAdviserSkills;
			});
		}

		// @ts-ignore
		const response = yield call(
			getTalentsAPI(accessToken, page, {
				type,
				markets,
				sectors,
				regions,
				countries,
				schools,
				languages,
				adviserSkills,
				...averageSalary,
				...averageEquity,
				...costPerHour,
			}),
		);
		if (response.message) {
			yield put(Searcher.getTalentsFinishedWithErrors(response.message));
		} else {
			const contentSearcher = convertAPIRequestToTalentState(response.items);
			contentSearcher.map((element: TalentModel) => {
				element.isMine = false;
			});

			if (page > 1) {
				const prevItems: TalentModel[] = yield select((state: AppState) => state.searcherReducer.talents.items);

				yield put(Searcher.getTalentsFinished([...prevItems, ...contentSearcher]));
			} else {
				yield put(Searcher.getTalentsFinished(contentSearcher));
			}
		}
	} catch (err) {
		yield put(Searcher.getTalentsFinishedWithErrors(defaultError().message));
	}
}

function* getPostulate(action: {
	type: typeof Searcher.SEARCHER_GET_POSTULATE;
	payload: {
		options: SearcherOptions;
		searcherFetching: Pick<SearcherFetching, "loading">;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	try {
		yield put(Searcher.getPostulateStarted(action.payload.searcherFetching));

		const { filters, page } = action.payload.options;
		let regions: any;
		let sectors: any;
		let languages: any;
		let type: BusinessProfileTypes;
		let averageInvestment: any;
		let averageInvestmentPercentage: any;
		let numberOfStartupsToInvest: any;

		if (filters) {
			regions = filters.regions || {};
			regions = convertObjectSearcherToArray(regions) || [];
			sectors = filters.markets || {};
			sectors = convertObjectSearcherToArray(sectors) || [];
			languages = filters.languages || {};
			languages = convertObjectSearcherToArray(languages) || [];

			yield Promise.all([
				getRegionsIDByListKeyNamesAsPromise(regions),
				getSectorsIDByListKeyNamesAsPromise(sectors),
				getLanguagesIDByListKeyNamesAsPromise(languages),
			]).then(([idsRegions, idsSectors, idsLanguages]) => {
				regions = idsRegions;
				sectors = idsSectors;
				languages = idsLanguages;
			});

			if (filters.averageInvestment) {
				const { range } = filters.averageInvestment;
				averageInvestment = NumberUtils.getDataFromRange(range, "averageInvestment");
			}

			if (filters.averageInvestmentPercentage) {
				const { range } = filters.averageInvestmentPercentage;
				averageInvestmentPercentage = NumberUtils.getDataFromRange(range, "averageInvestmentPercentage");
			}

			if (filters.numberOfStartupsToInvest) {
				const { range } = filters.numberOfStartupsToInvest;
				numberOfStartupsToInvest = NumberUtils.getDataFromRange(range, "numberOfStartupsToInvest");
			}

			for (const key in filters.investor_type) {
				type = key as BusinessProfileTypes;
			}
		}

		// @ts-ignore
		const response = yield call(
			getAllInvestors(accessToken, page, type!!, {
				// @ts-ignore
				type,
				regions,
				sectors,
				languages,
				...averageInvestment,
				...averageInvestmentPercentage,
				...numberOfStartupsToInvest,
			}),
		);
		if (response.message) {
			yield put(Searcher.getPostulateFinishedWithErrors(response.message));
		} else {
			// @ts-ignore
			const contentSearcher = yield convertAPIRequestToPostulateState(response.items, false);

			if (page > 1) {
				const prevItems: PostulateLandscapeCardProps[] = yield select(
					(state: AppState) => state.searcherReducer.postulate.items,
				);

				yield put(Searcher.getPostulateFinished([...prevItems, ...contentSearcher]));
			} else {
				yield put(Searcher.getPostulateFinished(contentSearcher));
			}
		}
	} catch (err) {
		yield put(Searcher.getPostulateFinishedWithErrors(defaultError().message));
	}
}

export const rootSearcherSagas = [
	takeLatest(Searcher.SEARCHER_GET_TALENTS, getTalents),
	takeLatest(Searcher.SEARCHER_GET_POSTULATE, getPostulate),
];
