export interface Social {
	facebook: string;
	twitter: string;
	linkedin: string;
	instagram: string;
	youtube: string;
}

class SocialModel implements Social {
	facebook: string;
	twitter: string;
	linkedin: string;
	instagram: string;
	youtube: string;

	constructor(facebook: string, twitter: string, linkedin: string, instagram: string, youtube: string) {
		this.facebook = facebook;
		this.twitter = twitter;
		this.linkedin = linkedin;
		this.instagram = instagram;
		this.youtube = youtube;
	}
}

export default SocialModel;
