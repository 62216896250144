import ProfileModel from "models/profiles/Profile";
import { Reducer } from "redux";
import { Auth } from "redux-app/actions/AuthAction";
import { AuthState } from "./typing";

const initialState: AuthState = {
	accessToken: "",
	name: "",
	surname: "",
	password: "",
	email: "",
	termsAndConditions: false,
	isLogged: false,
	isSignedUp: false,
	hasOnboarding: false,
	hasFailed: false,
	loading: false,
	checkingLogin: false,
	errorMessage: "",
	userState: ProfileModel.generateEmpty(),
};

const authReducer: Reducer<AuthState> = (state: AuthState = initialState, action: Auth.AuthActionTypes) => {
	switch (action.type) {
		case Auth.AUTH_NAME:
			state = {
				...state,
				name: action.payload,
			};
			break;
		case Auth.AUTH_SURNAME:
			state = {
				...state,
				surname: action.payload,
			};
			break;
		case Auth.AUTH_EMAIL:
			state = {
				...state,
				email: action.payload,
			};
			break;
		case Auth.AUTH_PASSWORD:
			state = {
				...state,
				password: action.payload,
			};
			break;
		case Auth.AUTH_TERMS_AND_CONDITIONS:
			state = {
				...state,
				termsAndConditions: action.payload,
			};
			break;
		case Auth.AUTH_CHECKING_LOGIN:
			state = {
				...state,
				checkingLogin: action.payload,
			};
			break;
		case Auth.AUTH_LOADING:
			state = {
				...state,
				loading: action.payload,
			};
			break;
		case Auth.AUTH_LOGOUT_FAILED:
			state = {
				...state,
				accessToken: "",
				hasFailed: true,
				isLogged: false,
				loading: false,
				errorMessage: (action.payload && action.payload.message) || "",
			};
			break;
		case Auth.AUTH_LOGOUT_SUCCEDED:
			state = initialState;
			break;
		case Auth.AUTH_STATUS_LOGIN:
			if (action.payload) {
				state = {
					...state,
					...action.payload,
				};
			} else {
				state = initialState;
			}
			break;
		case Auth.AUTH_HAS_ONBOARDING:
			state = {
				...state,
				hasOnboarding: action.payload,
			};
			break;
		case Auth.AUTH_ERASE_MESSAGE_ERROR:
			state = {
				...state,
				errorMessage: "",
			};
			break;
		case Auth.AUTH_RECEIVE_DATA_API:
			state = {
				...state,
				...action.payload,
			};
			break;
		case Auth.AUTH_UPDATE_USER_PROFILE:
			state = {
				...state,
				userState: action.payload,
			};
			break;
	}

	return state;
};

export default authReducer;
