import { defaultError, getPatchHeaderWithCredentials, getPostHeaderWithCredentials } from "api/config";
import { URL_NEW_USER_PROFILE, URL_UPDATE_USER_PROFILE } from "./urls";

export function fetchNewUserProfile(data: object, accessToken: string) {
	return function () {
		return fetch(URL_NEW_USER_PROFILE, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function updateUserProfile(data: object, accessToken: string, slug: string) {
	return function () {
		return fetch(URL_UPDATE_USER_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
