import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { Talent } from "./types";

type UpdateTalentPayload = {
	talentId: string;
	data: Talent;
};

function updateTalent(payload: UpdateTalentPayload, config: AxiosRequestConfig) {
	return axios.patch(`${PROD_URL}/talents/${payload.talentId}`, payload.data, config);
}

export const useUpdateTalentMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, UpdateTalentPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: UpdateTalentPayload) => updateTalent(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
