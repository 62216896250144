import * as React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import { HeaderLink } from "./useDropdownHeaderLinks";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

function DropdownHeaderLinkDisabledItem(props: { item: HeaderLink; pathname: string; disabledReason: string }) {
	const { disabledReason, item } = props;

	return (
		<Popup
			trigger={
				<Menu.Item
					disabled
					className="padding-1-y"
					name={item.label.toLowerCase()}
				>
					<Icon
						className={"font-color-white-grey"}
						disabled
						name={item.icon}
						size={"large"}
					/>
					<span className={"margin-1-top font-color-white-grey"}>{item.label}</span>
				</Menu.Item>
			}
			content={disabledReason}
			position="bottom center"
		/>
	);
}

export default React.memo(DropdownHeaderLinkDisabledItem);
