import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { MarketplacePayload } from "./types";

type UpdateMarketplacePayload = {
	marketplaceSlug: string;
	data: MarketplacePayload;
};

function updateMarketplace(payload: UpdateMarketplacePayload, config: AxiosRequestConfig) {
	return axios.patch(`${PROD_URL}/marketplace/${payload.marketplaceSlug}`, payload.data, config);
}

export const useUpdateMarketplaceMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, UpdateMarketplacePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: UpdateMarketplacePayload) => updateMarketplace(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
