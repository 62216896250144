export interface BusinessProfileStats {
	averageInvestment: number;
	averageInvestmentPercentage: number;
	numberOfStartupsToInvest: number;
}

class BusinessProfileStatsModel implements BusinessProfileStats {
	averageInvestment: number;
	averageInvestmentPercentage: number;
	numberOfStartupsToInvest: number;

	constructor(averageInvestment: number, averageInvestmentPercentage: number, numberOfStartupsToInvest: number) {
		this.averageInvestment = averageInvestment;
		this.averageInvestmentPercentage = averageInvestmentPercentage;
		this.numberOfStartupsToInvest = numberOfStartupsToInvest;
	}

	static generateFromAPI = (data: any): BusinessProfileStatsModel => {
		return new BusinessProfileStatsModel(
			data.average_investment,
			data.average_investment_percentage,
			data.number_of_startups_to_invest,
		);
	};

	static generateFromObject = (data: BusinessProfileStats): BusinessProfileStatsModel => {
		return new BusinessProfileStatsModel(
			data.averageInvestment,
			data.averageInvestmentPercentage,
			data.numberOfStartupsToInvest,
		);
	};

	static generateEmpty = (): BusinessProfileStatsModel => {
		return new BusinessProfileStatsModel(0, 0, 0);
	};
}

export default BusinessProfileStatsModel;
