import {
	defaultError,
	getDeleteHeaderWithCredentials,
	getGetHeaderWithCredentials,
	getPatchHeaderWithCredentials,
	getPostHeaderWithCredentials,
} from "api/config";
import {
	URL_ADD_APPLIER_TALENT,
	URL_CHANGE_STATUS_APPLIER_TALENT,
	URL_DELETE_TALENT,
	URL_GET_ALL_APPLIERS_TALENT,
	URL_GET_TALENT_BY_SLUG,
	URL_GET_TALENT_PROFILE_BY_SLUG,
	URL_GET_UNIT_TALENT,
	URL_PATCH_TALENT,
	URL_POST_APPLIER_TENDER_TEAM,
	URL_POST_NEW_TALENT,
} from "./urls";

export function getTalentByIdAPI(talentId: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_UNIT_TALENT(talentId), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function fetchNewTalent(data: object, accessToken: string) {
	return function () {
		return fetch(URL_POST_NEW_TALENT, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function fetchUpdateTalent(data: object, talentId: string, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_TALENT(talentId), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function fetchDeleteTalent(talentId: string, profileId: string, accessToken: string) {
	return function () {
		return fetch(
			URL_DELETE_TALENT(talentId),
			getDeleteHeaderWithCredentials(accessToken, {
				profile_id: profileId,
			}),
		)
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getTalentsByBusinessProfileSlug(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_TALENT_PROFILE_BY_SLUG(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getTalentFromTalentSlug(slug: string, accessToken: string = "") {
	return function () {
		return fetch(URL_GET_TALENT_BY_SLUG(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function addApplierToTalentAPI(data: object, idTalent: string, accessToken: string) {
	return function () {
		return fetch(URL_ADD_APPLIER_TALENT(idTalent), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function changeStatusApplierAPI(data: object, applicationId: number, accessToken: string) {
	return function () {
		return fetch(URL_CHANGE_STATUS_APPLIER_TALENT(applicationId), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function addApplierHiredToTender(
	profileSlug: string,
	positionID: string,
	challengeTenderID: number,
	accessToken: string,
) {
	const data = {
		profile_slug: profileSlug,
		position_id: positionID,
	};

	return function () {
		return fetch(URL_POST_APPLIER_TENDER_TEAM(challengeTenderID), getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getAppliersTalentAPI(idTalent: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_ALL_APPLIERS_TALENT(idTalent), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
