import { PROD_URL } from "api/config";

enum CategoryType {
	SKILL = "SKILL",
	ADVISER_SKILLS = "CONSULTANT", // AKA ADVISER_SKILLS
	SCHOOL = "SCHOOL",
	ASESOR = "ASESOR",
	SECTOR = "SECTOR",
	MARKET = "MARKET",
	LANGUAGES = "LANGUAGES",
	SOCIAL_NETWORK = "SOCIAL_NETWORK",
	ATTENDANCE = "ATTENDANCE",
	AREA = "AREA",
	TALENT_ROLE = "TALENT_ROLE",
	TALENT_TYPE = "TALENT_TYPE",
	COUNTRY = "COUNTRY",
	REGION = "REGION",
	EDUCATION = "EDUCATION",
	APPLIER_STATUS = "APPLIERSTATUS",
	TOPIC = "TOPIC",
}

const CATEGORY_SIZE_PER_PAGE = 1000;
const URL_GET_CATEGORIES = `${PROD_URL}/categories?perPage=1000`;
const URL_GET_SPECIFIC_CATEGORY = (type: CategoryType) =>
	`${PROD_URL}/categories?search[category]=${type}&perPage=${CATEGORY_SIZE_PER_PAGE}`;

export { CategoryType, URL_GET_CATEGORIES, URL_GET_SPECIFIC_CATEGORY };
