import CategoryModel from "models/categories/Category";
import RequestModel, { Request } from "models/requests/Request";
import Social from "models/social-networks/Social";
import CategoriesServiceSingleton from "services/categories/CategoriesService";

const CATEGORY_KEY_FACEBOOK = "facebook";
const CATEGORY_KEY_TWITTER = "twitter";
const CATEGORY_KEY_LINKEDIN = "linkedin";
const CATEGORY_KEY_INSTAGRAM = "instagram";
const CATEGORY_KEY_YOUTUBE = "youtube";

export type ProfileSocial = Social & {
	fetching?: Request;
};

class ProfileSocialModel extends Social implements ProfileSocial {
	fetching?: Request;

	constructor(
		facebook: string,
		twitter: string,
		linkedin: string,
		instagram: string,
		youtube: string,
		fetching?: Request,
	) {
		super(facebook, twitter, linkedin, instagram, youtube);
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const socials = {
			facebook: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === CATEGORY_KEY_FACEBOOK)
				: "",
			twitter: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === CATEGORY_KEY_TWITTER)
				: "",
			linkedin: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === CATEGORY_KEY_LINKEDIN)
				: "",
			instagram: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === CATEGORY_KEY_INSTAGRAM)
				: "",
			youtube: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === CATEGORY_KEY_YOUTUBE)
				: "",
		};

		return new ProfileSocialModel(
			socials.facebook && socials.facebook.url,
			socials.twitter && socials.twitter.url,
			socials.linkedin && socials.linkedin.url,
			socials.instagram && socials.instagram.url,
			socials.youtube && socials.youtube.url,
			RequestModel.requestDefault(),
		);
	}

	static generateEmpty = () => {
		return new ProfileSocialModel("", "", "", "", "", RequestModel.requestLoading());
	};

	exportToAPIRequest = () => {
		const listCategories = CategoriesServiceSingleton.getList();
		const socialCategories = listCategories.filterByKey([
			CATEGORY_KEY_FACEBOOK,
			CATEGORY_KEY_TWITTER,
			CATEGORY_KEY_LINKEDIN,
			CATEGORY_KEY_INSTAGRAM,
			CATEGORY_KEY_YOUTUBE,
		]) as CategoryModel[];

		return [
			{
				url: this.facebook || "",
				social_network_id: socialCategories.find((item) => item.key === CATEGORY_KEY_FACEBOOK)?.id,
			},
			{
				url: this.twitter || "",
				social_network_id: socialCategories.find((item) => item.key === CATEGORY_KEY_TWITTER)?.id,
			},
			{
				url: this.linkedin || "",
				social_network_id: socialCategories.find((item) => item.key === CATEGORY_KEY_LINKEDIN)?.id,
			},
			{
				url: this.instagram || "",
				social_network_id: socialCategories.find((item) => item.key === CATEGORY_KEY_INSTAGRAM)?.id,
			},
			{
				url: this.youtube || "",
				social_network_id: socialCategories.find((item) => item.key === CATEGORY_KEY_YOUTUBE)?.id,
			},
		];
	};
}

export default ProfileSocialModel;
