import React from "react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";

import messages from "./messages";

export const VingletLoader = () => {
	return (
		<div className="mt-2 w-full text-center">
			<div className="relative mx-auto mt-40 block h-40 w-72">
				<Image
					className="object-contain object-center"
					src="/logo-complete.png"
					layout="fill"
					alt="Vinglet Logo"
				/>
			</div>
			<p>
				<FormattedMessage {...messages.loading} />
			</p>
		</div>
	);
};
