import {
	Label as FlowbiteLabel,
	Checkbox as FlowbiteCheckbox,
	CheckboxProps as FlowbiteCheckboxProps,
} from "flowbite-react";
import React from "react";

export const InputCheckboxField = ({
	labelValue,
	...rest
}: FlowbiteCheckboxProps & {
	labelValue?: JSX.Element;
}) => {
	return (
		<div className="flex items-center gap-2">
			<FlowbiteCheckbox {...rest} />
			{labelValue && (
				<div className="block w-full text-sm font-medium text-gray-900 dark:text-white">
					<FlowbiteLabel htmlFor={rest.id}>{labelValue}</FlowbiteLabel>
				</div>
			)}
		</div>
	);
};
