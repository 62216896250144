import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { StringUtils } from "utils/StringUtils";

export interface Skill {
	id: string | number;
	name: string;
	porcentage?: number;
	color?: SemanticCOLORS;
	text?: string;
}

class SkillModel implements Skill {
	id: string | number;
	name: string;
	porcentage: number;
	color: SemanticCOLORS;
	text: string;

	constructor(
		id: string | number,
		name: string,
		porcentage: number = 0,
		color: SemanticCOLORS = "grey",
		text: string = "",
	) {
		this.id = id;
		this.name = name;
		this.porcentage = porcentage;
		this.color = color;
		this.text = StringUtils.capitalize(text);
	}
}

export default SkillModel;
