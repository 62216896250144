import ElearningModel from "models/elearnings/Elearning";

export function convertElearningStateToAPIRequest(elearning: ElearningModel): any {
	return elearning.exportToAPIRequest();
}

export function convertWebinarStateToAPIRequest(elearning: ElearningModel): any {
	return elearning.exportToAPIRequest();
}

export function convertAPIResponseToElearningState(data: any[]): ElearningModel[] {
	const newData: ElearningModel[] = [];

	if (data && Array.isArray(data)) {
		data.forEach((item) => newData.push(ElearningModel.generateFromAPI(item)));
	}

	return newData;
}
