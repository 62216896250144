import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Sidebar from "semantic-ui-react/dist/commonjs/modules/Sidebar";
import useDropdownHeaderLinks from "./useDropdownHeaderLinks";
import RightMenu from "./RightMenu/RightMenu";
import RightMenuLoggedUser from "./RightMenuLoggedUser/RightMenuLoggedUser";
import { HeaderProps } from "./typing";
import Link from "next/link";

const LogoComplete = "/logo-complete.png";

const HeaderMobile = (props: HeaderProps) => {
	const { myEmblem, myFullName, linkToMyProfile, avatar, businessProfiles, pathname, logout } = props;
	const [isSidebarActivated, activateSidebar] = React.useState(false);
	const {dropdownHeaderLinks} = useDropdownHeaderLinks()
	const openSidebar = () => activateSidebar(true);
	const closeSidebar = () => activateSidebar(false);
	const handleLogOut = () => {
		closeSidebar();
		logout();
	};

	return (
		<>
			<Sidebar
				as={Menu}
				animation="push"
				className="HeaderMobile"
				inverted
				vertical
				onHidden={closeSidebar}
				visible={isSidebarActivated}
				width="wide"
			>
				<Menu.Item
					onClick={closeSidebar}
					className="padding-0-x text-right"
				>
					<Button
						className="margin-1-right"
						icon="close"
						size="large"
						color="black"
					/>
				</Menu.Item>
				{dropdownHeaderLinks.map((item) => {
					return (
						<Link
							key={item.link}
							href={item.link}
							passHref
						>
							<Menu.Item
								as="a"
								className="label-section font-medium"
								onClick={closeSidebar}
								active={item.link === pathname}
								name={item.label.toLowerCase()}
							>
								{item.label}
							</Menu.Item>
						</Link>
					);
				})}
				{linkToMyProfile ? (
					<div className="HeaderMobile__WrapperRightMenuLoggedUserSidebar margin-1-y">
						<RightMenuLoggedUser
							profileFullName={myFullName}
							profileEmblem={myEmblem}
							profileUrl={linkToMyProfile}
							profileAvatarSrc={avatar}
							businessProfiles={businessProfiles}
							logout={logout}
						/>
					</div>
				) : (
					<div className="HeaderMobile__WrapperRightMenuSidebar margin-1-all">
						<RightMenu />
					</div>
				)}
				<Menu.Item>
					<Link
						href="/"
						passHref
					>
						<a>
							<Image
								src={String(LogoComplete)}
								centered
								size="small"
							/>
						</a>
					</Link>
				</Menu.Item>
			</Sidebar>
			<Menu className="Header HeaderMobile HeaderMobile__WrapperRightMenuLoggedUser margin-0-y">
				<Menu.Item as="section">
					<Button
						className="margin-1-right"
						icon="bars"
						basic
						onClick={openSidebar}
					/>
					<Link
						href="/"
						passHref
					>
						<a>
							<Image
								src={String(LogoComplete)}
								size="tiny"
							/>
						</a>
					</Link>
				</Menu.Item>
				{linkToMyProfile && (
					<RightMenuLoggedUser
						profileFullName={myFullName}
						profileEmblem={myEmblem}
						profileUrl={linkToMyProfile}
						profileAvatarSrc={avatar}
						businessProfiles={businessProfiles}
						logout={handleLogOut}
					/>
				)}
			</Menu>
		</>
	);
};

export default React.memo(HeaderMobile);
