import { StringUtils } from "utils/StringUtils";

export interface Awards {
	id: number;
	title: string;
	date: number;
	description: string;
}

class AwardsModel implements Awards {
	id: number;
	title: string;
	date: number;
	description: string;

	constructor(id: number, title: string, date: number, description: string) {
		this.id = id;
		this.title = StringUtils.capitalize(title);
		this.date = date;
		this.description = description;
	}
}

export default AwardsModel;
