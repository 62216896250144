import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { WorkshopPayload } from "./types";

type UpdateWorkshopPayload = {
	workshopId: string;
	data: WorkshopPayload;
};

function updateWorkshop(payload: UpdateWorkshopPayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/courses/${payload.workshopId}`, payload.data, config);
}

export const useUpdateWorkshopMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, UpdateWorkshopPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: UpdateWorkshopPayload) => updateWorkshop(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
