export const WolrdEn = `
{
    "region": {
      "key": {
        "singular": "Region",
        "plural": "Regions"
      },
      "items": {
        "europe": "Europe",
        "northAmerica": "North America",
        "centralAmerica": "Central America",
        "southAmerica": "South America",
        "asia": "Asia",
        "africa": "Africa",
        "oceania": "Oceania"
      }
    },
    "country": {
      "key": {
        "singular": "Country",
        "plural": "Countries"
      },
      "items": {
        "argentina": "Argentina",
        "chile": "Chile",
        "colombia": "Colombia",
        "france": "France",
        "germany": "Germany",
        "ireland": "Ireland",
        "israel": "Israel",
        "mexico": "Mexico",
        "netherlands": "Netherlands",
        "peru": "Peru",
        "portugal": "Portugal",
        "spain": "Spain",
        "sweden": "Sweden",
        "unitedKingdom": "United Kingdom",
        "unitedStates": "United States",
        "uruguay": "Uruguay",
        "venezuela": "Venezuela",
        "ecuador":"Ecuador",
        "brazil":"Brazil"
      }
    },
    "language": {
      "key": {
        "singular": "Language",
        "plural": "Idioms"
      },
      "items": {
        "catalan": "Catalan",
        "simplifiedchinese": "Simplified Chinese",
        "traditionalchinese": "Traditional Chinese",
        "spanish": "Spanish",
        "french": "French",
        "english": "English",
        "italian": "Italian",
        "japanese": "Japanese",
        "portuguese": "Portuguese",
        "russian": "Russian",
        "german": "German",
        "hindi": "Hindi",
        "arab": "Arab",
        "hebrew": "Hebrew"
      }
    }
  }
`;
