import { rootAuthSagas } from "redux-app/sagas/AuthSagas";
import { all } from "redux-saga/effects";
import { rootBusinessProfileSagas } from "./BusinessProfileSagas";
import { rootElearningSagas } from "./ElearningSagas";
import { rootProfileSagas } from "./ProfileSagas";
import { rootSearcherSagas } from "./SearcherSagas";
import { rootTalentSagas } from "./TalentSagas";
import { rootUserSagas } from "./UserSagas";

export function* rootSagas() {
	yield all([
		...rootAuthSagas,
		...rootUserSagas,
		...rootBusinessProfileSagas,
		...rootElearningSagas,
		...rootTalentSagas,
		...rootProfileSagas,
		...rootSearcherSagas,
	]);
}
