import Header from "containers/Header/Header";
import { HeaderProps } from "containers/Header/typing";
import { useRouter } from "next/router";
import * as React from "react";
import { UserProfileContext } from "@context";

const withHeader = (ChildComponent: React.ComponentType<any | string>) => {
	// eslint-disable-next-line react/display-name
	return (props: any) => {
		const router = useRouter();
		const { userProfile, businessProfiles } = React.useContext(UserProfileContext);
		const headerProps: Omit<HeaderProps, "logout"> = {
			myId: "",
			myName: "",
			myFullName: "",
			myEmblem: "",
			linkToMyProfile: "",
			avatar: "",
			pathname: router.pathname,
			businessProfiles,
		};

		if (userProfile.id) {
			const { id, info } = userProfile;

			headerProps["myId"] = id;
			headerProps["myName"] = info.name;
			headerProps["myFullName"] = info.fullName;
			headerProps["myEmblem"] = info.emblem;
			headerProps["avatar"] = info.avatar;
		}

		if (userProfile.links) {
			const { links } = userProfile;

			headerProps["linkToMyProfile"] = links.link;
		}

		return (
			<>
				<Header {...headerProps} />
				<ChildComponent {...props} />
			</>
		);
	};
};

export default withHeader;
