import { DatesCat } from "i18n/localization/dates/ca";
import { FeaturesCat } from "i18n/localization/features/ca";
import { WolrdCat } from "i18n/localization/world/ca";

export const localizationCat = `
[  
    {
        "field": "dates",
        "translations": [
            {
                "lang": "ca",
                "data": ${DatesCat}
            }
        ]
    },
    {
        "field": "features",
        "translations": [
            {
                "lang": "ca",
                "data": ${FeaturesCat}
            }
        ]
    },
    {
        "field": "world",
        "translations": [
            {
                "lang": "ca",
                "data": ${WolrdCat}
            }
        ]
    }
]
`;
