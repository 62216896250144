import { defaultError, getGetHeaderWithCredentials, getPatchHeaderWithCredentials } from "api/config";
import {
	URL_CHECK_SLUG_PROFILE,
	URL_GET_CHALLENGUES,
	URL_GET_CURRENTLY_USER_LOGGED,
	URL_GET_INVITATION_FOR_CHALLENGUE_BY_PROFILE,
	URL_GET_PROFILE,
	URL_GET_PROFILE_BY_USER,
	URL_PATCH_USER_PROFILE,
} from "./urls";

export function hasTheUserLoggedAPI(accessToken: string) {
	return function () {
		return fetch(URL_GET_CURRENTLY_USER_LOGGED, getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function getProfilesByUser(accessToken: string) {
	return function () {
		return fetch(URL_GET_PROFILE_BY_USER, getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function getChallengesByProfileAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_CHALLENGUES(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function getInvitationsForChallengesByProfileAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_INVITATION_FOR_CHALLENGUE_BY_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function checkSlugAPI(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_CHECK_SLUG_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => {
				return response.json();
			})
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getUserProfileBySlug(slug: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_PROFILE(slug), getGetHeaderWithCredentials(accessToken))
			.then((response) => {
				return response.json();
			})
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchUserProfileBySlug(data: any, slug: string, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_USER_PROFILE(slug), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => {
				return response.json();
			})
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
