import {HeaderTexts} from "i18n/containers/Header";
import {HeaderTranslation} from "i18n/containers/Header/typing";
import * as React from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {RightMenuSubItemsProps} from "../typing";
import {mapToBusinessProfileUnitDropdown} from "containers/Header/RightMenuLoggedUser/mappers";
import {BusinessProfile} from "models/business-profiles/BusinessProfile";
import RightMenuSubItems from "containers/Header/RightMenuLoggedUser/RightMenuSubItems";
import Link from "next/link";
import {useLocales} from "@libs";

function RightMenuLoggedUserDropdownLinks(props: {
    businessProfiles: BusinessProfile[];
    rightMenuSubItemsProps: RightMenuSubItems[];
    dispatchSubItemsActions: (subItem: RightMenuSubItemsProps) => () => void;
}) {
    const {businessProfiles = [], rightMenuSubItemsProps, dispatchSubItemsActions} = props;
    const {currentShortLocale} = useLocales();
    const headerTranslation: HeaderTranslation = {
        ...HeaderTexts[currentShortLocale],
    };
    const businessProfileThatIAmCreator = businessProfiles.filter(
        (businessProfile) => businessProfile.privileges.isCreator,
    );
    const businessProfileThatIAmAdmin = businessProfiles.filter(
        (businessProfile) => businessProfile.privileges.isAdmin && !businessProfile.privileges.isCreator,
    );

    return (
        <Dropdown
            pointing={"top right"}
            icon={false}
            inline
            item
        >
            <Dropdown.Menu className="margin-0_5-all">
                {businessProfileThatIAmCreator.length > 0 && (
                    <React.Fragment>
                        <Dropdown.Header>{headerTranslation.profiles}</Dropdown.Header>
                        {businessProfileThatIAmCreator.map(mapToBusinessProfileUnitDropdown)}
                    </React.Fragment>
                )}
                {businessProfileThatIAmAdmin.length > 0 && (
                    <React.Fragment>
                        <Dropdown.Header>{headerTranslation.adminProfiles}</Dropdown.Header>
                        {businessProfileThatIAmAdmin.map(mapToBusinessProfileUnitDropdown)}
                    </React.Fragment>
                )}
                <Dropdown.Divider/>
                {rightMenuSubItemsProps.map((rightMenuSubItemsProp) => {
                    const {icon, isAnAction, label, link} = rightMenuSubItemsProp;

                    if (isAnAction) {
                        return (
                            <Dropdown.Item
                                key={label}
                                onClick={dispatchSubItemsActions(rightMenuSubItemsProp)}
                                icon={icon}
                                text={label}
                            />
                        );
                    }

                    return (
                        <Link
                            key={label}
                            href={link}
                            passHref
                        >
                            <Dropdown.Item
                                as="a"
                                icon={icon}
                                text={label}
                            />
                        </Link>
                    );
                })}
                <Dropdown.Divider/>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default React.memo(RightMenuLoggedUserDropdownLinks);
