import CategoryModel from "models/categories/Category";
import TalentModel from "models/talents/Talent";
import { TalentApplier } from "models/talents/TalentApplier";

export namespace Talent {
	export const TALENT_GET_BY_ID = "TALENT_GET_BY_ID";
	export const TALENT_GET_BY_ID_STARTED = "TALENT_GET_BY_ID_STARTED";
	export const TALENT_GET_BY_ID_FINISHED = "TALENT_GET_BY_ID_FINISHED";
	export const TALENT_GET_BY_ID_FINISHED_WITH_ERRORS = "TALENT_GET_BY_ID_FINISHED_WITH_ERRORS";

	export const TALENT_GET_TALENTS_PROFILE = "TALENT_GET_TALENTS_PROFILE";
	export const TALENT_GET_TALENTS_PROFILE_STARTED = "TALENT_GET_TALENTS_PROFILE_STARTED";
	export const TALENT_GET_TALENTS_PROFILE_FINISHED = "TALENT_GET_TALENTS_PROFILE_FINISHED";
	export const TALENT_GET_TALENTS_PROFILE_FINISHED_WITH_ERRORS = "TALENT_GET_TALENTS_PROFILE_FINISHED_WITH_ERRORS";

	export const TALENT_NEW = "TALENT_NEW";
	export const TALENT_NEW_STARTED = "TALENT_NEW_STARTED";
	export const TALENT_NEW_FINISHED = "TALENT_NEW_FINISHED";
	export const TALENT_NEW_FINISHED_WITH_ERRORS = "TALENT_NEW_FINISHED_WITH_ERRORS";

	export const TALENT_UPDATE = "TALENT_UPDATE";
	export const TALENT_UPDATE_STARTED = "TALENT_UPDATE_STARTED";
	export const TALENT_UPDATE_FINISHED = "TALENT_UPDATE_FINISHED";
	export const TALENT_UPDATE_FINISHED_WITH_ERRORS = "TALENT_UPDATE_FINISHED_WITH_ERRORS";

	export const TALENT_DELETE = "TALENT_DELETE";
	export const TALENT_DELETE_STARTED = "TALENT_DELETE_STARTED";
	export const TALENT_DELETE_FINISHED = "TALENT_DELETE_FINISHED";
	export const TALENT_DELETE_FINISHED_WITH_ERRORS = "TALENT_DELETE_FINISHED_WITH_ERRORS";

	export const TALENT_ADD_APPLIER = "TALENT_ADD_APPLIER";
	export const TALENT_ADD_APPLIER_STARTED = "TALENT_ADD_APPLIER_STARTED";
	export const TALENT_ADD_APPLIER_FINISHED = "TALENT_ADD_APPLIER_FINISHED";
	export const TALENT_ADD_APPLIER_FINISHED_WITH_ERRORS = "TALENT_ADD_APPLIER_FINISHED_WITH_ERRORS";

	export const TALENT_CHANGE_STATUS_APPLIER = "TALENT_CHANGE_STATUS_APPLIER";
	export const TALENT_CHANGE_STATUS_APPLIER_STARTED = "TALENT_CHANGE_STATUS_APPLIER_STARTED";
	export const TALENT_CHANGE_STATUS_APPLIER_FINISHED = "TALENT_CHANGE_STATUS_APPLIER_FINISHED";
	export const TALENT_CHANGE_STATUS_APPLIER_FINISHED_WITH_ERRORS = "TALENT_CHANGE_STATUS_APPLIER_FINISHED_WITH_ERRORS";

	export const TALENT_GET_APPLIERS = "TALENT_GET_APPLIERS";
	export const TALENT_GET_APPLIERS_STARTED = "TALENT_GET_APPLIERS_STARTED";
	export const TALENT_GET_APPLIERS_FINISHED = "TALENT_GET_APPLIERS_FINISHED";
	export const TALENT_GET_APPLIERS_FINISHED_WITH_ERRORS = "TALENT_GET_APPLIERS_FINISHED_WITH_ERRORS";

	export const TALENT_RESET_STATE = "TALENT_RESET_STATE";

	interface TalentGetByIDAction {
		type: typeof TALENT_GET_BY_ID;
		payload: string;
	}

	interface TalentGetByIDStartedAction {
		type: typeof TALENT_GET_BY_ID_STARTED;
	}

	interface TalentGetByIDFinishedAction {
		type: typeof TALENT_GET_BY_ID_FINISHED;
		payload: TalentModel;
	}

	interface TalentGetByIDFinishedWithErrosAction {
		type: typeof TALENT_GET_BY_ID_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentGetTalentsProfileAction {
		type: typeof TALENT_GET_TALENTS_PROFILE;
		payload: string;
	}

	interface TalentGetTalentsProfileStartedAction {
		type: typeof TALENT_GET_TALENTS_PROFILE_STARTED;
	}

	interface TalentGetTalentsProfileFinishedAction {
		type: typeof TALENT_GET_TALENTS_PROFILE_FINISHED;
		payload: TalentModel[];
	}

	interface TalentGetTalentsProfileFinishedWithErrosAction {
		type: typeof TALENT_GET_TALENTS_PROFILE_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentNewAction {
		type: typeof TALENT_NEW;
		payload: TalentModel;
	}

	interface TalentNewStartedAction {
		type: typeof TALENT_NEW_STARTED;
	}

	interface TalentNewFinishedAction {
		type: typeof TALENT_NEW_FINISHED;
		payload: TalentModel;
	}

	interface TalentNewFinishedWithErrosAction {
		type: typeof TALENT_NEW_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentUpdateAction {
		type: typeof TALENT_UPDATE;
		payload: TalentModel;
	}

	interface TalentUpdateStartedAction {
		type: typeof TALENT_UPDATE_STARTED;
	}

	interface TalentUpdateFinishedAction {
		type: typeof TALENT_UPDATE_FINISHED;
		payload: TalentModel;
	}

	interface TalentUpdateFinishedWithErrosAction {
		type: typeof TALENT_UPDATE_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentDeleteAction {
		type: typeof TALENT_DELETE;
		payload: {
			talentId: string;
			profileId: string;
		};
	}

	interface TalentDeleteStartedAction {
		type: typeof TALENT_DELETE_STARTED;
	}

	interface TalentDeleteFinishedAction {
		type: typeof TALENT_DELETE_FINISHED;
		payload: string;
	}

	interface TalentDeleteFinishedWithErrosAction {
		type: typeof TALENT_DELETE_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentAddApplierAction {
		type: typeof TALENT_ADD_APPLIER;
		payload: {
			talentId: string;
			profileSlug: string;
			documentId: string;
		};
	}

	interface TalentAddApplierStartedAction {
		type: typeof TALENT_ADD_APPLIER_STARTED;
	}

	interface TalentAddApplierFinishedAction {
		type: typeof TALENT_ADD_APPLIER_FINISHED;
	}

	interface TalentAddApplierFinishedWithErrosAction {
		type: typeof TALENT_ADD_APPLIER_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentGetAppliersAction {
		type: typeof TALENT_GET_APPLIERS;
		payload: string;
	}

	interface TalentChangeStatusApplierAction {
		type: typeof TALENT_CHANGE_STATUS_APPLIER;
		payload: {
			status: CategoryModel;
			applicationId: number;
		};
	}

	interface TalentChangeStatusApplierStartedAction {
		type: typeof TALENT_CHANGE_STATUS_APPLIER_STARTED;
	}

	interface TalentChangeStatusApplierFinishedAction {
		type: typeof TALENT_CHANGE_STATUS_APPLIER_FINISHED;
		payload: {
			status: CategoryModel;
			applicationId: number;
		};
	}

	interface TalentChangeStatusApplierFinishedWithErrosAction {
		type: typeof TALENT_CHANGE_STATUS_APPLIER_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentGetAppliersAction {
		type: typeof TALENT_GET_APPLIERS;
		payload: string;
	}

	interface TalentGetAppliersStartedAction {
		type: typeof TALENT_GET_APPLIERS_STARTED;
	}

	interface TalentGetAppliersFinishedAction {
		type: typeof TALENT_GET_APPLIERS_FINISHED;
		payload: TalentApplier[];
	}

	interface TalentGetAppliersFinishedWithErrosAction {
		type: typeof TALENT_GET_APPLIERS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface TalentResetStateAction {
		type: typeof TALENT_RESET_STATE;
	}

	export type TalentActionTypes =
		| TalentGetByIDAction
		| TalentGetByIDStartedAction
		| TalentGetByIDFinishedAction
		| TalentGetByIDFinishedWithErrosAction
		| TalentGetTalentsProfileAction
		| TalentGetTalentsProfileStartedAction
		| TalentGetTalentsProfileFinishedAction
		| TalentGetTalentsProfileFinishedWithErrosAction
		| TalentNewAction
		| TalentNewStartedAction
		| TalentNewFinishedAction
		| TalentNewFinishedWithErrosAction
		| TalentUpdateAction
		| TalentUpdateStartedAction
		| TalentUpdateFinishedAction
		| TalentUpdateFinishedWithErrosAction
		| TalentDeleteAction
		| TalentDeleteStartedAction
		| TalentDeleteFinishedAction
		| TalentResetStateAction
		| TalentAddApplierAction
		| TalentAddApplierStartedAction
		| TalentAddApplierFinishedAction
		| TalentAddApplierFinishedWithErrosAction
		| TalentChangeStatusApplierAction
		| TalentChangeStatusApplierStartedAction
		| TalentChangeStatusApplierFinishedAction
		| TalentChangeStatusApplierFinishedWithErrosAction
		| TalentGetAppliersAction
		| TalentGetAppliersStartedAction
		| TalentGetAppliersFinishedAction
		| TalentGetAppliersFinishedWithErrosAction
		| TalentDeleteFinishedWithErrosAction;

	export function getByID(talentId: string): TalentGetByIDAction {
		return {
			type: TALENT_GET_BY_ID,
			payload: talentId,
		};
	}

	export function getByIDStarted(): TalentGetByIDStartedAction {
		return {
			type: TALENT_GET_BY_ID_STARTED,
		};
	}

	export function getByIDFinished(newTalent: TalentModel): TalentGetByIDFinishedAction {
		return {
			type: TALENT_GET_BY_ID_FINISHED,
			payload: newTalent,
		};
	}

	export function getByIDFinishedWithErrors(err: string): TalentGetByIDFinishedWithErrosAction {
		return {
			type: TALENT_GET_BY_ID_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getTalentsProfile(slug: string): TalentGetTalentsProfileAction {
		return {
			type: TALENT_GET_TALENTS_PROFILE,
			payload: slug,
		};
	}

	export function getTalentsProfileStarted(): TalentGetTalentsProfileStartedAction {
		return {
			type: TALENT_GET_TALENTS_PROFILE_STARTED,
		};
	}

	export function getTalentsProfileFinished(talents: TalentModel[]): TalentGetTalentsProfileFinishedAction {
		return {
			type: TALENT_GET_TALENTS_PROFILE_FINISHED,
			payload: talents,
		};
	}

	export function getTalentsProfileFinishedWithErrors(err: string): TalentGetTalentsProfileFinishedWithErrosAction {
		return {
			type: TALENT_GET_TALENTS_PROFILE_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function newTalent(newTalent: TalentModel): TalentNewAction {
		return {
			type: TALENT_NEW,
			payload: newTalent,
		};
	}

	export function newTalentStarted(): TalentNewStartedAction {
		return {
			type: TALENT_NEW_STARTED,
		};
	}

	export function newTalentFinished(newTalent: TalentModel): TalentNewFinishedAction {
		return {
			type: TALENT_NEW_FINISHED,
			payload: newTalent,
		};
	}

	export function newTalentFinishedWithErrors(err: string): TalentNewFinishedWithErrosAction {
		return {
			type: TALENT_NEW_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function updateTalent(updateTalent: TalentModel): TalentUpdateAction {
		return {
			type: TALENT_UPDATE,
			payload: updateTalent,
		};
	}

	export function updateTalentStarted(): TalentUpdateStartedAction {
		return {
			type: TALENT_UPDATE_STARTED,
		};
	}

	export function updateTalentFinished(updateTalent: TalentModel): TalentUpdateFinishedAction {
		return {
			type: TALENT_UPDATE_FINISHED,
			payload: updateTalent,
		};
	}

	export function updateTalentFinishedWithErrors(err: string): TalentUpdateFinishedWithErrosAction {
		return {
			type: TALENT_UPDATE_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function deleteTalent(talentId: string, profileId: string): TalentDeleteAction {
		return {
			type: TALENT_DELETE,
			payload: {
				talentId,
				profileId,
			},
		};
	}

	export function deleteTalentStarted(): TalentDeleteStartedAction {
		return {
			type: TALENT_DELETE_STARTED,
		};
	}

	export function deleteTalentFinished(talentId: string): TalentDeleteFinishedAction {
		return {
			type: TALENT_DELETE_FINISHED,
			payload: talentId,
		};
	}

	export function deleteTalentFinishedWithErrors(err: string): TalentDeleteFinishedWithErrosAction {
		return {
			type: TALENT_DELETE_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function addApplierToTalent(
		talentId: string,
		profileSlug: string,
		documentId: string,
	): TalentAddApplierAction {
		return {
			type: TALENT_ADD_APPLIER,
			payload: {
				talentId,
				profileSlug,
				documentId,
			},
		};
	}

	export function addApplierToTalentStarted(): TalentAddApplierStartedAction {
		return {
			type: TALENT_ADD_APPLIER_STARTED,
		};
	}

	export function addApplierToTalentFinished(): TalentAddApplierFinishedAction {
		return {
			type: TALENT_ADD_APPLIER_FINISHED,
		};
	}

	export function addApplierToTalentFinishedWithErrors(err: string): TalentAddApplierFinishedWithErrosAction {
		return {
			type: TALENT_ADD_APPLIER_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function changeStatusApplierToTalent(
		status: CategoryModel,
		applicationId: number,
	): TalentChangeStatusApplierAction {
		return {
			type: TALENT_CHANGE_STATUS_APPLIER,
			payload: {
				status,
				applicationId,
			},
		};
	}

	export function changeStatusApplierToTalentStarted(): TalentChangeStatusApplierStartedAction {
		return {
			type: TALENT_CHANGE_STATUS_APPLIER_STARTED,
		};
	}

	export function changeStatusApplierToTalentFinished(
		status: CategoryModel,
		applicationId: number,
	): TalentChangeStatusApplierFinishedAction {
		return {
			type: TALENT_CHANGE_STATUS_APPLIER_FINISHED,
			payload: {
				status,
				applicationId,
			},
		};
	}

	export function changeStatusApplierToTalentFinishedWithErrors(
		err: string,
	): TalentChangeStatusApplierFinishedWithErrosAction {
		return {
			type: TALENT_CHANGE_STATUS_APPLIER_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getAppliersToTalent(talentId: string): TalentGetAppliersAction {
		return {
			type: TALENT_GET_APPLIERS,
			payload: talentId,
		};
	}

	export function getAppliersToTalentStarted(): TalentGetAppliersStartedAction {
		return {
			type: TALENT_GET_APPLIERS_STARTED,
		};
	}

	export function getAppliersToTalentFinished(appliers: TalentApplier[]): TalentGetAppliersFinishedAction {
		return {
			type: TALENT_GET_APPLIERS_FINISHED,
			payload: appliers,
		};
	}

	export function getAppliersToTalentFinishedWithErrors(err: string): TalentGetAppliersFinishedWithErrosAction {
		return {
			type: TALENT_GET_APPLIERS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function reset(): TalentResetStateAction {
		return {
			type: TALENT_RESET_STATE,
		};
	}
}
