import { CategoryType } from "api/CategoriesAPI/urls";
import { MicroBusinessProfileTypes } from "constants/Profiles";
import { URL_VINGLET_IMAGE } from "constants/URLs";
import CategorySelectableModel from "models/categories/CategorySelectable";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import BusinessProfileType from "./BusinessProfileType";
import { StringUtils } from "utils/StringUtils";

const DEFAULT_BACKGROUND_IMAGE = "/default-image.png";
const DEFAULT_AVATAR = "/default_profile_image.png";
// This should exactly the same structure as BusinessProfileState
export interface BusinessProfileInfo {
	id: number | null;
	fullName: string;
	description: string;
	url: string;
	type: MicroBusinessProfileTypes;
	slug: {
		value: string;
		isValid: boolean;
	};
	avatar: {
		src: string;
	};
	emblem: string;
	externalURL: string;
	email: string;
	backgroundImage: {
		src: string;
	};
	termsAndCondition: boolean;
	country: CategorySelectableModel;
	agreementAccepted: boolean;
}

class BusinessProfileInfoModel implements BusinessProfileInfo {
	private _id: number | null;
	fullName: string;
	description: string;
	url: string;
	type: MicroBusinessProfileTypes;
	slug: {
		value: string;
		isValid: boolean;
	};
	avatar: {
		src: string;
	};
	emblem: string;
	externalURL: string;
	email: string;
	backgroundImage: {
		src: string;
	};
	termsAndCondition: boolean;
	country: CategorySelectableModel;
	agreementAccepted: boolean;

	constructor(
		id: number | null,
		name: string = "",
		description: string = "",
		slug: string = "",
		type: MicroBusinessProfileTypes = MicroBusinessProfileTypes.STARTUP,
		avatarSrc: string = "",
		emblem: string = "",
		externalURL: string = "",
		email: string = "",
		backgroundImageSrc: string = "",
		termsAndCondition: boolean = false,
		country: CategorySelectableModel,
		agreementAccepted: boolean = false,
	) {
		this._id = id;
		this.fullName = StringUtils.capitalizeEveryWord(name);
		this.description = description;
		this.type = type;
		this.url = this.generateUrl(type, slug);
		this.slug = {
			value: slug,
			isValid: true,
		};
		this.avatar = {
			src: this.generateAvatarURL(avatarSrc),
		};
		this.emblem = emblem;
		this.externalURL = externalURL;
		this.email = email;
		this.backgroundImage = {
			src: this.generateBackgroundURL(backgroundImageSrc),
		};
		this.termsAndCondition = termsAndCondition;
		this.country = country;
		this.agreementAccepted = agreementAccepted;
	}

	get id(): number {
		return Number(this._id);
	}

	set id(value: number | null) {
		this._id = value;
	}

	generateUrl = (type: MicroBusinessProfileTypes, slug: string) => `/${type}/${slug}`;

	generateAvatarURL = (avatar: string) => {
		if (avatar === DEFAULT_AVATAR || avatar === null) {
			return DEFAULT_AVATAR;
		}

		// To avoid double assignment
		const urlRegex = new RegExp(URL_VINGLET_IMAGE, "g");
		const cleantImage = avatar.replace(urlRegex, "");

		return cleantImage ? URL_VINGLET_IMAGE + cleantImage : String(DEFAULT_AVATAR);
	};

	generateBackgroundURL = (background: string) => {
		if (background == DEFAULT_BACKGROUND_IMAGE || background === null) {
			return DEFAULT_BACKGROUND_IMAGE;
		}

		// To avoid double assignment
		const urlRegex = new RegExp(URL_VINGLET_IMAGE, "g");
		const cleantImage = background.replace(urlRegex, "");

		return cleantImage ? URL_VINGLET_IMAGE + cleantImage : String(DEFAULT_BACKGROUND_IMAGE);
	};

	// We have to change the "any" by a new object that specifies which
	// data is served by the API
	static generateFromAPI = (data: any): BusinessProfileInfoModel => {
		const { country: countryTranslation } = TranslationsServiceSingleton.getWorldTranslations(["country"]);
		const typeSpecialProfile = data.institution_type || data.company_type || data.investor_type;
		let businessProfile: BusinessProfileInfoModel;
		let country = data.country;
		country = new CategorySelectableModel(
			country._id,
			country.name,
			countryTranslation.items[country.name],
			country.category as CategoryType,
			true,
		);

		if (typeSpecialProfile) {
			businessProfile = new BusinessProfileInfoModel(
				data.id,
				data.name,
				data.description,
				data.slug,
				BusinessProfileType.getMicroTypeFromAPI(typeSpecialProfile),
				data.profile_image,
				data.emblem,
				data.url,
				data.email,
				data.cover_image,
				true,
				country,
				data.agreement_accepted,
			);
		} else {
			businessProfile = new BusinessProfileInfoModel(
				data.id,
				`${data.user.first_name} ${data.user.last_name}`,
				data.description,
				data.slug,
				BusinessProfileType.getMicroTypeFromAPI(data.type),
				data.profile_image,
				data.emblem,
				"",
				data.email,
				data.cover_image,
				true,
				country,
				data.agreement_accepted,
			);
		}

		return businessProfile;
	};

	static generateFromObject = (data: BusinessProfileInfo): BusinessProfileInfoModel => {
		return new BusinessProfileInfoModel(
			data.id,
			data.fullName,
			data.description,
			data.slug.value,
			data.type,
			data.avatar.src,
			data.emblem,
			data.externalURL,
			data.email,
			data.backgroundImage.src,
			data.termsAndCondition,
			data.country,
			data.agreementAccepted,
		);
	};

	static generateListFromAPI = (data: any[]): BusinessProfileInfo[] => {
		return data ? data.map((item: any) => BusinessProfileInfoModel.generateFromAPI(item)) : [];
	};

	static generateEmpty = (): BusinessProfileInfoModel => {
		return new BusinessProfileInfoModel(
			null,
			"",
			"",
			"",
			MicroBusinessProfileTypes.STARTUP,
			"",
			"",
			"",
			"",
			"",
			false,
			CategorySelectableModel.generateFromEmpty(),
			false,
		);
	};
}

export default BusinessProfileInfoModel;
