export const WolrdCat = `
{
    "region":{
       "key":{
          "singular":"Regió",
          "plural":"Regions"
       },
       "items":{
          "europe":"Europa",
          "northAmerica":"Amèrica del Nord",
          "centralAmerica":"America Central",
          "southAmerica":"Amèrica del Sud",
          "asia":"Àsia",
          "africa":"Àfrica",
          "oceania":"Oceania"
       }
    },
    "country":{
       "key":{
          "singular":"Pais",
          "plural":"Paises"
       },
       "items":{
         "argentina":"Argentina",
         "chile":"Xile",
         "colombia":"Colòmbia",
         "france":"França",
         "germany":"Alemanya",
         "ireland":"Irlanda",
         "israel":"Israel",
         "mexico":"Mèxic",
         "netherlands":"Països Baixos",
         "peru":"Perú",
         "portugal":"Portugal",
         "spain":"Espanya",
         "sweden":"Suècia",
         "unitedKingdom":"Regne Unit",
         "unitedStates":"Estats Units",
         "uruguay":"Uruguai",
         "venezuela":"Veneçuela",
         "ecuador":"Ecuador",
         "brazil":"Brasil"
       }
    },
    "language":{
       "key":{
          "singular":"Lenguaje",
          "plural":"Lenguajes"
       },
       "items":{
             "catalan": "Català",
             "simplifiedchinese": "Xinès simplificat",
             "traditionalchinese": "Xinés tradicional",
             "spanish": "Espanyol",
             "french": "Francès",
             "english": "Anglès",
             "italian": "Italià",
             "japanese": "Japonès",
             "portuguese": "Portuguès",
             "russian": "Rus",
             "german": "Alemany",
             "hindi": "Hindi",
             "arab": "Àrab",
             "hebrew": "Hebreu"
       }
    }
 }
`;
