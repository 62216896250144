import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { INTERACTION_SERVICE_URL } from "@api";

export type ProductWithPrice = {
	productId: string;
	productName: string;
	price: number;
	priceId: string;
};

interface FetchPaymentsProductsWithPrices {
	productsWithPrices: ProductWithPrice[];
}

function fetchPaymentsProductsWithPrices() {
	return function () {
		return axios
			.get(`${INTERACTION_SERVICE_URL}/payments/products/getProductsWithPrices`)
			.then(({ data }) => data)
			.then((parsedData: FetchPaymentsProductsWithPrices) => parsedData.productsWithPrices)
			.catch(() => []);
	};
}

export const usePaymentsProductsWithPricesQuery = () => {
	return useQuery<ProductWithPrice[]>(["payments-products-with-prices"], fetchPaymentsProductsWithPrices());
};
