import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "../config";

import { UserLogin } from "./types";
import { CookiesUtils } from "@utils";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

interface UserLoginPayload {
	email: string;
	password: string;
}

function logUserIn(data: UserLoginPayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/login`, data, config).then((response) => {
		return response.data;
	});
}

export const useLogUserInMutation = ({
	onSuccess,
	...options
}: UseMutationOptions<UserLogin, AxiosError, UserLoginPayload> = {}) => {
	return useMutation((data: UserLoginPayload) => logUserIn(data, getRequestConfig()), {
		...options,
		onSuccess: (data, values, context) => {
			CookiesUtils.setCookieForever("accessToken", data.authToken);

			onSuccess && onSuccess(data, values, context);
		},
	});
};
