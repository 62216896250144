import { URL_AUTH_LOGOUT } from "api/AuthAPI/urls";
import { defaultError, getPatchHeaderWithCredentials } from "api/config";

export function fetchLogout(accessToken: string) {
	return function () {
		return fetch(URL_AUTH_LOGOUT, getPatchHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
