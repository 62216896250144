import {HeaderTexts} from "i18n/containers/Header";
import {HeaderTranslation} from "i18n/containers/Header/typing";
import {HeaderLink} from "./useDropdownHeaderLinks";
import {useLocales} from "@libs";

const useHeaderLinks = () => {
    const {currentShortLocale} = useLocales();
    const headerTranslation: HeaderTranslation = {
        ...HeaderTexts[currentShortLocale],
    };
    const headerLinks: HeaderLink[] = [
        // {
        // 	label: headerTranslation.home,
        // 	link: "/",
        // 	icon: "home",
        // 	color: "black",
        // },
        {
            label: headerTranslation.notifications,
            link: "/notifications",
            icon: "bell",
            color: "black",
        },
        {
            label: headerTranslation.invitations,
            link: "/mynetwork",
            icon: "users",
            color: "black",
        },
        // {
        // 	label: headerTranslation.messages,
        // 	link: "/messages",
        // 	icon: "chat",
        // 	color: "black",
        // },
    ];

    return {
        headerLinks
    }
}

export default useHeaderLinks;
