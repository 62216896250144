import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Talent } from "./types";

interface User {
	id: number;
	name: string;
	email: string;
	phone_number: string;
	slug: string;
	profile_image: string;
}

interface Status {
	category_id: number;
	date: string;
}

export interface TalentApplier {
	id: number;
	user: User;
	document: string;
	ranking: number;
	note?: any;
	status: Status[];
	talent: Talent;
}

export const fetchTalentAppliers = (talentId: number, accessToken: string) => {
	return function (): Promise<TalentApplier[]> {
		return axios
			.get(`${PROD_URL}/talents/${talentId}/appliers`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
};

export const useTalentAppliersQuery = (
	talentId: number,
	options: UseQueryOptions<TalentApplier[], any, TalentApplier[]> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<TalentApplier[]>(["talent-appliers", talentId], fetchTalentAppliers(talentId, accessToken), {
		...options,
	});
};
