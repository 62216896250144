import { Recommendation } from "models/recommendations/Recommendation";
import RequestModel, { Request } from "models/requests/Request";

export interface ProfileRecommendations {
	items: Recommendation[];
	fetching?: Request;
}

class ProfileRecommendationsModel implements ProfileRecommendations {
	items: Recommendation[];
	fetching?: Request;

	constructor(items: Recommendation[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		return new ProfileRecommendationsModel([], RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileRecommendationsModel([], RequestModel.requestLoading());
	};
}

export default ProfileRecommendationsModel;
