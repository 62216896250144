import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

class RightMenuSubItems {
	private readonly _label: string;
	private readonly _link: string;
	private readonly _icon: SemanticICONS;

	constructor(label: string, link: string, icon: SemanticICONS, action?: () => string | void) {
		this._label = label;
		this._link = link;
		this._icon = icon;
		this._action = action;
	}

	private readonly _action?: () => string | void;

	get action(): (() => string | void) | undefined {
		return this._action;
	}

	get label(): string {
		return this._label;
	}

	get link(): string {
		return this._link;
	}

	get icon(): SemanticICONS {
		return this._icon;
	}

	get isAnAction(): boolean {
		return Boolean(this._action);
	}
}

export default RightMenuSubItems;
