import ProfileModel from "models/profiles/Profile";
import { AuthState } from "redux-app/reducers/AuthReducer/typing";
import { UnitBusinessProfile } from "redux-app/reducers/UserReducer/typing";

export namespace Auth {
	export const AUTH_NAME = "AUTH_NAME";
	export const AUTH_SURNAME = "AUTH_SURNAME";
	export const AUTH_EMAIL = "AUTH_CHANGE_EMAIL";
	export const AUTH_PASSWORD = "AUTH_PASSWORD";
	export const AUTH_TERMS_AND_CONDITIONS = "AUTH_TERMS_AND_CONDITIONS";
	export const AUTH_LOADING = "AUTH_LOADING";
	export const AUTH_CHECKING_LOGIN = "AUTH_CHECKING_LOGIN";
	export const AUTH_GET_STATUS_LOGIN = "AUTH_GET_STATUS_LOGIN";
	export const AUTH_STATUS_LOGIN = "AUTH_STATUS_LOGIN";
	export const AUTH_LOGOUT = "AUTH_LOGOUT";
	export const AUTH_LOGOUT_FAILED = "AUTH_LOGOUT_FAILED";
	export const AUTH_LOGOUT_SUCCEDED = "AUTH_LOGOUT_SUCCEDED";
	export const AUTH_HAS_ONBOARDING = "AUTH_HAS_ONBOARDING";
	export const AUTH_ERASE_MESSAGE_ERROR = "AUTH_ERASE_MESSAGE_ERROR";
	export const AUTH_RECEIVE_DATA_API = "AUTH_RECEIVE_DATA_API";
	export const AUTH_GET_PROFILES_BY_USER_STARTED = "AUTH_GET_PROFILES_BY_USER_STARTED";
	export const AUTH_GET_PROFILES_BY_USER_FINISH = "AUTH_GET_PROFILES_BY_USER_FINISH";
	export const AUTH_GET_PROFILES_BY_USER_FINISH_WITH_ERRORS = "AUTH_GET_PROFILES_BY_USER_FINISH_WITH_ERRORS";
	export const AUTH_UPDATE_USER_PROFILE = "AUTH_UPDATE_USER_PROFILE";

	interface AuthNameAction {
		type: typeof AUTH_NAME;
		payload: string;
	}

	interface AuthSurnameAction {
		type: typeof AUTH_SURNAME;
		payload: string;
	}

	interface AuthEmailAction {
		type: typeof AUTH_EMAIL;
		payload: string;
	}

	interface AuthPasswordAction {
		type: typeof AUTH_PASSWORD;
		payload: string;
	}

	interface AuthTermsAndConditionsAction {
		type: typeof AUTH_TERMS_AND_CONDITIONS;
		payload: boolean;
	}

	interface AuthCheckingLoginAction {
		type: typeof AUTH_CHECKING_LOGIN;
		payload: boolean;
	}

	interface AuthLoadingAction {
		type: typeof AUTH_LOADING;
		payload: boolean;
	}

	interface AuthLoginGetStatusAction {
		type: typeof AUTH_GET_STATUS_LOGIN;
	}

	interface AuthLoginStatusAction {
		type: typeof AUTH_STATUS_LOGIN;
		payload: AuthState | null;
	}

	interface AuthLogoutAction {
		type: typeof AUTH_LOGOUT;
	}

	interface AuthLogoutFailedAction {
		type: typeof AUTH_LOGOUT_FAILED;
		payload: any;
	}

	interface AuthLogoutSuccededAction {
		type: typeof AUTH_LOGOUT_SUCCEDED;
	}

	interface AuthHasOnboardingAction {
		type: typeof AUTH_HAS_ONBOARDING;
		payload: boolean;
	}

	interface AuthEraseMessageErrorAction {
		type: typeof AUTH_ERASE_MESSAGE_ERROR;
	}

	interface AuthReceiveDataAPI {
		type: typeof AUTH_RECEIVE_DATA_API;
		payload: AuthState;
	}

	interface AuthGetProfilesByUserStarted {
		type: typeof AUTH_GET_PROFILES_BY_USER_STARTED;
	}

	interface AuthGetProfilesByUserFinish {
		type: typeof AUTH_GET_PROFILES_BY_USER_FINISH;
		payload: UnitBusinessProfile[];
	}

	interface AuthGetProfilesByUserFinishWithErrors {
		type: typeof AUTH_GET_PROFILES_BY_USER_FINISH_WITH_ERRORS;
		payload: string;
	}

	interface AuthUpdateUserProfile {
		type: typeof AUTH_UPDATE_USER_PROFILE;
		payload: ProfileModel;
	}

	export type AuthActionTypes =
		| AuthNameAction
		| AuthSurnameAction
		| AuthEmailAction
		| AuthPasswordAction
		| AuthTermsAndConditionsAction
		| AuthLoadingAction
		| AuthCheckingLoginAction
		| AuthLoginGetStatusAction
		| AuthLoginStatusAction
		| AuthLogoutAction
		| AuthLogoutFailedAction
		| AuthLogoutSuccededAction
		| AuthHasOnboardingAction
		| AuthEraseMessageErrorAction
		| AuthReceiveDataAPI
		| AuthGetProfilesByUserStarted
		| AuthGetProfilesByUserFinish
		| AuthGetProfilesByUserFinishWithErrors
		| AuthUpdateUserProfile;

	export function name(newName: string): AuthNameAction {
		return {
			type: AUTH_NAME,
			payload: newName,
		};
	}

	export function surname(newSurname: string): AuthSurnameAction {
		return {
			type: AUTH_SURNAME,
			payload: newSurname,
		};
	}

	export function email(newEmail: string): AuthEmailAction {
		return {
			type: AUTH_EMAIL,
			payload: newEmail,
		};
	}

	export function password(newPassword: string): AuthPasswordAction {
		return {
			type: AUTH_PASSWORD,
			payload: newPassword,
		};
	}

	export function termsAndConditions(accepted: boolean): AuthTermsAndConditionsAction {
		return {
			type: AUTH_TERMS_AND_CONDITIONS,
			payload: accepted,
		};
	}

	export function checkingLogin(status: boolean): AuthCheckingLoginAction {
		return {
			type: AUTH_CHECKING_LOGIN,
			payload: status,
		};
	}

	export function loading(isLoading: boolean): AuthLoadingAction {
		return {
			type: AUTH_LOADING,
			payload: isLoading,
		};
	}

	export function logout(): AuthLogoutAction {
		return {
			type: AUTH_LOGOUT,
		};
	}

	export function logoutSucceded(): AuthLogoutSuccededAction {
		return {
			type: AUTH_LOGOUT_SUCCEDED,
		};
	}

	export function statusLogin(status: AuthState | null): AuthLoginStatusAction {
		return {
			type: AUTH_STATUS_LOGIN,
			payload: status,
		};
	}

	export function hasOnboarding(passed: boolean): AuthHasOnboardingAction {
		return {
			type: AUTH_HAS_ONBOARDING,
			payload: passed,
		};
	}

	export function receiveDataAPI(data: AuthState): AuthReceiveDataAPI {
		return {
			type: AUTH_RECEIVE_DATA_API,
			payload: data,
		};
	}

	export function getProfilesByUserFinish(list: UnitBusinessProfile[]): AuthGetProfilesByUserFinish {
		return {
			type: AUTH_GET_PROFILES_BY_USER_FINISH,
			payload: list,
		};
	}

	export function updateUserProfile(userProfile: ProfileModel): AuthUpdateUserProfile {
		return {
			type: AUTH_UPDATE_USER_PROFILE,
			payload: userProfile,
		};
	}
}
