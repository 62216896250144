import * as React from "react";
import classNames from "classnames";
import {
	getWindowDimensions,
	maxMobile,
	maxTablet,
	minComputer,
	minMobile,
	minTablet,
} from "stylesheet/breakpoints/utils";

const RESPONSIVE_MOBILE_CLASS = "responsive-mobile";
const RESPONSIVE_TABLET_CLASS = "responsive-tablet";
const RESPONSIVE_DESKTOP_CLASS = "responsive-desktop";

const RESPONSIVE_MIN_SIZE_SCREEN = 0;
const RESPONSIVE_MAX_SIZE_SCREEN = 3000;

const isMobile = () => {
	return getWindowDimensions().width < maxMobile;
};

const isTablet = () => {
	return getWindowDimensions().width >= maxMobile && getWindowDimensions().width <= maxTablet;
};

const isDesktop = () => {
	return getWindowDimensions().width > maxTablet;
};

interface BreakpointsProps {
	children?: JSX.Element | JSX.Element[];
	minWidth?: number;
	maxWidth?: number;
	as?: any;
	className?: string;
	[componentAttributes: string]: any;
}

const Breakpoints = ({
	children,
	minWidth = RESPONSIVE_MIN_SIZE_SCREEN,
	maxWidth = RESPONSIVE_MAX_SIZE_SCREEN,
	as,
	className,
	...componentAttributes
}: BreakpointsProps) => {
	let responsiveClasses = "";
	const AsComponent = as;

	if (minWidth <= maxMobile && maxWidth > minMobile) {
		responsiveClasses += RESPONSIVE_MOBILE_CLASS + " ";
	}

	if (minWidth <= maxTablet && maxWidth > minTablet) {
		responsiveClasses += RESPONSIVE_TABLET_CLASS + " ";
	}

	if (maxWidth > minComputer) {
		responsiveClasses += RESPONSIVE_DESKTOP_CLASS + " ";
	}

	return AsComponent ? (
		<AsComponent
			{...componentAttributes}
			className={classNames(responsiveClasses, className)}
		>
			{children}
		</AsComponent>
	) : (
		<div className={responsiveClasses}>{children}</div>
	);
};

export default Breakpoints;
export { isMobile, isTablet, isDesktop };
