import { CategoryType } from "api/CategoriesAPI/urls";
import { StringUtils } from "utils/StringUtils";

export interface Category {
	// 0,1,2,3,4,5
	id: number | null;
	// workHard, somethingElse
	key: string;
	// Work Hard, Something Else
	text: string;
	// SCHOOL
	category: CategoryType | null;
	// LEGACY
	idCategory?: string;
}

// This interface represents how we received the categories from the SERVER
export interface CategoryAPI {
	// 0,1,2,3,4,5
	id: number;
	// workHard, somethingElse
	name: string;
	// SCHOOL
	category: CategoryType;
	// Almost always comes in null
	subcategory?: string;
}

class CategoryModel implements Category {
	id: number | null;
	key: string;
	// TO-DO Add translations
	text: string;
	category: CategoryType | null;
	// LEGACY
	idCategory: string;

	constructor(id: number | null, key: string, text: string, category: CategoryType | null) {
		this.id = id;
		this.key = key;
		this.text = StringUtils.capitalize(text);
		this.category = category;
		this.idCategory = key;
	}

	setText = (text: string) => {
		this.text = StringUtils.capitalize(text);
	};

	static generateFromAPI(data: any) {
		return new CategoryModel(data.id, data.name, "", data.category as CategoryType);
	}

	static generateFromObject(category: Category) {
		return new CategoryModel(category.id, category.key, category.text, category.category);
	}

	static generateEmpty() {
		return new CategoryModel(0, "", "", CategoryType.SKILL);
	}
}

export default CategoryModel;
