import { defaultError } from "api/config";
import {
	addApplierToTalentAPI,
	changeStatusApplierAPI,
	fetchDeleteTalent,
	fetchNewTalent,
	fetchUpdateTalent,
	getAppliersTalentAPI,
	getTalentByIdAPI,
	getTalentsByBusinessProfileSlug,
} from "api/TalentAPI";
import CategoryModel from "models/categories/Category";
import TalentModel from "models/talents/Talent";
import { Talent } from "redux-app/actions/TalentAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { checkCredentials } from "../AuthSagas";
import {
	convertAPIRequestToListAppliersTalent,
	convertAPIRequestToTalentState,
	convertTalentStateToAPIRequest,
	createAPIRequestToApplyToTalent,
	createAPIRequestToChangeStatusToApplier,
} from "./formatter";
import { CookiesUtils } from "@utils";

function* getTalentById(action: { type: typeof Talent.TALENT_GET_BY_ID; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	yield put(Talent.getByIDStarted());

	try {
		// @ts-ignore
		const talent = yield call(getTalentByIdAPI(action.payload, accessToken));

		if (talent.message) {
			yield put(Talent.getByIDFinishedWithErrors(talent.message));
		} else {
			// @ts-ignore
			const finishedTalents = yield convertAPIRequestToTalentState([talent]);
			yield put(Talent.getByIDFinished(finishedTalents[0]));
		}
	} catch (err) {
		yield put(Talent.getByIDFinishedWithErrors(defaultError().message));
	}
}

function* newTalent(action: { type: typeof Talent.TALENT_NEW; payload: TalentModel }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.newTalentStarted());

		try {
			const preparedDataToSend = convertTalentStateToAPIRequest(action.payload);
			// @ts-ignore
			const talent = yield call(fetchNewTalent(preparedDataToSend, accessToken));

			if (talent.message) {
				yield put(Talent.newTalentFinishedWithErrors(talent.message));
			} else {
				yield put(Talent.newTalentFinished(action.payload));
				yield put(Talent.reset());
			}
		} catch (err) {
			yield put(Talent.newTalentFinishedWithErrors(defaultError().message));
		}
	}
}

function* getTalentsByProfile(action: { type: typeof Talent.TALENT_GET_TALENTS_PROFILE; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	yield put(Talent.getTalentsProfileStarted());

	try {
		// @ts-ignore
		const talents = yield call(getTalentsByBusinessProfileSlug(action.payload, accessToken));

		if (talents.message) {
			yield put(Talent.getTalentsProfileFinishedWithErrors(talents.message));
		} else {
			// @ts-ignore
			const finishedTalents = yield convertAPIRequestToTalentState(talents);
			yield put(Talent.getTalentsProfileFinished(finishedTalents));
		}
	} catch (err) {
		yield put(Talent.getTalentsProfileFinishedWithErrors(defaultError().message));
	}
}

function* updateTalent(action: { type: typeof Talent.TALENT_UPDATE; payload: TalentModel }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.updateTalentStarted());

		try {
			const preparedDataToSend = convertTalentStateToAPIRequest(action.payload);
			// @ts-ignore
			const talents = yield call(fetchUpdateTalent(preparedDataToSend, action.payload.id!!, accessToken));

			if (talents.message) {
				yield put(Talent.updateTalentFinishedWithErrors(talents.message));
			} else {
				yield put(Talent.updateTalentFinished(action.payload));
			}
		} catch (err) {
			yield put(Talent.updateTalentFinishedWithErrors(defaultError().message));
		}
	}
}

function* deleteTalent(action: {
	type: typeof Talent.TALENT_DELETE;
	payload: {
		talentId: string;
		profileId: string;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const { talentId, profileId } = action.payload;

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.deleteTalentStarted());

		try {
			// @ts-ignore
			const talent = yield call(fetchDeleteTalent(talentId, profileId, accessToken));

			if (talent.message) {
				yield put(Talent.deleteTalentFinishedWithErrors(talent.message));
			} else {
				yield put(Talent.deleteTalentFinished(talentId));
			}
		} catch (err) {
			yield put(Talent.deleteTalentFinishedWithErrors(defaultError().message));
		}
	}
}

function* addApplier(action: {
	type: typeof Talent.TALENT_ADD_APPLIER;
	payload: {
		talentId: string;
		profileSlug: string;
		documentId: string;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const { talentId, profileSlug, documentId } = action.payload;

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.addApplierToTalentStarted());

		try {
			// @ts-ignore
			const apply = yield call(
				addApplierToTalentAPI(createAPIRequestToApplyToTalent(profileSlug, documentId), talentId, accessToken),
			);

			if (apply.message) {
				yield put(Talent.addApplierToTalentFinishedWithErrors(apply.message));
			} else {
				yield put(Talent.addApplierToTalentFinished());
			}
		} catch (err) {
			yield put(Talent.addApplierToTalentFinishedWithErrors(defaultError().message));
		}
	}
}

function* changeStatusApplier(action: {
	type: typeof Talent.TALENT_CHANGE_STATUS_APPLIER;
	payload: {
		status: CategoryModel;
		applicationId: number;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.changeStatusApplierToTalentStarted());

		try {
			const { status, applicationId } = action.payload;
			// @ts-ignore
			const apply = yield call(
				changeStatusApplierAPI(createAPIRequestToChangeStatusToApplier(status), applicationId, accessToken),
			);

			if (apply.message) {
				yield put(Talent.changeStatusApplierToTalentFinishedWithErrors(apply.message));
			} else {
				yield put(Talent.changeStatusApplierToTalentFinished(status, applicationId));
			}
		} catch (err) {
			yield put(Talent.changeStatusApplierToTalentFinishedWithErrors(defaultError().message));
		}
	}
}

function* getAppliers(action: { type: typeof Talent.TALENT_GET_APPLIERS; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Talent.getAppliersToTalentStarted());

		try {
			// @ts-ignore
			const rawAppliers = yield call(getAppliersTalentAPI(action.payload, accessToken));

			if (rawAppliers.message) {
				yield put(Talent.getAppliersToTalentFinishedWithErrors(rawAppliers.message));
			} else {
				// @ts-ignore
				const appliers = yield convertAPIRequestToListAppliersTalent(rawAppliers);
				yield put(Talent.getAppliersToTalentFinished(appliers));
			}
		} catch (err) {
			yield put(Talent.getAppliersToTalentFinishedWithErrors(defaultError().message));
		}
	}
}

export const rootTalentSagas = [
	takeLatest(Talent.TALENT_GET_BY_ID, getTalentById),
	takeLatest(Talent.TALENT_NEW, newTalent),
	takeLatest(Talent.TALENT_GET_TALENTS_PROFILE, getTalentsByProfile),
	takeLatest(Talent.TALENT_UPDATE, updateTalent),
	takeLatest(Talent.TALENT_DELETE, deleteTalent),
	takeLatest(Talent.TALENT_ADD_APPLIER, addApplier),
	takeLatest(Talent.TALENT_CHANGE_STATUS_APPLIER, changeStatusApplier),
	takeLatest(Talent.TALENT_GET_APPLIERS, getAppliers),
];
