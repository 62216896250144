import { ReactNode } from "react";
import classNames from "classnames";

import { ChevronDown, ChevronUp } from "@common";
import { useToggle } from "@libs";

interface FilterProps {
	id: string;
	title: string;
	children: ReactNode;
}

export const Filter = ({ id, title, children }: FilterProps) => {
	const [expanded, { toggle }] = useToggle(true);

	return (
		<div
			id={id}
			data-accordion="collapse"
			className="border-b border-neutral-400"
		>
			<h4
				id={`${id}-heading`}
				className="m-0"
			>
				<button
					type="button"
					className="flex w-full items-center justify-between px-3 py-5 text-left font-semibold text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800"
					onClick={toggle}
					data-accordion-target={`#${id}-body`}
					aria-controls={`${id}-body`}
					aria-expanded="true"
				>
					<span>{title}</span>
					{expanded ? <ChevronUp className="h-6 w-6" /> : <ChevronDown className="h-6 w-6" />}
				</button>
			</h4>
			<section
				id={`${id}-body`}
				className={classNames("px-3 pt-3 pb-6", !expanded && "hidden")}
				aria-labelledby={`${id}-heading`}
			>
				{children}
			</section>
		</div>
	);
};
