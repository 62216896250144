import ProfileModel from "models/profiles/Profile";
import React from "react";

import { User } from "@api";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

interface UserProfileContextType {
	user: User | null;
	isUserLoading: boolean;
	userProfile: ProfileModel;
	isUserProfileLoading: boolean;
	businessProfiles: BusinessProfileModel[];
	isBusinessProfilesLoading: boolean;
}

export const userProfileContextDefaultValue = {
	user: null,
	isUserLoading: false,
	userProfile: ProfileModel.generateEmpty(),
	isUserProfileLoading: false,
	businessProfiles: [] as BusinessProfileModel[],
	isBusinessProfilesLoading: false,
};

export const UserProfileContext = React.createContext<UserProfileContextType>(userProfileContextDefaultValue);
