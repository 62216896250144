import { DatesEs } from "i18n/localization/dates/es";
import { FeaturesEs } from "i18n/localization/features/es";
import { WolrdEs } from "i18n/localization/world/es";

export const localizationEs = `
[  
    {
        "field": "dates",
        "translations": [
            {
                "lang": "es",
                "data": ${DatesEs}
            }
        ]
    },
    {
        "field": "features",
        "translations": [
            {
                "lang": "es",
                "data": ${FeaturesEs}
            }
        ]
    },
    {
        "field": "world",
        "translations": [
            {
                "lang": "es",
                "data": ${WolrdEs}
            }
        ]
    }
]
`;
