import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { MarketplacePayload } from "./types";

type CreateMarketplacePayload = MarketplacePayload;

function createMarketplace(data: CreateMarketplacePayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/marketplace`, data, config);
}

export const useCreateMarketplaceMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, CreateMarketplacePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: CreateMarketplacePayload) => createMarketplace(data, getRequestConfig(accessToken)), {
		...options,
	});
};
