import { DateUtils } from "@utils";

import { CategoryType } from "api/CategoriesAPI/urls";
import { CommonTranslationPromiseProps } from "constants/Feature";
import CategoryModel from "models/categories/Category";
import RequestModel, { Request } from "models/requests/Request";
import { ProfileStyle } from "redux-app/reducers/UserReducer/typing";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

export interface ProfileOverview {
	gender: string;
	experience: number;
	speciality: ProfileStyle;
	birthdate: number;
	// TO-DO: This should be replaced by Category
	areas: CommonTranslationPromiseProps[];
	country: CommonTranslationPromiseProps;
	languages: CommonTranslationPromiseProps[];
	fetching?: Request;
}

class ProfileOverviewModel implements ProfileOverview {
	gender: string;
	experience: number;
	speciality: ProfileStyle;
	birthdate: number;
	// TO-DO: This should be replaced by Category
	areas: CommonTranslationPromiseProps[];
	country: CommonTranslationPromiseProps;
	languages: CommonTranslationPromiseProps[];
	fetching?: Request;

	constructor(
		gender: string,
		experience: number,
		speciality: ProfileStyle,
		birthdate: number,
		// TO-DO: This should be replaced by Category
		areas: CommonTranslationPromiseProps[],
		country: CommonTranslationPromiseProps,
		languages: CommonTranslationPromiseProps[],
		fetching?: Request,
	) {
		this.gender = gender;
		this.experience = experience;
		this.speciality = speciality;
		this.birthdate = birthdate;
		this.areas = areas;
		this.country = country;
		this.languages = languages;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const { area: areasTranslations } = TranslationsServiceSingleton.getFeatureTranslations(["area"]);
		const { country: countryTranslations, language: languagesTranslations } =
			TranslationsServiceSingleton.getWorldTranslations(["country", "language"]);
		let gender = "indeterminate";

		switch (data.gender) {
			case "MALE":
				gender = "male";
				break;
			case "FEMALE":
				gender = "female";
				break;
		}

		let birthday = DateUtils.getTimeAPIFormat(DateUtils.MAX_DATE_FULL_AGE);

		try {
			const parsedBirthday = JSON.parse(data.birthday);
			birthday = parsedBirthday.datetime;
		} catch (err) {}

		const selectedCountry =
			data.country && countryTranslations.items[data.country.name]
				? {
						value: data.country.name,
						text: countryTranslations.items[data.country.name],
				  }
				: {
						value: "",
						text: "",
				  };
		const selectedAreas = data.areas
			? data.areas
					.filter((area: any) => area && areasTranslations.items[area.name])
					.map((area: any) => {
						const translatedArea = areasTranslations.items[area.name];

						return {
							value: area.name,
							text: translatedArea,
						};
					})
			: [];

		const selectedLanguages = data.languages
			? data.languages
					.filter((language: any) => language && languagesTranslations.items[language.name])
					.map((language: any) => {
						const translatedLanguage = languagesTranslations.items[language.name];

						return {
							value: language.name,
							text: translatedLanguage,
						};
					})
			: [];

		return new ProfileOverviewModel(
			gender,
			0,
			ProfileStyle.CommonProfile,
			DateUtils.getEpochFromAPIFormat(birthday),
			selectedAreas,
			selectedCountry,
			selectedLanguages,
			RequestModel.requestDefault(),
		);
	}

	static generateEmpty = () => {
		return new ProfileOverviewModel(
			"",
			0,
			ProfileStyle.CommonProfile,
			DateUtils.MAX_DATE_FULL_AGE,
			[],
			{
				value: "",
				text: "",
			},
			[],
			RequestModel.requestLoading(),
		);
	};

	exportToAPIRequest = () => {
		const areas = CategoriesServiceSingleton.getList().filterByKeyAndCategoryType(
			this.areas.map((area) => area.value),
			CategoryType.AREA,
		) as CategoryModel[];
		const languages = CategoriesServiceSingleton.getList().filterByKey(
			this.languages.map((lang) => lang.value),
		) as CategoryModel[];
		const country = CategoriesServiceSingleton.getList().filterByKey(this.country.value) as CategoryModel;
		let apiGender = "OTHERS";

		switch (this.gender) {
			case "male":
				apiGender = "MALE";
				break;
			case "female":
				apiGender = "FEMALE";
				break;
		}

		return {
			gender: apiGender,
			country: country.id,
			birthday: {
				datetime: DateUtils.getTimeAPIFormat(this.birthdate),
				datetimezone: DateUtils.getCurrentTimezone(),
			},
			languages: languages.map((lang) => lang.id),
			areas: areas.map((area) => area.id),
		};
	};
}

export default ProfileOverviewModel;
