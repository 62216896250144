import { AxiosRequestConfig } from "axios";
import { BrowserUtils } from "@utils";

export const PROD_URL = process.env.NEXT_PUBLIC_VINGLET_API_URL;
export const INTERACTION_SERVICE_URL = process.env.NEXT_PUBLIC_INTERACTION_SERVICE_URL;
export const IS_IT_PROD = process.env.NEXT_PUBLIC_ENVIRONMENT === "prod";

export const PLATFORM_URL = BrowserUtils.isRunningOnBrowser() ? location.origin : "";

export const getRequestConfig = (accessToken?: string): AxiosRequestConfig => {
	if (accessToken) {
		return {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				"Content-Type": "application/json",
			},
		};
	}

	return {
		headers: {
			"Content-Type": "application/json",
		},
	};
};

//TODO Change this by the getRequestConfig
export function getGetHeaderWithCredentials(accessToken: string): any {
	const credentials = {
		method: "GET",
		mode: "cors",
		headers: {},
	};

	if (accessToken) {
		credentials.headers = {
			Authorization: accessToken && `Bearer ${accessToken}`,
			"Access-Control-Allow-Headers": "*",
			"Access-Control-Allow-Origin": "*",
		};
	}

	return credentials;
}
