import { PROD_URL } from "api/config";

const URL_POST_NEW_COURSE = `${PROD_URL}/courses`;
const URL_PATCH_UPDATE_COURSE = (courseId: string) => `${PROD_URL}/courses/${courseId}`;
const URL_DELETE_COURSE_BY_ID = (courseId: string) => `${PROD_URL}/courses/${courseId}`;
const URL_POST_NEW_STREAMING = `${PROD_URL}/streamings`;
const URL_PATCH_UPDATE_STREAMING = (courseId: string) => `${PROD_URL}/streamings/${courseId}`;
const URL_DELETE_STREAMING_BY_ID = (courseId: string) => `${PROD_URL}/streamings/${courseId}`;
const URL_GET_ALL_PROFILE_ELEARNING = (slug: string) => `${PROD_URL}/profiles/${slug}/elearnings`;
const URL_GET_ELEARNING_BY_ID = (elearningId: string) => `${PROD_URL}/elearnings/${elearningId}`;
const URL_GET_ELEARNING_BY_SLUG = (slug: string) => `${PROD_URL}/elearnings/${slug}`;
const URL_SUBSCRIBE_TO_ELEARNING = (elearningID: string, profileId: string) =>
	`${PROD_URL}/elearnings/${elearningID}/add-signup/${profileId}`;

export {
	URL_POST_NEW_COURSE,
	URL_PATCH_UPDATE_COURSE,
	URL_DELETE_COURSE_BY_ID,
	URL_POST_NEW_STREAMING,
	URL_PATCH_UPDATE_STREAMING,
	URL_DELETE_STREAMING_BY_ID,
	URL_GET_ALL_PROFILE_ELEARNING,
	URL_GET_ELEARNING_BY_ID,
	URL_GET_ELEARNING_BY_SLUG,
	URL_SUBSCRIBE_TO_ELEARNING,
};
