import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { BusinessProfile } from "models/business-profiles/BusinessProfile";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import * as React from "react";
import Link from "next/link";

export function mapToBusinessProfileUnitDropdown(item: BusinessProfile) {
	const {
		info: { fullName, url, avatar },
	} = item;

	return (
		<Link
			key={url}
			href={url}
			passHref
		>
			<Dropdown.Item
				as="a"
				className="Header__business-profile-item"
			>
				<Image
					className="margin-1-right"
					avatar
					src={avatar.src}
					alt={fullName}
				/>
				{fullName}
			</Dropdown.Item>
		</Link>
	);
}
