import BusinessProfileFeatureModel, { BusinessProfileFeature } from "./BusinessProfileFeatures";
import BusinessProfileInfoModel, { BusinessProfileInfo } from "./BusinessProfileInfo";
import BusinessProfileSocialModel, { BusinessProfileSocial } from "./BusinessProfileSocial";
import BusinessProfileStatsModel from "./BusinessProfileStats";
import BusinessProfilePrivilegesModel from "models/business-profiles/BusinessProfilePrivileges";
import CategorySelectableModel from "../categories/CategorySelectable";

// This should exactly the same structure as BusinessProfileState
export interface BusinessProfile {
	info: BusinessProfileInfo;
	features: BusinessProfileFeature;
	social: BusinessProfileSocial;
	stats: BusinessProfileStatsModel;
	privileges: BusinessProfilePrivilegesModel;
}

class BusinessProfileModel implements Partial<BusinessProfile> {
	info: BusinessProfileInfoModel;
	features: BusinessProfileFeatureModel;
	social: BusinessProfileSocialModel;
	stats: BusinessProfileStatsModel;
	privileges: BusinessProfilePrivilegesModel;

	constructor(
		info: BusinessProfileInfoModel,
		features: BusinessProfileFeatureModel,
		social: BusinessProfileSocialModel,
		stats: BusinessProfileStatsModel,
		privileges: BusinessProfilePrivilegesModel,
	) {
		this.info = info;
		this.features = features;
		this.social = social;
		this.stats = stats;
		this.privileges = privileges;
	}

	static generateMiniFromAPI = (data: any): BusinessProfileModel => {
		const infoModel = new BusinessProfileInfoModel(
			data.id,
			data.name,
			"",
			data.slug,
			data.type,
			data.profile_image,
			"",
			"",
			"",
			"",
			false,
			CategorySelectableModel.generateFromEmpty(),
			false,
		);

		return new BusinessProfileModel(
			infoModel,
			BusinessProfileFeatureModel.generateEmpty(),
			BusinessProfileSocialModel.generateEmpty(),
			BusinessProfileStatsModel.generateEmpty(),
			BusinessProfilePrivilegesModel.generateEmpty(),
		);
	};

	static generateFromAPI = (data: any): BusinessProfileModel => {
		return new BusinessProfileModel(
			BusinessProfileInfoModel.generateFromAPI(data),
			BusinessProfileFeatureModel.generateFromAPI(data),
			BusinessProfileSocialModel.generateFromAPI(data),
			BusinessProfileStatsModel.generateFromAPI(data),
			BusinessProfilePrivilegesModel.generateFromAPI(data),
		);
	};

	static generateFromObject = (data: BusinessProfile): BusinessProfileModel => {
		return new BusinessProfileModel(
			BusinessProfileInfoModel.generateFromObject(data.info),
			BusinessProfileFeatureModel.generateFromObject(data.features),
			BusinessProfileSocialModel.generateFromObject(data.social),
			BusinessProfileStatsModel.generateFromObject(data.stats),
			BusinessProfilePrivilegesModel.generateFromObject(data.privileges),
		);
	};

	static generateListFromAPI = (data: any[]): BusinessProfile[] => {
		return data ? data.map((item: any) => BusinessProfileModel.generateFromAPI(item)) : [];
	};

	static generateEmpty = (): BusinessProfileModel => {
		return new BusinessProfileModel(
			BusinessProfileInfoModel.generateEmpty(),
			BusinessProfileFeatureModel.generateEmpty(),
			BusinessProfileSocialModel.generateEmpty(),
			BusinessProfileStatsModel.generateEmpty(),
			BusinessProfilePrivilegesModel.generateEmpty(),
		);
	};
}

export default BusinessProfileModel;
