import { PROD_URL } from "api/config";

const URL_LIST_PROFILES = `${PROD_URL}/profiles`;
const URL_CHECK_SLUG_PROFILE = (slug: string) => `${PROD_URL}/slugify?slug=${slug}`;
// GETS
const URL_GET_CHALLENGUES = (slug: string) => `${PROD_URL}/profiles/${slug}/challenges?order_direction=DESC`;
const URL_GET_INVITATION_FOR_CHALLENGUE_BY_PROFILE = (slug: string) =>
	`${PROD_URL}/profile/${slug}/challenges/invitations`;
const URL_GET_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
const URL_GET_PROFILE_BY_ID = (userId: string) => `${PROD_URL}/user-profiles/${userId}/id`;
const URL_GET_AVAILABLES_PROFILES = (profileId: string) => `${PROD_URL}/profiles/${profileId}/availabe-users`;
const URL_GET_PROFILE_BY_USER = `${PROD_URL}/profiles-by-user`;
const URL_GET_CURRENTLY_USER_LOGGED = `${PROD_URL}/users-profile-logged-in`;
// DELETE
const URL_DELETE_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
// POSTS
const URL_ADD_FOLLOWER_TO_PROFILE = (originId: string, followedId: string) =>
	`${PROD_URL}/profiles/${originId}/add-follower/${followedId}`;
const URL_REMOVE_FOLLOWER_TO_PROFILE = (originId: string, followedId: string) =>
	`${PROD_URL}/profiles/${originId}/remove-follower/${followedId}`;
const URL_POST_USER_PROFILE = `${PROD_URL}/users-profile`;
// PATCHS
const URL_PATCH_USER_PROFILE = (slug: string) => `${PROD_URL}/users-profile/${slug}`;

export {
	URL_LIST_PROFILES,
	URL_CHECK_SLUG_PROFILE,
	URL_GET_CHALLENGUES,
	URL_GET_INVITATION_FOR_CHALLENGUE_BY_PROFILE,
	URL_GET_PROFILE,
	URL_GET_PROFILE_BY_ID,
	URL_DELETE_PROFILE,
	URL_ADD_FOLLOWER_TO_PROFILE,
	URL_REMOVE_FOLLOWER_TO_PROFILE,
	URL_GET_AVAILABLES_PROFILES,
	URL_GET_PROFILE_BY_USER,
	URL_POST_USER_PROFILE,
	URL_PATCH_USER_PROFILE,
	URL_GET_CURRENTLY_USER_LOGGED,
};
