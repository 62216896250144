import TalentModel from "models/talents/Talent";
import { Reducer } from "redux";
import { Talent } from "redux-app/actions/TalentAction";
import { TalentState } from "./typing";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";

const initialState: TalentState = {
	talent: [],
	fetching: {
		loading: false,
		done: false,
		error: "",
		goBack: false,
	},
	fetchingDocument: {
		loading: false,
		done: false,
		error: "",
		goBack: false,
	},
};

const talentReducer: Reducer<TalentState> = (state: TalentState = initialState, action: Talent.TalentActionTypes) => {
	switch (action.type) {
		case Talent.TALENT_GET_BY_ID_STARTED:
		case Talent.TALENT_NEW_STARTED:
		case Talent.TALENT_UPDATE_STARTED:
		case Talent.TALENT_GET_TALENTS_PROFILE_STARTED:
		case Talent.TALENT_DELETE_STARTED:
			state = {
				...state,
				fetching: {
					loading: true,
					done: false,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_GET_BY_ID_FINISHED:
		case Talent.TALENT_GET_TALENTS_PROFILE_FINISHED:
			state = {
				...state,
				talent: action.payload,
				fetching: {
					loading: false,
					done: true,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_NEW_FINISHED:
			state = {
				...state,
				talent: action.payload,
				fetching: {
					loading: false,
					done: true,
					error: "",
					goBack: true,
				},
			};
			break;
		case Talent.TALENT_GET_BY_ID_FINISHED_WITH_ERRORS:
		case Talent.TALENT_NEW_FINISHED_WITH_ERRORS:
		case Talent.TALENT_UPDATE_FINISHED_WITH_ERRORS:
		case Talent.TALENT_GET_TALENTS_PROFILE_FINISHED_WITH_ERRORS:
		case Talent.TALENT_DELETE_FINISHED_WITH_ERRORS:
			state = {
				...state,
				fetching: {
					loading: false,
					done: false,
					error: action.payload,
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_UPDATE_FINISHED:
			let updatedTalentState = {
				...state.talent,
			};

			if (Array.isArray(updatedTalentState)) {
				updatedTalentState.forEach((item) => {
					if (item.id === action.payload.id) {
						item = action.payload;
					}
				});
			} else {
				updatedTalentState = action.payload;
			}

			state = {
				...state,
				talent: updatedTalentState,
				fetching: {
					loading: false,
					done: true,
					error: "",
					goBack: true,
				},
			};
			break;
		case Talent.TALENT_DELETE_FINISHED:
			let deletedTalentState = {
				...initialState.talent,
			};

			if (Array.isArray(state.talent)) {
				deletedTalentState = [...state.talent];
				deletedTalentState = deletedTalentState.filter((item: any) => item.id !== action.payload);
			}

			state = {
				...state,
				talent: deletedTalentState,
				fetching: {
					loading: false,
					done: true,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_ADD_APPLIER_STARTED:
			state = {
				...state,
				fetchingDocument: {
					loading: true,
					done: false,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_ADD_APPLIER_FINISHED:
			state = {
				...state,
				talent: {
					...state.talent,
					owner: {
						...((state.talent as TalentModel).owner as BusinessProfileModel),
					},
					visitorAlreadyApplied: true,
				},
				fetchingDocument: {
					loading: false,
					done: true,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_ADD_APPLIER_FINISHED_WITH_ERRORS:
			state = {
				...state,
				fetchingDocument: {
					loading: false,
					done: false,
					error: action.payload,
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_GET_APPLIERS_STARTED:
			state = {
				...state,
				fetching: {
					loading: true,
					done: false,
					error: "",
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_GET_APPLIERS_FINISHED:
			state = {
				...state,
				fetching: {
					loading: false,
					done: true,
					error: "",
					goBack: false,
				},
				talent: {
					...state.talent,
					appliers: action.payload,
				},
			};
			break;
		case Talent.TALENT_GET_APPLIERS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				fetching: {
					loading: false,
					done: false,
					error: action.payload,
					goBack: false,
				},
			};
			break;
		case Talent.TALENT_CHANGE_STATUS_APPLIER_FINISHED:
			const oldAppliers = (state.talent as TalentModel).appliers;

			oldAppliers.forEach((applier) => {
				if (applier.id == action.payload.applicationId) {
					applier.status = {
						...applier.status,
						...action.payload.status,
					};
				}
			});

			state = {
				...state,
				talent: {
					...state.talent,
					appliers: [...oldAppliers],
				},
			};
			break;
		case Talent.TALENT_RESET_STATE:
			state = {
				...initialState,
			};
			break;
	}

	return state;
};

export default talentReducer;
