export namespace NumberUtils {
	/**
     * Convert number to a shorter version, like for example:
     *  convertNumberToShorterNumber(12 , 1)          => 12
        convertNumberToShorterNumber(0 , 2)           => 0
        convertNumberToShorterNumber(1234 , 0)        => 1k
        convertNumberToShorterNumber(34567 , 2)       => 34.57k
        convertNumberToShorterNumber(918395 , 1)      => 918.4k
        convertNumberToShorterNumber(2134124 , 2)     => 2.13m
        convertNumberToShorterNumber(47475782130 , 2) => 47.48b)
     */
	export function convertNumberToShorterNumber(value: number): string {
		const thousand = 1000;
		const houndredThousand = 100000;
		const million = 1000000;
		const houndredMillion = 100000000;
		const billion = 1000000000;
		const trillion = 1000000000000;
		let stringValue = String(value);

		if (value >= houndredThousand && value <= 1000000) {
			stringValue = Math.round(value / thousand) + "K";
		}

		if (value >= houndredMillion && value <= billion) {
			stringValue = Math.round(value / million) + "M";
		}

		if (value >= billion && value <= trillion) {
			stringValue = Math.round(value / billion) + "B";
		}

		if (value >= trillion) {
			stringValue = Math.round(value / trillion) + "T";
		}

		return stringValue;
	}

	export const getDataFromRange = (range: string, key: string) => {
		const [min, max] = range.split("-");
		const dataResponse = {
			[`min_${key}`]: Number(min),
			[`max_${key}`]: Number(max),
		};

		Object.keys(dataResponse).forEach((key) => {
			if (
				dataResponse[key] === undefined ||
				Number.isNaN(dataResponse[key])
				//|| dataResponse[key] === 0
			) {
				delete dataResponse[key];
			}
		});

		return dataResponse;
	};
}
