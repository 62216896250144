import * as React from "react";
import Footer from "containers/Footer";

const withFooter = (ChildComponent: React.ComponentType<any | string>) => {
	// eslint-disable-next-line react/display-name
	return (childComponentProps: any) => {
		return (
			<>
				<div className={"WrapperFooter full-width full-height padding-3-bottom position-relative"}>
					<ChildComponent {...childComponentProps} />
					<Footer />
				</div>
			</>
		);
	};
};

export default withFooter;
