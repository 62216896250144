import { getSystemConstants } from "i18n";
import { I18N, TranslationData, TranslationDataUnit } from "i18n/typing";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import { CategoryType } from "api/CategoriesAPI/urls";

export interface TranslationService {
	getTranslations: () => I18N[];
	getFeatureTranslations: (dataUnits?: string[]) => TranslationData;
	getWorldTranslations: (dataUnits?: string[]) => TranslationData;
	getDatesTranslations: (dataUnits?: string[]) => TranslationData;
}

class TranslationsServiceImp implements TranslationService {
	static instance: TranslationsServiceImp;
	translations: I18N[] = [];

	constructor() {
		if (!TranslationsServiceImp.instance) {
			TranslationsServiceImp.instance = this;
			this.translations = getSystemConstants();
		}

		return TranslationsServiceImp.instance;
	}

	getTranslations = () => {
		return this.translations;
	};

	filterTranslationsByDataUnits = (data: TranslationData, dataUnits: string[]): TranslationData => {
		const filteredData: TranslationData = {};

		if (dataUnits) {
			for (const dataUnit of dataUnits) {
				if (data[dataUnit]) {
					filteredData[dataUnit] = data[dataUnit];
				}
			}
		}

		return filteredData;
	};

	getFeatureTranslations = (dataUnits?: string[]) => {
		const featuresTranslations = this.translations.find((item: I18N) => item.field === "features");
		let data = featuresTranslations!!.translations[0].data;

		if (dataUnits) {
			data = this.filterTranslationsByDataUnits(data, dataUnits);
		}

		return data;
	};

	getSplitFeatureSkillsTranslations = (): TranslationData => {
		return this.getFeatureTranslations([
			"accountantSkills",
			"businessSkills",
			"designAndProductoSkills",
			"engineeringAndArchitectureSkills",
			"investmentSkills",
			"itSkills",
			"legalSkills",
			"personalSkills",
			"salesAndMarketingSkills",
		]);
	};

	getFeatureSkillsTranslations = (): TranslationDataUnit => {
		const {
			accountantSkills,
			businessSkills,
			designAndProductoSkills,
			engineeringAndArchitectureSkills,
			investmentSkills,
			itSkills,
			legalSkills,
			personalSkills,
			salesAndMarketingSkills,
		} = this.getSplitFeatureSkillsTranslations();

		return {
			key: {},
			items: {
				...accountantSkills.items,
				...businessSkills.items,
				...designAndProductoSkills.items,
				...engineeringAndArchitectureSkills.items,
				...investmentSkills.items,
				...itSkills.items,
				...legalSkills.items,
				...personalSkills.items,
				...salesAndMarketingSkills.items,
			},
		};
	};

	getSplitFeatureAdviserSkillsTranslations = (): TranslationData => {
		return this.getFeatureTranslations([
			"categoriesBusiness",
			"categoriesSalesAndMarketing",
			"categoriesInvestment",
			"categoriesDesingAndProduct",
			"categoriesIT",
			"categoriesLegal",
			"categoriesAccountant",
			"categoriesEngineeringAndArchitecture",
		]);
	};

	getFeatureAdviserSkillsTranslations = (): TranslationDataUnit => {
		const {
			// For Advisers
			categoriesBusiness,
			categoriesSalesAndMarketing,
			categoriesInvestment,
			categoriesDesingAndProduct,
			categoriesIT,
			categoriesLegal,
			categoriesAccountant,
			categoriesEngineeringAndArchitecture,
		} = this.getSplitFeatureAdviserSkillsTranslations();

		return {
			key: {},
			items: {
				...categoriesBusiness.items,
				...categoriesSalesAndMarketing.items,
				...categoriesInvestment.items,
				...categoriesDesingAndProduct.items,
				...categoriesIT.items,
				...categoriesLegal.items,
				...categoriesAccountant.items,
				...categoriesEngineeringAndArchitecture.items,
			},
		};
	};

	getFeatureTranslationsForDropdown = (dataUnits?: string[]) => {
		const translations = this.getFeatureTranslations(dataUnits);

		return this.getTranslationsDataForDropdown(translations);
	};

	getWorldTranslations = (dataUnits?: string[]) => {
		const worldTranslations = this.translations.find((item: I18N) => item.field === "world");
		let data = worldTranslations!!.translations[0].data;

		if (dataUnits) {
			data = this.filterTranslationsByDataUnits(data, dataUnits);
		}

		return data;
	};

	getWorldTranslationsForDropdown = (dataUnits?: string[]) => {
		const translations = this.getWorldTranslations(dataUnits);

		return this.getTranslationsDataForDropdown(translations);
	};

	getDatesTranslations = (dataUnits?: string[]) => {
		const datesTranslations = this.translations.find((item: I18N) => item.field === "dates");

		let data = datesTranslations!!.translations[0].data;

		if (dataUnits) {
			data = this.filterTranslationsByDataUnits(data, dataUnits);
		}

		return data;
	};

	getDatesTranslationsForDropdown = (dataUnits?: string[]) => {
		const translations = this.getDatesTranslations(dataUnits);

		return this.getTranslationsDataForDropdown(translations);
	};

	getTranslationsDataForDropdown(translationData: TranslationData): {
		[key: string]: DropdownItemProps[];
	} {
		const data: { [key: string]: DropdownItemProps[] } = {};

		for (const key in translationData) {
			if (Object.prototype.hasOwnProperty.call(translationData, key)) {
				data[key] = this.getTranslationsDataUnitForDropdown(translationData[key]);
			}
		}

		return data;
	}

	getTranslationsDataUnitForDropdown(translationDataUnit: TranslationDataUnit): DropdownItemProps[] {
		const translationsForDropdown: DropdownItemProps[] = [];

		if (translationDataUnit && translationDataUnit.items) {
			for (const key in translationDataUnit.items) {
				if (Object.prototype.hasOwnProperty.call(translationDataUnit.items, key)) {
					translationsForDropdown.push({
						value: key,
						text: translationDataUnit.items[key],
					});
				}
			}
		}

		return translationsForDropdown.sort((dropdownItem, anotherDropdownItem) =>
			String(dropdownItem.text) > String(anotherDropdownItem.text) ? 1 : -1,
		);
	}

	getApplierStatusTranslations = (): { value: string; text: string }[] => {
		const categoryKeys = ["new", "interview", "extendedOffer", "hired", "filed"];

		const categoryIdsWithTranslation = categoryKeys.map((categoryKey) => {
			return CategoriesServiceSingleton.getList()
				.filterByCategoryType(CategoryType.APPLIER_STATUS)
				.find((category) => category.key == categoryKey);
		});

		return categoryIdsWithTranslation.map((categoryModel) => ({
			value: categoryModel?.key || "",
			text: categoryModel?.text || "",
		}));
	};

	getTendersApplierStatusTranslations = (): {
		value: string;
		text: string;
	}[] => {
		const applierStatusTranslations = this.getApplierStatusTranslations();
		const translations = this.getFeatureTranslations(["applierStatusForTender"]);
		const withinTheTeamTranslation = translations["applierStatusForTender"].items["withinTheTeam"];

		applierStatusTranslations.forEach((translation) => {
			if (translation.value === "hired") {
				translation.text = withinTheTeamTranslation;
			}
		});

		return applierStatusTranslations;
	};
}

const TranslationsServiceSingleton = new TranslationsServiceImp();
Object.freeze(TranslationsServiceSingleton);

export default TranslationsServiceSingleton;
