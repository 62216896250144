export const FeaturesCat = `
{
  "gender": {
    "key": {
      "singular": "Gènere",
      "plural": "Generes"
    },
    "items": {
      "male": "Home",
      "female": "Femení",
      "indeterminate": "Sense esmentar"
    }
  },
  "area": {
    "key": {
      "singular": "Àrea",
      "plural": "Àrees"
    },
    "items": {
      "tech": "Tecnologia",
      "business": "Negocis",
      "science": "Ciència"
    }
  },
  "personalSkills": {
    "key": {
      "singular": "Personal Skills",
      "plural": "Personal Skills"
    },
    "items": {
      "positiveAttitude": "Actitud positiva",
      "adaptationToChange": "Adaptació al canvi",
      "autonomy": "Autonomia",
      "resolutionCreativity": "Capacitat resolutiva",
      "creativity": "Creativitat",
      "curiosity": "Curiositat",
      "empathy": "Empatia",
      "activeListening": "Escolta activa",
      "focusAndVision": "Focus i visió",
      "integrity": "Integritat",
      "emotionalInteligence": "Intel·ligència emocional",
      "criticalThinking": "Pensament crític",
      "teamwork": "Treball en equip",
      "commitment": "Compromís",
      "flexibility": "Flexibilitat",
      "frustrationTolerance": "Tolerància a la frustració",
      "motivation": "Motivació",
      "negotiation": "Negociació",
      "patience": "Paciència",
      "persuasion": "Persuasió",
      "skillsToSolveProblems": "Habilitats per resoldre problemes",
      "timeManagement": "Gestió del temps",
      "workEthic": "Ètica de treball"
    }
  },
  "businessSkills": {
    "key": {
      "singular": "Negocis",
      "plural": "Negocis"
    },
    "items": {
      "communicationAndNegotiation": "Comunicació i negociació",
      "delegation": "Delegació",
      "financialManagement": "Gestió financera",
      "leadership": "Lideratge",
      "networking": "Treball en xarxa",
      "problemSolving": "Solucionar problemes"
    }
  },
  "salesAndMarketingSkills": {
    "key": {
      "singular": "Vendes i Màrqueting",
      "plural": "Vendes i Màrqueting"
    },
    "items": {
      "buyerSellerAgreement": "Acord comprador-venedor",
      "communication": "Comunicació",
      "demoSkills": "Habilitats de demostració",
      "productKnowledge": "Coneixement del producte",
      "qualificationQuestioning": "Preguntes de qualificació",
      "rapportBuilding": "Construcció de relacions",
      "strategicProspectingSkills": "Habilitats de prospecció estratègica"
    }
  },
  "investmentSkills": {
    "key": {
      "singular": "Investment",
      "plural": "Investments"
    },
    "items": {
      "confidence": "Confiança",
      "determination": "Determinació",
      "goodNumerical": "Bona numèrica",
      "selfMotivation": "Automotivació",
      "strongTime": "Gestió del temps",
      "teamworkingSkills": "Habilitats de treball en equip",
      "understandingOfFinancialMarkets": "Comprensió dels mercats financers",
      "workUnderPressure": "Treballar sota pressió"
    }
  },
  "designAndProductoSkills": {
    "key": {
      "singular": "Disseny i Producte",
      "plural": "Disseny i Producte"
    },
    "items": {
      "ABTesting": "Prova A / B",
      "benchmarking": "Benchmarking",
      "dataCollection": "Recopilació, extracció i anàlisi de dades",
      "fabrication": "Fabricació",
      "interactionDesing": "Interacció dissenyada",
      "learningAboutCode": "Aprenentatge del codi",
      "prototyping": "Prototips",
      "serviceDesign": "Disseny del servei",
      "visualDesign": "Disseny visual"
    }
  },
  "itSkills": {
    "key": {
      "singular": "IT",
      "plural": "IT"
    },
    "items": {
      "digitalCommunications": "Comunicacions digitals",
      "goalOriented": "Orientat a objectius",
      "informationAndCommunicationsTechnology": "Tecnologies de la informació i comunicacions",
      "manageRemoteWorkingTeams": "Gestiona equips de treball remot",
      "meetingDeadlines": "Terminis de la reunió",
      "multitasking": "Multitasca",
      "reviewProcessesForImprovement": "Revisar els processos de millora",
      "scheduling": "Planificació"
    }
  },
  "legalSkills": {
    "key": {
      "singular": "Legal",
      "plural": "Legal"
    },
    "items": {
      "analyticalAndLogicalReasoning": "Raonament analític i lògic",
      "clientService": "Servei al client",
      "legalProcedure": "Procediment legal",
      "legalResearch": "Recerca Jurídica",
      "oralCommunication": "Comunicació oral",
      "organization": "Organització",
      "technologySkills": "Habilitats tecnològiques",
      "writtenCommunication": "Comunicació escrita"
    }
  },
  "accountantSkills": {
    "key": {
      "singular": "Accountant",
      "plural": "Accountant"
    },
    "items": {
      "adaptability": "Adaptabilitat",
      "analyticalSkills": "Habilitats analítiques",
      "financialSkills": "Habilitats financeres",
      "generalBusinessSkills": "Habilitats empresarials generals",
      "industryKnowledge": "Coneixements d’indústria",
      "informationTechnologySkills": "Competències en tecnologia de la informació",
      "interpersonalCommunication": "Comunicació interpersonal",
      "organizationalSkills": "Habilitats organitzatives"
    }
  },
  "engineeringAndArchitectureSkills": {
    "key": {
      "singular": "Accountant",
      "plural": "Accountant"
    },
    "items": {
      "budgeting": "Pressupost",
      "calculations": "Càlculs",
      "computerScience": "Ciències de la Computació",
      "conceptualandLogical": "Lògic conceptual i conceptual",
      "convertingScaleFromBlueprints": "Conversió d'escala de models",
      "estimating": "Estimant",
      "nanotechnology": "Nanotecnologia",
      "physicalDataModeling": "Modelització de dades físiques",
      "processManagement": "Gestió de processos",
      "programmingLanguages": "Idiomes de programació",
      "specifications": "Especificacions",
      "statistics": "Estadístiques",
      "structuralAnalysis": "Anàlisi Estructural"
    }
  },
  "marketplaceType": {
    "key": {
      "singular": "Tipus d&#39;Marketplace",
      "plural": "Tipus de Marketplace"
    },
    "items": {
      "product": "Producte",
      "service": "Servei"
    }
  },
  "school": {
    "key": {
      "singular": "Facultat",
      "plural": "Facultats"
    },
    "items": {
      "agronomy": "Agronomia",
      "nutrition": "Nutricion",
      "architecture": "Arquitectura",
      "fineArts": "Belles Arts",
      "librarianship": "Bibliotecari",
      "biology": "Biologia",
      "biochemistry": "Bioquímica",
      "cinema": "Cinema",
      "earthScience": "Ciències Naturalez",
      "exactScience": "Ciències Exactes",
      "socialScience": "Ciències Socials",
      "law": "Lleis",
      "design": "Disseny",
      "documentation": "Documentació",
      "economyAndCompany": "Administracion d&#39;Empreses",
      "pharmacy": "Farmacologia",
      "philology": "Filologia",
      "philosofy": "Filosofia",
      "phisyc": "Física",
      "geography": "Geografia",
      "history": "Història",
      "it": "Desenvolupament IT",
      "engineer": "Enginyeria",
      "math": "Matematica",
      "medicine": "Medicina",
      "information_and_audiovisual_media": "Mitjans d'informació i audiovisuals",
      "psicology": "Psicologia",
      "chemistry": "Quimica",
      "health": "Salut",
      "townPlanning": "Urbanisme",
      "veterinary": "Veterinària"
    }
  },
  "topic": {
    "key": {
      "singular": "Topic",
      "plural": "Topics"
    },
    "items": {
      "education": "Educació",
      "mobile": "Mòbil",
      "personalizedMedicine": "Medicina personalitzada",
      "syntheticBiology": "Biologia sintètica",
      "government": "Govern",
      "foodAndDrinks": "Aliments i begudes",
      "travelToTheSpace": "Viatjar a l'espai",
      "agronomy": "Agronomia",
      "healthServices": "Serveis de salut",
      "internetWebservices": "Internet / Serveis Web",
      "legal": "Legal",
      "cattleRaising": "Ramaderia",
      "cleanEnergy": "Energia neta",
      "robotics": "Robòtica",
      "water": "Dessalinització d'l'aigua",
      "cloudEducation": "Educació en el núvol",
      "lifestyle": "Estil de vida",
      "mediaAndEntertainment": "Mitjans de comunicació i entreteniment",
      "cleanTransportation": "Transport net",
      "oilAndGas": "Petroli i gas",
      "dataStorage": "Emmagatzematge de dades",
      "transport": "Transport",
      "construction": "Construcció",
      "sports": "Esports",
      "trip": "Viatge",
      "retailSale": "Venda minorista",
      "brainaging": "Envelliment de el cervell",
      "virtualReality": "Realitat virtual",
      "aerospace": "Aeroespacial",
      "3dPrint": "Impressió 3d",
      "blockChain": "Blockchain",
      "electronicsInstrumentation": "Electrònica / Instrumentació",
      "itServices": "Serveis d'informàtica",
      "fintech": "Fintech",
      "artificialIntelligence": "Intel·ligència artificial",
      "syntheticMaterials": "Materials sintètics",
      "financialServices": "Serveis financers",
      "pc": "PC",
      "smartThings": "Smartthings",
      "augmentEdreality": "Realitat augmentada",
      "brainConnectomy": "Conectoma cerebral",
      "logistics": "Logística",
      "smartCities": "Ciutats intel·ligents",
      "fishing": "Pesca",
      "biotechnology": "Biotecnologia",
      "digitalAdvertising": "Publicitat digital",
      "others": "Altres",
      "smartphonesWithContactlenses": "Smartphones amb lents de contacte",
      "artificialFood": "Menjar artificial",
      "foreignTrade": "Comerç Exterior",
      "customerService": "Servei a l'Client",
      "nanoTechnology": "Nanotecnologia",
      "fashion": "Moda",
      "naturalUserInterfaces": "Interfícies d'usuari naturals",
      "realEstate": "Béns arrels",
      "software": "Programari",
      "telecommunications": "Telecomunicacions",
      "geneSequencing": "Seqüenciació de gens",
      "computersAndPeripherals": "Ordinadors i perifèrics",
      "marketingAds": "Anuncis de màrqueting",
      "gaming": "Jocs",
      "bigData": "Big data",
      "chemicalsAndProductChemicals": "Productes químics i químics",
      "commerce": "Comerç",
      "electronicsDevices": "Dispositius electrònics",
      "networksAndEquipment": "Xarxes i equipament",
      "quantumComputing": "Computació quàntica",
      "drones": "Drones",
      "cybersecurity": "La seguretat cibernètica",
      "iot": "IOT",
      "climateEngineering": "Enginyeria climàtica",
      "medicalDevicesAndEquipment": "Dispositius i equips mèdics",
      "consumerProducts": "Productes de consum",
      "productsForCompanies": "Productes per a empreses",
      "mining": "Mineria",
      "smartCar": "Acte intel·ligent"
    }
  },
  "careerType": {
    "key": {
      "singular": "Tipus de Carrera",
      "plural": "Tipus de Carreres"
    },
    "items": {
      "course": "Curs",
      "degree": "Grau",
      "master": "Màster"
    }
  },
  "careerModality": {
    "key": {
      "singular": "Modalitat de Carrera",
      "plural": "Modalitats de Carreres"
    },
    "items": {
      "online": "En línia",
      "presential": "Presencial",
      "semiPresential": "Semi-Presencial"
    }
  },
  "adviserSkills": {
    "key": {
      "singular": "Habilitat del Adviser",
      "plural": "Habilitats del Adviser"
    },
    "items": {
      "careerAdvise": "Conseller de Carrera",
      "financialConsulting": "Conseller Financer",
      "businessDevelopment": "Desenvolupament de Negocis",
      "legal": "Legals",
      "socialMedia": "Xarxes Socials",
      "branding": "Marca",
      "salesAnd_lead": "Lideratge i Vendes",
      "advertising": "Publicitat",
      "crowfounding": "Crowfounding",
      "ventureCapital": "Capital de Risc"
    }
  },
  "market": {
    "key": {
      "singular": "Mercat",
      "plural": "Mercats"
    },
    "items": {
      "aerospace": "Aeroespacial",
      "agriculture": "Agricultura",
      "retailer": "Al detall",
      "foodAndDrink": "Menjars i Begudes",
      "marketing": "Màrqueting",
      "realState": "Immobiliari",
      "biotechnology": "Biotecnologia",
      "computers": "Ordinadors",
      "construction": "Construcció",
      "sports": "Esports",
      "medicalDevices": "Dispositius Metges",
      "education": "Educacio",
      "electronic": "Electrònica",
      "lifestyle": "Estil de Vida",
      "fintech": "Tecnologies Financeres",
      "industryAndEnergy": "Indústria i Energia",
      "internet": "Internet",
      "insurtech": "Insurtech",
      "gameOfChance": "Jocs d&#39;Atzar",
      "legal": "Legal",
      "logistic": "Logística",
      "entertainmentAndMedia": "Entreteniment",
      "mining": "Mineria",
      "moda": "Moda",
      "movil": "Mòbil",
      "nanotechnology": "Nanotecnologia",
      "others": "Altres",
      "oilAndGas": "Petroli i Gas",
      "consumableProducts": "Productes consumibles",
      "companyProducts": "Companyia de Producte",
      "chemistryProducts": "Productes Químics",
      "chemistry": "Quimica",
      "networkAndEquipment": "Xarxes i Equipament",
      "robotic": "Robotica",
      "security": "Seguretat",
      "clientService": "Servei al Client",
      "health": "Salut",
      "finance": "Finances",
      "it": "Desenvolupament IT",
      "software": "Programari",
      "cleanTechnology": "Tecnologies Netes",
      "telecomunication": "Telecomunicacion",
      "transport": "Transport",
      "trips": "Viatges",
      "videogames": "Videojocs"
    }
  },
  "roles": {
    "key:": {
      "singlular": "Rol",
      "plural": "Roles"
    },
    "items": {
      "CEO": "CEO",
      "COO": "COO",
      "CTO": "CTO",
      "CDO": "CDO",
      "CIO": "CIO",
      "CFO": "CFO",
      "CMO": "CMO",
      "CCO": "CCO",
      "CSO": "CSO",
      "CLO": "CLO",
      "director": "Director",
      "manager": "Gerent",
      "chief": "Cap",
      "delegate": "Delegat",
      "coordinator": "Coordinador",
      "senior": "Sènior",
      "semiSenior": "Semi sènior",
      "junior": "Junior",
      "operative": "Operatiu",
      "assistant": "Auxiliar",
      "intern": "Passant",
      "scholar": "Becari"
    }
  },
  "categoriesBusiness": {
    "key": {
      "singular": "Negocis",
      "plural": "Negocis"
    },
    "items": {
      "businessCoaching": "Business Coaching",
      "businessPlan": "Business Pla",
      "canvas": "Canvas",
      "financialQuery": "Consulta financera",
      "businessDevelopment": "Desenvolupament de negoci",
      "strategy": "Estratègia",
      "executiveCoach": "Executive Coach",
      "legal": "Legal",
      "brandAndPatents": "Marca i patents",
      "humanResources": "Recursos humans"
    }
  },
  "categoriesSalesAndMarketing": {
    "key": {
      "singular": "Vendes i Màrqueting",
      "plural": "Vendes i Màrqueting"
    },
    "items": {
      "commercialCoaching": "Coaching Comercial",
      "emailMarketing": "Email Marketing",
      "commercialStrategy": "Estratègia comercial",
      "inboundMarketing": "Inbound Marketing",
      "leadsAndSales": "Leads & Vendes",
      "branding": "Marca",
      "others": "Altres",
      "publishing": "Publicació",
      "advertising": "Publicitat",
      "copywriting": "Redacció",
      "publicRelations": "Relacions públiques",
      "socialMedia": "Social media",
      "translations": "Traduccions"
    }
  },
  "categoriesInvestment": {
    "key": {
      "singular": "Inversió",
      "plural": "Inversió"
    },
    "items": {
      "accelerator": "Acceleradora",
      "businessAngel": "Business Angel",
      "ventureCapital": "Capital de risc",
      "crowdfunding": "Crowdfunding",
      "incubator": "Incubadora",
      "investmentNegotiation": "Negociació amb Inversors",
      "nonprofit": "Sense ànim de lucre"
    }
  },
  "categoriesDesingAndProduct": {
    "key": {
      "singular": "Disseny i Producte",
      "plural": "Disseny i Producte"
    },
    "items": {
      "agile": "Agile",
      "identity": "Identitat",
      "learnStartup": "Lean Startup",
      "metricAndAnalytics": "Mètrica i Analítica",
      "mvp": "MVP",
      "other": "Altre",
      "productManager": "Product Management",
      "projectManager": "Project Management",
      "scrum": "Scrum",
      "userExperience": "Experiència d'usuari"
    }
  },
  "categoriesIT": {
    "key": {
      "singular": "IT",
      "plural": "IT"
    },
    "items": {
      "itDesing": "Disseny IT",
      "erp": "ERP",
      "leanLogistics": "Lean Logistics",
      "leanManagement": "Lean Management",
      "mobile": "Mobile",
      "reviewAndDiagnosis": "Revisió i Diagnòstic",
      "digitalTransformation": "Transformació Digital",
      "web": "Web",
      "Wordpress": "Wordpress"
    }
  },
  "categoriesLegal": {
    "key": {
      "singular": "Legal",
      "plural": "Legals"
    },
    "items": {
      "nda": "Acord de confidencialitat",
      "database": "Base de dades",
      "compliance": "Compliance",
      "softwareDevelopmentContract": "Contracte desenvolupament software",
      "intellectualPropertyContract": "Contracte propietat intel·lectual",
      "seedContract": "Contracte llavor",
      "lopd": "LOPD",
      "softwareRegistration": "Registre de programari",
      "termsAndConditions": "Termes i condicions",
      "vehicleSAAS": "Vehicle S.A.S"
    }
  },
  "categoriesAccountant": {
    "key": {
      "singular": "Comptable",
      "plural": "Comptable"
    },
    "items": {
      "accounting": "Comptabilitat",
      "dueDiligence": "Due Diligence",
      "taxationAndTaxes": "Fiscalitat i impostos",
      "managementOfPayroll": "Gestió de nòmines",
      "acquisitionOperations": "Operacions d'adquisició",
      "phantomShares": "Phantom Shares",
      "socialSecurity": "Seguretat Social",
      "stockOptions": "Stock Options"
    }
  },
  "categoriesEngineeringAndArchitecture": {
    "key": {
      "singular": "Enginyeria i Arquitectura",
      "plural": "Enginyeria i Arquitectura"
    },
    "items": {
      "analysisOfTheSupplyChain": "Anàlisi de la Cadena de Subministraments",
      "audits": "Auditories",
      "quality": "Qualitat",
      "viabilityStudy": "Estudi de viabilitat",
      "legalEngineeringAndIndustrialSafety": "Enginyeria legal i seguretat industrial",
      "masterplan": "Masterplan",
      "enviroment": "Medi ambient",
      "costOptimization": "Optimització de Costos",
      "industrialPlanning": "Planificació industrial",
      "supplyChainAndLogistics": "Supply Chain i Logística"
    }
  },
  "categoriesMedicineAndPsychology": {
    "key": {
      "singular": "Medicina y Psicologia",
      "plural": "Medicina y Psicologia"
    },
    "items": {
      "accreditWorks": "Acreditar treballs",
      "applyAndInterpretAssessments": "Aplicar i interpretar avaluacions",
      "clinicalTrials": "Assaigs clínics",
      "co-reportStudies": "Co-informar estudis",
      "developTreatmentsAndPlans": "Desenvolupar tractaments i plans",
      "directMedicalProjects": "Dirigir projectes mèdics",
      "documentTheInformation": "Documentar la informació",
      "evaluateTheResults": "Avaluar els resultats",
      "evaluateTrials": "Avaluar assajos",
      "gatherInformation": "Recopilar informació",
      "investigate": "Investigar",
      "keyDecisionMaker": "Prenedor de decisions claus",
      "meetingCoordinator": "Coordinador de juntes",
      "presentScientificInformation": "Presentar informació científica",
      "supervise": "Supervisar",
      "toDiagnose": "Diagnosticar"
    }
  },
  "talentType": {
    "key": {
      "singular": "Tipus de Talents",
      "plural": "Tipus de Talents"
    },
    "items": {
      "employee": "Ocupació",
      "co-founder": "Cofundador",
      "advisor": "Assessor"
    }
  },
  "applierStatus": {
  	"key": {
      "singular": "Estat del procés",
      "plural": "Eestat del procés"
    },
    "items": {
      "new": "Nou",
      "interview": "Entrevista",
      "extendedOffer": "Oferta estesa",
      "hired": "Contractat",
      "filed": "Classificat"
    }
  },
  "applierStatusForTender": {
  	"key": {
      "singular": "Estat del procés",
      "plural": "Eestat del procés"
    },
    "items": {
      "new": "Nou",
      "interview": "Entrevista",
      "extendedOffer": "Oferta estesa",
      "withinTheTeam": "Dins l'equip",
      "filed": "Classificat"
    }
  }
}

`;
