// @ts-nocheck
import { MicroBusinessProfileTypes } from "constants/Profiles";
import { Image } from "elements/HeroImage/typing";
import { Profile } from "models/profiles/Profile";
import { ProfileSocial } from "models/profiles/ProfileSocial";
import { Request } from "models/requests/Request";

export enum ProfileStyle {
	AdviserProfile = "AdviserProfile",
	CommonProfile = "CommonProfile",
	BussinessAngelProfile = "BussinessAngelProfile",
}

export interface GenericProfile {
	style: ProfileStyle;
	myProfile: boolean;
}

export interface LinkProfilePropsState {
	link: string;
	isValid?: boolean;
	checking?: boolean;
	getting?: boolean;
}

export interface UnitBusinessProfile {
	name: string;
	slug: string;
	type: MicroBusinessProfileTypes;
	image: Image;
}

export interface BusinessProfileByProfileState {
	profiles: UnitBusinessProfile[];
	loading: boolean;
	done: boolean;
	error: string;
}

export interface SocialProfilePropsState extends ProfileSocial {
	[key: string]: string | Request;
}

export interface ErrorsProfilePropsState {
	onboardingFailed: string;
	onboardingSucceded: boolean;
	getProfileFailed: string;
}

export interface UserState {
	profile: Profile;
}
