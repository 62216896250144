import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { CoursePayload } from "./types";

type UpdateCoursePayload = {
	courseId: string;
	data: CoursePayload;
};

function updateCourse(payload: UpdateCoursePayload, config: AxiosRequestConfig) {
	return axios.patch(`${PROD_URL}/courses/${payload.courseId}`, payload.data, config);
}

export const useUpdateCourseMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, UpdateCoursePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: UpdateCoursePayload) => updateCourse(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
