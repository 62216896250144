export const DatesCat = `
{
    "days": {
      "key": {
            "singular": "dia",
            "plural": "dies"
      },
      "items": {
            "monday": "dilluns",
            "tuesday": "dimarts",
            "wednesday": "dimecres",
            "thursday": "dijous",
            "friday": "divendres",
            "saturday": "dissabte",
            "sunday": "diumenge"
      }
    },
    "months": {
      "key": {
            "singular": "Mes",
            "plural": "mesos"
      },
      "items": {
            "january": "gener",
            "february": "febrer",
            "march": "març",
            "april": "abril",
            "may": "maig",
            "june": "juny",
            "july": "juliol",
            "august": "agost",
            "september": "setembre",
            "october": "octubre",
            "november": "novembre",
            "december": "desembre"
      }
    }
}
`;
