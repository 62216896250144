import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";
import { getRequestConfig } from "@api";

import { PROD_URL } from "../config";

import { Talent } from "./types";

const fetchTalent = (talentSlug: string, accessToken: string) => {
	return function (): Promise<Talent> {
		return axios
			.get(`${PROD_URL}/talents/${talentSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
};

export const useTalentQuery = (talentSlug: string) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Talent>(["talent", talentSlug], fetchTalent(talentSlug, accessToken));
};
