import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "../config";

import { UserSignup } from "./types";
import { CookiesUtils } from "@utils";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

interface UserSignUpPayload {
	first_name: string;
	second_last_name: string;
	last_name: string;
	email: string;
	password: string;
	repeat_password: string;
	provider: string;
	phone_number_country_code: string;
	phone_number: string;
	registration_key: string;
}

type UserSignUpShortPayload = Pick<UserSignUpPayload, "first_name" | "last_name" | "email" | "password">;

function signUserUp(data: UserSignUpPayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/register`, data, config).then((response) => {
		return response.data;
	});
}

export const useSignUserUpMutation = ({
	onSuccess,
	...options
}: UseMutationOptions<UserSignup, AxiosError, UserSignUpShortPayload> = {}) => {
	return useMutation(
		(data: UserSignUpShortPayload) => {
			const fullSignupData = {
				first_name: data.first_name,
				last_name: data.last_name,
				email: data.email,
				password: data.password,
				repeat_password: data.password,
				provider: "VINGLET",
				second_last_name: "",
				phone_number_country_code: "",
				phone_number: "",
				registration_key: "",
			};

			return signUserUp(fullSignupData, getRequestConfig());
		},
		{
			...options,
			onSuccess: (data, values, context) => {
				CookiesUtils.setCookieForever("accessToken", data.authToken);

				onSuccess && onSuccess(data, values, context);
			},
		},
	);
};
