import {CookiesUtils} from "@utils";

import {IS_IT_PROD} from "@api";

export const useAnalytics = () => {
    const getMixPanelInstance = async () => {
        const MixPanel = await import("mixpanel-browser");

        MixPanel.init(String(process.env.NEXT_PUBLIC_TRACKING_MIX_PANEL));

        return MixPanel;
    };

    const trackActionWithData = async (action: string, data: any) => {
        try {
            const MixPanel = await getMixPanelInstance();
            const connectedProfileId = CookiesUtils.getCookie("idProfile");

            MixPanel.identify(connectedProfileId ? connectedProfileId : "Unknown");

            const dataToTrack = {
                ...data,
                _LANGUAGE: CookiesUtils.getCookie("lang") || "Unknown",
                _ID_PROFILE: connectedProfileId ? connectedProfileId : "Unknown",
            };

            if (!IS_IT_PROD) {
                return;
            }

            MixPanel.track(action, dataToTrack);
        } catch {
        }
    };

    const pageView = async (path: string, data: any = {}) => {
        await trackActionWithData(path, data);
    };

    const event = async (action: string, data: any = {}) => {
        await trackActionWithData(action, data);
    };

    return {
        pageView,
        event,
    };
};
