import CategoryModel from "models/categories/Category";
import RequestModel, { Request } from "models/requests/Request";
import StudyModel, { Study } from "models/studies/Study";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { DateUtils } from "@utils";

export interface ProfileStudy {
	items: StudyModel[];
	fetching?: Request;
}

class ProfileStudyModel implements ProfileStudy {
	items: Study[];
	fetching?: Request;

	constructor(items: StudyModel[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const categoriesServices = CategoriesServiceSingleton.getList();
		const studies: StudyModel[] =
			data.educations &&
			data.educations.map((education: any) => {
				const { school: schoolsTranslations } = TranslationsServiceSingleton.getFeatureTranslations(["school"]);
				const selectedSchool = categoriesServices.filterById(Number(education.schools)) as CategoryModel;

				if (selectedSchool && schoolsTranslations.items[selectedSchool.key]) {
					selectedSchool.setText(schoolsTranslations.items[selectedSchool.key]);
				}

				return new StudyModel(
					education.id,
					DateUtils.getEpochFromAPIFormat(education.date_from),
					DateUtils.getEpochFromAPIFormat(education.date_to),
					education.description,
					education.institution,
					education.name,
					education.now,
					selectedSchool,
				);
			});

		return new ProfileStudyModel(studies, RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileStudyModel([], RequestModel.requestLoading());
	};

	exportToAPIRequest = () => {
		return this.items.map((item) => ({
			name: item.name,
			institution: item.institution,
			schools: item.school.id,
			description: item.description,
			date_from: DateUtils.getTimeAPIFormat(item.dateFrom),
			date_to: DateUtils.getTimeAPIFormat(item.dateTo),
			now: item.now,
		}));
	};
}

export default ProfileStudyModel;
