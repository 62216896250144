import AwardsModel from "models/awards/Award";
import ExperienceModel from "models/experiences/Experience";
import ProfileModel from "models/profiles/Profile";
import ProfileAboutModel from "models/profiles/ProfileAbout";
import ProfileInfoModel from "models/profiles/ProfileInfo";
import ProfileOverviewModel from "models/profiles/ProfileOverview";
import { ProfileSkills } from "models/profiles/ProfileSkills";
import ProfileSocialModel from "models/profiles/ProfileSocial";
import RecommendationModel from "models/recommendations/Recommendation";
import SkillModel from "models/skills/Skill";
import StudyModel from "models/studies/Study";
import * as UserReducer from "redux-app/reducers/UserReducer/typing";

export namespace User {
	export const USER_SET_PROFILE = "USER_SET_PROFILE";
	export const USER_SET_PROFILE_STARTED = "USER_SET_PROFILE_STARTED";
	export const USER_SET_PROFILE_FINISH = "USER_SET_PROFILE_FINISH";
	export const USER_SET_PROFILE_FINISH_WITH_ERRORS = "USER_SET_PROFILE_FINISH_WITH_ERRORS";

	export const USER_SET_ABOUT = "USER_SET_ABOUT";
	export const USER_SET_ADVISER_SKILLS = "USER_SET_ADVISER_SKILLS";
	export const USER_SET_AWARDS = "USER_SET_AWARDS";
	export const USER_SET_EXPERIENCES = "USER_SET_EXPERIENCES";
	export const USER_SET_INFO = "USER_SET_INFO";
	export const USER_SET_LINKS = "USER_SET_LINKS";
	export const USER_SET_OVERVIEW = "USER_SET_OVERVIEW";
	export const USER_SET_RECOMMENDATIONS = "USER_SET_RECOMMENDATIONS";
	export const USER_SET_SKILLS = "USER_SET_SKILLS";
	export const USER_SET_SOCIAL = "USER_SET_SOCIAL";
	export const USER_SET_STUDIES = "USER_SET_STUDIES";

	export const USER_SET_LINK = "USER_SET_LINK";
	export const USER_CHECK_LINKS = "USER_CHECK_LINKS";
	export const USER_CHECK_LINKS_STATUS = "USER_CHECK_LINKS_STATUS";
	export const USER_CHECK_LINKS_FAILED = "USER_CHECK_LINKS_FAILED";
	export const USER_CHECK_LINKS_SUCCEDED = "USER_CHECK_LINKS_SUCCEDED";

	export const USER_FETCH_ONBOARDING = "USER_FETCH_ONBOARDING";
	export const USER_FETCH_ONBOARDING_FAILED = "USER_FETCH_ONBOARDING_FAILED";
	export const USER_FETCH_ONBOARDING_SUCCEDED = "USER_FETCH_ONBOARDING_SUCCEDED";
	export const USER_UPDATE = "USER_UPDATE";
	export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
	export const USER_UPDATE_SUCCEDED = "USER_UPDATE_SUCCEDED";

	export const USER_GET_PROFILE = "USER_GET_PROFILE";
	export const USER_RECEIVE_PROFILE_FAILED = "USER_RECEIVE_PROFILE_FAILED";
	export const USER_RECEIVE_PROFILE_SUCCEDED = "USER_RECEIVE_PROFILE_SUCCEDED";

	export const USER_GET_PROFILES_BY_USER = "USER_GET_PROFILES_BY_USER";
	export const USER_GET_PROFILES_BY_USER_STARTED = "USER_GET_PROFILES_BY_USER_STARTED";
	export const USER_GET_PROFILES_BY_USER_FINISHED = "USER_GET_PROFILES_BY_USER_FINISHED";
	export const USER_GET_PROFILES_BY_USER_FINISHED_WITH_ERRORS = "USER_GET_PROFILES_BY_USER_FINISHED_WITH_ERRORS";

	export const USER_RECEIVE_DATA_API = "USER_RECEIVE_DATA_API";
	export const USER_LOADING = "USER_LOADING";

	interface UserSetProfileAction {
		type: typeof USER_SET_PROFILE;
		payload: ProfileModel;
	}

	interface UserSetProfileStartedAction {
		type: typeof USER_SET_PROFILE_STARTED;
		payload: ProfileModel;
	}

	interface UserSetProfileFinishAction {
		type: typeof USER_SET_PROFILE_FINISH;
		payload: ProfileModel;
	}

	interface UserSetProfileFinishWithErrorsAction {
		type: typeof USER_SET_PROFILE_FINISH_WITH_ERRORS;
		payload: ProfileModel;
	}

	interface UserSetAboutAction {
		type: typeof USER_SET_ABOUT;
		payload: ProfileAboutModel;
	}

	interface UserSetAdviserSkillsAction {
		type: typeof USER_SET_ADVISER_SKILLS;
		payload: ProfileSkills;
	}

	interface UserSetAwardAction {
		type: typeof USER_SET_AWARDS;
		payload: AwardsModel[];
	}

	interface UserSetExperiencesAction {
		type: typeof USER_SET_EXPERIENCES;
		payload: ExperienceModel[];
	}

	interface UserSetInfoAction {
		type: typeof USER_SET_INFO;
		payload: ProfileInfoModel;
	}

	interface UserSetLinkAction {
		type: typeof USER_SET_LINKS;
		payload: UserReducer.LinkProfilePropsState;
	}

	interface UserSetUniqueLinkAction {
		type: typeof USER_SET_LINK;
		payload: string;
	}

	interface UserCheckLinkAction {
		type: typeof USER_CHECK_LINKS;
		payload: string;
	}

	interface UserCheckLinkStatusAction {
		type: typeof USER_CHECK_LINKS_STATUS;
		payload: boolean;
	}

	interface UserCheckLinkFailedAction {
		type: typeof USER_CHECK_LINKS_FAILED;
	}

	interface UserCheckLinkSuccededAction {
		type: typeof USER_CHECK_LINKS_SUCCEDED;
	}

	interface UserSetOverviewAction {
		type: typeof USER_SET_OVERVIEW;
		payload: ProfileOverviewModel;
	}

	interface UserSetRecommendationsAction {
		type: typeof USER_SET_RECOMMENDATIONS;
		payload: RecommendationModel[];
	}

	interface UserSetSkillsAction {
		type: typeof USER_SET_SKILLS;
		payload: SkillModel[];
	}

	interface UserSetSocialAction {
		type: typeof USER_SET_SOCIAL;
		payload: ProfileSocialModel;
	}

	interface UserSetStudiesAction {
		type: typeof USER_SET_STUDIES;
		payload: StudyModel[];
	}

	interface UserGetProfileAction {
		type: typeof USER_GET_PROFILE;
		payload: string;
	}

	interface UserReceiveProfileFailedAction {
		type: typeof USER_RECEIVE_PROFILE_FAILED;
		payload: string;
	}

	interface UserReceiveProfileSuccededAction {
		type: typeof USER_RECEIVE_PROFILE_SUCCEDED;
		payload: ProfileModel;
	}

	interface UserFetchOnboardingAction {
		type: typeof USER_FETCH_ONBOARDING;
	}

	interface UserFetchOnboardingFailedAction {
		type: typeof USER_FETCH_ONBOARDING_FAILED;
		payload: string;
	}

	interface UserFetchOnboardingSuccededAction {
		type: typeof USER_FETCH_ONBOARDING_SUCCEDED;
	}

	interface UserGetProfilesByUserAction {
		type: typeof USER_GET_PROFILES_BY_USER;
	}

	interface UserGetProfilesByUserStartedAction {
		type: typeof USER_GET_PROFILES_BY_USER_STARTED;
	}

	interface UserGetProfilesByUserFinishedAction {
		type: typeof USER_GET_PROFILES_BY_USER_FINISHED;
		payload: UserReducer.UnitBusinessProfile[];
	}

	interface UserGetProfilesByUserFinishedWithErrorsAction {
		type: typeof USER_GET_PROFILES_BY_USER_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface UserReceiveDataApiAction {
		type: typeof USER_RECEIVE_DATA_API;
		payload: ProfileModel;
	}

	interface UserLoading {
		type: typeof USER_LOADING;
		payload: boolean;
	}

	export type UserActionTypes =
		| UserSetProfileAction
		| UserSetProfileStartedAction
		| UserSetProfileFinishAction
		| UserSetProfileFinishWithErrorsAction
		| UserSetAboutAction
		| UserSetAdviserSkillsAction
		| UserSetAwardAction
		| UserSetExperiencesAction
		| UserSetInfoAction
		| UserSetLinkAction
		| UserSetUniqueLinkAction
		| UserCheckLinkAction
		| UserCheckLinkStatusAction
		| UserCheckLinkFailedAction
		| UserCheckLinkSuccededAction
		| UserSetOverviewAction
		| UserSetRecommendationsAction
		| UserSetSkillsAction
		| UserSetSocialAction
		| UserSetStudiesAction
		| UserGetProfileAction
		| UserReceiveProfileFailedAction
		| UserReceiveProfileSuccededAction
		| UserFetchOnboardingAction
		| UserFetchOnboardingFailedAction
		| UserFetchOnboardingSuccededAction
		| UserGetProfilesByUserAction
		| UserGetProfilesByUserStartedAction
		| UserGetProfilesByUserFinishedAction
		| UserGetProfilesByUserFinishedWithErrorsAction
		| UserReceiveDataApiAction
		| UserLoading;

	export function setProfile(profile: ProfileModel): UserSetProfileAction {
		return {
			type: USER_SET_PROFILE,
			payload: profile,
		};
	}

	export function setProfileStarted(profile: ProfileModel): UserSetProfileStartedAction {
		return {
			type: USER_SET_PROFILE_STARTED,
			payload: profile,
		};
	}

	export function setProfileFinish(profile: ProfileModel): UserSetProfileFinishAction {
		return {
			type: USER_SET_PROFILE_FINISH,
			payload: profile,
		};
	}

	export function setProfileFinishWithErrors(profile: ProfileModel): UserSetProfileFinishWithErrorsAction {
		return {
			type: USER_SET_PROFILE_FINISH_WITH_ERRORS,
			payload: profile,
		};
	}

	export function setAbout(profile: ProfileAboutModel): UserSetAboutAction {
		return {
			type: USER_SET_ABOUT,
			payload: profile,
		};
	}

	export function setAwards(awards: AwardsModel[]): UserSetAwardAction {
		return {
			type: USER_SET_AWARDS,
			payload: awards,
		};
	}

	export function setExperiences(experiences: ExperienceModel[]): UserSetExperiencesAction {
		return {
			type: USER_SET_EXPERIENCES,
			payload: experiences,
		};
	}

	export function setInfo(newInfo: ProfileInfoModel): UserSetInfoAction {
		return {
			type: USER_SET_INFO,
			payload: newInfo,
		};
	}

	export function setLink(newLink: UserReducer.LinkProfilePropsState): UserSetLinkAction {
		return {
			type: USER_SET_LINKS,
			payload: newLink,
		};
	}

	export function setUniqueLink(newLink: string): UserSetUniqueLinkAction {
		return {
			type: USER_SET_LINK,
			payload: newLink,
		};
	}

	export function checkLink(link: string): UserCheckLinkAction {
		return {
			type: USER_CHECK_LINKS,
			payload: link,
		};
	}

	export function checkLinkStatus(status: boolean): UserCheckLinkStatusAction {
		return {
			type: USER_CHECK_LINKS_STATUS,
			payload: status,
		};
	}

	export function checkLinkFailed(): UserCheckLinkFailedAction {
		return {
			type: USER_CHECK_LINKS_FAILED,
		};
	}

	export function checkLinkSucceded(): UserCheckLinkSuccededAction {
		return {
			type: USER_CHECK_LINKS_SUCCEDED,
		};
	}

	export function setOverview(newOverview: ProfileOverviewModel): UserSetOverviewAction {
		return {
			type: USER_SET_OVERVIEW,
			payload: newOverview,
		};
	}

	export function setRecommendations(newRecommendations: RecommendationModel[]): UserSetRecommendationsAction {
		return {
			type: USER_SET_RECOMMENDATIONS,
			payload: newRecommendations,
		};
	}

	export function setSkills(newSkills: SkillModel[]): UserSetSkillsAction {
		return {
			type: USER_SET_SKILLS,
			payload: newSkills,
		};
	}

	export function setSocial(newSocial: ProfileSocialModel): UserSetSocialAction {
		return {
			type: USER_SET_SOCIAL,
			payload: newSocial,
		};
	}

	export function setStudies(studies: StudyModel[]): UserSetStudiesAction {
		return {
			type: USER_SET_STUDIES,
			payload: studies,
		};
	}

	export function getProfile(slug: string): UserGetProfileAction {
		return {
			type: USER_GET_PROFILE,
			payload: slug,
		};
	}

	export function receiveProfileFailed(error: string): UserReceiveProfileFailedAction {
		return {
			type: USER_RECEIVE_PROFILE_FAILED,
			payload: error,
		};
	}

	export function receiveProfileSucceded(user: ProfileModel): UserReceiveProfileSuccededAction {
		return {
			type: USER_RECEIVE_PROFILE_SUCCEDED,
			payload: user,
		};
	}

	export function setOnboarding(): UserFetchOnboardingAction {
		return {
			type: USER_FETCH_ONBOARDING,
		};
	}

	export function onboardingFailed(message: string): UserFetchOnboardingFailedAction {
		return {
			type: USER_FETCH_ONBOARDING_FAILED,
			payload: message,
		};
	}

	export function onboardingSucceded(): UserFetchOnboardingSuccededAction {
		return {
			type: USER_FETCH_ONBOARDING_SUCCEDED,
		};
	}

	export function userGetProfilesByUser(): UserGetProfilesByUserAction {
		return {
			type: USER_GET_PROFILES_BY_USER,
		};
	}

	export function userGetProfilesByUserStarted(): UserGetProfilesByUserStartedAction {
		return {
			type: USER_GET_PROFILES_BY_USER_STARTED,
		};
	}

	export function userGetProfilesByUserFinished(
		profiles: UserReducer.UnitBusinessProfile[],
	): UserGetProfilesByUserFinishedAction {
		return {
			type: USER_GET_PROFILES_BY_USER_FINISHED,
			payload: profiles,
		};
	}

	export function userGetProfilesByUserFinishedWithErrors(err: string): UserGetProfilesByUserFinishedWithErrorsAction {
		return {
			type: USER_GET_PROFILES_BY_USER_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function receiveDataAPI(data: ProfileModel): UserReceiveDataApiAction {
		return {
			type: USER_RECEIVE_DATA_API,
			payload: data,
		};
	}

	export function loading(status: boolean): UserLoading {
		return {
			type: USER_LOADING,
			payload: status,
		};
	}
}
