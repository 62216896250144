import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { Challenge, getRequestConfig, PROD_URL, TenderMentor } from "@api";
import { CookiesUtils } from "@utils";

const addMentorToTender = (tenderId: number, mentorSlug: string, config: AxiosRequestConfig) =>
	axios.post(`${PROD_URL}/challenge-tenders/${tenderId}/consultant/${mentorSlug}`, {}, config);

export const useAddMentorToTenderMutation = (
	tenderId: number,
	challengeSlug: string,
	{ ...options }: UseMutationOptions<AxiosResponse, AxiosError, string> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useMutation((mentorSlug: string) => addMentorToTender(tenderId, mentorSlug, getRequestConfig(accessToken)), {
		...options,
		onSuccess: async (data, mentorSlug, context) => {
			const challenge = queryClient.getQueryData<Challenge>(["challenge", challengeSlug]);
			const mentorFromChallenge = challenge?.consultants.find((mentor) => mentor.slug === mentorSlug);
			const tenderMentors = queryClient.getQueryData<TenderMentor[]>(["tender-mentors", tenderId]);

			if (tenderMentors && challenge && mentorFromChallenge) {
				const newTenderMentor: TenderMentor = {
					id: 0,
					challenge_tender: {
						id: tenderId,
					},
					consultant_profile: {
						id: mentorFromChallenge.id,
						slug: mentorFromChallenge.slug,
						name: mentorFromChallenge.name,
						profile_image: mentorFromChallenge.profile_image,
					},
				};
				const newTenderMentorsList = [...tenderMentors, newTenderMentor];

				queryClient.setQueryData<TenderMentor[]>(["tender-mentors", tenderId], newTenderMentorsList);
			}

			await queryClient.invalidateQueries(["tender-mentors", tenderId]);
			options.onSuccess && options.onSuccess(data, mentorSlug, context);
		},
	});
};
