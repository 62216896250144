import {HeaderTexts} from "i18n/containers/Header";
import {HeaderTranslation} from "i18n/containers/Header/typing";
import {PostulateTexts} from "i18n/containers/Profiles";
import {PostulateTranslation} from "i18n/containers/Profiles/typing";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";
import {useLocales} from "@libs";

export interface HeaderLink {
    label: string;
    link: string;
    icon: SemanticICONS;
    color: SemanticCOLORS;
    userMustBeLoggedToUseIt?: boolean;
}

const useDropdownHeaderLinks = () => {
    const {currentShortLocale} = useLocales();
    const headerTranslation: HeaderTranslation = {
        ...HeaderTexts[currentShortLocale],
    };
    const postulateTranslation: PostulateTranslation = {
        ...PostulateTexts[currentShortLocale],
    };

    const dropdownHeaderLinks: HeaderLink[] = [
        {
            label: headerTranslation.startup,
            link: "/startups/explorer",
            icon: "rocket",
            color: "violet",
        },
        {
            label: headerTranslation.talent,
            link: "/talents/explorer",
            icon: "briefcase",
            color: "blue",
        },
        {
            label: headerTranslation.elearning,
            link: "/elearnings",
            icon: "book",
            color: "green",
        },
        {
            label: headerTranslation.marketplace,
            link: "/marketplaces/explorer",
            icon: "chart line",
            color: "teal",
        },
        {
            label: postulateTranslation.title,
            link: "/postulate/explorer",
            icon: "handshake",
            color: "yellow",
        },
        {
            label: headerTranslation.challenges,
            link: "/searcher/challenges",
            icon: "trophy",
            color: "orange",
            userMustBeLoggedToUseIt: true,
        },
    ];

    return {
        dropdownHeaderLinks
    }
}

export default useDropdownHeaderLinks;
