import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "@api";

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { CookiesUtils } from "@utils";

interface Birthday {
	datetime: string;
	datetimezone: string;
}

interface User {
	name: string;
	first_name: string;
	last_name: string;
}

interface CreateUserProfilePayload {
	user: User;
	emblem: string;
	name: string;
	first_name: string;
	last_name: string;
	gender: string;
	country: number;
	slug: string;
	description: string;
	birthday: Birthday;
	skills: number[];
	languages: any[];
	areas: any[];
	schools: any[];
	jobs: any[];
	educations: any[];
	social_networks: any[];
}

function createUserProfile(data: CreateUserProfilePayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/users-profile`, data, config).then((response) => {
		return response.data;
	});
}

export const useCreateUserProfileMutation = ({
	...options
}: UseMutationOptions<null, AxiosError, CreateUserProfilePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: CreateUserProfilePayload) => createUserProfile(data, getRequestConfig(accessToken)), {
		...options,
	});
};
