import axios from "axios";
import queryString from "query-string";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "@api";
import { getStringifiedList } from "@libs";

import { Marketplace, MarketplaceOffer, MarketplaceType } from "./types";

interface FetchMarketplacesQuantityParams {
	"search[min_quantity]": number | undefined;
	"search[max_quantity]": number | undefined;
}

interface FetchMarketplacesPriceParams {
	"search[min_pvp]": number | undefined;
	"search[max_pvp]": number | undefined;
}

export interface FetchMarketplacesRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[type]"?: MarketplaceType;
	"search[offer]"?: MarketplaceOffer;
	"search[regions]"?: number[];
	"search[sectors]"?: number[];
	"search[discount_percentage]"?: number;
	quantity?: FetchMarketplacesQuantityParams;
	price?: FetchMarketplacesPriceParams;
}

export interface FetchMarketplacesResponse {
	total: number;
	page: number;
	num_pages: number;
	items: Marketplace[];
}

export function fetchMarketplaces(queryParams: FetchMarketplacesRequestParams) {
	const params = {
		page: queryParams.page,
		perPage: queryParams.perPage,
		orderDirection: queryParams.orderDirection,
		"search[type]": queryParams["search[type]"] || "",
		"search[offer]": queryParams["search[offer]"] || "",
		"search[regions]": getStringifiedList(queryParams["search[regions]"]),
		"search[sectors]": getStringifiedList(queryParams["search[sectors]"]),
		"search[min_quantity]": queryParams.quantity?.["search[min_quantity]"] || "",
		"search[max_quantity]": queryParams.quantity?.["search[max_quantity]"] || "",
		"search[min_pvp]": queryParams.price?.["search[min_pvp]"] || "",
		"search[max_pvp]": queryParams.price?.["search[max_pvp]"] || "",
		"search[discount_percentage]": queryParams["search[discount_percentage]"] || "",
	};

	return function () {
		return axios
			.get<FetchMarketplacesResponse>(`${PROD_URL}/marketplace`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
}

export const useMarketplacesQuery = (
	queryParams: FetchMarketplacesRequestParams,
	options: UseQueryOptions<FetchMarketplacesResponse> = {},
) => {
	const queryClient = useQueryClient();

	return useQuery<FetchMarketplacesResponse>(["marketplaces", queryParams], fetchMarketplaces(queryParams), {
		...options,
		onSuccess: (marketplaces: FetchMarketplacesResponse) => {
			if (marketplaces && marketplaces.items) {
				marketplaces.items.forEach((marketplace) => {
					queryClient.setQueryData(["marketplace", marketplace.slug], marketplace);
				});
			}

			options.onSuccess && options.onSuccess(marketplaces);
		},
	});
};
