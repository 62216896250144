import { fetchLogout } from "api/AuthAPI";
import { hasTheUserLoggedAPI } from "api/ProfileAPI";
import { getUserData } from "api/UserAPI";
import ProfileModel from "models/profiles/Profile";
import { Auth } from "redux-app/actions/AuthAction";
import { AuthState } from "redux-app/reducers/AuthReducer/typing";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { CookiesUtils } from "@utils";
import { convertAPIProfileResponseToUserState } from "../ProfileSagas/formatter";

export function* checkCredentials(accessToken: string): any {
	if (accessToken && CookiesUtils.getCookie("accessToken")) {
		return true;
	} else {
		localStorage.removeItem("accessToken");
		CookiesUtils.removeCookie("accessToken");
		CookiesUtils.removeCookie("idProfile");

		yield put(Auth.statusLogin(null));

		return false;
	}
}

function* logout() {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		localStorage.removeItem("accessToken");
		CookiesUtils.removeCookie("accessToken");
		CookiesUtils.removeCookie("idProfile");

		try {
			yield call(fetchLogout(accessToken));
			yield put(Auth.logoutSucceded());
		} catch (err) {
		} finally {
			location.reload();
		}
	}
}

// TO-DO: We're just using this method to trigger when the user is logged
// We should think to improve this and find a better way to solve it
function* userHasLogged(): any {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	yield put(Auth.checkingLogin(true));

	if (yield checkCredentials(accessToken)) {
		try {
			const [user, profile] = yield all([call(getUserData(accessToken)), call(hasTheUserLoggedAPI(accessToken))]);

			if (user.message) {
				yield logout();
			} else {
				const authState: AuthState = {
					accessToken,
					name: user.first_name,
					surname: user.last_name,
					password: "",
					email: user.email,
					termsAndConditions: !Boolean(user.message),
					isLogged: !Boolean(user.message),
					isSignedUp: !Boolean(user.message),
					hasOnboarding: !Boolean(profile.code),
					hasFailed: false,
					loading: true, // To keep animation over login and signup's button -> very coupled
					checkingLogin: false,
					errorMessage: "",
					// CHANGE THIS
					userState: profile.message ? ProfileModel.generateEmpty() : convertAPIProfileResponseToUserState(profile),
				};

				yield put(Auth.receiveDataAPI(authState));
			}
		} catch (err) {
			yield logout();
		}
	}
}

export const rootAuthSagas = [takeLatest(Auth.AUTH_LOGOUT, logout)];
