import { ReactNode } from "react";
import classNames from "classnames";

interface ContainerProps {
	children: ReactNode;
	className?: string;
}

export const Container = ({ children, className }: ContainerProps) => {
	return <div className={classNames("px-4 lg:px-10 xl:px-20 2xl:px-44", className)}>{children}</div>;
};
