// Languages
import { localizationEs } from "i18n/localization/languages/localizationEs";
import { localizationEn } from "i18n/localization/languages/localizationEn";
import { localizationCat } from "i18n/localization/languages/localizationCat";
import { localizationPrt } from "i18n/localization/languages/localizationPrt";

export function getLocalizationByLanguageLEGACY(language: string): Promise<string> {
	switch (language) {
		case "es":
			return new Promise((resolve, reject) => {
				resolve(localizationEs);
			});
		case "en":
			return new Promise((resolve, reject) => {
				resolve(localizationEn);
			});
		case "ca":
			return new Promise((resolve, reject) => {
				resolve(localizationCat);
			});
		case "pt":
			return new Promise((resolve, reject) => {
				resolve(localizationPrt);
			});
	}
	return new Promise<string>((resolve, reject) => {
		reject("");
	});
}

export function getLocalizationByLanguage(language: string): string {
	switch (language) {
		case "es":
			return localizationEs;
		case "en":
			return localizationEn;
		case "ca":
			return localizationCat;
		case "pt":
			return localizationPrt;
		default:
			return localizationEn;
	}
}
