import { defaultError } from "api/config";
import {
	deleteCourseById,
	deleteWebinarById,
	fetchNewCourse,
	fetchNewWebinar,
	getElearningsFromSlugProfile,
	patchUpdateCourse,
	patchUpdateWebinar,
	subscribeToElearningAPI,
} from "api/ElearningAPI";
import ElearningModel from "models/elearnings/Elearning";
import { BusinessProfile } from "redux-app/actions/BusinessProfileAction";
import { Elearning } from "redux-app/actions/ElearningAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { checkCredentials } from "../AuthSagas";
import {
	convertAPIResponseToElearningState,
	convertElearningStateToAPIRequest,
	convertWebinarStateToAPIRequest,
} from "./formatter";
import { CookiesUtils } from "@utils";

function* newCourse(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_SET_COURSE; payload: ElearningModel }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.newCourseStarted());

		try {
			const preparedDataToSend = convertElearningStateToAPIRequest(action.payload);
			// @ts-ignore
			const course = yield call(fetchNewCourse(preparedDataToSend, accessToken));

			if (course.message) {
				yield put(BusinessProfile.newCourseFailed(course.message));
			} else {
				yield put(BusinessProfile.newCourseSucceded(action.payload));
				yield put(BusinessProfile.finishCreateElearning());
			}
		} catch (err) {
			yield put(BusinessProfile.newCourseFailed(defaultError().message));
		}
	}
}

function* updateCourse(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_UPDATE_COURSE;
	payload: ElearningModel;
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.updateCourseStarted());

		try {
			const preparedDataToSend = convertElearningStateToAPIRequest(action.payload);
			// @ts-ignore
			const course = yield call(patchUpdateCourse(preparedDataToSend, String(action.payload.id), accessToken));

			if (course.message) {
				yield put(BusinessProfile.updateCourseFailed(course.message));
			} else {
				yield put(BusinessProfile.updateCourseSucceded());
			}
		} catch (err) {
			yield put(BusinessProfile.updateCourseFailed(defaultError().message));
		}
	}
}

function* deleteCourse(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_DELETE_COURSE; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.deleteCourseStarted());

		try {
			// @ts-ignore
			const course = yield call(deleteCourseById(action.payload, accessToken));

			if (course.message) {
				yield put(BusinessProfile.deleteCourseFailed(course.message));
			} else {
				yield put(BusinessProfile.deleteCourseSucceded());
			}
		} catch (err) {
			yield put(BusinessProfile.deleteCourseFailed(defaultError().message));
		}
	}
}

function* newWebinar(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_SET_WEBINAR; payload: ElearningModel }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.newWebinarStarted());

		try {
			const preparedDataToSend = convertWebinarStateToAPIRequest(action.payload);
			// @ts-ignore
			const webinar = yield call(fetchNewWebinar(preparedDataToSend, accessToken));

			if (webinar.message) {
				yield put(BusinessProfile.newWebinarFailed(webinar.message));
			} else {
				yield put(BusinessProfile.newWebinarSucceded(action.payload));
				yield put(BusinessProfile.finishCreateElearning());
			}
		} catch (err) {
			yield put(BusinessProfile.newWebinarFailed(defaultError().message));
		}
	}
}

function* updateWebinar(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_UPDATE_WEBINAR;
	payload: ElearningModel;
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.updateWebinarStarted());

		try {
			const preparedDataToSend = convertWebinarStateToAPIRequest(action.payload);
			// @ts-ignore
			const webinar = yield call(patchUpdateWebinar(preparedDataToSend, String(action.payload.id), accessToken));

			if (webinar.message) {
				yield put(BusinessProfile.updateWebinarFailed(webinar.message));
			} else {
				yield put(BusinessProfile.updateWebinarSucceded());
			}
		} catch (err) {
			yield put(BusinessProfile.updateWebinarFailed(defaultError().message));
		}
	}
}

function* deleteWebinar(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_DELETE_WEBINAR; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.deleteWebinarStarted());

		try {
			// @ts-ignore
			const webinar = yield call(deleteWebinarById(action.payload, accessToken));

			if (webinar.message) {
				yield put(BusinessProfile.deleteWebinarFailed(webinar.message));
			} else {
				yield put(BusinessProfile.deleteWebinarSucceded());
			}
		} catch (err) {
			yield put(BusinessProfile.deleteWebinarFailed(defaultError().message));
		}
	}
}

function* getElearningsBySlug(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_GET_ELEARNINGS;
	payload: string;
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	yield put(BusinessProfile.getElearningStarted());

	try {
		// @ts-ignore
		const elearnings = yield call(getElearningsFromSlugProfile(action.payload, accessToken));

		if (elearnings.message) {
			yield put(BusinessProfile.getElearningFinishedWithErrors(elearnings.message));
		} else {
			const state = convertAPIResponseToElearningState(elearnings);
			// @ts-ignore
			const data = yield Promise.all(state).then((fullResponse) => fullResponse);
			yield put(BusinessProfile.getElearningFinished(data));
		}
	} catch (err) {
		yield put(BusinessProfile.getElearningFinishedWithErrors(defaultError().message));
	}
}

function* subscribeToElearning(action: {
	type: typeof Elearning.ELEARNING_SUBSCRIBE;
	payload: {
		elearningId: string;
		userId: string;
	};
}) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(Elearning.subscribeStarted());

		try {
			// @ts-ignore
			const elearnings = yield call(
				subscribeToElearningAPI(action.payload.elearningId, action.payload.userId, accessToken),
			);

			if (elearnings.message) {
				yield put(Elearning.subscribeFinishedWithErrors(elearnings.message));
			} else {
				yield put(Elearning.subscribeFinished());
			}
		} catch (err) {
			yield put(Elearning.subscribeFinishedWithErrors(defaultError().message));
		}
	}
}

export const rootElearningSagas = [
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_COURSE, newCourse),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_WEBINAR, newWebinar),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_UPDATE_COURSE, updateCourse),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_UPDATE_WEBINAR, updateWebinar),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_DELETE_COURSE, deleteCourse),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_DELETE_WEBINAR, deleteWebinar),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_GET_ELEARNINGS, getElearningsBySlug),
	takeLatest(Elearning.ELEARNING_SUBSCRIBE, subscribeToElearning),
];
