import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { Talent } from "./types";

function createTalent(data: Talent, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/talents`, data, config);
}

export const useCreateTalentMutation = ({ ...options }: UseMutationOptions<AxiosResponse, AxiosError, Talent> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: Talent) => createTalent(data, getRequestConfig(accessToken)), {
		...options,
	});
};
