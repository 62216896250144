import axios from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Talent } from "./types";

const fetchTalentsByProfile = (profileSlug: string, accessToken: string) => {
	return function (): Promise<Talent[]> {
		return axios
			.get(`${PROD_URL}/talents/profile/${profileSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data)
			.then((parsedResponse: Talent[]) => parsedResponse || [])
			.then((talents: Talent[]) => talents.reverse());
	};
};

export const useTalentsByProfileQuery = (
	profileSlug: string,
	options: UseQueryOptions<Talent[], any, Talent[]> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useQuery<Talent[]>(["talents", profileSlug], fetchTalentsByProfile(profileSlug, accessToken), {
		...options,
		onSuccess: (talents: Talent[]) => {
			if (talents) {
				talents.forEach((talent) => {
					queryClient.setQueryData(["talent", talent.slug], talent);
					queryClient.setQueryData(["talent-by-id", String(talent.id)], talent);
				});
			}

			options.onSuccess && options.onSuccess(talents);
		},
	});
};
