import {
	fetchNewAdviser,
	getAdviser,
	getCompanyAPI,
	getInstitution,
	getInvestor,
	getUniversity,
	patchAdviser,
	patchCompany,
	patchInstitution,
	patchInvestor,
	patchUniversity,
} from "api/BusinessProfileAPI";
import { defaultError } from "api/config";
import { BusinessProfileTypes } from "constants/Profiles";
import { URL_HTTPS } from "constants/URLs";
import { AppState } from "redux-app";
import { BusinessProfile } from "redux-app/actions/BusinessProfileAction";
import { User } from "redux-app/actions/UserAction";
import {
	AboutBusinessProfileState,
	AdviseSkillsBusinessProfileState,
	BusinessProfileState,
	InfoBusinessProfileState,
	LanguagesBusinessProfileState,
	MarketsBusinessProfileState,
	PartnersBusinessProfileState,
	RegionBusinessProfileState,
	SchoolsBusinessProfileState,
	StatisticBusinessProfileAdviserState,
	StatisticBusinessProfileState,
	TeamBusinessProfileState,
} from "redux-app/reducers/BusinessProfileReducer/typing";
import { SocialProfilePropsState } from "redux-app/reducers/UserReducer/typing";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkCredentials } from "../AuthSagas";
import {
	convertAdviserBusinessProfileStateToAPIRequest,
	convertBusinessProfileStateToAPIRequest,
} from "./request-formatter";
import { convertAPIResponseToBusinessProfileState } from "./response-formatter";
import { CookiesUtils } from "@utils";

function* FactoryGetProfile(type: BusinessProfileTypes, slug: string, accessToken: string): any {
	let response;

	switch (type) {
		case BusinessProfileTypes.UNIVERSITY:
			response = yield call(getUniversity(slug, accessToken));
			break;
		case BusinessProfileTypes.BUSINESS_ANGEL:
			response = yield call(getInvestor(slug, accessToken));
			break;
		case BusinessProfileTypes.COMPANY:
			response = yield call(getCompanyAPI(slug, accessToken));
			break;
		case BusinessProfileTypes.ACCELERATOR:
			response = yield call(getInvestor(slug, accessToken));
			break;
		case BusinessProfileTypes.INSTITUTION:
			response = yield call(getInstitution(slug, accessToken));
			break;
		case BusinessProfileTypes.INCUBATOR:
			response = yield call(getInvestor(slug, accessToken));
			break;
		case BusinessProfileTypes.ADVISER:
			response = yield call(getAdviser(slug, accessToken));
			break;
		case BusinessProfileTypes.STARTUP:
			response = yield call(getCompanyAPI(slug, accessToken));
			break;
	}

	return response;
}

function* FactoryPatchProfile(type: BusinessProfileTypes, slug: string, accessToken: string, data: object): any {
	let response;

	switch (type) {
		case BusinessProfileTypes.UNIVERSITY:
			response = yield call(patchUniversity(accessToken, slug, data));
			break;
		case BusinessProfileTypes.BUSINESS_ANGEL:
			response = yield call(patchInvestor(accessToken, slug, data));
			break;
		case BusinessProfileTypes.COMPANY:
			response = yield call(patchCompany(accessToken, slug, data));
			break;
		case BusinessProfileTypes.ACCELERATOR:
			response = yield call(patchInvestor(accessToken, slug, data));
			break;
		case BusinessProfileTypes.INSTITUTION:
			response = yield call(patchInstitution(accessToken, slug, data));
			break;
		case BusinessProfileTypes.INCUBATOR:
			response = yield call(patchInvestor(accessToken, slug, data));
			break;
		case BusinessProfileTypes.ADVISER:
			response = yield call(patchAdviser(accessToken, slug, data));
			break;
		case BusinessProfileTypes.STARTUP:
			response = yield call(patchCompany(accessToken, slug, data));
			break;
	}

	return response;
}

function* checkSlug(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_CHECK_SLUG; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const currentState: BusinessProfileState = yield select((state: AppState) => state.businessProfileReducer);

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.checkSlugInit(action.payload));

		try {
			// @ts-ignore
			const profile = yield FactoryGetProfile(currentState.type!!, action.payload, accessToken);
			//If exists or return an error
			if (profile.id || profile.message) {
				yield put(BusinessProfile.checkSlugFailed(profile.message));
			} else {
				yield put(BusinessProfile.checkSlugSucceded());
			}
		} catch (err) {
			yield put(BusinessProfile.checkSlugFailed(defaultError().message));
		}
	}
}

function* getBusinessProfile(action: { type: typeof BusinessProfile.BUSINESS_PROFILE_GET; payload: string }) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const currentState: BusinessProfileState = yield select((state: AppState) => state.businessProfileReducer);
	yield put(BusinessProfile.getBusinessProfileStarted());

	try {
		// @ts-ignore
		const profile = yield FactoryGetProfile(currentState.type!!, action.payload, accessToken);
		// If exists or return an error
		if (profile.message) {
			yield put(BusinessProfile.getBusinessProfileFinishedWithErrors(profile.message));
		} else {
			// @ts-ignore
			const state = yield convertAPIResponseToBusinessProfileState(profile);
			yield put(BusinessProfile.getBusinessProfileFinished(state));
		}
	} catch (err) {
		BusinessProfile.getBusinessProfileFinishedWithErrors(defaultError().message);
	}
}

function* newAdviser() {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.startCreation());

		try {
			const preparedDataToSend =
				// @ts-ignore
				yield convertAdviserBusinessProfileStateToAPIRequest(currentState);
			// @ts-ignore
			const adviser = yield call(fetchNewAdviser(accessToken, preparedDataToSend));

			if (adviser.message) {
				yield put(BusinessProfile.createAdviserFailed(adviser.message));
			} else {
				yield put(BusinessProfile.createAdviserSucceded());
				yield put(User.userGetProfilesByUser());
			}
			yield put(BusinessProfile.finishCreation());
		} catch (err) {
			yield put(BusinessProfile.createAdviserFailed(defaultError().message));
		}
	}
}

function* patchBusinessProfile(state: BusinessProfileState) {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		let preparedDataToSend = {};

		try {
			// For Advisers
			if (state.type === BusinessProfileTypes.ADVISER) {
				preparedDataToSend = yield convertAdviserBusinessProfileStateToAPIRequest(state);
				// For the rest
			} else {
				preparedDataToSend = yield convertBusinessProfileStateToAPIRequest(state);
			}
			// @ts-ignore
			return yield FactoryPatchProfile(state.type!!, state.info.slug.value, accessToken, preparedDataToSend);
		} catch (err) {
			return defaultError();
		}
	}
}

function* updateAbout(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_ABOUT;
	payload: AboutBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		about: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setAboutStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setAboutFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setAboutFinished(action.payload));
		}
	}
}

function* updateMarkets(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_MARKETS;
	payload: MarketsBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		markets: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setMarketsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setMarketsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setMarketsFinished(action.payload));
		}
	}
}

function* updateSchools(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_SCHOOLS;
	payload: SchoolsBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		schools: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setSchoolsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setSchoolsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setSchoolsFinished(action.payload));
		}
	}
}

function* updateLanguages(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_LANGUAGES;
	payload: LanguagesBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		languages: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setLanguagesStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setLanguagesFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setLanguagesFinished(action.payload));
		}
	}
}

function* updateRegions(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_REGIONS;
	payload: RegionBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		regions: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setRegionsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setRegionsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setRegionsFinished(action.payload));
		}
	}
}

function* updateTeam(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_TEAM;
	payload: TeamBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		team: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.saveTeamStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.saveTeamFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.saveTeamFinished(action.payload));
		}
	}
}

function* updatePartners(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_PARTNERS;
	payload: PartnersBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		partners: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.savePartnersStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.savePartnersFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.savePartnersFinished(action.payload));
		}
	}
}

function* updateSocial(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_SOCIAL;
	payload: SocialProfilePropsState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		social: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setSocialStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setSocialFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setSocialFinished(action.payload));
		}
	}
}

function* updateInfo(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_INFO;
	payload: InfoBusinessProfileState;
}) {
	const updatedPayload: InfoBusinessProfileState = {
		...action.payload,
		url: URL_HTTPS + action.payload.url,
	};
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		info: updatedPayload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setInfoStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setInfoFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setInfoFinished(updatedPayload));
			yield put(User.userGetProfilesByUser());
		}
	}
}

function* updateStatistics(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_STATISTICS;
	payload: StatisticBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		statistic: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setStatisticsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setStatisticsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setStatisticsFinished(action.payload));
		}
	}
}

function* updateAdviserStatistics(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_STATISTICS;
	payload: StatisticBusinessProfileAdviserState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		adviserStatistic: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setAdviserStatisticsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setAdviserStatisticsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setAdviserStatisticsFinished(action.payload));
		}
	}
}

function* updateAdviserSkills(action: {
	type: typeof BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_SKILLS;
	payload: AdviseSkillsBusinessProfileState;
}) {
	const currentState: BusinessProfileState = {
		...(yield select((state: AppState) => state.businessProfileReducer)),
		adviserSkills: action.payload,
	};
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(BusinessProfile.setAdviserSkillsStarted());
		// @ts-ignore
		const profileResponse = yield patchBusinessProfile(currentState);

		if (profileResponse.message) {
			yield put(BusinessProfile.setAdviserSkillsFinishedWithErrors(profileResponse.message));
		} else {
			yield put(BusinessProfile.setAdviserSkillsFinished(action.payload));
		}
	}
}

export const rootBusinessProfileSagas = [
	takeLatest(BusinessProfile.BUSINESS_PROFILE_CREATE_ADVISER, newAdviser),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_CHECK_SLUG, checkSlug),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_GET, getBusinessProfile),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_ABOUT, updateAbout),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_MARKETS, updateMarkets),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_SCHOOLS, updateSchools),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_LANGUAGES, updateLanguages),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_REGIONS, updateRegions),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_TEAM, updateTeam),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_PARTNERS, updatePartners),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_SOCIAL, updateSocial),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_INFO, updateInfo),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_STATISTICS, updateStatistics),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_STATISTICS, updateAdviserStatistics),
	takeLatest(BusinessProfile.BUSINESS_PROFILE_SET_ADVISER_SKILLS, updateAdviserSkills),
];
