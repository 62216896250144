export const DateUtils = {
	DEFAULT_FORMAT: "DD-MM-YYYY",
	// NOW - 15 YEAR  (HARDCODED BECAUSE BACKEND HANDLE SO BAD THE LIMIT OF THE TIME :D )
	MAX_DATE_FULL_AGE: Math.ceil(Date.now() - 31556926000 * 15),

	getDefaultFormatFromEPOCH: function (miliseconds: number) {
		const date = new Date(miliseconds);
		const filledDay = ("0" + date.getDate()).slice(-2);
		const filledMonth = ("0" + (date.getMonth() + 1)).slice(-2);
		return `${filledDay}-${filledMonth}-${date.getFullYear()}`;
	},

	getLocalizedFormatFromEPOCH: function (miliseconds: number) {
		//const date = new Date(miliseconds);
		//return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
		//Localized with the current timezone
	},

	getEPOCH: function (
		day: number = 0,
		month: number = 0,
		year: number = 0,
		hour: number = 0,
		minute: number = 0,
		second: number = 0,
	) {
		const date = new Date(year, month, day, hour, minute, second);
		return date.getTime();
	},

	getEpochWithSeconds: function (
		day: number = 0,
		month: number = 0,
		year: number = 0,
		hour: number = 0,
		minute: number = 0,
		second: number = 0,
	) {
		return this.getEPOCH(day, month, year, hour, minute, second) / 1000;
	},

	getEPOCHFromDefaultFormat: function (dateFormated: string) {
		const [day, month, year] = dateFormated.split("-");
		return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
	},

	getDiffBetweenTwoEPOCHDates: function (startEPOCH: number, endEPOCH: number) {
		return endEPOCH - startEPOCH;
	},

	getDiffInYearsBetweenTwoEPOCHDates: function (startEPOCH: number, endEPOCH: number) {
		let diffDatesInYears = this.getDiffBetweenTwoEPOCHDates(startEPOCH, endEPOCH) / (1000 * 60 * 60 * 24 * 365);
		// If we don't do this, the proper diff is not going to be properly
		// set if the diff's range is minor than 7 days :/
		diffDatesInYears = Number(diffDatesInYears.toFixed(3));

		if (diffDatesInYears % 1 <= 0.01) {
			diffDatesInYears -= 1;
		}

		return Math.floor(diffDatesInYears);
	},

	getCurrentTimezone: function () {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	},

	getCurrentTime: function () {
		return Date.now();
	},

	getFutureTime: function (yearsInMiliseconds: number) {
		return Date.now() + yearsInMiliseconds;
	},

	getYearsInMiliseconds: function (years: number) {
		return years * 365 * 24 * 60 * 60 * 1000;
	},

	appendLeadingZeroes: function (number: number) {
		if (number <= 9) {
			return "0" + number;
		}
		return number;
	},

	getTimeAPIFormat: function (miliseconds: number) {
		const date = new Date(miliseconds);
		/// "YYYY-mm-dd HH:ii:ssGMT"
		return `${date.getFullYear()}-${this.appendLeadingZeroes(date.getMonth() + 1)}-${this.appendLeadingZeroes(
			date.getDate(),
		)} ${this.appendLeadingZeroes(date.getHours())}:${this.appendLeadingZeroes(
			date.getMinutes(),
		)}:${this.appendLeadingZeroes(date.getSeconds())}GMT`;
	},

	getEpochFromAPIFormat: function (timeAPIFormat: string) {
		timeAPIFormat = timeAPIFormat.replace(/-/g, "/");
		let splitTimeAPIFormat: string[] = timeAPIFormat.split(" ");

		if (splitTimeAPIFormat.length > 2) {
			splitTimeAPIFormat.length = 2;
			return new Date(splitTimeAPIFormat.join(" ")).getTime();
		}

		return new Date(timeAPIFormat).getTime();
	},

	// Chrome 03-22-2019 => 22-03-2019
	// Firefox 03-22-2019 => 2019-03-22
	getCommonFormatFromDefaultFormat: function (dateWithDefaultFormat: string) {
		if (dateWithDefaultFormat) {
			const splittedDate = dateWithDefaultFormat.split("-");
			const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

			if (isChrome) {
				return `${splittedDate[1]}-${splittedDate[0]}-${splittedDate[2]}`;
			} else {
				return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
			}
		}

		return null;
	},

	// Month here is 1-indexed (January is 1, February is 2, etc.). This is
	// because we're using 0 as the day so that it returns the last day
	// of the last month, so you have to add 1 to the month number,
	// so it returns the correct amount of days
	daysInMonth: function (month: number, year: number) {
		return new Date(year, month, 0).getDate();
	},
};
