export const DatesPrt = `
{
    "days": {
        "key": {            
            "singular": "Dia",
            "plural": "Dias"
        },
        "items": {
            "monday": "Segunda-feira",
            "tuesday": "Terça-feira",
            "wednesday": "Quarta-feira",
            "thursday": "Quinta-feira",
            "friday": "Sexta-feira",
            "saturday": "Sábado",
            "sunday": "Domingo"
        }
    },
    "months": {
        "key": {            
            "singular": "Mês",
            "plural": "Meses"
        },
        "items": {
            "january": "Janeiro",
            "february": "Fevereiro",
            "march": "Março",
            "april": "Abril",
            "may": "Maio",
            "june": "Junho",
            "july": "Julho",
            "august": "Agosto",
            "september": "Setembro",
            "october": "Outubro",
            "november": "Novembro",
            "december": "Dezembro"
        }
    }  
}
`;
