import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getRequestConfig, PROD_URL } from "../../config";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { BusinessProfileTypes, GenericBusinessProfilePayload } from "../types";
import { CookiesUtils } from "@utils";

type InstitutionProfilePayload = Omit<GenericBusinessProfilePayload, "type">;

function createInstitution(data: InstitutionProfilePayload, config: AxiosRequestConfig) {
	return axios
		.post(
			`${PROD_URL}/institutions-profile`,
			{
				...data,
				type: BusinessProfileTypes.INSTITUTION,
			},
			config,
		)
		.then((response) => {
			return response.status;
		});
}

export const useCreateInstitutionMutation = (
	options: UseMutationOptions<number, AxiosError, InstitutionProfilePayload> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: InstitutionProfilePayload) => createInstitution(data, getRequestConfig(accessToken)), {
		...options,
	});
};
