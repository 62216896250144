import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { Tender } from "@api";
import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

function fetchTender(tenderId: number, accessToken: string) {
	return function () {
		return axios
			.get<Tender>(`${PROD_URL}/challenge-tenders/${tenderId}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useTenderQuery = (tenderId: number, options: UseQueryOptions<Tender> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Tender>(["tender", tenderId], fetchTender(tenderId, accessToken), {
		...options,
	});
};
