import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

interface TenderMentorProfile {
	id: number;
	slug: string;
	name: string;
	profile_image: string;
}

interface TenderMentorChallenge {
	id: number;
}

export interface TenderMentor {
	id: number;
	consultant_profile: TenderMentorProfile;
	challenge_tender: TenderMentorChallenge;
}

function fetchTenderMentors(tenderId: number, accessToken: string) {
	return function () {
		return axios
			.get<TenderMentor[]>(`${PROD_URL}/challenge-tenders/${tenderId}/consultant`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useTenderMentorsQuery = (tenderId: number, options: UseQueryOptions<TenderMentor[]> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<TenderMentor[]>(["tender-mentors", tenderId], fetchTenderMentors(tenderId, accessToken), {
		...options,
	});
};
