import { CategoryType } from "api/CategoriesAPI/urls";
import CategoryModel, { Category } from "./Category";
import { StringUtils } from "utils/StringUtils";

export interface CategorySelectable extends Category {
	selected?: boolean;
}

class CategorySelectableModel implements CategorySelectable {
	id: number | null;
	key: string;
	// TO-DO Add translations
	text: string;
	category: CategoryType | null;
	selected: boolean;

	constructor(id: number | null, key: string, text: string, category: CategoryType | null, selected: boolean) {
		this.id = id;
		this.key = key;
		this.text = StringUtils.capitalize(text);
		this.category = category;
		this.selected = selected;
	}

	setSelection = (selected: boolean) => {
		this.selected = selected;
	};

	static generateFromAPI(data: any, selected?: boolean) {
		return new CategorySelectableModel(data.id, data.name, "", data.category as CategoryType, Boolean(selected));
	}

	static generateFromEmpty() {
		return new CategorySelectableModel(0, "", "", "" as CategoryType, false);
	}

	static createFromCommonCategory(category: CategoryModel, selected?: boolean) {
		return new CategorySelectableModel(category.id, category.key, category.text, category.category, Boolean(selected));
	}
}

export default CategorySelectableModel;
