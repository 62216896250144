import { ElearningType, getCategoryFromElearningType, getElearningTypeFromString } from "constants/Elearning";
import { URL_HTTPS, URL_VINGLET_IMAGE } from "constants/URLs";
import { Image } from "elements/HeroImage/typing";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";
import CategoryModel, { CategoryAPI } from "models/categories/Category";
import CategorySelectableModel, { CategorySelectable } from "models/categories/CategorySelectable";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { DateUtils } from "@utils";

import { StringUtils } from "utils/StringUtils";
import { PageDirectoryUtils } from "@utils";
import {Locales} from "@libs";

const DEFAULT_IMAGE = "/default-image.png";

export interface Elearning {
	id: number | undefined;
	title: string;
	slug: string;
	image: Image;
	location: string;
	description: string;
	owner: BusinessProfileModel;
	schools: CategorySelectable[];
	markets: CategorySelectable[];
	languages: CategorySelectable[];
	careerModality: CategorySelectable;
	email: string;
	startDate: number;
	endDate: number;
	type: ElearningType;
	url: string;
	withCertification: boolean;
	visitorAlreadyApplied: boolean;
}

class ElearningModel implements Elearning {
	id: number | undefined;
	title: string;
	slug: string;
	image: Image;
	location: string;
	description: string;
	owner: BusinessProfileModel;
	schools: CategorySelectable[];
	markets: CategorySelectable[];
	languages: CategorySelectable[];
	careerModality: CategorySelectable;
	email: string;
	startDate: number;
	endDate: number;
	type: ElearningType;
	url: string;
	withCertification: boolean;
	visitorAlreadyApplied: boolean;

	constructor(
		id: number | undefined,
		title: string,
		slug: string,
		image: Image,
		location: string,
		description: string,
		owner: BusinessProfileModel,
		schools: CategorySelectable[],
		markets: CategorySelectable[],
		languages: CategorySelectable[],
		careerModality: CategorySelectable,
		email: string,
		startDate: number,
		endDate: number,
		type: ElearningType,
		url: string,
		withCertification: boolean,
		visitorAlreadyApplied: boolean = false,
	) {
		this.id = id;
		this.title = StringUtils.capitalize(title);
		this.slug = slug;
		this.image = image;
		this.location = location;
		this.description = description;
		this.owner = owner;
		this.schools = schools;
		this.markets = markets;
		this.languages = languages;
		this.careerModality = careerModality;
		this.email = email;
		this.startDate = startDate;
		this.endDate = endDate;
		this.type = type;
		this.url = url;
		this.withCertification = withCertification;
		this.visitorAlreadyApplied = visitorAlreadyApplied;
	}

	getFormattedImage = (): Image => {
		return {
			...this.image,
			src: this.image.src === DEFAULT_IMAGE || !this.image.src ? DEFAULT_IMAGE : URL_VINGLET_IMAGE + this.image.src,
		};
	};

	getInternalURL = (locale: Locales) => {
		let internalURL = `/id/${this.id}`;

		if (this.slug && this.slug !== "null") {
			internalURL = `/${this.slug}`;
		}

		return PageDirectoryUtils.ROUTE_ELEARNING_WITH_LOCALE(locale) + internalURL;
	};

	getDateUIText = () => {
		return this.startDate === this.endDate
			? DateUtils.getDefaultFormatFromEPOCH(this.startDate)
			: `${DateUtils.getDefaultFormatFromEPOCH(this.startDate)}
			 ${DateUtils.getDefaultFormatFromEPOCH(this.endDate)}`;
	};

	exportToAPIRequest = () => {
		let url = this.url;
		let preparedDataToSend: {};

		if (URL_HTTPS !== url.substr(0, URL_HTTPS.length)) {
			url = URL_HTTPS + url;
		}

		preparedDataToSend = {
			profile_id: this.owner.info.id,
			title: this.title,
			slug: StringUtils.convertStringToCorrectSlug(this.title),
			email: this.email,
			url,
			description: this.description,
			start_date: {
				datetime: DateUtils.getTimeAPIFormat(this.startDate),
				datetimezone: DateUtils.getCurrentTimezone(),
			},
			end_date: {
				datetime: DateUtils.getTimeAPIFormat(this.endDate),
				datetimezone: DateUtils.getCurrentTimezone(),
			},
			schools: this.schools.map((school) => school.id),
			markets: this.markets.map((market) => market.id),
			languages: this.languages.map((language) => language.id),
			duration: "",
			useful_for: "",
			requirements: "",
			background_image: this.image.src,
		};

		if (this.type !== ElearningType.Webinar) {
			preparedDataToSend = {
				...preparedDataToSend,
				education_id: getCategoryFromElearningType(this.type).id,
				attendance: this.careerModality.id,
				certified: this.withCertification,
				certification_name: "",
				certification_desription: "",
				course_type: this.type && this.type.toUpperCase(),
			};
		}

		return preparedDataToSend;
	};

	static generateMiniFromAPI(data: any = {}) {
		const elearningType = data.type ? getElearningTypeFromString(data.type) : ElearningType.Webinar;

		return new ElearningModel(
			data.id,
			data.title,
			data.slug,
			{
				src: data.image,
			},
			"",
			data.description,
			BusinessProfileModel.generateEmpty(),
			[],
			[],
			[],
			CategorySelectableModel.generateFromEmpty(),
			"",
			DateUtils.getCurrentTime(),
			DateUtils.getCurrentTime(),
			elearningType,
			"",
			false,
			false,
		);
	}

	static generateFromAPI(data: any = {}) {
		const categoriesServices = CategoriesServiceSingleton.getList();
		const alreadyApplied = data.already_applied;
		const startDate = JSON.parse(data.start_date);
		const endDate = JSON.parse(data.end_date);
		const elearningImage = {
			src: data.background_image || DEFAULT_IMAGE,
			alt: data.title,
		};
		const elearningURL = data.url ? (data.url.includes(URL_HTTPS) ? data.url : URL_HTTPS + data.url) : "";
		const elearningType = data.course_type ? getElearningTypeFromString(data.course_type) : ElearningType.Webinar;
		const {
			market: marketsTranslations,
			school: schoolsTranslations,
			careerModality: careerModalityTranslations,
		} = TranslationsServiceSingleton.getFeatureTranslations(["market", "school", "careerModality"]);
		const { language: languagesTranslations } = TranslationsServiceSingleton.getWorldTranslations(["language"]);
		// SCHOOLS
		const idsSelectedSchools = data.schools ? data.schools.map((item: CategoryAPI) => item.id) : [];
		const selectedSchools = categoriesServices.filterById(idsSelectedSchools) as CategoryModel[];
		// MARKETS
		const idsSelectedMarkets = data.markets ? data.markets.map((item: CategoryAPI) => item.id) : [];
		const selectedMarkets = categoriesServices.filterById(idsSelectedMarkets) as CategoryModel[];
		// CAREER MODALITY
		const idSelectedCareerModality = data.attendance && data.attendance.id;
		const selectedCareerModality = categoriesServices.filterById(idSelectedCareerModality) as CategoryModel;
		// LANGUAGES
		const idsSelectedLanguages = data.languages ? data.languages.map((item: CategoryAPI) => item.id) : [];
		const selectedLanguages = categoriesServices.filterById(idsSelectedLanguages) as CategoryModel[];

		try {
			selectedSchools.map((school) => {
				if (schoolsTranslations.items[school.key]) {
					school.setText(schoolsTranslations.items[school.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(school, true);
			});
			selectedMarkets.map((market) => {
				if (marketsTranslations.items[market.key]) {
					market.setText(marketsTranslations.items[market.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(market, true);
			});
			selectedLanguages.map((language) => {
				if (languagesTranslations.items[language.key]) {
					language.setText(languagesTranslations.items[language.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(language, true);
			});
			if (elearningType !== ElearningType.Webinar && careerModalityTranslations.items[selectedCareerModality.key]) {
				selectedCareerModality.setText(careerModalityTranslations.items[selectedCareerModality.key]);
			}
		} catch (err) {}

		return new ElearningModel(
			data.id,
			data.title,
			data.slug + "-" + data.id,
			elearningImage,
			"",
			data.description,
			BusinessProfileModel.generateFromAPI(data.profile),
			selectedSchools,
			selectedMarkets,
			selectedLanguages,
			selectedCareerModality && CategorySelectableModel.createFromCommonCategory(selectedCareerModality, true),
			data.email || "",
			DateUtils.getEpochFromAPIFormat(startDate.datetime),
			DateUtils.getEpochFromAPIFormat(endDate.datetime),
			elearningType,
			elearningURL,
			data.is_certified,
			alreadyApplied,
		);
	}

	static generateEmpty() {
		return new ElearningModel(
			undefined,
			"",
			"",
			{
				src: DEFAULT_IMAGE,
			},
			"",
			"",
			BusinessProfileModel.generateEmpty(),
			[],
			[],
			[],
			CategorySelectableModel.generateFromEmpty(),
			"",
			DateUtils.getCurrentTime(),
			DateUtils.getCurrentTime(),
			ElearningType.Course,
			"",
			false,
			false,
		);
	}

	static generateFromObject(elearningObject: Elearning) {
		return new ElearningModel(
			elearningObject.id,
			elearningObject.title,
			elearningObject.slug,
			elearningObject.image,
			elearningObject.location,
			elearningObject.description,
			elearningObject.owner,
			elearningObject.schools,
			elearningObject.markets,
			elearningObject.languages,
			elearningObject.careerModality,
			elearningObject.email,
			elearningObject.startDate,
			elearningObject.endDate,
			elearningObject.type,
			elearningObject.url,
			elearningObject.withCertification,
			elearningObject.visitorAlreadyApplied,
		);
	}
}

export default ElearningModel;
