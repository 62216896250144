import { ChangeEventHandler, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";

import { CookiesUtils } from "@utils";

import { LanguageOptions } from "i18n";

import messages, { languagesMessages } from "./messages";

export const SwitchLanguage = () => {
	const router = useRouter();
	const lang = CookiesUtils.getCookie("lang");
	const [currentLanguage, setCurrentLanguage] = useState(LanguageOptions.find((option) => option.key === lang));

	const handleOnChangeCurrentLanguage: ChangeEventHandler<HTMLSelectElement> = (event) => {
		const { target } = event;

		if (target.value) {
			const selectedLanguage = LanguageOptions.find((languageOption) => languageOption.key === target.value);

			if (selectedLanguage?.key) {
				setCurrentLanguage(selectedLanguage);

				router
					.push("/", undefined, {
						locale: selectedLanguage.key,
						shallow: false,
					})
					.then(() => CookiesUtils.setCookieForever("lang", selectedLanguage.key))
					.then(() => location.reload());
			}
		}
	};

	return (
		<>
			<label
				htmlFor="languages"
				className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
			>
				<FormattedMessage {...messages.changeLanguage} />
			</label>
			<select
				id="languages"
				className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pr-10 pl-5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
				onChange={handleOnChangeCurrentLanguage}
			>
				{LanguageOptions.map((languageOption) => (
					<option
						key={languageOption.key}
						value={languageOption.key}
						selected={currentLanguage?.key === languageOption.key}
					>
						<FormattedMessage {...languagesMessages[languageOption.key]} />
					</option>
				))}
			</select>
		</>
	);
};
