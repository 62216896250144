import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { WebinarPayload } from "./types";

function createWebinar(data: WebinarPayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/streamings`, data, config);
}

export const useCreateWebinarMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, WebinarPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: WebinarPayload) => createWebinar(data, getRequestConfig(accessToken)), {
		...options,
	});
};
