import * as React from "react";

const Footer = () => {
	return (
		<footer className={"Footer full-width font-color-white-grey padding-2-y text-centered"}>
			Vinglet® - {new Date().getUTCFullYear()}/{new Date().getUTCFullYear() + 1}
		</footer>
	);
};

export default Footer;
