import { defaultError } from "api/config";
import { getProfilesByUser } from "api/ProfileAPI";
import { fetchNewUserProfile } from "api/UserProfileAPI";
import { AppState } from "redux-app";
import { Auth } from "redux-app/actions/AuthAction";
import { User } from "redux-app/actions/UserAction";
import { UserState } from "redux-app/reducers/UserReducer/typing";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkCredentials } from "../AuthSagas";
import { convertBusinessProfileResponseToListUnitBusinessProfile, convertUserStateToRequestAPI } from "./formatter";
import { CookiesUtils } from "@utils";

function* onboarding() {
	const data: UserState = yield select((state: AppState) => state.userReducer);
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		try {
			// @ts-ignore
			const prepareDataToSend = yield convertUserStateToRequestAPI(data);
			// @ts-ignore
			const response = yield call(fetchNewUserProfile(prepareDataToSend, accessToken));

			if (response.message) {
				yield put(User.onboardingFailed(response.message));
			} else {
				yield put(User.onboardingSucceded());
			}
		} catch (err) {
			yield put(User.onboardingFailed(defaultError().message));
		}
	}
}

function* getBusinessProfiles() {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	// @ts-ignore
	if (yield checkCredentials(accessToken)) {
		yield put(User.userGetProfilesByUserStarted());

		try {
			// @ts-ignore
			const response = yield call(getProfilesByUser(accessToken));

			if (response.message) {
				yield put(User.userGetProfilesByUserFinishedWithErrors(response.message));
			} else {
				const list = convertBusinessProfileResponseToListUnitBusinessProfile(response.data);

				yield put(User.userGetProfilesByUserFinished(list));
				yield put(Auth.getProfilesByUserFinish(list));
			}
		} catch (err) {
			yield put(User.userGetProfilesByUserFinishedWithErrors(defaultError().message));
		}
	}
}

export const rootUserSagas = [
	takeLatest(User.USER_FETCH_ONBOARDING, onboarding),
	takeLatest(User.USER_GET_PROFILES_BY_USER, getBusinessProfiles),
];
