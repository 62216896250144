import { getSpecificCategory } from "api/CategoriesAPI";
import { CategoryType } from "api/CategoriesAPI/urls";
import { BusinessProfileTypes } from "constants/Profiles";
import { PostulateLandscapeCardProps } from "containers/Searcher/ContentSearcher/PostulateLandscapeCard/typing";
import { StartUpLandscapeCardProps } from "containers/Searcher/ContentSearcher/StartUpLandscapeCard/typing";
import BusinessProfileType from "models/business-profiles/BusinessProfileType";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import CategoryModel from "models/categories/Category";
import CategorySelectableModel from "../../../models/categories/CategorySelectable";
import { LabelListItemProfileLegacy } from "../../../components/Profile/LabelListProfile/typing";

export function convertAPIRequestToStartupState(payload: any[], isMine: boolean): StartUpLandscapeCardProps[] {
	const categoriesList = CategoriesServiceSingleton.getList();
	const marketsCategories = categoriesList.filterByCategoryType(CategoryType.SECTOR) as CategoryModel[];
	const countriesCategories = categoriesList.filterByCategoryType(CategoryType.COUNTRY) as CategoryModel[];
	const regionsCategories = categoriesList.filterByCategoryType(CategoryType.REGION) as CategoryModel[];

	return payload.map((item) => {
		const selectedMarkets = item.sectors
			? item.sectors
					.map((market: any) => {
						// @ts-ignore
						const chosenMarket: CategoryModel = marketsCategories.find(
							(marketToCompare) => marketToCompare.key === market.name,
						);

						return (
							chosenMarket && {
								idCategory: chosenMarket.idCategory,
								name: chosenMarket.text,
								selected: true,
							}
						);
					})
					.filter((market: any) => market)
			: [];
		const selectedCountry = item.country && countriesCategories.find((country) => country.key === item.country.name);
		const selectedRegions = item.regions
			? item.regions
					.map((region: any) => {
						const chosenRegion = regionsCategories.find((regionToCompare) => regionToCompare.key === region.name);

						return chosenRegion && chosenRegion.text;
					})
					.filter((region: any) => region)
			: [];

		return {
			title: item.name,
			image: {
				src: item.profile_image,
				alt: item.name,
			},
			owner: {
				data: {
					id: item.id,
					name: item.name,
					emblem: item.emblem,
					slug: item.slug,
					type: BusinessProfileTypes.STARTUP,
					localUrl: BusinessProfileType.getURLFromType(BusinessProfileTypes.STARTUP, item.slug),
					image: {
						src: item.profile_image,
						alt: item.name,
					},
				},
				alreadyApplied: item.already_applied,
				isMine,
			},
			location: selectedCountry && selectedCountry.text,
			description: item.description,
			regions: selectedRegions,
			markets: {
				items: selectedMarkets,
			},
			investmentNeeded: 0,
			givenEquity: 0,
		};
	});
}

export function convertAPIRequestToPostulateState(payload: any[], isMine: boolean): PostulateLandscapeCardProps[] {
	const categoriesList = CategoriesServiceSingleton.getList();
	const marketsCategories = categoriesList.filterByCategoryType(CategoryType.SECTOR) as CategoryModel[];
	const countriesCategories = categoriesList.filterByCategoryType(CategoryType.COUNTRY) as CategoryModel[];
	const languagesCategories = categoriesList.filterByCategoryType(CategoryType.LANGUAGES) as CategoryModel[];
	const regionsCategories = categoriesList.filterByCategoryType(CategoryType.REGION) as CategoryModel[];

	function mapCategoriesToLabelList(
		selectedCategories: any[],
		listOfCategories: CategoryModel[],
	): LabelListItemProfileLegacy[] {
		// @ts-ignore
		return selectedCategories.map((market: any) => {
			const category = listOfCategories.find((categoryToCompare) => categoryToCompare.key === market.name);

			return (
				category && {
					idCategory: category.key,
					name: category.text,
					selected: true,
				}
			);
		});
	}

	function mapCategoriesToText(selectedCategories: any[]) {
		return selectedCategories.map((lang: any) => {
			const chosenLang = languagesCategories.find((language) => language.key === lang.name);

			return chosenLang && chosenLang.text;
		});
	}

	// @ts-ignore
	return payload.map((item) => {
		const selectedMarkets = item.sectors
			? mapCategoriesToLabelList(item.sectors, marketsCategories).filter((market: any) => market)
			: [];
		const selectedRegions = item.regions
			? mapCategoriesToLabelList(item.regions, regionsCategories).filter((region: any) => region)
			: [];
		const selectedLanguages = item.languages ? mapCategoriesToText(item.languages).filter((lang: any) => lang) : [];

		const selectedCountry = item.country && countriesCategories.find((country) => country.key === item.country.name);

		return {
			title: item.name,
			image: {
				src: item.profile_image,
				alt: item.name,
			},
			owner: {
				data: {
					id: item.id,
					name: item.name,
					emblem: item.emblem,
					slug: item.slug,
					type: item.investor_type,
					localUrl: BusinessProfileType.getURLFromType(item.investor_type, item.slug),
					image: {
						src: item.profile_image,
						alt: item.name,
					},
				},
				alreadyApplied: item.already_applied,
				isMine,
			},
			location: selectedCountry && selectedCountry.text,
			description: item.description,
			regions: {
				items: selectedRegions,
			},
			markets: {
				items: selectedMarkets,
			},
			languages: selectedLanguages,
			averageInvestment: item.average_investment || 0,
			averageEquity: item.average_investment_percentage || 0,
			numberOfStartupsToInvest: item.number_of_startups_to_invest || 0,
			type: item.investor_type,
		};
	});
}

export const convertObjectSearcherToArray = (object: any) => {
	try {
		return Object.keys(object)
			.filter((key) => object[key])
			.map(function (key) {
				return key;
			});
	} catch (err) {
		return null;
	}
};

const mapCategoriesWithKeys = (categories: CategorySelectableModel[], keyNames: string[]) => {
	return categories
		? keyNames
				.map((item) => {
					const selectedCategory = categories.find((category: any) => category.key === item);

					return selectedCategory ? selectedCategory.id : null;
				})
				.filter((item) => item)
		: [];
};

export function* getMarketsIDByListKeyNames(keyNames: string[]): any {
	const [markets] = [getSpecificCategory(CategoryType.MARKET)];

	return mapCategoriesWithKeys(markets, keyNames);
}

export function getMarketsIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.MARKET), keyNames);
}

export function* getSectorsIDByListKeyNames(keyNames: string[]): any {
	const [markets] = [getSpecificCategory(CategoryType.SECTOR)];

	return mapCategoriesWithKeys(markets, keyNames);
}

export function getSectorsIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.SECTOR), keyNames);
}

export function* getRegionsIDByListKeyNames(keyNames: string[]): any {
	const [regions] = [getSpecificCategory(CategoryType.REGION)];

	return mapCategoriesWithKeys(regions, keyNames);
}

export function getRegionsIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.REGION), keyNames);
}

export function* getSchoolsIDByListKeyNames(keyNames: string[]): any {
	const [schools] = [getSpecificCategory(CategoryType.SCHOOL)];

	return mapCategoriesWithKeys(schools, keyNames);
}

export function getSchoolsIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.SCHOOL), keyNames);
}

export function* getCountriesIDByListKeyNames(keyNames: string[]): any {
	const [countries] = [getSpecificCategory(CategoryType.COUNTRY)];

	return mapCategoriesWithKeys(countries, keyNames);
}

export function getCountriesIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.COUNTRY), keyNames);
}

export function* getLanguagesIDByListKeyNames(keyNames: string[]): any {
	const [languages] = [getSpecificCategory(CategoryType.LANGUAGES)];

	return mapCategoriesWithKeys(languages, keyNames);
}

export function getLanguagesIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.LANGUAGES), keyNames);
}

export function* getCareerModalityIDByListKeyNames(keyNames: string[]): any {
	const [modalities] = [getSpecificCategory(CategoryType.ATTENDANCE)];

	return mapCategoriesWithKeys(modalities, keyNames);
}

export function getCareerModalityIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.ATTENDANCE), keyNames);
}

export function* getTalentTypeIDByListKeyNames(keyNames: string[]): any {
	const [talentTypes] = [getSpecificCategory(CategoryType.TALENT_TYPE)];

	return mapCategoriesWithKeys(talentTypes, keyNames);
}

export function getTalentTypeIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.TALENT_TYPE), keyNames);
}

export function* getAdviserSkillsTypeIDByListKeyNames(keyNames: string[]): any {
	const [adviserSkills] = [getSpecificCategory(CategoryType.ADVISER_SKILLS)];

	return mapCategoriesWithKeys(adviserSkills, keyNames);
}

export function getAdviserSkillsTypeIDByListKeyNamesAsPromise(keyNames: string[]) {
	return mapCategoriesWithKeys(getSpecificCategory(CategoryType.ADVISER_SKILLS), keyNames);
}
