import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL, TenderMentor } from "@api";
import { CookiesUtils } from "@utils";

const removeMentorFromTender = (tenderId: number, challengeTenderConsultantId: number, config: AxiosRequestConfig) =>
	axios.delete(`${PROD_URL}/challenge-tenders/${tenderId}/consultant/${challengeTenderConsultantId}`, config);

export const useRemoveMentorFromTenderMutation = (
	tenderId: number,
	{ ...options }: UseMutationOptions<AxiosResponse, AxiosError, number> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useMutation(
		(mentorId: number) => {
			const tenderMentors = queryClient.getQueryData<TenderMentor[]>(["tender-mentors", tenderId]);
			const mentorToRemove = tenderMentors?.find((tenderMentor) => tenderMentor.consultant_profile.id === mentorId);

			if (mentorToRemove) {
				return removeMentorFromTender(tenderId, mentorToRemove?.id, getRequestConfig(accessToken));
			}

			return Promise.reject();
		},
		{
			...options,
			onSuccess: async (data, mentorId, context) => {
				const tenderMentors = queryClient.getQueryData<TenderMentor[]>(["tender-mentors", tenderId]);

				if (tenderMentors) {
					const newTenderMentorsList = tenderMentors.filter((tenderMentor) => tenderMentor.id !== mentorId);

					queryClient.setQueryData<TenderMentor[]>(["tender-mentors", tenderId], newTenderMentorsList);
				}

				await queryClient.invalidateQueries(["tender-mentors", tenderId]);
				options.onSuccess && options.onSuccess(data, mentorId, context);
			},
		},
	);
};
