import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

// The same applies to Workshops
const deleteCourse = (courseId: string, config: AxiosRequestConfig) =>
	axios.delete(`${PROD_URL}/courses/${courseId}`, config);

export const useDeleteCourseMutation = ({ ...options }: UseMutationOptions<AxiosResponse, AxiosError, string> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((courseId: string) => deleteCourse(courseId, getRequestConfig(accessToken)), {
		...options,
	});
};
