import { BusinessProfileTypes, MicroBusinessProfileTypes } from "constants/Profiles";

class BusinessProfileType {
	static getMicroTypeFromAPI = (type: string): MicroBusinessProfileTypes => {
		let microType: MicroBusinessProfileTypes;

		switch (type) {
			case "UNIVERSITY":
				microType = MicroBusinessProfileTypes.UNIVERSITY;
				break;
			case "BUSINESS_ANGEL":
				microType = MicroBusinessProfileTypes.BUSINESS_ANGEL;
				break;
			case "COMPANY":
				microType = MicroBusinessProfileTypes.COMPANY;
				break;
			case "ACCELERATOR":
				microType = MicroBusinessProfileTypes.ACCELERATOR;
				break;
			case "INSTITUTION":
				microType = MicroBusinessProfileTypes.INSTITUTION;
				break;
			case "INCUBATOR":
				microType = MicroBusinessProfileTypes.INCUBATOR;
				break;
			case "ADVISER":
			case "CONSULTANT":
				microType = MicroBusinessProfileTypes.ADVISER;
				break;
			case "STARTUP":
				microType = MicroBusinessProfileTypes.STARTUP;
				break;
			default:
				microType = "" as MicroBusinessProfileTypes;
				break;
		}

		return microType;
	};

	static convertTypeToMicroType = (type: BusinessProfileTypes) => {
		let microType: MicroBusinessProfileTypes;

		switch (type) {
			case BusinessProfileTypes.UNIVERSITY:
				microType = MicroBusinessProfileTypes.UNIVERSITY;
				break;
			case BusinessProfileTypes.BUSINESS_ANGEL:
				microType = MicroBusinessProfileTypes.BUSINESS_ANGEL;
				break;
			case BusinessProfileTypes.COMPANY:
				microType = MicroBusinessProfileTypes.COMPANY;
				break;
			case BusinessProfileTypes.ACCELERATOR:
				microType = MicroBusinessProfileTypes.ACCELERATOR;
				break;
			case BusinessProfileTypes.INSTITUTION:
				microType = MicroBusinessProfileTypes.INSTITUTION;
				break;
			case BusinessProfileTypes.INCUBATOR:
				microType = MicroBusinessProfileTypes.INCUBATOR;
				break;
			case BusinessProfileTypes.ADVISER:
			case BusinessProfileTypes.CONSULTANT:
				microType = MicroBusinessProfileTypes.ADVISER;
				break;
			case BusinessProfileTypes.STARTUP:
				microType = MicroBusinessProfileTypes.STARTUP;
				break;
		}

		return microType;
	};

	static convertMicroTypeToType = (microType: MicroBusinessProfileTypes): BusinessProfileTypes => {
		let type: BusinessProfileTypes;

		switch (microType) {
			case MicroBusinessProfileTypes.UNIVERSITY:
				type = BusinessProfileTypes.UNIVERSITY;
				break;
			case MicroBusinessProfileTypes.BUSINESS_ANGEL:
				type = BusinessProfileTypes.BUSINESS_ANGEL;
				break;
			case MicroBusinessProfileTypes.COMPANY:
				type = BusinessProfileTypes.COMPANY;
				break;
			case MicroBusinessProfileTypes.ACCELERATOR:
				type = BusinessProfileTypes.ACCELERATOR;
				break;
			case MicroBusinessProfileTypes.INSTITUTION:
				type = BusinessProfileTypes.INSTITUTION;
				break;
			case MicroBusinessProfileTypes.INCUBATOR:
				type = BusinessProfileTypes.INCUBATOR;
				break;
			case MicroBusinessProfileTypes.ADVISER:
				type = BusinessProfileTypes.ADVISER;
				break;
			case MicroBusinessProfileTypes.STARTUP:
				type = BusinessProfileTypes.STARTUP;
				break;
		}

		return type;
	};

	static getURLFromType = (type: BusinessProfileTypes, slug: string) => {
		return BusinessProfileType.getURLFromMicroType(BusinessProfileType.convertTypeToMicroType(type), slug);
	};

	static getURLFromMicroType = (type: MicroBusinessProfileTypes, slug: string) => {
		return `/${type}/${slug}`;
	};
}

export default BusinessProfileType;
