import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { CoursePayload } from "./types";

function createCourse(data: CoursePayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/courses`, data, config);
}

export const useCreateCourseMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, CoursePayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: CoursePayload) => createCourse(data, getRequestConfig(accessToken)), {
		...options,
	});
};
