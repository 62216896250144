import * as React from "react";
import classNames from "classnames";

import { MAX_COUNT_LABELS_TO_SHOW } from "constants/Format";

import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { LabelProps } from "semantic-ui-react/dist/commonjs/elements/Label/Label";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

import CategorySelectableModel from "models/categories/CategorySelectable";

export interface LabelListProfileProps {
	color: SemanticCOLORS;
	items?: CategorySelectableModel[];
	wrapperClassName?: string;
	gridClassName?: string;
	labelsClassName?: string;
	labelsTextClassName?: string;
	basic?: boolean;
	editable?: boolean;
	showAll?: boolean;
	loading?: boolean;
	onUpdate?: (labels: CategorySelectableModel[]) => void;
}

export const LabelListProfile = (props: LabelListProfileProps) => {
	const {
		color,
		basic,
		items = [],
		editable,
		wrapperClassName = "",
		gridClassName,
		labelsClassName,
		labelsTextClassName,
		loading,
		showAll,
		onUpdate,
	} = props;
	let insertedElements = 0;
	const setSelectedLabel = (selectedLabel: LabelProps) => {
		const newSetOfItems = items.map((item) => {
			return {
				...item,
				selected: item.key === selectedLabel.value ? !item.selected : item.selected,
			};
		});

		if (onUpdate) {
			onUpdate(newSetOfItems);
		}
	};
	const onClickLabel = (event: React.MouseEvent<HTMLElement, MouseEvent>, data: LabelProps) => {
		return editable && setSelectedLabel(data);
	};

	return (
		<React.Fragment>
			<ul className={wrapperClassName}>
				{loading ? (
					<Loader
						className="block"
						active={loading}
						inline="centered"
					/>
				) : (
					<div
						className={classNames(
							editable ? "grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4" : "",
							gridClassName,
						)}
					>
						{items &&
							items.map((item) => {
								const { key, text, selected } = item;

								if ((selected || editable) && (showAll || insertedElements < MAX_COUNT_LABELS_TO_SHOW)) {
									insertedElements++;

									return (
										<Label
											key={key}
											basic={!(editable && selected) && basic}
											color={selected && editable ? "green" : color}
											value={key}
											onClick={onClickLabel}
											className={classNames(editable ? "cursor-pointer" : "", "capitalize", labelsClassName)}
										>
											{selected && editable && <Icon name="check circle" />}
											{!selected && editable && (
												<Icon
													name="add circle"
													className="text-gray-200"
												/>
											)}
											<span className={classNames("capitalize", labelsTextClassName)}>{text}</span>
										</Label>
									);
								}
							})}
					</div>
				)}
			</ul>
		</React.Fragment>
	);
};
