import { URL_VINGLET_IMAGE } from "constants/URLs";
import { Image } from "elements/HeroImage/typing";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { TalentApplier } from "./TalentApplier";
import TalentFeatureModel, { TalentFeature } from "./TalentFeature";
import TalentInfoModel, { TalentInfo } from "./TalentInfo";
import TalentRequirementsModel, { TalentRequirements } from "./TalentRequirements";
import TalentRequirementsAdvisorModel, { TalentRequirementsAdvisor } from "./TalentRequirementsAdvisor";
import { StringUtils } from "utils/StringUtils";
import ProfileModel from "models/profiles/Profile";
import TalentApplierModel from "models/talents/TalentApplier";
import { PageDirectoryUtils } from "@utils";
import {Locales} from "@libs";

const DEFAULT_IMAGE = "/default-image.png";
const DEFAULT_PROFILE_IMAGE = "/default_profile_image.png";

export interface Talent {
	id: string | null;
	idChallengeTender?: string;
	title: string;
	slug: string;
	image: Image;
	owner: BusinessProfileModel | ProfileModel;
	info: TalentInfo;
	feature: TalentFeature;
	requirements: TalentRequirements;
	requirementsAdvisor: TalentRequirementsAdvisor;
	appliers: TalentApplier[];
	published: boolean;
	visitorAlreadyApplied: boolean;
	isMine: boolean;
}

class TalentModel implements Talent {
	id: string | null;
	idChallengeTender?: string;
	title: string;
	slug: string;
	image: Image;
	owner: BusinessProfileModel | ProfileModel;
	info: TalentInfoModel;
	feature: TalentFeatureModel;
	requirements: TalentRequirementsModel;
	requirementsAdvisor: TalentRequirementsAdvisorModel;
	appliers: TalentApplier[];
	published: boolean;
	visitorAlreadyApplied: boolean;
	isMine: boolean;

	constructor(
		id: string | null,
		title: string,
		slug: string,
		image: string,
		owner: BusinessProfileModel | ProfileModel,
		info: TalentInfoModel,
		feature: TalentFeatureModel,
		requirements: TalentRequirementsModel,
		appliers: TalentApplier[],
		published: boolean,
		visitorAlreadyApplied: boolean,
		isMine: boolean,
		requirementsAdvisor: TalentRequirementsAdvisorModel = TalentRequirementsAdvisorModel.generateEmpty(),
	) {
		this.id = id;
		this.title = StringUtils.capitalize(title);
		this.slug = slug;
		this.image = {
			src: this.generateAvatarURL(image),
			alt: this.title,
		};
		this.owner = owner;
		this.info = info;
		this.feature = feature;
		this.requirements = requirements;
		this.appliers = appliers;
		this.published = published;
		this.visitorAlreadyApplied = visitorAlreadyApplied;
		this.isMine = isMine;
		this.requirementsAdvisor = requirementsAdvisor;
	}

	generateAvatarURL = (image: string) => {
		if (!image) {
			return String(DEFAULT_IMAGE);
		}

		if (image === DEFAULT_IMAGE) {
			return DEFAULT_IMAGE;
		}

		// To avoid double assignment
		const urlRegex = new RegExp(URL_VINGLET_IMAGE, "g");
		const cleantImage = image.replace(urlRegex, "");

		return URL_VINGLET_IMAGE + cleantImage;
	};

	getInternalURL = (locale: Locales) => {
		let internalURL = `/id/${this.id}`;

		if (this.slug && this.slug !== "null") {
			internalURL = `/${this.slug}`;
		}

		if (this.idChallengeTender) {
			return `/tenders/${this.idChallengeTender}/talents${internalURL}`;
		} else {
			return PageDirectoryUtils.ROUTE_TALENT_WITH_LOCALE(locale) + internalURL;
		}
	};

	getOwnerURL = () => {
		return this.owner instanceof BusinessProfileModel ? this.owner.info.url : `/profile/${this.owner.links.link}`;
	};

	getOwnerFullName = () => {
		return this.owner instanceof BusinessProfileModel
			? this.owner.info.fullName
			: this.owner.info.name + " " + this.owner.info.surname;
	};

	getOwnerAvatarSrc = () => {
		let cleantImage = "";

		if (this.owner instanceof BusinessProfileModel) {
			cleantImage = this.owner.info.avatar.src;
		} else {
			cleantImage = this.owner.info.avatar;
		}

		if (cleantImage === DEFAULT_PROFILE_IMAGE) {
			return cleantImage;
		}

		// To avoid double assignment
		const urlRegex = new RegExp(URL_VINGLET_IMAGE, "g");
		cleantImage = cleantImage.replace(urlRegex, "");

		return URL_VINGLET_IMAGE + cleantImage;
	};

	getInternalAppliersURL = (locale: Locales) => {
		let internalAppliersURL = `/id/${this.id}/appliers`;

		if (this.idChallengeTender) {
			return `/tenders/${this.idChallengeTender}/talents${internalAppliersURL}`;
		} else {
			return PageDirectoryUtils.ROUTE_TALENT_WITH_LOCALE(locale) + internalAppliersURL;
		}
	};

	// This is just going to work on non-advisor talents
	exportToAPIRequest = () => {
		const talentAPI: any = {
			profile_id: this.owner instanceof BusinessProfileModel ? this.owner.info.id : this.owner.id,
			title: this.title,
			slug: StringUtils.convertStringToCorrectSlug(this.title),
			description: this.info.description,
			schools: this.feature.schools.map((item) => item.id),
			markets: this.feature.sectors.map((item) => item.id),
			sectors: this.feature.sectors.map((item) => item.id),
			skills: this.feature.skills.map((item) => item.id),
			role: this.info.role.id,
			city: this.info.location,
			country: this.info.country.id,
			published: this.published,
			expiration_date: "2099-04-03 11:11:11GMT",
			min_shares: this.requirements.minEquity,
			max_shares: this.requirements.maxEquity,
			salary: this.requirements.salary,
			min_experience: this.requirements.minExperience,
		};

		if (this.info.type.id) {
			talentAPI["type"] = this.info.type.id;
		}

		return talentAPI;
	};

	static generateFromAPI(data: any = {}) {
		const talentModel = new TalentModel(
			data.id,
			data.title,
			data.slug + "-" + data.id,
			data.profile && data.profile.profile_image,
			data.profile && data.profile.type === "USER"
				? ProfileModel.generateFromAPI(data.profile)
				: BusinessProfileModel.generateFromAPI(data.profile),
			TalentInfoModel.generateFromAPI(data),
			TalentFeatureModel.generateFromAPI(data),
			TalentRequirementsModel.generateFromAPI(data),
			[],
			data.published,
			data.already_applied,
			data.is_mine,
			TalentRequirementsAdvisorModel.generateFromAPI(data),
		);
		talentModel.idChallengeTender = data.challenge_tender ? data.challenge_tender.id : "";

		return talentModel;
	}

	static generateEmpty() {
		return new TalentModel(
			null,
			"",
			"",
			"",
			BusinessProfileModel.generateEmpty(),
			TalentInfoModel.generateEmpty(),
			TalentFeatureModel.generateEmpty(),
			TalentRequirementsModel.generateEmpty(),
			[],
			false,
			false,
			false,
		);
	}

	static generateFromObject(talentObject: Talent) {
		const talentModel = new TalentModel(
			talentObject.id,
			talentObject.title,
			talentObject.slug,
			talentObject.image.src,
			talentObject.owner && (talentObject.owner as any).info.type
				? BusinessProfileModel.generateFromObject(talentObject.owner as BusinessProfileModel)
				: ProfileModel.generateFromObject(talentObject.owner as ProfileModel),
			TalentInfoModel.generateFromObject(talentObject.info),
			TalentFeatureModel.generateFromObject(talentObject.feature),
			TalentRequirementsModel.generateFromObject(talentObject.requirements),
			talentObject.appliers
				? talentObject.appliers.map((applier) => TalentApplierModel.generateFromObject(applier))
				: [],
			talentObject.published,
			talentObject.visitorAlreadyApplied,
			talentObject.isMine,
			TalentRequirementsAdvisorModel.generateFromObject(talentObject.requirementsAdvisor),
		);
		talentModel.idChallengeTender = talentObject.idChallengeTender;

		return talentModel;
	}
}

export default TalentModel;
