import { NotificationContext } from "./NotificationContext";
import { ErrorToast, SuccessToast } from "@common";
import { useState } from "react";

type NotificationType = "error" | "success";

export const NotificationProvider = ({ children }: { children: JSX.Element }) => {
	const [notification, setNotification] = useState<
		| {
				message: string;
				type: NotificationType;
		  }
		| undefined
	>(undefined);

	const addSuccessNotification = (message: string) => {
		setNotification({
			message,
			type: "success",
		});
	};

	const addErrorNotification = (message: string) => {
		setNotification({
			message,
			type: "error",
		});
	};

	return (
		<NotificationContext.Provider
			value={{
				addSuccessNotification,
				addErrorNotification,
			}}
		>
			{children}
			{notification && (
				<div className="z-index-99 fixed bottom-0 flex h-0 h-full w-full items-center justify-center">
					<div className="absolute bottom-16 w-96">
						{notification.type === "error" && (
							<ErrorToast
								message={notification.message}
								closable
								fluid
							/>
						)}
						{notification.type === "success" && (
							<SuccessToast
								message={notification.message}
								closable
								fluid
							/>
						)}
					</div>
				</div>
			)}
		</NotificationContext.Provider>
	);
};
