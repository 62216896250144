import { Toast } from "flowbite-react";
import { Check } from "@common";

export const SuccessToast = ({
	message,
	closable,
	fluid,
}: {
	message: string;
	closable?: boolean;
	fluid?: boolean;
}) => {
	return (
		<Toast
			style={{
				width: fluid ? "100%" : undefined,
			}}
		>
			<div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
				<Check className="h-5 w-5" />
			</div>
			<div className="ml-3 text-base font-normal">{message}</div>
			{closable && <Toast.Toggle />}
		</Toast>
	);
};
