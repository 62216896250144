import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, INTERACTION_SERVICE_URL } from "../config";

interface FetchNewInvitationsWithoutBeingRead {
	areThereNewInvitations: boolean;
}

function fetchNewInvitationsWithoutBeingRead(userId: string, accessToken: string) {
	return function () {
		return axios
			.get(`${INTERACTION_SERVICE_URL}/connect/${userId}/check/new-invitations`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useNewInvitationsWithoutBeingRead = (
	userId: string,
	options: UseQueryOptions<FetchNewInvitationsWithoutBeingRead> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<FetchNewInvitationsWithoutBeingRead>(
		["new-invitations-without-being-read", accessToken],
		fetchNewInvitationsWithoutBeingRead(userId, accessToken),
		{
			...options,
		},
	);
};
