import ProfileModel from "models/profiles/Profile";
import { Reducer } from "redux";
import { User } from "redux-app/actions/UserAction";
import { UserState } from "./typing";

const initialState: UserState = {
	profile: ProfileModel.generateEmpty(),
};

const userReducer: Reducer<UserState> = (state: UserState = initialState, action: User.UserActionTypes) => {
	switch (action.type) {
		case User.USER_SET_PROFILE:
		case User.USER_SET_PROFILE_STARTED:
		case User.USER_SET_PROFILE_FINISH:
		case User.USER_SET_PROFILE_FINISH_WITH_ERRORS:
			state = {
				...state,
				profile: {
					...state.profile,
					...action.payload,
				},
			};
			break;
		case User.USER_SET_LINK:
			state = {
				...state,
				profile: {
					...state.profile,
					links: {
						...state.profile.links,
						link: action.payload,
					},
				},
			};
			break;
		case User.USER_CHECK_LINKS_STATUS:
			state = {
				...state,
				profile: {
					...state.profile,
					links: {
						...state.profile.links,
						checking: action.payload,
					},
				},
			};
			break;
		case User.USER_CHECK_LINKS_FAILED:
			state = {
				...state,
				profile: {
					...state.profile,
					links: {
						...state.profile.links,
						isValid: false,
						checking: false,
					},
				},
			};
			break;
		case User.USER_CHECK_LINKS_SUCCEDED:
			state = {
				...state,
				profile: {
					...state.profile,
					links: {
						...state.profile.links,
						isValid: true,
						checking: false,
					},
				},
			};
			break;
		case User.USER_FETCH_ONBOARDING_FAILED:
			state = {
				...state,
				profile: {
					...state.profile,
					request: {
						...state.profile.request,
						done: true,
						error: action.payload,
						loading: false,
					},
				},
			};
			break;
		case User.USER_FETCH_ONBOARDING_SUCCEDED:
			state = {
				...state,
				profile: {
					...state.profile,
					request: {
						...state.profile.request,
						done: true,
						error: "",
						loading: false,
					},
				},
			};
			break;
		case User.USER_RECEIVE_PROFILE_SUCCEDED:
			state = {
				profile: action.payload,
			};
			break;
		case User.USER_RECEIVE_DATA_API:
			state = {
				profile: action.payload,
			};
			break;
	}

	return state;
};

export default userReducer;
