import { defineMessages } from "react-intl";

export default defineMessages({
	talentsNotUploadedYet: {
		defaultMessage: "The owners of this proposals have not created any talent yet",
	},
	talentsOffers: {
		defaultMessage: "Talents offers",
	},
	loadMoreTalents: {
		defaultMessage: "Load +{numberOfHiddenTalents}",
	},
});
