import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";
import { Category } from "../common-types";
import { UserProfile } from "../users/types";

interface ChallengeTender {
	id: number;
	title: string;
	profile_image: string;
}

type Skill = Category;
type Country = Category;
type Type = Category;
type School = Category;
type Sector = Category;
type Role = Category;
type Languages = Category;

export interface TenderTalent {
	id: number;
	min_experience: number;
	salary: number;
	min_shares: number;
	max_shares: number;
	slug: string;
	expiration_date: string;
	title: string;
	description: string;
	city: string;
	salary_currency?: string;
	published: boolean;
	challenge_tender: ChallengeTender;
	profile: UserProfile;
	type: Type;
	role: Role;
	country: Country;
	schools: School[];
	skills: Skill[];
	sectors: Sector[];
	languages: Languages[];
}

function fetchTenderTalents(tenderId: number, accessToken: string) {
	return function () {
		return axios
			.get<TenderTalent[]>(`${PROD_URL}/challenge-tenders/${tenderId}/talents`, getRequestConfig(accessToken))
			.then((response) => response.data)
			.then((tenderTalents: TenderTalent[]) => tenderTalents.reverse());
	};
}

export const useTenderTalentsQuery = (tenderId: number, options: UseQueryOptions<TenderTalent[]> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<TenderTalent[]>(["tender-talents", tenderId], fetchTenderTalents(tenderId, accessToken), {
		...options,
	});
};
