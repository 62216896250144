import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";
import { getRequestConfig, PROD_URL, GenericBusinessProfilePayload, BusinessProfileTypes } from "@api";

type IncubatorProfilePayload = Omit<GenericBusinessProfilePayload, "investor_type" | "type">;

function createIncubator(data: IncubatorProfilePayload, config: AxiosRequestConfig) {
	return axios
		.post(
			`${PROD_URL}/investors-profiles`,
			{
				...data,
				investor_type: BusinessProfileTypes.INCUBATOR,
				type: BusinessProfileTypes.INCUBATOR,
			},
			config,
		)
		.then((response) => {
			return response.status;
		});
}

export const useCreateIncubatorMutation = (
	options: UseMutationOptions<number, AxiosError, IncubatorProfilePayload> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: IncubatorProfilePayload) => createIncubator(data, getRequestConfig(accessToken)), {
		...options,
	});
};
