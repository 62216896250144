import {
	Label as FlowbiteLabel,
	Select as FlowbiteSelect,
	SelectProps as FlowbiteSelectProps,
	Tooltip as FlowbiteTooltip,
	TooltipProps as FlowbiteTooltipProps,
} from "flowbite-react";
import { InformationCircle } from "../../Icons";

export const Select = ({
	labelValue,
	labelTooltip,
	...rest
}: FlowbiteSelectProps & {
	labelValue?: string;
	labelTooltip?: FlowbiteTooltipProps;
}) => {
	return (
		<div>
			{labelValue && (
				<div className="mb-2 block">
					<div className="mb-2 flex gap-2 text-sm font-medium text-gray-900 dark:text-white">
						<FlowbiteLabel
							htmlFor={rest.id}
							value={labelValue}
						/>
						{labelTooltip?.content && (
							<FlowbiteTooltip {...labelTooltip}>
								<InformationCircle className="h-5 w-5" />
							</FlowbiteTooltip>
						)}
					</div>
				</div>
			)}
			<FlowbiteSelect {...rest} />
		</div>
	);
};
