import { getSpecificCategory } from "api/CategoriesAPI";
import { CategoryType } from "api/CategoriesAPI/urls";
import BusinessProfileInfoModel from "models/business-profiles/BusinessProfileInfo";
import { UnitBusinessProfile, UserState } from "redux-app/reducers/UserReducer/typing";
import { DateUtils } from "@utils";

function FactoryGender(gender: string) {
	let apiGender;

	switch (gender) {
		case "male":
			apiGender = "MALE";
			break;
		case "female":
			apiGender = "FEMALE";
			break;
		case "indeterminate":
		case "":
			apiGender = "OTHERS";
			break;
	}

	return apiGender;
}

export function* convertUserStateToRequestAPI(data: UserState): any {
	const {
		profile: { info, overview, links, skills },
	} = data;
	const [countries] = [getSpecificCategory(CategoryType.COUNTRY)];

	function getFallbackCountry() {
		return countries.find((place: any) => place.key === "argentina");
	}

	function getCountry() {
		return countries.find((country: any) => overview.country && country.key === overview.country.value);
	}

	function getSkills() {
		return skills.items ? skills.items.map((item) => item.id).filter((item) => item) : [];
	}

	function getInitialImage() {
		return info.getCleantImage() || undefined;
	}

	const selectedCountry = getCountry() || getFallbackCountry();

	return {
		emblem: info.emblem,
		name: info.name,
		first_name: info.name,
		last_name: info.surname,
		skills: getSkills(),
		educations: [] as string[],
		languages: [] as string[],
		areas: [] as string[],
		jobs: [] as string[],
		schools: [] as string[],
		gender: FactoryGender(overview.gender),
		birthday: {
			datetime: DateUtils.getTimeAPIFormat(overview.birthdate),
			datetimezone: DateUtils.getCurrentTimezone(),
		},
		country: selectedCountry?.id,
		slug: links.link,
		description: "",
		cover_image: getInitialImage(),
		profile_image: getInitialImage(),
		social_networks: [] as string[],
		user: {
			name: info.name,
			first_name: info.name,
			last_name: info.surname,
		},
	};
}

export function convertBusinessProfileResponseToListUnitBusinessProfile(data: any): UnitBusinessProfile[] {
	return data ? data.map((item: any) => BusinessProfileInfoModel.generateFromAPI(item)) : [];
}
