export const WolrdEs = `
{
    "region": {
        "key": {
            "singular": "Región",
            "plural": "Regiones"
        },
        "items": {
            "europe": "Europa",
            "northAmerica": "América del Norte",
            "centralAmerica": "América Central",
            "southAmerica": "América del Sur",
            "asia": "Asia",
            "africa": "Africa",
            "oceania": "Oceania"
        }
    },
    "country": {
        "key": {
            "singular": "Pais",
            "plural": "Paises"
        }, 
        "items": {
            "argentina": "Argentina",
            "chile": "Chile",
            "colombia": "Colombia",
            "france": "Francia",
            "germany": "Alemania",
            "ireland": "Irlanda",
            "israel": "Israel",
            "mexico": "Mexico",
            "netherlands": "Holanda",
            "peru": "Peru",
            "portugal": "Portugal",
            "spain": "España",
            "sweden": "Suecia",
            "unitedKingdom": "Reino Unido",
            "unitedStates": "Estados Unidos",
            "uruguay": "Uruguay",
            "venezuela": "Venezuela",
            "ecuador":"Ecuador",
            "brazil":"Brasil"
        }
    },
    "language": {
        "key": {
            "singular": "Lenguaje",
            "plural": "Lenguajes"
        },
        "items": {
            "catalan": "Catalán",
            "simplifiedchinese": "Chino Simplificado",
            "traditionalchinese": "Chino Tradicional",
            "spanish": "Español",
            "french": "Francés",
            "english": "Ingles",
            "italian": "Italiano",
            "japanese": "Japonés",
            "portuguese": "Portugués",
            "russian": "Ruso",
            "german": "Aleman",
            "hindi": "Hindi",
            "arab": "Árabe",
            "hebrew": "Hebreo"
        }
    }
}
`;
