import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

import { WorkshopPayload } from "./types";

function createWorkshop(data: WorkshopPayload, config: AxiosRequestConfig) {
	return axios.post(`${PROD_URL}/courses`, data, config);
}

export const useCreateWorkshopMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, WorkshopPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: WorkshopPayload) => createWorkshop(data, getRequestConfig(accessToken)), {
		...options,
	});
};
