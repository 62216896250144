import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../../config";

interface SocialNetworkPayload {
	url: string;
	social_network_id: number;
}

type AdviserProfilePayload = {
	currency: string;
	name: string;
	city: string;
	url: string;
	email: string;
	emblem: string;
	description: string;
	slug: string;
	cover_image?: string;
	profile_image?: string;
	cost: number;
	percentage: number;
	country?: number;
	languages: number[];
	areas: number[];
	skills: number[];
	region: number[];
	social_networks: SocialNetworkPayload[];
};

function createAdviser(data: AdviserProfilePayload, config: AxiosRequestConfig) {
	return axios
		.post(
			`${PROD_URL}/consultants-profile`,
			{
				...data,
			},
			config,
		)
		.then((response) => {
			return response.status;
		});
}

export const useCreateAdviserMutation = (
	options: UseMutationOptions<number, AxiosError, AdviserProfilePayload> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: AdviserProfilePayload) => createAdviser(data, getRequestConfig(accessToken)), {
		...options,
	});
};
