import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getRequestConfig, PROD_URL } from "@api";
import { CookiesUtils } from "@utils";

type DeleteTalentPayload = {
	talentId: string;
	profileId: string;
};

function deleteTalent(payload: DeleteTalentPayload, config: AxiosRequestConfig) {
	return axios.delete(`${PROD_URL}/talents/${payload.talentId}/delete`, {
		...config,
		data: {
			profile_id: payload.profileId,
		},
	});
}

export const useDeleteTalentMutation = ({
	...options
}: UseMutationOptions<AxiosResponse, AxiosError, DeleteTalentPayload> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((payload: DeleteTalentPayload) => deleteTalent(payload, getRequestConfig(accessToken)), {
		...options,
	});
};
