export enum CategoryType {
	SKILL = "SKILL",
	ADVISER_SKILLS = "CONSULTANT", // AKA ADVISER_SKILLS
	SCHOOL = "SCHOOL",
	ASESOR = "ASESOR",
	SECTOR = "SECTOR",
	MARKET = "MARKET",
	LANGUAGES = "LANGUAGES",
	SOCIAL_NETWORK = "SOCIAL_NETWORK",
	ATTENDANCE = "ATTENDANCE",
	AREA = "AREA",
	TALENT_ROLE = "TALENT_ROLE",
	TALENT_TYPE = "TALENT_TYPE",
	COUNTRY = "COUNTRY",
	REGION = "REGION",
	EDUCATION = "EDUCATION",
	APPLIER_STATUS = "APPLIERSTATUS",
	TOPIC = "TOPIC",
}

export interface User {
	id: number;
	first_name: string;
	last_name: string;
	second_last_name: string;
	email: string;
	provider: string;
	phone_number: string;
	roles: string[];
}

export interface Category {
	id: number;
	name: string;
	category: CategoryType;
	subcategory?: string | null;
}
