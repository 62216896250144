import { Translation } from "i18n/config";
import * as React from "react";
import {
	ElearningTranslation,
	MarketplaceTranslation,
	PostulateTranslation,
	ProfileTranslation,
	StartUpsTranslation,
	TalentTranslation,
} from "./typing";

const StartUpTexts: Translation<StartUpsTranslation> = {
	es: {
		title: "Startups",
		description: "Bienvenido a la cuarta revolución industrial: Startups",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	en: {
		title: "Startups",
		description: "Welcome to the fourth industrial revolution: Startups",
		searchButton: () => {
			return <button>Search</button>;
		},
	},
	ca: {
		title: "Startups",
		description: "Benvingut a la quarta revolució industrial: Startups",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	pt: {
		title: "Startups",
		description: "Bem-vindo à quarta revolução industrial: Startups",
		searchButton: () => {
			return <button>Procurar</button>;
		},
	},
};

const TalentTexts: Translation<TalentTranslation> = {
	es: {
		title: "Talento",
		description: "Ofertas de Co-founders, Talento y Asesores que contendrán tu propuesta de emprendimiento",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	en: {
		title: "Talent",
		description: "Offers of Co-founders, Talent and Advisors that will contain your Startup’s proposal",
		searchButton: () => {
			return <button>Search</button>;
		},
	},
	ca: {
		title: "Talent",
		description: "Ofertes de Co-Founders, Talent i Assessors que contindran la teva proposta d&#39;emprenedoria",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	pt: {
		title: "Talento",
		description: "Ofertas de Co-fundadores, Talentos e Consultores que conterão sua proposta de empreendedorismo",
		searchButton: () => {
			return <button>Procurar</button>;
		},
	},
};

const ELearningTexts: Translation<ElearningTranslation> = {
	es: {
		title: "E-learning",
		description: "Capacítate con webinars, cursos, talleres, online, semipresencial y presencial",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	en: {
		title: "E-learning",
		description: "Train yourself with webinars, courses, workshops, online, blended and face-to-face",
		searchButton: () => {
			return <button>Search</button>;
		},
	},
	ca: {
		title: "E-Learning",
		description: "Capacita't amb webinars, cursos, tallers, online, semipresencial i presencial",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	pt: {
		title: "E-learning",
		description: "Treine-se com webinars, cursos, workshops, online, combinados e presenciais",
		searchButton: () => {
			return <button>Procurar</button>;
		},
	},
};

const MarketplaceTexts: Translation<MarketplaceTranslation> = {
	es: {
		title: "Marketplace",
		description: "Ofertas de productos y servicios de Startups: valida, compra o contrata",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	en: {
		title: "Marketplace",
		description: "Offers of products and services of Startups: validate, buy or contract",
		searchButton: () => {
			return <button>Search</button>;
		},
	},
	ca: {
		title: "Marketplace",
		description: "Ofertes de productes i serveis de Startups: valida, compra o contracta",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	pt: {
		title: "Marketplace",
		description: "Ofertas de produtos e serviços de startups: valide, compre ou alugue",
		searchButton: () => {
			return <button>Procurar</button>;
		},
	},
};

const PostulateTexts: Translation<PostulateTranslation> = {
	es: {
		title: "Postúlate",
		description: "Bienvenida Startup: antes de contactar con un Inversor, aconsejamos que cargues métricas",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	en: {
		title: "Postulate",
		description: "Welcome Startup: before contacting an Investor, we advise you to upload metrics",
		searchButton: () => {
			return <button>Search</button>;
		},
	},
	ca: {
		title: "Postulate",
		description: "Benvinguda Startup: abans de contactar amb un Inversor, aconsellem que carreguis mètriques",
		searchButton: () => {
			return <button>Buscar</button>;
		},
	},
	pt: {
		title: "Aplique",
		description:
			"Bem-vindo Startup: antes de entrar em contato com um Investidor, recomendamos que você carregue as métricas",
		searchButton: () => {
			return <button>Procurar</button>;
		},
	},
};

const ProfileTexts: Translation<ProfileTranslation> = {
	es: {
		overview: "Info adicional",
		info: "Información",
		social: "Social",
		about: "Acerca de",
		skills: "Habilidades",
		experience: "Experiencia",
		studies: "Estudios",
		awards: "Premios",
		noLoaded: "Sin información",
		doubtsOnHowToCreateChallenge: "¿Tienes dudas sobre cómo crear un Reto?",
		linkToTheVideoTutorial: "Enlace al videotutorial",
	},
	en: {
		overview: "Additional info",
		info: "Info",
		social: "Social",
		about: "About",
		skills: "Skills",
		experience: "Experience",
		studies: "Estudios",
		awards: "Awards",
		noLoaded: "No info to show",
		doubtsOnHowToCreateChallenge: "Do you have any doubts about how to create a Challenge?",
		linkToTheVideoTutorial: "Link to the video tutorial",
	},
	ca: {
		overview: "Info addicional",
		info: "Informacio",
		social: "Social",
		about: "Quant a",
		skills: "Habilitats",
		experience: "Experiència",
		studies: "Estudis",
		awards: "Premis",
		noLoaded: "Sense informació",
		doubtsOnHowToCreateChallenge: "Tens algun dubte sobre com crear un repte?",
		linkToTheVideoTutorial: "Enllaç al videotutorial",
	},
	pt: {
		overview: "Informação adicional",
		info: "Informação",
		social: "Social",
		about: "Sobre",
		skills: "Habilidades",
		experience: "Experiência",
		studies: "Estudos",
		awards: "Prêmios",
		noLoaded: "Sem informação",
		doubtsOnHowToCreateChallenge: "Tem alguma dúvida sobre como criar um Desafio?",
		linkToTheVideoTutorial: "Link para o vídeo tutorial",
	},
};

export { StartUpTexts, TalentTexts, ELearningTexts, MarketplaceTexts, PostulateTexts, ProfileTexts };
