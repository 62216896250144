import CategoryModel, { CategoryAPI } from "models/categories/Category";
import CategorySelectableModel, { CategorySelectable } from "models/categories/CategorySelectable";
import SkillModel, { Skill } from "models/skills/Skill";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

export interface TalentFeature {
	sectors: CategorySelectable[];
	schools: CategorySelectable[];
	skills: Skill[];
	regions: CategorySelectable[];
	languages: CategorySelectable[];
	adviserSkills: Skill[];
}

class TalentFeatureModel implements TalentFeature {
	sectors: CategorySelectable[];
	schools: CategorySelectable[];
	skills: Skill[];
	regions: CategorySelectable[];
	languages: CategorySelectable[];
	adviserSkills: Skill[];

	constructor(
		sectors: CategorySelectable[],
		schools: CategorySelectable[],
		skills: Skill[],
		regions: CategorySelectable[],
		languages: CategorySelectable[],
		adviserSkills: Skill[] = [],
	) {
		this.sectors = sectors;
		this.schools = schools;
		this.skills = skills;
		this.regions = regions;
		this.languages = languages;
		this.adviserSkills = adviserSkills;
	}

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}) {
		const categoriesServices = CategoriesServiceSingleton.getList();
		const { market: sectorsTranslations, school: schoolsTranslations } =
			TranslationsServiceSingleton.getFeatureTranslations(["market", "school"]);
		const skillsTranslations = TranslationsServiceSingleton.getFeatureSkillsTranslations();
		const adviserSkillsTranslations = TranslationsServiceSingleton.getFeatureAdviserSkillsTranslations();
		const { language: languagesTranslations, region: regionsTranslations } =
			TranslationsServiceSingleton.getWorldTranslations(["language", "region"]);
		// SCHOOLS
		const idsSelectedSchools = data.schools ? data.schools.map((item: CategoryAPI) => item.id) : [];
		const selectedSchools = categoriesServices.filterById(idsSelectedSchools) as CategoryModel[];
		// SECTORS
		const idsSelectedSectors = data.sectors ? data.sectors.map((item: CategoryAPI) => item.id) : [];
		const selectedSectors = categoriesServices.filterById(idsSelectedSectors) as CategoryModel[];
		// SKILLS
		let selectedSkills = data.skills ? data.skills : [];
		// ADVISER'S SKILLS
		let selectedAdviserSkills = data.categories ? data.categories : [];
		// LANGUAGES
		const idsSelectedLanguages = data.languages ? data.languages.map((item: CategoryAPI) => item.id) : [];
		const selectedLanguages = categoriesServices.filterById(idsSelectedLanguages) as CategoryModel[];
		// REGIONS
		const idsSelectedRegions = data.regions ? data.regions.map((item: CategoryAPI) => item.id) : [];
		const selectedRegions = categoriesServices.filterById(idsSelectedRegions) as CategoryModel[];

		try {
			selectedSchools.map((school) => {
				if (schoolsTranslations.items[school.key]) {
					school.setText(schoolsTranslations.items[school.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(school, true);
			});
			selectedSectors.map((sector) => {
				if (sectorsTranslations.items[sector.key]) {
					sector.setText(sectorsTranslations.items[sector.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(sector, true);
			});
			selectedSkills = selectedSkills.map((skill: any) => {
				const skillModel = new SkillModel(skill.id, skill.name, 0, "blue", skillsTranslations.items[skill.name]);

				return skillModel;
			});
			selectedAdviserSkills = selectedAdviserSkills.map((skill: any) => {
				const skillModel = new SkillModel(
					skill.id,
					skill.name,
					0,
					"yellow",
					adviserSkillsTranslations.items[skill.name],
				);

				return skillModel;
			});
			selectedLanguages.map((language) => {
				if (languagesTranslations.items[language.key]) {
					language.setText(languagesTranslations.items[language.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(language, true);
			});
			selectedRegions.map((region) => {
				if (regionsTranslations.items[region.key]) {
					region.setText(regionsTranslations.items[region.key]);
				}

				return CategorySelectableModel.createFromCommonCategory(region, true);
			});
		} catch (err) {}

		return new TalentFeatureModel(
			selectedSectors,
			selectedSchools,
			selectedSkills,
			selectedRegions,
			selectedLanguages,
			selectedAdviserSkills,
		);
	}

	static generateEmpty() {
		return new TalentFeatureModel([], [], [], [], []);
	}

	static generateFromObject(talentObject: TalentFeature) {
		return new TalentFeatureModel(
			talentObject.sectors,
			talentObject.schools,
			talentObject.skills,
			talentObject.regions,
			talentObject.languages,
		);
	}
}

export default TalentFeatureModel;
