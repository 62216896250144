import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";
import { User } from "../common-types";

function fetchUserLoggedIn(accessToken: string) {
	return function () {
		return axios.get(`${PROD_URL}/user-logged-in`, getRequestConfig(accessToken)).then((response) => response.data);
	};
}

export const useUserLoggedInQuery = (options: UseQueryOptions<User> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<User>(["current-user-logged-in", accessToken], fetchUserLoggedIn(accessToken), {
		...options,
	});
};
