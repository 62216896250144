export const WolrdPrt = `
{
    "region": {
        "key": {
            "singular": "Região",
            "plural": "Regiões"
        },
        "items": {
            "europe": "Europa",
            "northAmerica": "América do Norte",
            "centralAmerica": "América Central",
            "southAmerica": "América do Sul",
            "asia": "Ásia",
            "africa": "África",
            "oceania": "Oceânia"
        }
    },
    "country": {
        "key": {
            "singular": "País",
            "plural": "Países"
        }, 
        "items": {
            "argentina": "Argentina",
            "chile": "Chile",
            "colombia": "Colômbia",
            "france": "França",
            "germany": "Alemanha",
            "ireland": "Irlanda",
            "israel": "Israel",
            "mexico": "México",
            "netherlands": "Holanda",
            "peru": "Peru",
            "portugal": "Portugal",
            "spain": "Espanha",
            "sweden": "Suécia",
            "unitedKingdom": "Reino Unido",
            "unitedStates": "Estados Unidos",
            "uruguay": "Uruguai",
            "venezuela": "Venezuela",
            "ecuador":"Ecuador",
            "brazil":"Brasil"
        }
    },
    "language": {
        "key": {
            "singular": "Linguagem",
            "plural": "Línguas"
        },
        "items": {
            "catalan": "Catalão",
            "simplifiedchinese": "Chinês simplificado",
            "traditionalchinese": "Chinês tradicional",
            "spanish": "Espanhol",
            "french": "Francês",
            "english": "Inglês",
            "italian": "Italiano",
            "japanese": "Japonês",
            "portuguese": "Português",
            "russian": "Russo",
            "german": "Alemão",
            "hindi": "Hindi",
            "arab": "Árabe",
            "hebrew": "Hebraico"
        }
    }
}
`;
