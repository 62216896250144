import {HeaderTexts} from "i18n/containers/Header";
import {HeaderTranslation} from "i18n/containers/Header/typing";
import withRouter, {WithRouterProps} from "next/dist/client/with-router";
import * as React from "react";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import {RightMenuLoggedUserProps} from "./typing";
import RightMenuLoggedUserDropdownLinks from "containers/Header/RightMenuLoggedUser/RightMenuLoggedUserDropdownLinks";
import RightMenuSubItems from "containers/Header/RightMenuLoggedUser/RightMenuSubItems";
import {StringUtils} from "utils/StringUtils";
import {RightMenuSubItemsProps} from "../typing";
import Link from "next/link";
import {Button} from "@common";
import {useLocales} from "@libs";

const RightMenuLoggedUser = (props: RightMenuLoggedUserProps & WithRouterProps) => {
    const {profileUrl, profileFullName, profileEmblem, profileAvatarSrc, businessProfiles, logout} = props;
    const {currentShortLocale} = useLocales();
    const headerTranslation: HeaderTranslation = {
        ...HeaderTexts[currentShortLocale],
    };

    const dispatchSubItemsActions = (subItem: RightMenuSubItemsProps) => () => {
        if (subItem.action) {
            subItem.action();
        }
    };

    const subItems: RightMenuSubItems[] = [
        new RightMenuSubItems(headerTranslation.myProfile, `/profile/${profileUrl}`, "user"),
        new RightMenuSubItems(headerTranslation.setting, "/settings", "settings"),
        new RightMenuSubItems(headerTranslation.pricing, "/pricing", "shopping cart"),
        new RightMenuSubItems(headerTranslation.help, "/help", "help"),
        new RightMenuSubItems(headerTranslation.logout, "", "log out", logout),
    ];

    return (
        <Menu.Menu
            className="right-menu"
            position="right"
        >
            <Menu.Item name="suitcase">
                <Link
                    href={"/business-profile/new"}
                    passHref
                >
                    <a>
                        <Button color="purple">{headerTranslation.createBusinessProfile}</Button>
                    </a>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Image
                    src={profileAvatarSrc}
                    avatar
                />
                <span className="padding-1-x">
					<b> {profileFullName} </b>
					<p> {StringUtils.getLimittedParagraph(profileEmblem, 10)} </p>
				</span>
                <RightMenuLoggedUserDropdownLinks
                    businessProfiles={businessProfiles}
                    rightMenuSubItemsProps={subItems}
                    dispatchSubItemsActions={dispatchSubItemsActions}
                />
            </Menu.Item>
        </Menu.Menu>
    );
};

export default React.memo(withRouter(RightMenuLoggedUser));
