import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

import { Challenge } from "./types";

function fetchChallenge(challengeSlug: string, accessToken: string) {
	return function () {
		return axios
			.get<Challenge>(`${PROD_URL}/challenges/${challengeSlug}`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useChallengeQuery = (challengeSlug: string, options: UseQueryOptions<Challenge> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<Challenge>(["challenge", challengeSlug], fetchChallenge(challengeSlug, accessToken), {
		...options,
	});
};
