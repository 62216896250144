import { PROD_URL } from "api/config";

const URL_POST_NEW_TALENT = `${PROD_URL}/talents`;
const URL_POST_PATCH_TALENT = `${PROD_URL}/talents`;
const URL_GET_ALL_TALENT = `${PROD_URL}/talents`;
const URL_GET_UNIT_TALENT = (talentId: string) => `${PROD_URL}/talents/${talentId}`;
const URL_GET_TALENT_BY_SLUG = (talentSlug: string) => `${PROD_URL}/talents/${talentSlug}`;
const URL_PATCH_TALENT = (talentId: string) => `${PROD_URL}/talents/${talentId}`;
const URL_GET_TALENT_PROFILE_BY_SLUG = (slug: string) => `${PROD_URL}/talents/profile/${slug}?orderDirection=DSC`;
const URL_DELETE_TALENT = (talentId: string) => `${PROD_URL}/talents/${talentId}/delete`;
const URL_ADD_APPLIER_TALENT = (talentId: string) => `${PROD_URL}/talents/${talentId}/add-applier`;
const URL_CHANGE_STATUS_APPLIER_TALENT = (applicationId: number) => `${PROD_URL}/application/${applicationId}`;
const URL_POST_APPLIER_TENDER_TEAM = (challengeTenderID: number) =>
	`${PROD_URL}/challenge-tenders/${challengeTenderID}/team`;
const URL_GET_ALL_APPLIERS_TALENT = (talentId: string) => `${PROD_URL}/talents/${talentId}/appliers`;

export {
	URL_POST_NEW_TALENT,
	URL_POST_PATCH_TALENT,
	URL_GET_ALL_TALENT,
	URL_GET_UNIT_TALENT,
	URL_GET_TALENT_BY_SLUG,
	URL_GET_TALENT_PROFILE_BY_SLUG,
	URL_PATCH_TALENT,
	URL_DELETE_TALENT,
	URL_ADD_APPLIER_TALENT,
	URL_POST_APPLIER_TENDER_TEAM,
	URL_CHANGE_STATUS_APPLIER_TALENT,
	URL_GET_ALL_APPLIERS_TALENT,
};
