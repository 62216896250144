import { getLocalizationByLanguage, getLocalizationByLanguageLEGACY } from "i18n/localization/localization";

import {Locales} from "@libs";

import { I18N } from "./typing";
import {CookiesUtils} from "@utils";

export const LanguageOptions = [
	//{ key: 'Arabic', text: 'Arabic', value: 'Arabic' },
	{ key: Locales.catalan, text: "Catalan", value: "Catalan" },
	//{ key: 'Chinese', text: 'Chinese', value: 'Chinese' },
	//{ key: 'Danish', text: 'Danish', value: 'Danish' },
	//{ key: 'Dutch', text: 'Dutch', value: 'Dutch' },
	{ key: Locales.english, text: "English", value: "English" },
	//{ key: 'French', text: 'French', value: 'French' },
	//{ key: 'German', text: 'German', value: 'German' },
	//{ key: 'Greek', text: 'Greek', value: 'Greek' },
	//{ key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
	//{ key: 'Italian', text: 'Italian', value: 'Italian' },
	//{ key: 'Japanese', text: 'Japanese', value: 'Japanese' },
	//{ key: 'Korean', text: 'Korean', value: 'Korean' },
	//{ key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
	//{ key: 'Persian', text: 'Persian', value: 'Persian' },
	//{ key: 'Polish', text: 'Polish', value: 'Polish' },
	{ key: Locales.portuguese, text: "Portuguese", value: "Portuguese" },
	//{ key: 'Russian', text: 'Russian', value: 'Russian' },
	{ key: Locales.spanish, text: "Spanish", value: "Spanish" },
	//{ key: 'Swedish', text: 'Swedish', value: 'Swedish' },
	//{ key: 'Turkish', text: 'Turkish', value: 'Turkish' },
	//{ key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' },
];

export const getSystemConstants = (): I18N[] => {
	const language = CookiesUtils.getCookie("lang") || "es";
	const localizations = getLocalizationByLanguage(language);

	return JSON.parse(localizations);
};

export const getSystemConstantsLEGACY = () => {
	//TODO 2023 Fix and get the language dynamically
	const language = CookiesUtils.getCookie("lang") || "es";
	return getLocalizationByLanguageLEGACY(language).then((response: string) => {
		try {
			JSON.parse(response);
		} catch (err) {
			console.log(err);
		}

		return JSON.parse(response);
	});
};
