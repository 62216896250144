// @ts-nocheck
import { Reducer } from "redux";
import { Searcher } from "redux-app/actions/SearcherAction";
import { SearcherState } from "./typing";

const initialState: SearcherState = {
	elearnings: {
		items: [],
		fetching: {
			loading: false,
			loadingBottom: false,
			done: false,
			error: "",
		},
	},
	startups: {
		items: [],
		fetching: {
			loading: false,
			loadingBottom: false,
			done: false,
			error: "",
		},
	},
	talents: {
		items: [],
		fetching: {
			loading: false,
			loadingBottom: false,
			done: false,
			error: "",
		},
	},
	postulate: {
		items: [],
		fetching: {
			loading: false,
			loadingBottom: false,
			done: false,
			error: "",
		},
	},
};

const searcherReducer: Reducer<SearcherState> = (
	state: SearcherState = initialState,
	action: Searcher.SearcherActionTypes,
) => {
	switch (action.type) {
		case Searcher.SEARCHER_GET_ELEARNINGS_STARTED:
			state = {
				...state,
				elearnings: {
					...state.elearnings,
					fetching: {
						...action.payload,
						loadingBottom: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_ELEARNINGS_FINISHED:
			state = {
				...state,
				elearnings: {
					...state.elearnings,
					items: action.payload,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_ELEARNINGS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				elearnings: {
					...state.elearnings,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_TALENTS_STARTED:
			state = {
				...state,
				talents: {
					...state.talents,
					fetching: {
						...action.payload,
						loadingBottom: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_TALENTS_FINISHED:
			state = {
				...state,
				talents: {
					...state.talents,
					items: action.payload,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_TALENTS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				talents: {
					...state.talents,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_STARTUPS_STARTED:
			state = {
				...state,
				startups: {
					...state.startups,
					fetching: {
						...action.payload,
						loadingBottom: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_STARTUPS_FINISHED:
			state = {
				...state,
				startups: {
					...state.startups,
					items: action.payload,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_STARTUPS_FINISHED_WITH_ERRORS:
			state = {
				...state,
				startups: {
					...state.startups,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_POSTULATE_STARTED:
			state = {
				...state,
				postulate: {
					...state.postulate,
					fetching: {
						...action.payload,
						loadingBottom: true,
						done: false,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_POSTULATE_FINISHED:
			state = {
				...state,
				postulate: {
					...state.postulate,
					items: action.payload,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: true,
						error: "",
					},
				},
			};
			break;
		case Searcher.SEARCHER_GET_POSTULATE_FINISHED_WITH_ERRORS:
			state = {
				...state,
				postulate: {
					...state.postulate,
					fetching: {
						loading: false,
						loadingBottom: false,
						done: false,
						error: action.payload,
					},
				},
			};
			break;
	}

	return state;
};

export default searcherReducer;
