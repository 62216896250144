import { PROD_URL } from "api/config";

const URL_SEARCHER_GET_ELEARNINGS = (page: number = 1, parameters?: any) => {
	let completeURL = `${PROD_URL}/elearnings?orderDirection=DESC&page=${page}`;

	for (const filter in parameters) {
		if (parameters[filter]) {
			if (Array.isArray(parameters[filter])) {
				if (parameters[filter].length > 0) {
					completeURL += `&search[${filter}]=[${parameters[filter]}]`;
				}
			} else {
				completeURL += `&search[${filter}]=${parameters[filter]}`;
			}
		}
	}

	return completeURL;
};

const URL_SEARCHER_GET_TALENTS = (page: number = 1, perPage: number = 20, parameters?: any) => {
	let completeURL = `${PROD_URL}/talents?orderDirection=DESC&page=${page}&perPage=${perPage}`;

	for (const filter in parameters) {
		const isAValidNumber = !isNaN(parameters[filter]) && parameters[filter] >= 0;

		if (parameters[filter] || isAValidNumber) {
			if (Array.isArray(parameters[filter])) {
				if (parameters[filter].length > 0) {
					completeURL += `&search[${filter}]=[${parameters[filter]}]`;
				}
			} else {
				completeURL += `&search[${filter}]=${parameters[filter]}`;
			}
		}
	}

	return completeURL;
};

export { URL_SEARCHER_GET_ELEARNINGS, URL_SEARCHER_GET_TALENTS };
