import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../config";

interface Destination {
	id: number;
	name: string;
	slug: string;
}

interface Challenge {
	id: number;
	title: string;
	slug: string;
}

export enum ChallengeSituation {
	DENIED = "DENIED",
	PENDING = "PENDING",
	ACCEPTED = "ACCEPTED",
	FINISHED = "FINISHED",
}

interface ChallengeInvitation {
	id: number;
	destination: Destination;
	challenge: Challenge;
	situation: ChallengeSituation;
}

function fetchChallengeInvitations(challengeSlug: string, accessToken: string) {
	return function () {
		return axios
			.get<ChallengeInvitation[]>(`${PROD_URL}/challenges/${challengeSlug}/invitations`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useChallengeInvitationsQuery = (
	challengeSlug: string,
	options: UseQueryOptions<ChallengeInvitation[]> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<ChallengeInvitation[]>(
		["challenge-invitations", challengeSlug],
		fetchChallengeInvitations(challengeSlug, accessToken),
		{
			...options,
		},
	);
};
