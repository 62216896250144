import RequestModel, { Request } from "models/requests/Request";

export interface ProfileAbout {
	text: React.ReactText;
	fetching?: Request;
}

class ProfileAboutModel implements ProfileAbout {
	text: string;
	fetching?: Request;

	constructor(text: string, fetching?: Request) {
		this.text = text;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		return new ProfileAboutModel(data.description, RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileAboutModel("", RequestModel.requestLoading());
	};
}

export default ProfileAboutModel;
