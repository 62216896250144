import { Translation } from "i18n/config";
import Link from "next/link";
import * as React from "react";
import { LoginTranslation, SignupTranslation } from "./typing";

const LoginTexts: Translation<LoginTranslation> = {
	es: {
		HeroImage: {
			alt: "Vinglet - Semillero de talento y economía del conocimiento",
			title: "Bienvenido a Vinglet",
			summary: "Semillero de talento y economía del conocimiento",
		},
		title: "Inicie sesión",
		description: "Emprende sin riesgo, busca talento, capacítate, válida, vende y capta la atención del inversor.",
		inputEmail: "Email",
		inputPassword: "Contraseña",
		newAccout: () => {
			return (
				<span>
					Me encantaria crear una{" "}
					<Link
						href="/signup"
						passHref
					>
						<a>nueva cuenta</a>
					</Link>
				</span>
			);
		},
		forgotPassword: "Has olvidado tu contraseña?",
		completeFields: "Complete todos los campos antes de continuar.",
	},
	en: {
		HeroImage: {
			alt: "Vinglet - Seedbed of talent and knowledge economy",
			title: "Welcome to Vinglet",
			summary: "Seedbed of talent and knowledge economy",
		},
		title: "Log In",
		description: "Undertake risk-free, search for talent, train, validate, sell and attract the investor's attention.",
		inputEmail: "Email",
		inputPassword: "Password",
		newAccout: () => {
			return (
				<span>
					I would love creating a{" "}
					<Link
						href="/signup"
						passHref
					>
						<a>new account</a>
					</Link>
				</span>
			);
		},
		forgotPassword: "Have you forgotten your password?",
		completeFields: "Please complete all the fields before.",
	},
	ca: {
		HeroImage: {
			alt: "Vinglet - Llavor de talent i economia del coneixement",
			title: "Benvingut a Vinglet",
			summary: "Llavor de talent i economia del coneixement",
		},
		title: "Inicia sessió",
		description: "Emprengui sense riscos, busqui talent, capaciti, validi, vengui i atregui l'atenció de l'inversor.",
		inputEmail: "Email",
		inputPassword: "Clau",
		newAccout: () => {
			return (
				<span>
					M&apos;encantaria crear un{" "}
					<Link
						href="/signup"
						passHref
					>
						<a>nou compte</a>
					</Link>
				</span>
			);
		},
		forgotPassword: "Has olvidado tu contraseña?",
		completeFields: "Completi tots els camps abans de continuar.",
	},
	pt: {
		HeroImage: {
			alt: "Vinglet - Viveiro de talentos e economia do conhecimento",
			title: "Bem-vindo ao Vinglet",
			summary: "Viveiro de talentos e economia do conhecimento",
		},
		title: "Começar sessão",
		description:
			"Empreenda sem arriscar, busque talentos, treine a si mesmo, valide, venda e capture a atenção do investidor.",
		inputEmail: "E-mail",
		inputPassword: "Senha",
		newAccout: () => {
			return (
				<span>
					Gostaria de criar um{" "}
					<Link
						href="/signup"
						passHref
					>
						<a>nova conta</a>
					</Link>
				</span>
			);
		},
		forgotPassword: "Esqueceu sua senha?",
		completeFields: "Preencha todos os campos antes de continuar.",
	},
};

const SignUpTexts: Translation<SignupTranslation> = {
	es: {
		HeroImage: {
			alt: "Vinglet - Semillero de talento y economía del conocimiento",
			title: "Bienvenido a Vinglet",
			summary: "Semillero de talento y economía del conocimiento",
		},
		title: "Regístrate",
		description:
			"Plataforma para personas físicas y jurídicas como Asesores, Empresas, Inversores, Instituciones y Universidades.",
		inputName: "Nombre",
		inputSurname: "Apellido",
		inputEmail: "Email",
		inputPassword: "Contraseña",
		inputTermsAndConditions: () => {
			return (
				<label>
					Estas de acuerdo con los{" "}
					<a
						rel="nofollow noopener noreferrer"
						target="_blank"
						href="https://legal.vinglet.com/TyC.pdf"
					>
						{" "}
						Terminos y Condiciones?{" "}
					</a>
				</label>
			);
		},
		signup: () => {
			return (
				<span>
					Ya poseo una cuenta, quiero{" "}
					<Link
						href="/login"
						passHref
					>
						<a>iniciar sesion.</a>
					</Link>
				</span>
			);
		},
	},
	en: {
		HeroImage: {
			alt: "Vinglet - Seedbed of talent and knowledge economy",
			title: "Welcome to Vinglet",
			summary: "Seedbed of talent and knowledge economy",
		},
		title: "Sign up",
		description:
			"Platform for physical and legal persons as assessors, companies, investors, institutions and universities.",
		inputName: "Name",
		inputSurname: "Surname",
		inputEmail: "Email",
		inputPassword: "Password",
		inputTermsAndConditions: () => {
			return (
				<label>
					Do you agree with{" "}
					<a
						rel="nofollow noopener noreferrer"
						target="_blank"
						href="https://legal.vinglet.com/TyC.pdf"
					>
						{" "}
						Terms and Conditions?{" "}
					</a>
				</label>
			);
		},
		signup: () => {
			return (
				<span>
					I already have an account, I want{" "}
					<Link
						href="/login"
						passHref
					>
						<a>login</a>
					</Link>
				</span>
			);
		},
	},
	ca: {
		HeroImage: {
			alt: "Vinglet - Llavor de talent i economia del coneixement",
			title: "Benvingut a Vinglet",
			summary: "Llavor de talent i economia del coneixement",
		},
		title: "Registra't",
		description:
			"Plataforma per a persones físiques i Jurídiques com a assessors, empreses, inversors, institucions i Universitats.",
		inputName: "Nom",
		inputSurname: "Cognom",
		inputEmail: "E-mail",
		inputPassword: "Clau",
		inputTermsAndConditions: () => {
			return (
				<label>
					Aquestes d&apos;acord amb els{" "}
					<a
						rel="nofollow noopener noreferrer"
						target="_blank"
						href="https://legal.vinglet.com/TyC.pdf"
					>
						{" "}
						termes i condicions?{" "}
					</a>
				</label>
			);
		},
		signup: () => {
			return (
				<span>
					Ja tinc un compte, vull{" "}
					<Link
						href="/login"
						passHref
					>
						<a>iniciar sessió.</a>
					</Link>
				</span>
			);
		},
	},
	pt: {
		HeroImage: {
			alt: "Vinglet - Viveiro de talentos e economia do conhecimento",
			title: "Bem-vindo ao Vinglet",
			summary: "Viveiro de talentos e economia do conhecimento",
		},
		title: "Cadastre-se",
		description:
			"Plataforma para pessoas físicas e jurídicas como Assessores, Empresas, Investidores, Instituições e Universidades.",
		inputName: "Nome",
		inputSurname: "Sobrenome",
		inputEmail: "E-mail",
		inputPassword: "Senha",
		inputTermsAndConditions: () => {
			return (
				<label>
					Você concorda com o{" "}
					<a
						rel="nofollow noopener noreferrer"
						target="_blank"
						href="https://legal.vinglet.com/TyC.pdf"
					>
						{" "}
						Termos e Condições?{" "}
					</a>
				</label>
			);
		},
		signup: () => {
			return (
				<span>
					Já tenho uma conta, quero{" "}
					<Link
						href="/login"
						passHref
					>
						<a>iniciar sessão.</a>
					</Link>
				</span>
			);
		},
	},
};

export { LoginTexts, SignUpTexts };
