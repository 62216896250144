import { TranslationDataUnit } from "i18n/typing";
import RequestModel, { Request } from "models/requests/Request";
import SkillModel from "models/skills/Skill";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

export interface ProfileSkills {
	items: SkillModel[];
	fetching?: Request;
}

class ProfileSkillsModel implements ProfileSkills {
	items: SkillModel[];
	fetching?: Request;

	constructor(items: SkillModel[], fetching?: Request) {
		this.items = items;
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const skillsTranslations: TranslationDataUnit = TranslationsServiceSingleton.getFeatureSkillsTranslations();

		const skills: SkillModel[] =
			data.skills && Array.isArray(data.skills)
				? data.skills
						.filter((item: any) => item && skillsTranslations.items[item.name])
						.map((item: any) => new SkillModel(item.id, item.name, 0, "blue", skillsTranslations.items[item.name]))
				: [];

		return new ProfileSkillsModel(skills, RequestModel.requestDefault());
	}

	static generateFromAPIForAdviser(data: any) {
		return new ProfileSkillsModel([], RequestModel.requestDefault());
	}

	static generateEmpty = () => {
		return new ProfileSkillsModel([], RequestModel.requestLoading());
	};

	exportToAPIRequest = () => {
		return this.items.map((item) => item.id);
	};
}

export default ProfileSkillsModel;
