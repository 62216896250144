import { Children, ReactNode } from "react";

import { useToggle } from "@libs";

import messages from "./messages";
import { FormattedMessage } from "react-intl";

interface ShowMoreToggleProps {
	initialValue: boolean;
	className?: string;
	items: ReactNode[];
}

const MAX_ITEMS_TO_COLLAPSE = 5;

export const ShowMoreToggle = ({ initialValue, className, items }: ShowMoreToggleProps) => {
	const [viewAll, { toggleOn: showMore, toggleOff: showLess }] = useToggle(initialValue);

	const getListContent = () => {
		return (
			<ul className={className}>
				{items
					.filter((_, index) => viewAll || index < MAX_ITEMS_TO_COLLAPSE)
					.map((item: ReactNode, index) => (
						<li key={index}>{item}</li>
					))}
			</ul>
		);
	};

	if (items.length < MAX_ITEMS_TO_COLLAPSE) {
		return <div className="mb-2.5">{getListContent()}</div>;
	}

	return (
		<div>
			{getListContent()}
			{viewAll ? (
				<button
					type="button"
					onClick={showLess}
					className="mt-2 py-2.5 text-sm text-violet-500 underline dark:text-blue-500"
				>
					<FormattedMessage {...messages.showLess} />
				</button>
			) : (
				<button
					type="button"
					onClick={showMore}
					className="mt-2 py-2.5 text-sm text-violet-500 underline dark:text-blue-500"
				>
					<FormattedMessage {...messages.showMore} />
				</button>
			)}
		</div>
	);
};
