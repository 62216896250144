import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Auth } from "redux-app/actions/AuthAction";
import Breakpoints from "stylesheet/breakpoints";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import { HeaderDispatchProps, HeaderProps } from "./typing";
import { maxTablet, minComputer } from "stylesheet/breakpoints/utils";

class Header extends React.PureComponent<HeaderDispatchProps & Omit<HeaderProps, "logout">> {
	render() {
		const { myId, linkToMyProfile, pathname, logout, avatar, businessProfiles, myName, myFullName, myEmblem } =
			this.props;

		return (
			<>
				<Breakpoints maxWidth={maxTablet}>
					<HeaderMobile
						myId={myId}
						myName={myName}
						myFullName={myFullName}
						myEmblem={myEmblem}
						linkToMyProfile={linkToMyProfile}
						avatar={avatar}
						businessProfiles={businessProfiles}
						pathname={pathname}
						logout={logout}
					/>
				</Breakpoints>
				<Breakpoints minWidth={minComputer}>
					<HeaderDesktop
						myId={myId}
						myName={myName}
						myFullName={myFullName}
						myEmblem={myEmblem}
						linkToMyProfile={linkToMyProfile}
						avatar={avatar}
						businessProfiles={businessProfiles}
						pathname={pathname}
						logout={logout}
					/>
				</Breakpoints>
			</>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch): HeaderDispatchProps => {
	return {
		logout: () => {
			dispatch(Auth.logout());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
