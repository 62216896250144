import CategoryModel from "models/categories/Category";
import TalentModel from "models/talents/Talent";
import TalentApplierModel from "models/talents/TalentApplier";

export function convertTalentStateToAPIRequest(talent: TalentModel): any {
	return talent.exportToAPIRequest();
}

export function convertAPIRequestToTalentState(payload: any[]): TalentModel[] {
	return payload.map((item) => TalentModel.generateFromAPI(item));
}

export function createAPIRequestToApplyToTalent(profileSlug: string, documentId: string) {
	return {
		profile_slug: profileSlug,
		document: documentId,
	};
}

export function convertAPIRequestToListAppliersTalent(rawAppliers: any[]): TalentApplierModel[] {
	return rawAppliers.map((rawApplier: any) => TalentApplierModel.generateFromAPI(rawApplier));
}

export function createAPIRequestToChangeStatusToApplier(status: CategoryModel): any {
	return {
		status_id: status.id,
	};
}
