import * as React from "react";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import useDropdownHeaderLinks, {HeaderLink} from "./useDropdownHeaderLinks";
import RightMenu from "./RightMenu/RightMenu";
import RightMenuLoggedUser from "./RightMenuLoggedUser/RightMenuLoggedUser";
import {HeaderProps} from "./typing";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import HeaderLinkItem from "containers/Header/HeaderLinks/HeaderLinkItem";
import HeaderLinkItemWithPointer from "containers/Header/HeaderLinks/HeaderLinkItemWithPointer";
import DropdownHeaderLinkItem from "containers/Header/DropdownHeaderLinkItem";
import Input, {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {HeaderTranslation} from "i18n/containers/Header/typing";
import {HeaderTexts} from "i18n/containers/Header";
import {useRouter} from "next/router";
import DropdownHeaderLinkDisabledItem from "containers/Header/DropdownHeaderLinkDisabledItem";
import Link from "next/link";
import {useNewInvitationsWithoutBeingRead, useNewNotificationsWithoutBeingRead} from "@api";
import {useLocales} from "@libs";
import useHeaderLinks from "./useHeaderLinks";

const Logo = "/logo.png";

const HeaderDesktop = (props: HeaderProps) => {
    const {linkToMyProfile, myId, myName, myEmblem, avatar, businessProfiles, pathname, logout} = props;
    const [isSearcherDropdownOpen, setIsSearcherDropdownOpen] = React.useState(false);
    const [nameForUserSearching, setNameForUserSearching] = React.useState("");
    const router = useRouter();
    const {dropdownHeaderLinks} = useDropdownHeaderLinks();
    const {headerLinks} = useHeaderLinks();
    const {currentShortLocale} = useLocales();
    const headerTranslation: HeaderTranslation = {
        ...HeaderTexts[currentShortLocale],
    };

    const toggleSearcherDropdown = () => setIsSearcherDropdownOpen(!isSearcherDropdownOpen);

    const handleOnChangeUserSearchInput = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setNameForUserSearching(data.value);
    };
    const handleOnClickSearchUser = () => {
        if (router.pathname.includes("/searcher/user/")) {
            router.replace("/searcher/user/" + nameForUserSearching).then(() => location.reload());
        } else {
            router.push("/searcher/user/" + nameForUserSearching);
        }
    };
    const handleOnKeyDownOverSearcherUser = (event: any) => {
        if (event.key === "Enter") {
            handleOnClickSearchUser();
        }
    };

    const {data: newNotifications} = useNewNotificationsWithoutBeingRead(myId, {
        enabled: Boolean(myId),
    });
    const notificationPathname = "/notifications";
    const mustDisplayPointerInsideNotificationLink = Boolean(
        pathname != notificationPathname && newNotifications?.areThereNewNotifications,
    );

    const {data: newInvitations} = useNewInvitationsWithoutBeingRead(myId, {
        enabled: Boolean(myId),
    });
    const myNetworkPathname = "/mynetwork";
    const mustDisplayPointerInsideMyNetworkLink = Boolean(
        pathname != myNetworkPathname && newInvitations?.areThereNewInvitations,
    );

    function mapToProperHeaderLinkItem(item: HeaderLink) {
        switch (item.link) {
            case notificationPathname:
                return (
                    <HeaderLinkItemWithPointer
                        key={item.link}
                        item={item}
                        pathname={pathname}
                        pointer={mustDisplayPointerInsideNotificationLink}
                    />
                );
            case myNetworkPathname:
                return (
                    <HeaderLinkItemWithPointer
                        key={item.link}
                        item={item}
                        pathname={pathname}
                        pointer={mustDisplayPointerInsideMyNetworkLink}
                    />
                );
            default:
                return (
                    <HeaderLinkItem
                        key={item.link}
                        item={item}
                        pathname={pathname}
                    />
                );
        }
    }

    return (
        <Menu
            stackable
            className="Header margin-0-y"
        >
            <Menu.Item className={"padding-0_5-y"}>
                <Link
                    href="/"
                    passHref
                >
                    <a>
                        <Image
                            src={String(Logo)}
                            size="mini"
                        />
                    </a>
                </Link>
            </Menu.Item>
            <Menu.Item className={"padding-0_5-y"}>
                <Button
                    className="Header--button-dropdown-searchers margin-1-right margin-0_5-y"
                    basic
                    onClick={toggleSearcherDropdown}
                >
                    <Icon name={"bars"}/>
                    <p className={"margin-0_5-top"}> {headerTranslation.searchers} </p>
                </Button>
                {isSearcherDropdownOpen && (
                    <div className={"Header--dropdown-searchers"}>
                        {dropdownHeaderLinks.map((item) => {
                            if (item.userMustBeLoggedToUseIt && !myId) {
                                return (
                                    <DropdownHeaderLinkDisabledItem
                                        key={item.link}
                                        item={item}
                                        pathname={pathname}
                                        disabledReason={headerTranslation.youMustBeLoggedToUseIt}
                                    />
                                );
                            }

                            return (
                                <DropdownHeaderLinkItem
                                    key={item.link}
                                    item={item}
                                    pathname={pathname}
                                />
                            );
                        })}
                        {myId && (
                            <div className={"Header--dropdown-searchers-user"}>
                                <Divider className={"margin-0-all"}/>
                                <section className={"padding-1-all"}>
                                    <Input
                                        action={{
                                            icon: "search",
                                            color: "purple",
                                            onClick: handleOnClickSearchUser,
                                        }}
                                        size={"small"}
                                        placeholder={headerTranslation.searchUsers}
                                        onChange={handleOnChangeUserSearchInput}
                                        onKeyDown={handleOnKeyDownOverSearcherUser}
                                        fluid
                                    />
                                </section>
                            </div>
                        )}
                    </div>
                )}
                {linkToMyProfile && headerLinks.map(mapToProperHeaderLinkItem)}
            </Menu.Item>
            {linkToMyProfile ? (
                <RightMenuLoggedUser
                    profileFullName={myName}
                    profileEmblem={myEmblem}
                    profileUrl={linkToMyProfile}
                    profileAvatarSrc={avatar}
                    businessProfiles={businessProfiles}
                    logout={logout}
                />
            ) : (
                <RightMenu/>
            )}
        </Menu>
    );
};

export default React.memo(HeaderDesktop);
