import { PostulateLandscapeCardProps } from "containers/Searcher/ContentSearcher/PostulateLandscapeCard/typing";
import { StartUpLandscapeCardProps } from "containers/Searcher/ContentSearcher/StartUpLandscapeCard/typing";
import ElearningModel from "models/elearnings/Elearning";
import TalentModel from "models/talents/Talent";
import { SearcherFetching, SearcherOptions } from "redux-app/reducers/SearcherReducer/typing";

export namespace Searcher {
	export const SEARCHER_GET_ELEARNINGS = "SEARCHER_GET_ELEARNINGS";
	export const SEARCHER_GET_ELEARNINGS_STARTED = "SEARCHER_GET_ELEARNINGS_STARTED";
	export const SEARCHER_GET_ELEARNINGS_FINISHED = "SEARCHER_GET_ELEARNINGS_FINISHED";
	export const SEARCHER_GET_ELEARNINGS_FINISHED_WITH_ERRORS = "SEARCHER_GET_ELEARNINGS_FINISHED_WITH_ERRORS";

	export const SEARCHER_GET_STARTUPS = "SEARCHER_GET_STARTUPS";
	export const SEARCHER_GET_STARTUPS_STARTED = "SEARCHER_GET_STARTUPS_STARTED";
	export const SEARCHER_GET_STARTUPS_FINISHED = "SEARCHER_GET_STARTUPS_FINISHED";
	export const SEARCHER_GET_STARTUPS_FINISHED_WITH_ERRORS = "SEARCHER_GET_STARTUPS_FINISHED_WITH_ERRORS";

	export const SEARCHER_GET_POSTULATE = "SEARCHER_GET_POSTULATE";
	export const SEARCHER_GET_POSTULATE_STARTED = "SEARCHER_GET_POSTULATE_STARTED";
	export const SEARCHER_GET_POSTULATE_FINISHED = "SEARCHER_GET_POSTULATE_FINISHED";
	export const SEARCHER_GET_POSTULATE_FINISHED_WITH_ERRORS = "SEARCHER_GET_POSTULATE_FINISHED_WITH_ERRORS";

	export const SEARCHER_GET_TALENTS = "SEARCHER_GET_TALENTS";
	export const SEARCHER_GET_TALENTS_STARTED = "SEARCHER_GET_TALENTS_STARTED";
	export const SEARCHER_GET_TALENTS_FINISHED = "SEARCHER_GET_TALENTS_FINISHED";
	export const SEARCHER_GET_TALENTS_FINISHED_WITH_ERRORS = "SEARCHER_GET_TALENTS_FINISHED_WITH_ERRORS";

	interface SearcherGetElearningsAction {
		type: typeof SEARCHER_GET_ELEARNINGS;
		payload: {
			options: SearcherOptions;
			searcherFetching: Pick<SearcherFetching, "loading">;
		};
	}

	interface SearcherGetElearningsStartedAction {
		type: typeof SEARCHER_GET_ELEARNINGS_STARTED;
		payload: Pick<SearcherFetching, "loading">;
	}

	interface SearcherGetElearningsFinishedAction {
		type: typeof SEARCHER_GET_ELEARNINGS_FINISHED;
		payload: ElearningModel[];
	}
	interface SearcherGetElearningsFinishedWithErrorsAction {
		type: typeof SEARCHER_GET_ELEARNINGS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface SearcherGetStartupsAction {
		type: typeof SEARCHER_GET_STARTUPS;
		payload: {
			options: SearcherOptions;
			searcherFetching: Pick<SearcherFetching, "loading">;
		};
	}

	interface SearcherGetStartupsStartedAction {
		type: typeof SEARCHER_GET_STARTUPS_STARTED;
		payload: Pick<SearcherFetching, "loading">;
	}

	interface SearcherGetStartupsFinishedAction {
		type: typeof SEARCHER_GET_STARTUPS_FINISHED;
		payload: StartUpLandscapeCardProps[];
	}
	interface SearcherGetStartupsFinishedWithErrorsAction {
		type: typeof SEARCHER_GET_STARTUPS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface SearcherGetPostulateAction {
		type: typeof SEARCHER_GET_POSTULATE;
		payload: {
			options: SearcherOptions;
			searcherFetching: Pick<SearcherFetching, "loading">;
		};
	}

	interface SearcherGetPostulateStartedAction {
		type: typeof SEARCHER_GET_POSTULATE_STARTED;
		payload: Pick<SearcherFetching, "loading">;
	}

	interface SearcherGetPostulateFinishedAction {
		type: typeof SEARCHER_GET_POSTULATE_FINISHED;
		payload: PostulateLandscapeCardProps[];
	}
	interface SearcherGetPostulateFinishedWithErrorsAction {
		type: typeof SEARCHER_GET_POSTULATE_FINISHED_WITH_ERRORS;
		payload: string;
	}

	interface SearcherGetTalentsAction {
		type: typeof SEARCHER_GET_TALENTS;
		payload: {
			options: SearcherOptions;
			searcherFetching: Pick<SearcherFetching, "loading">;
		};
	}

	interface SearcherGetTalentsStartedAction {
		type: typeof SEARCHER_GET_TALENTS_STARTED;
		payload: Pick<SearcherFetching, "loading">;
	}

	interface SearcherGetTalentsFinishedAction {
		type: typeof SEARCHER_GET_TALENTS_FINISHED;
		payload: TalentModel[];
	}
	interface SearcherGetTalentsFinishedWithErrorsAction {
		type: typeof SEARCHER_GET_TALENTS_FINISHED_WITH_ERRORS;
		payload: string;
	}

	export type SearcherActionTypes =
		| SearcherGetElearningsAction
		| SearcherGetElearningsStartedAction
		| SearcherGetElearningsFinishedAction
		| SearcherGetElearningsFinishedWithErrorsAction
		| SearcherGetStartupsAction
		| SearcherGetStartupsStartedAction
		| SearcherGetStartupsFinishedAction
		| SearcherGetStartupsFinishedWithErrorsAction
		| SearcherGetTalentsAction
		| SearcherGetTalentsStartedAction
		| SearcherGetTalentsFinishedAction
		| SearcherGetTalentsFinishedWithErrorsAction
		| SearcherGetPostulateAction
		| SearcherGetPostulateStartedAction
		| SearcherGetPostulateFinishedAction
		| SearcherGetPostulateFinishedWithErrorsAction;

	export function getElearnings(
		options: SearcherOptions,
		searcherFetching: Pick<SearcherFetching, "loading">,
	): SearcherGetElearningsAction {
		return {
			type: SEARCHER_GET_ELEARNINGS,
			payload: {
				options,
				searcherFetching,
			},
		};
	}

	export function getElearningsStarted(payload: Pick<SearcherFetching, "loading">): SearcherGetElearningsStartedAction {
		return {
			type: SEARCHER_GET_ELEARNINGS_STARTED,
			payload,
		};
	}

	export function getElearningsFinished(cards: ElearningModel[]): SearcherGetElearningsFinishedAction {
		return {
			type: SEARCHER_GET_ELEARNINGS_FINISHED,
			payload: cards,
		};
	}

	export function getElearningsFinishedWithErrors(err: string): SearcherGetElearningsFinishedWithErrorsAction {
		return {
			type: SEARCHER_GET_ELEARNINGS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getStartups(
		options: SearcherOptions,
		searcherFetching: Pick<SearcherFetching, "loading">,
	): SearcherGetStartupsAction {
		return {
			type: SEARCHER_GET_STARTUPS,
			payload: {
				options,
				searcherFetching,
			},
		};
	}

	export function getStartupsStarted(payload: Pick<SearcherFetching, "loading">): SearcherGetStartupsStartedAction {
		return {
			type: SEARCHER_GET_STARTUPS_STARTED,
			payload,
		};
	}

	export function getStartupsFinished(cards: StartUpLandscapeCardProps[]): SearcherGetStartupsFinishedAction {
		return {
			type: SEARCHER_GET_STARTUPS_FINISHED,
			payload: cards,
		};
	}

	export function getStartupsFinishedWithErrors(err: string): SearcherGetStartupsFinishedWithErrorsAction {
		return {
			type: SEARCHER_GET_STARTUPS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getPostulate(
		options: SearcherOptions,
		searcherFetching: Pick<SearcherFetching, "loading">,
	): SearcherGetPostulateAction {
		return {
			type: SEARCHER_GET_POSTULATE,
			payload: {
				options,
				searcherFetching,
			},
		};
	}

	export function getPostulateStarted(payload: Pick<SearcherFetching, "loading">): SearcherGetPostulateStartedAction {
		return {
			type: SEARCHER_GET_POSTULATE_STARTED,
			payload,
		};
	}

	export function getPostulateFinished(cards: PostulateLandscapeCardProps[]): SearcherGetPostulateFinishedAction {
		return {
			type: SEARCHER_GET_POSTULATE_FINISHED,
			payload: cards,
		};
	}

	export function getPostulateFinishedWithErrors(err: string): SearcherGetPostulateFinishedWithErrorsAction {
		return {
			type: SEARCHER_GET_POSTULATE_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}

	export function getTalents(
		options: SearcherOptions,
		searcherFetching: Pick<SearcherFetching, "loading">,
	): SearcherGetTalentsAction {
		return {
			type: SEARCHER_GET_TALENTS,
			payload: {
				options,
				searcherFetching,
			},
		};
	}

	export function getTalentsStarted(payload: Pick<SearcherFetching, "loading">): SearcherGetTalentsStartedAction {
		return {
			type: SEARCHER_GET_TALENTS_STARTED,
			payload,
		};
	}

	export function getTalentsFinished(cards: TalentModel[]): SearcherGetTalentsFinishedAction {
		return {
			type: SEARCHER_GET_TALENTS_FINISHED,
			payload: cards,
		};
	}

	export function getTalentsFinishedWithErrors(err: string): SearcherGetTalentsFinishedWithErrorsAction {
		return {
			type: SEARCHER_GET_TALENTS_FINISHED_WITH_ERRORS,
			payload: err,
		};
	}
}
