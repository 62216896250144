import { CategoryType } from "api/CategoriesAPI/urls";
import CategoryModel from "models/categories/Category";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import CategoriesServiceSingleton from "services/categories/CategoriesService";

export enum ElearningType {
	Course = "Course",
	Degree = "Degree",
	DoctorDegree = "Doctor's Degree",
	Master = "Master",
	PostgraduateDegree = "Postgraduate Degree",
	Webinar = "Webinar",
	Workshop = "Workshop",
}

export enum ElearningTypeColor {
	Course = "blue",
	Degree = "purple",
	DoctorDegree = "yellow",
	Master = "teal",
	PostgraduateDegree = "black",
	Webinar = "orange",
	Workshop = "green",
}

export interface ElearningTypeFullProps {
	name: string;
	color: SemanticCOLORS;
}

export const getCategoryFromElearningType = (type: ElearningType): CategoryModel => {
	const categoriesList = CategoriesServiceSingleton.getList();
	const educations = categoriesList.filterByCategoryType(CategoryType.EDUCATION) as CategoryModel[];
	let response = educations.find((item) => item.key === "webinar");

	switch (type) {
		case ElearningType.Course:
			response = educations.find((item) => item.key.toLowerCase() === "course");
			break;
		case ElearningType.Degree:
			response = educations.find((item) => item.key.toLowerCase() === "doctorado");
			break;
		case ElearningType.DoctorDegree:
			response = educations.find((item) => item.key.toLowerCase() === "doctorado");
			break;
		case ElearningType.Master:
			response = educations.find((item) => item.key.toLowerCase() === "doctorado");
			break;
		case ElearningType.PostgraduateDegree:
			response = educations.find((item) => item.key.toLowerCase() === "posgrado");
			break;
		case ElearningType.Webinar:
			response = educations.find((item) => item.key.toLowerCase() === "webinar");
			break;
		case ElearningType.Workshop:
			response = educations.find((item) => item.key.toLowerCase() === "workshop");
			break;
	}

	return response || CategoryModel.generateEmpty();
};

export const getElearningTypeFromString = (type: string) => {
	let response = ElearningType.Course;

	switch (type.toLowerCase()) {
		case "course":
			response = ElearningType.Course;
			break;
		case "doctorado":
		case "doctor_degree":
			response = ElearningType.DoctorDegree;
			break;
		case "degree":
			response = ElearningType.Degree;
			break;
		case "master":
			response = ElearningType.Master;
			break;
		case "posgrado":
		case "post_graduate_degree":
			response = ElearningType.PostgraduateDegree;
			break;
		case "streaming":
		case "webinar":
			response = ElearningType.Webinar;
			break;
		case "workshop":
			response = ElearningType.Workshop;
			break;
	}

	return response;
};

export const ElearningTypeArray = [
	{
		name: ElearningType.Course,
		color: ElearningTypeColor.Course,
	},
	// {
	// 	name: ElearningType.Degree,
	// 	color: ElearningTypeColor.Degree,
	// },
	// {
	// 	name: ElearningType.DoctorDegree,
	// 	color: ElearningTypeColor.DoctorDegree,
	// },
	// {
	// 	name: ElearningType.Master,
	// 	color: ElearningTypeColor.Master,
	// },
	// {
	// 	name: ElearningType.PostgraduateDegree,
	// 	color: ElearningTypeColor.PostgraduateDegree,
	// },
	{
		name: ElearningType.Webinar,
		color: ElearningTypeColor.Webinar,
	},
	{
		name: ElearningType.Workshop,
		color: ElearningTypeColor.Workshop,
	},
].sort();
