import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL, TenderTalent } from "@api";
import { CookiesUtils } from "@utils";

const deleteTenderTalent = (tenderTalentId: number, config: AxiosRequestConfig) =>
	axios.delete(`${PROD_URL}/challenge-tenders/${tenderTalentId}/talent`, config);

export const useDeleteTenderTalentMutation = (
	tenderId: number,
	{ ...options }: UseMutationOptions<AxiosResponse, AxiosError, number> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();
	const queryClient = useQueryClient();

	return useMutation((tenderTalentId: number) => deleteTenderTalent(tenderTalentId, getRequestConfig(accessToken)), {
		...options,
		onSuccess: async (data, tenderTalentId, context) => {
			const tenderTalents = queryClient.getQueryData<TenderTalent[]>(["tender-talents", tenderId]);

			if (tenderTalents) {
				const newTenderTalentsList = tenderTalents.filter((tenderTalent) => tenderTalent.id !== tenderTalentId);

				queryClient.setQueryData<TenderTalent[]>(["tender-talents", tenderId], newTenderTalentsList);
			}

			await queryClient.invalidateQueries(["tender-talents", tenderId]);
			options.onSuccess && options.onSuccess(data, tenderTalentId, context);
		},
	});
};
