import { Label as FlowbiteLabel } from "flowbite-react";
import { HTMLProps } from "react";

export const InputSlider = ({
	labelValue,
	errorMessage,
	...inputProps
}: HTMLProps<HTMLInputElement> & {
	labelValue?: string;
	errorMessage?: string;
}) => {
	return (
		<>
			{labelValue && (
				<div className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
					<FlowbiteLabel
						htmlFor={inputProps.id}
						value={labelValue}
					/>
				</div>
			)}
			<input
				{...inputProps}
				type="range"
				className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-700"
			/>
			{errorMessage && <span className="text-sm text-red-600"> {errorMessage} </span>}
		</>
	);
};
