import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getRequestConfig, PROD_URL } from "../config";
import { CookiesUtils } from "@utils";

import { UserProfile } from "./types";
import axios from "axios";

function fetchUserProfileLoggedIn(accessToken: string) {
	return function () {
		return axios
			.get(`${PROD_URL}/users-profile-logged-in`, getRequestConfig(accessToken))
			.then((response) => response.data);
	};
}

export const useUserProfileLoggedInQuery = (options: UseQueryOptions<UserProfile, any, UserProfile> = {}) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useQuery<UserProfile>(["current-user-profile-logged-in", accessToken], fetchUserProfileLoggedIn(accessToken), {
		...options,
	});
};
