export interface BusinessProfilePrivileges {
	isAdmin: boolean;
	isCreator: boolean;
}

class BusinessProfilePrivilegesModel implements BusinessProfilePrivileges {
	isAdmin: boolean;
	isCreator: boolean;

	constructor(isAdmin: boolean, isCreator: boolean) {
		this.isAdmin = isAdmin;
		this.isCreator = isCreator;
	}

	static generateFromAPI = (data: { is_admin: boolean; is_creator: boolean }): BusinessProfilePrivilegesModel => {
		return new BusinessProfilePrivilegesModel(data.is_admin, data.is_creator);
	};

	static generateFromObject = (data: BusinessProfilePrivileges): BusinessProfilePrivilegesModel => {
		return new BusinessProfilePrivilegesModel(data.isAdmin, data.isCreator);
	};

	static generateEmpty = (): BusinessProfilePrivilegesModel => {
		return new BusinessProfilePrivilegesModel(false, false);
	};
}

export default BusinessProfilePrivilegesModel;
