import { AnchorHTMLAttributes } from "react";
import {
	Button as FlowbiteButton,
	ButtonProps as FlowbiteButtonProps,
	Spinner as FlowbiteSpinner,
} from "flowbite-react";
import { BusinessProfileTypes } from "constants/Profiles";

type BusinessProfileColorStyle = Record<BusinessProfileTypes, string>;

const businessProfileStyles: BusinessProfileColorStyle = {
	ACCELERATOR: "#00b5ad",
	ADVISER: "#723B13",
	BUSINESS_ANGEL: "#a333c8",
	COMPANY: "#f2711c",
	CONSULTANT: "#723B13",
	INCUBATOR: "#21ba45",
	INSTITUTION: "#fbbd08",
	STARTUP: "#111827",
	UNIVERSITY: "#2185d0",
};

export const Button = ({
	fluid,
	loading,
	businessProfileType,
	children,
	...rest
}: FlowbiteButtonProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & {
		fluid?: boolean;
		loading?: boolean;
		businessProfileType?: BusinessProfileTypes;
	}) => {
	const backgroundCustomColor = businessProfileType ? businessProfileStyles[businessProfileType] : undefined;

	//TODO Due to a bug we have to declare an style object for getting a fluid button -> Flowbite is fixing the bug currently
	return (
		<div>
			<FlowbiteButton
				{...rest}
				disabled={rest.disabled || loading}
				style={{
					width: fluid ? "100%" : "auto",
					backgroundColor: backgroundCustomColor,
				}}
			>
				{loading ? <FlowbiteSpinner color={rest.color} /> : children}
			</FlowbiteButton>
		</div>
	);
};
