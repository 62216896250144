import { BusinessProfile } from "@api";
import { Category } from "@api";

export enum TalentTypes {
	COFOUNDER = "co-founder",
	JOBS = "employee",
	ADVISER = "advisor",
}

type School = Category;
type Type = Category;
type Language = Category;
type Country = Category;
type Skill = Category;
type Sector = Category;
type Role = Category;

export interface TalentChallengeTender {
	id: number;
	title: string;
	profile_image: string;
}

export interface Talent {
	profile: BusinessProfile;
	id: number;
	salary: number;
	min_shares: number;
	max_shares: number;
	min_experience: number;
	is_mine: boolean;
	already_applied: boolean;
	published: boolean;
	title: string;
	description: string;
	city: string;
	salary_currency?: string;
	expiration_date: string;
	slug: string;
	languages: Language[];
	type: Type;
	role: Role;
	country: Country;
	schools: School[];
	skills: Skill[];
	sectors: Sector[];
	challenge_tender?: TalentChallengeTender;
}
