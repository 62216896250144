import {useEffect, useState} from "react";
import {CookiesUtils} from "@utils";

export enum Locales {
    english = "en",
    spanish = "es",
    catalan = "ca",
    portuguese = "pt",
}

export type AvailableLanguages = Record<Locales, boolean>;

export const availableLanguages: AvailableLanguages = {
    ca: true,
    en: true,
    es: true,
    pt: true
}

export const getCurrentLocale = () => {
    const currentLocale = CookiesUtils.getCookie("lang");
    const currentLocalePath = currentLocale ? `/${currentLocale}` : "";

    return {
        currentLocale,
        currentLocalePath,
    };
};

export const useLocales = () => {
    const {currentLocale} = getCurrentLocale();
    const [currentShortLocale, setCurrentShortLocale] = useState<Locales | undefined>(undefined);

    useEffect(() => {
        if (currentLocale && availableLanguages[currentLocale as Locales]) {
            setCurrentShortLocale(currentLocale as Locales)
            CookiesUtils.setCookieForever("lang", currentLocale);
            return;
        }

        const navigatorLanguage = window.navigator?.language;
        const [shortLocale] = navigatorLanguage.split("-") as [Locales];

        if (availableLanguages[shortLocale]) {
            setCurrentShortLocale(shortLocale);
            CookiesUtils.setCookieForever("lang", shortLocale);
        } else {
            setCurrentShortLocale(Locales.spanish);
            CookiesUtils.setCookieForever("lang", Locales.spanish);
        }
    }, [currentLocale]);

    return {
        currentShortLocale: currentShortLocale || Locales.spanish
    };
};
