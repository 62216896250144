import { PROD_URL } from "api/config";

export const URL_VINGLET_UI = "https://ui.vinglet.dev/";
export const URL_VINGLET_UI_PROD = "https://app.vinglet.com/";
export const URL_VINGLET_IMAGE = `${PROD_URL}/images/`;
export const URL_VINGLET_DOCUMENT = `${PROD_URL}/document/`;
export const URL_HTTP = "http://";
export const URL_HTTPS = "https://";

export interface SocialItemProps {
	providerName: string;
	root: string;
	longRoot: string;
	smallRoot: string;
	profileRoot: string;
	profileCompleteUrl: (profileName: string) => string;
}

export interface SocialProps {
	facebook: SocialItemProps;
	twitter: SocialItemProps;
	linkedin: SocialItemProps;
	instagram: SocialItemProps;
	youtube: SocialItemProps;
	website: SocialItemProps;
	meet: SocialItemProps;
	team: SocialItemProps;
	zoom: SocialItemProps;
	trello: SocialItemProps;
}

function getProfileCompleteUrl(profileName: string) {
	// @ts-ignore
	if (profileName.includes(this.smallRoot)) {
		return profileName;
	}

	// @ts-ignore
	return this.profileRoot + profileName;
}

export function getSocial(): SocialProps {
	return {
		facebook: {
			providerName: "Facebook",
			root: "https://facebook.com/",
			longRoot: "https://www.facebook.com/",
			smallRoot: "facebook.com",
			profileRoot: "https://facebook.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		twitter: {
			providerName: "Twitter",
			root: "https://twitter.com/",
			longRoot: "https://www.twitter.com/",
			smallRoot: "twitter.com",
			profileRoot: "https://twitter.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		linkedin: {
			providerName: "Linkedin",
			root: "https://linkedin.com/",
			longRoot: "https://www.linkedin.com/",
			smallRoot: "linkedin.com",
			profileRoot: "https://linkedin.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		instagram: {
			providerName: "Instagram",
			root: "https://instagram.com/",
			longRoot: "https://www.instagram.com/",
			smallRoot: "instagram.com",
			profileRoot: "https://instagram.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		youtube: {
			providerName: "Youtube",
			root: "https://youtube.com/",
			longRoot: "https://www.youtube.com/",
			smallRoot: "youtube.com",
			profileRoot: "https://youtube.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		website: {
			providerName: "Website",
			root: "https://www.",
			longRoot: "https://www.",
			smallRoot: "www.",
			profileRoot: "https://www.",
			profileCompleteUrl: function (profileName: string) {
				return this.profileRoot + profileName;
			},
		},
		meet: {
			providerName: "Meet",
			root: "https://meet.google.com/",
			longRoot: "https://meet.google.com/",
			smallRoot: "meet.google.com",
			profileRoot: "https://meet.google.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		team: {
			providerName: "Team",
			root: "https://teams.microsoft.com/",
			longRoot: "https://teams.microsoft.com/",
			smallRoot: "teams.microsoft.com",
			profileRoot: "https://teams.microsoft.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		zoom: {
			providerName: "Zoom",
			root: "https://zoom.us/",
			longRoot: "https://www.zoom.us/",
			smallRoot: "zoom.us",
			profileRoot: "https://zoom.us/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
		trello: {
			providerName: "Trello",
			root: "https://trello.com/",
			longRoot: "https://www.trello.com/",
			smallRoot: "trello.com",
			profileRoot: "https://trello.com/",
			profileCompleteUrl: getProfileCompleteUrl,
		},
	};
}
