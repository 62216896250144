import { defineMessages } from "react-intl";

export default defineMessages({
	showMore: {
		defaultMessage: "Show more",
	},
	showLess: {
		defaultMessage: "Show less",
	},
});
