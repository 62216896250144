import RequestModel, { Request } from "models/requests/Request";
import Social from "models/social-networks/Social";

export type BusinessProfileSocial = Social & {
	fetching?: Request;
};

class BusinessProfileSocialModel extends Social implements BusinessProfileSocial {
	fetching?: Request;

	constructor(
		facebook: string,
		twitter: string,
		linkedin: string,
		instagram: string,
		youtube: string,
		fetching?: Request,
	) {
		super(facebook, twitter, linkedin, instagram, youtube);
		this.fetching = fetching;
	}

	static generateFromAPI(data: any) {
		const socials = {
			facebook: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === "facebook")
				: "",
			twitter: data.social_networks ? data.social_networks.find((item: any) => item.social_network === "twitter") : "",
			linkedin: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === "linkedin")
				: "",
			instagram: data.social_networks
				? data.social_networks.find((item: any) => item.social_network === "instagram")
				: "",
			youtube: data.social_networks ? data.social_networks.find((item: any) => item.social_network === "youtube") : "",
		};

		return new BusinessProfileSocialModel(
			socials.facebook && socials.facebook.url,
			socials.twitter && socials.twitter.url,
			socials.linkedin && socials.linkedin.url,
			socials.instagram && socials.instagram.url,
			socials.youtube && socials.youtube.url,
			RequestModel.requestDefault(),
		);
	}

	static generateFromObject = (data: BusinessProfileSocial): BusinessProfileSocialModel => {
		return new BusinessProfileSocialModel(
			data.facebook,
			data.twitter,
			data.linkedin,
			data.instagram,
			data.youtube,
			data.fetching,
		);
	};

	static generateEmpty = (): BusinessProfileSocialModel => {
		return new BusinessProfileSocialModel("", "", "", "", "");
	};
}

export default BusinessProfileSocialModel;
