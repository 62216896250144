import { CategoryType } from "api/CategoriesAPI/urls";
import CategoryModel from "models/categories/Category";
import ProfileModel from "models/profiles/Profile";
import ProfileInfoModel from "models/profiles/ProfileInfo";
import CategoriesServiceSingleton from "services/categories/CategoriesService";

export interface TalentApplier {
	id: number;
	documentId: string;
	// note: null
	// ranking: 0
	status: CategoryModel;
	user: ProfileModel;
}

class TalentApplierModel implements TalentApplier {
	id: number;
	documentId: string;
	status: CategoryModel;
	user: ProfileModel;

	constructor(id: number, documentId: string, status: CategoryModel, user: ProfileModel) {
		this.id = id;
		this.documentId = documentId;
		this.status = status;
		this.user = user;
	}

	exportToAPIRequest = () => {};

	static generateFromAPI(data: any = {}): TalentApplierModel {
		const lastStatus = [...data.status].pop();
		const categoryId = lastStatus ? lastStatus.category_id : "new";
		let categories: CategoryModel[] = CategoriesServiceSingleton.getList().filterByIdsAndCategoryType(
			[categoryId],
			CategoryType.APPLIER_STATUS,
		) as CategoryModel[];

		if (!categories[0]) {
			categories = CategoriesServiceSingleton.getList().filterByKeyAndCategoryType(
				["new"],
				CategoryType.APPLIER_STATUS,
			) as CategoryModel[];
		}

		const profile = ProfileModel.generateEmpty();
		const profileInfo = new ProfileInfoModel(data.user.name, "", "", data.user.profile_image);
		profile.id = String(data.user.id);
		profile.setInfo = profileInfo;
		profile.setLinks = {
			link: data.user.slug,
		};
		profile.setEmail = data.user.email;

		return new TalentApplierModel(data.id, data.document, categories[0], profile);
	}

	static generateEmpty(): TalentApplierModel {
		return new TalentApplierModel(0, "", CategoryModel.generateEmpty(), ProfileModel.generateEmpty());
	}

	static generateFromObject(applierObject: TalentApplier) {
		return new TalentApplierModel(
			applierObject.id,
			applierObject.documentId,
			applierObject.status,
			ProfileModel.generateFromObject(applierObject.user),
		);
	}
}

export default TalentApplierModel;
