import {
	URL_DELETE_USER_ACCOUNT,
	URL_FORGOT_PASSWORD,
	URL_GET_USERS_BY_FULL_NAME,
	URL_RESET_PASSWORD,
	URL_USER_DATA,
} from "./urls";

import {
	defaultError,
	defaultSuccess,
	getDeleteHeaderWithCredentials,
	getGetHeaderWithCredentials,
	getPostHeader,
	getPostHeaderWithCredentials,
} from "api/config";

export function getUserData(accessToken: string) {
	return function () {
		return fetch(URL_USER_DATA(), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function forgotPassword(email: string) {
	return function () {
		return fetch(URL_FORGOT_PASSWORD(), getPostHeader({ email }))
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				}

				return defaultSuccess();
			})
			.catch(() => defaultError());
	};
}

export function changePassword(oldPassword: string, newPassword: string, accessToken: string) {
	return function () {
		return fetch(
			URL_RESET_PASSWORD(),
			getPostHeaderWithCredentials(
				{
					["old_password"]: oldPassword,
					password: newPassword,
					["new_password"]: newPassword,
				},
				accessToken,
			),
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				}

				return defaultSuccess();
			})
			.catch(() => defaultError());
	};
}

export function resetPassword(token: string, password: string, newPassword: string) {
	return function () {
		return fetch(
			URL_RESET_PASSWORD(),
			getPostHeader({
				token,
				password,
				["new_password"]: newPassword,
			}),
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				}

				return defaultSuccess();
			})
			.catch(() => defaultError());
	};
}

export function deleteUserAccountAPI(accessToken: string) {
	return fetch(URL_DELETE_USER_ACCOUNT(), getDeleteHeaderWithCredentials(accessToken))
		.then((response) => {
			if (response.status >= 400) {
				throw new Error();
			}

			return defaultSuccess();
		})
		.catch(() => defaultError());
}

export function getUsersByFullNameAPI(
	fullNameToSearch: string,
	accessToken: string,
	page: number = 1,
	itemsPerPage: number = 20,
) {
	return function () {
		return fetch(
			URL_GET_USERS_BY_FULL_NAME(fullNameToSearch, page, itemsPerPage),
			getGetHeaderWithCredentials(accessToken),
		)
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}
