import axios from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import queryString from "query-string";

import { Category, getRequestConfig, PROD_URL, TalentTypes } from "@api";
import { getStringifiedList } from "@libs";

import { UserProfile } from "../../users/types";

type Country = Category;
type Language = Category;
type Type = Category;
type Region = Category;
type AdviserCategory = Category;

export interface Adviser {
	profile: UserProfile;
	id: number;
	title: string;
	description: string;
	country: Country;
	type: Type;
	categories: AdviserCategory[];
	regions: Region[];
	languages: Language[];
	cost: number;
	cost_currency: string;
	percentage: number;
	stars: number;
	is_mine: boolean;
}

interface FetchAdvisersRequestAverageEquityParams {
	"search[min_averageEquity]": number | undefined;
	"search[max_averageEquity]": number | undefined;
}

interface FetchAdvisersRequestCostPerHourParams {
	"search[min_costPerHour]": number | undefined;
	"search[max_costPerHour]": number | undefined;
}

export interface FetchAdvisersRequestParams {
	page: number;
	perPage?: number;
	orderDirection?: "DESC" | "ASC";
	"search[type]"?: number;
	"search[regions]"?: number[];
	"search[languages]"?: number[];
	averageEquity?: FetchAdvisersRequestAverageEquityParams;
	costPerHour?: FetchAdvisersRequestCostPerHourParams;
}

export interface FetchAdvisersResponse {
	total: number;
	page: number;
	num_pages: number;
	items: Adviser[];
}

export const fetchAdvisers = (queryParams: FetchAdvisersRequestParams) => {
	const params = {
		page: queryParams.page,
		perPage: queryParams.perPage,
		orderDirection: queryParams.orderDirection,
		"search[type]": queryParams["search[type]"] || "",
		"search[regions]": getStringifiedList(queryParams["search[regions]"]),
		"search[languages]": getStringifiedList(queryParams["search[languages]"]),
		"search[min_averageEquity]": queryParams.averageEquity?.["search[min_averageEquity]"] || "",
		"search[max_averageEquity]": queryParams.averageEquity?.["search[max_averageEquity]"] || "",
		"search[min_costPerHour]": queryParams.costPerHour?.["search[min_costPerHour]"] || "",
		"search[max_costPerHour]": queryParams.costPerHour?.["search[max_costPerHour]"] || "",
	};

	return function (): Promise<FetchAdvisersResponse> {
		return axios
			.get(`${PROD_URL}/talents`, {
				paramsSerializer: {
					serialize: (params: Record<string, any>) =>
						queryString.stringify(params, {
							skipEmptyString: true,
						}),
				},
				params,
				...getRequestConfig(),
			})
			.then((response) => response.data);
	};
};

export const useAdvisersQuery = (
	queryParams: FetchAdvisersRequestParams,
	options: UseQueryOptions<FetchAdvisersResponse, any, FetchAdvisersResponse> = {},
) => {
	return useQuery<FetchAdvisersResponse>(["advisers", queryParams], fetchAdvisers(queryParams), {
		...options,
	});
};
