export const DatesEn = `
{
    "days": {
        "key": {
            "singular": "Day",
            "plural": "Days"
        },
        "items": {
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        }
    },
    "months": {
        "key": {
            "singular": "Month",
            "plural": "Months"
        },
        "items": {
            "january": "January",
            "february": "February",
            "march": "March",
            "april": "April",
            "may": "May",
            "june": "June",
            "july": "July",
            "august": "August",
            "september": "September",
            "october": "October",
            "november": "November",
            "december": "December"
        }
    }
}
`;
