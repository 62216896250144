import { ReactNode } from "react";

import Link from "next/link";
import classNames from "classnames";

interface InternalLinkProps {
	href: string;
	className?: string;
	children: ReactNode;
}

export const InternalLink = ({ href, className, children }: InternalLinkProps) => {
	return (
		<Link
			href={href}
			passHref
		>
			<a className={classNames(className)}>{children}</a>
		</Link>
	);
};
