import React from "react";

import { useLocales } from "@libs";
import { VingletLoader } from "@common";
import { useFetchCategoriesQuery } from "@api";

import CategoryModel from "models/categories/Category";
import CategoriesServiceInstance from "services/categories/CategoriesService";

import { CategoriesContext } from "./CategoriesContext";

export const CategoriesProvider = ({ children }: { children: JSX.Element }) => {
	const { currentShortLocale } = useLocales();
	const { data: categories, isLoading } = useFetchCategoriesQuery(currentShortLocale, {
		staleTime: 7 * 24 * 60 * 60 * 1000,
		cacheTime: 7 * 24 * 60 * 60 * 1000,
	});

	if (isLoading || !categories) {
		return <VingletLoader />;
	}

	const categoriesModels = categories.items.map((category) => CategoryModel.generateFromAPI(category));

	//TODO Implement categories and analytics by using the Provider's API from React.
	// Init categories' service on backend
	CategoriesServiceInstance.setCategoriesToList(categoriesModels);
	CategoriesServiceInstance.initAllTranslations();

	return (
		<CategoriesContext.Provider
			value={{
				categories: [...categoriesModels],
			}}
		>
			{children}
		</CategoriesContext.Provider>
	);
};
