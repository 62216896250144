import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { CookiesUtils } from "@utils";

import { getRequestConfig, PROD_URL } from "../../config";
import { BusinessProfileTypes, GenericBusinessProfilePayload } from "../types";

type CompanyProfilePayload = Omit<GenericBusinessProfilePayload, "company_type" | "type">;

function createCompany(data: CompanyProfilePayload, config: AxiosRequestConfig) {
	return axios
		.post(
			`${PROD_URL}/companies-profiles`,
			{
				...data,
				company_type: BusinessProfileTypes.COMPANY,
				type: BusinessProfileTypes.COMPANY,
			},
			config,
		)
		.then((response) => {
			return response.status;
		});
}

export const useCreateCompanyMutation = (
	options: UseMutationOptions<number, AxiosError, CompanyProfilePayload> = {},
) => {
	const accessToken = CookiesUtils.getAccessTokenFromCookie();

	return useMutation((data: CompanyProfilePayload) => createCompany(data, getRequestConfig(accessToken)), {
		...options,
	});
};
