import { BusinessProfileTypes } from "constants/Profiles";
import { PROD_URL } from "api/config";
import { URLBuilder } from "../URLBuilder";

// INSTITUTION
const URL_GET_INSTITUTION_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
const URL_PATCH_INSTITUTION_PROFILE = (slug: string) => `${PROD_URL}/institutions-profile/${slug}`;
const URL_PATCH_ACCEPT_INSTITUTION_AGREEMENT = (slug: string) => `${PROD_URL}/institution-profile/${slug}/agreement`;
// ADVISER
const URL_GET_ADVISER_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
const URL_POST_ADVISER_PROFILE = `${PROD_URL}/consultants-profile`;
const URL_GET_ADVISER_PROFILE_BY_NAME = (name: string) => `${PROD_URL}/consultant-profile/by-name?name=${name}`;
const URL_GET_ADVISER_ADVISED_CHALLENGES = (adviserSlug: string) =>
	`${PROD_URL}/consultant-profile/${adviserSlug}/challenges`;
const URL_GET_ADVISER_TENDERS = (adviserSlug: string) =>
	`${PROD_URL}/consultant-profile/${adviserSlug}/challenge-tenders`;
const URL_PATCH_ADVISER_PROFILE = (slug: string) => `${PROD_URL}/consultants-profile/${slug}`;
// UNIVERSITY
const URL_GET_UNIVERSITY_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
const URL_PATCH_UNIVERSITY_PROFILE = (slug: string) => `${PROD_URL}/institutions-profile/${slug}`;
// INVESTOR
const URL_PATCH_INVESTOR_PROFILE = (slug: string) => `${PROD_URL}/investors-profiles/${slug}`;
const URL_GET_INVESTOR_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;
// COMPANY
const URL_PATCH_COMPANY_PROFILE = (slug: string) => `${PROD_URL}/companies-profiles/${slug}`;
const URL_GET_COMPANY_PROFILE = (slug: string) => `${PROD_URL}/profiles/${slug}`;

// GET ALL
const URL_GET_ALL_COMPANY_PROFILES = (
	type: BusinessProfileTypes,
	markets: string[] = [],
	regions: string[] = [],
	page: number = 1,
	perPage: number = 20,
) => {
	let completeURL = `${PROD_URL}/companies-profiles?search[company_type]=${BusinessProfileTypes.COMPANY}`;

	if (type === BusinessProfileTypes.STARTUP) {
		completeURL = `${PROD_URL}/companies-profiles?search[company_type]=${BusinessProfileTypes.STARTUP}`;

		if (markets && markets.length > 0) {
			completeURL += `&search[markets]=[${markets}]`;
		}

		if (regions && regions.length > 0) {
			completeURL += `&search[regions]=[${regions}]`;
		}
	}

	completeURL += `&page=${page}&perPage=${perPage}`;

	return completeURL;
};
const URL_GET_ALL_UNIVERSITIES_PROFILES = (page: number = 1, parameters?: any) => {
	return new URLBuilder(`${PROD_URL}/profiles?search[type]=UNIVERSITY&page=${page}`)
		.withSearchParameters(parameters)
		.build();
};
const URL_GET_ALL_INSTITUTIONS_PROFILES = (page: number = 1, parameters?: any) => {
	return new URLBuilder(`${PROD_URL}/profiles?search[type]=INSTITUTION&page=${page}`)
		.withSearchParameters(parameters)
		.build();
};
const URL_GET_ALL_ACCELERATORS_PROFILES = (page: number = 1, parameters?: any) => {
	return new URLBuilder(`${PROD_URL}/profiles?search[type]=ACCELERATOR&page=${page}`)
		.withSearchParameters(parameters)
		.build();
};
const URL_GET_ALL_INCUBATORS_PROFILES = (page: number = 1, parameters?: any) => {
	return new URLBuilder(`${PROD_URL}/profiles?search[type]=INCUBATOR&page=${page}`)
		.withSearchParameters(parameters)
		.build();
};
const URL_GET_ALL_INVESTOR_PROFILES = (page: number = 1, type: BusinessProfileTypes, parameters?: any) => {
	let completeURL = `${PROD_URL}/investors-profiles?page=${page}`;

	if (type) {
		completeURL += `&search[investor_type]=${type}`;
	}

	return new URLBuilder(completeURL).withSearchParameters(parameters).build();
};

// BUSINESS PROFILES' ADMINS
const URL_POST_ADD_ADMIN_TO_BUSINESS_PROFILE = (profileSlug: string, consultantProfileSlug: string) =>
	new URLBuilder(`${PROD_URL}/profiles/${profileSlug}/consultant-profile/${consultantProfileSlug}`).build();
const URL_DELETE_ADMIN_FROM_BUSINESS_PROFILE = (profileSlug: string, consultantProfileSlug: string) =>
	new URLBuilder(`${PROD_URL}/profiles/${profileSlug}/consultant-profile/${consultantProfileSlug}`).build();
const URL_GET_ALL_ADMINS_FROM_BUSINESS_PROFILE = (profileSlug: string) =>
	new URLBuilder(`${PROD_URL}/profiles/${profileSlug}/available-users`).build();

export {
	URL_GET_INSTITUTION_PROFILE,
	URL_GET_ALL_INSTITUTIONS_PROFILES,
	URL_PATCH_INSTITUTION_PROFILE,
	URL_PATCH_ACCEPT_INSTITUTION_AGREEMENT,
	URL_POST_ADVISER_PROFILE,
	URL_GET_ADVISER_PROFILE_BY_NAME,
	URL_GET_ADVISER_ADVISED_CHALLENGES,
	URL_GET_ADVISER_TENDERS,
	URL_GET_ADVISER_PROFILE,
	URL_PATCH_ADVISER_PROFILE,
	URL_GET_UNIVERSITY_PROFILE,
	URL_GET_ALL_UNIVERSITIES_PROFILES,
	URL_PATCH_UNIVERSITY_PROFILE,
	URL_PATCH_INVESTOR_PROFILE,
	URL_GET_ALL_INVESTOR_PROFILES,
	URL_GET_ALL_ACCELERATORS_PROFILES,
	URL_GET_ALL_INCUBATORS_PROFILES,
	URL_GET_INVESTOR_PROFILE,
	URL_PATCH_COMPANY_PROFILE,
	URL_GET_ALL_COMPANY_PROFILES,
	URL_GET_COMPANY_PROFILE,
	URL_POST_ADD_ADMIN_TO_BUSINESS_PROFILE,
	URL_DELETE_ADMIN_FROM_BUSINESS_PROFILE,
	URL_GET_ALL_ADMINS_FROM_BUSINESS_PROFILE,
};
